
import KCard from '@/components/KCard.vue';
import {
	Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import { General } from '@/services/types/loan/Loan';
import { RecurringPayment } from '@/services/types/loan/RecurringPayment';
import { Formatters } from '@/utils/formatters';

@Component({
	components: {
		KCard,
		KReadOnly,
	},
})
export default class OverviewTermsCard extends Vue {
	@Prop({ required: true })
	data!: RecurringPayment;

	@Prop({ required: true })
	general!: General;

	@Inject('format')
	format!: Formatters;
}
