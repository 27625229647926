import { Component, Prop } from 'vue-property-decorator';
import {
	MenuHeading,
	MenuItem,
	NoticeClass,
} from '@kasasa/fbase-components/lib';
import BaseConsumerPage from '@/pages/consumer/BaseConsumerPage';
import { Loan, LoanType } from '@/services/types/loan/Loan';
import { Bankruptcy, isActiveBankruptcy } from '@/services/types/loan/Bankruptcy';
import { Consumer } from '@/services/ConsumerService';
import { ExtendedBankAccount } from '@/services/types/BankAccount';
import { Borrower } from '@/services/types/loan/Borrower';
import { namespace } from 'vuex-class';
import { handleErrorResponse } from '@/utils/xhr';
import {
	KLOANS_COLLECTIONS,
	KLOANS_MAINTENANCE,
	KLOANS_NOTES, VKLOANS,
} from '@/pages/PermissionGroups';
import FeatureFlagSet from '@/services/types/FeatureFlagSet';

const loanStoreModule = namespace('loan');
const bankAccountStoreModule = namespace('bankAccount');
const featureFlagsStoreModule = namespace('featuresFlag');

@Component
export default class BaseLoanPage extends BaseConsumerPage {
	@Prop()
	loanId!: string;

	consumer: Consumer | null = null;

	bankruptcies: Bankruptcy[] = [];

	@loanStoreModule.Getter('getLoan')
	loan!: Loan;

	@loanStoreModule.Mutation('setLoan')
	setLoan!: (loan: Loan) => void;

	@bankAccountStoreModule.Getter('getBankAccounts')
	bankAccounts!: ExtendedBankAccount[];

	@bankAccountStoreModule.Mutation('setBankAccounts')
	setBankAccounts!: (accounts: ExtendedBankAccount[]) => void;

	@featureFlagsStoreModule.Getter('featureFlags')
	featureFlags!: FeatureFlagSet | null;

	@featureFlagsStoreModule.Action('fetchAndSetFeatureFlags')
	fetchAndSetFeatureFlags!: (fiId: string) => Promise<void>;

	async created(): Promise<void> {
		await this.fetchAndSetLoan();
		this.setMenuItems(this.makeMenuItems());
		await this.fetchFeaturesFlag();
	}

	async fetchFeaturesFlag(): Promise<void> {
		if (this.featureFlags) {
			return;
		}
		try {
			await this.fetchAndSetFeatureFlags(this.fiId);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		}
	}

	async getBankAccounts(): Promise<void> {
		if (this.bankAccounts.length) {
			return;
		}

		try {
			const response = await this.consumerSvc.getBankAccounts(
				this.consumerId,
				this.fiId,
			);

			this.setBankAccounts(response.data.data);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		}
	}

	async getBankruptcies(force: boolean = false): Promise<Bankruptcy[] | null> {
		if (force || this.bankruptcies.length === 0) {
			try {
				const response = await this.loanSvc.getBankruptcies(this.loanId);
				this.bankruptcies = response.data.data;
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			}
		}
		return this.bankruptcies;
	}

	// chapter 7: discharged, dismissed, withdrawn, reaffirmation of debt, closed/terminated
	// chapter 13: dismissed, withdrawn, closed/terminated
	get hasActiveBankruptcy(): boolean {
		return (this.bankruptcies || []).some(isActiveBankruptcy);
	}

	get canModifyLoanMaintenance(): boolean {
		return this.canModifyLoan && this.canWrite(KLOANS_MAINTENANCE) && this.canRead(KLOANS_MAINTENANCE);
	}

	get canReadLoan(): boolean {
		return this.canRead(VKLOANS);
	}

	get canManageCollectionNotes(): boolean {
		return this.canRead(KLOANS_NOTES) || this.canWrite(KLOANS_NOTES);
	}

	loadingBorrowers: boolean = true;

	borrowers: Borrower[] = [];

	async getBorrowers(force: boolean = false, excludeAddresses: boolean = false): Promise<Borrower[]> {
		if (force || this.borrowers.length === 0) {
			try {
				this.loadingBorrowers = true;
				const response = await this.loanSvc.getBorrowers(this.fiId, this.loanId, excludeAddresses);
				// Our api returns the primary borrower last. Reversing the array to display it first.
				this.borrowers = response.data.data.reverse();
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			} finally {
				this.loadingBorrowers = false;
			}
		}

		return this.borrowers;
	}

	async fetchAndSetLoan(force: boolean = false): Promise<void> {
		if (this.loan.general && !force) {
			return;
		}

		try {
			const loan: Loan = await this.loanSvc.getLoan(
				this.consumerId,
				this.loanId,
			);

			this.setLoan(loan);
		} catch (error) {
			await handleErrorResponse((error as any).response, this);
		}

		if (!this.loan) {
			this.showAlert('Error loading loan details.', NoticeClass.ERROR);
			throw new Error('Missing loan details.');
		}
	}

	async getConsumer(force: boolean = false): Promise<Consumer | null> {
		if (force || this.consumer === null) {
			try {
				const responseConsumer = await this.consumerSvc.getConsumer(this.fiId, this.consumerId);
				this.consumer = responseConsumer.data.data;
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			}
		}

		return this.consumer;
	}

	getConsumersName(): string {
		if (this.consumer && this.consumer.name) {
			// Lowercase the name because we use CSS to correct it.
			return this.consumer.name.toLowerCase();
		}

		return 'MIA';
	}

	getLoanName(): string {
		if (this.loan && this.loan.general.description) {
			return this.loan.general.description;
		}

		return 'Unknown Loan';
	}

	// Shared menus for loan pages.
	menuItems: (MenuItem | MenuHeading)[] = this.makeMenuItems();

	makeMenuItems(): MenuItem[] {
		let items: { label: string, routeName: string }[] = [
			{ label: 'Overview', routeName: 'loan-overview' },
			{ label: 'Transactions', routeName: 'loan-transactions' },
			{ label: 'Statements', routeName: 'loan-statements' },
			{ label: 'Fees', routeName: 'loan-fees' },
			{ label: 'Collections', routeName: 'loan-collections' },
			{ label: 'Borrowers', routeName: 'loan-borrowers' },
			{ label: 'Loan Maintenance', routeName: 'loan-maintenance' },
			{ label: 'Payment Schedule', routeName: 'loan-payment-schedule' },
			{ label: 'Comments', routeName: 'loan-comments' },
			{ label: 'Audit Log', routeName: 'loan-audit-log' },
		];

		// Show collateral menu option for auto loans only
		if (this.loan?.general?.loanTypeId === LoanType.VEHICLE) {
			items.splice(6, 0, { label: 'Collateral', routeName: 'loan-collateral' });
		}

		if (!this.canModifyLoan || !this.canRead(KLOANS_COLLECTIONS)) {
			items = items.filter((group) => group.label !== 'Collections');
		}

		if (!this.canModifyLoan && !this.canRead(KLOANS_MAINTENANCE)) {
			items = items.filter((group) => group.label !== 'Loan Maintenance');
		}

		return items.map((item) => ({
			icon: '',
			label: item.label,
			to: {
				name: item.routeName,
				params: {
					clientId: this.clientId,
					consumerId: this.consumerId,
					loanId: this.loanId,
				},
			},
		}));
	}
}
