import {
	Action, Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import FeatureFlagSet from '@/services/types/FeatureFlagSet';
import FeatureService from '@/services/FeatureService';
import { Store } from 'vuex';

export interface FeatureFlagState {
	featureFlag: FeatureFlagSet;
  }

@Module({
	namespaced: true,
})
export default class featureFlag extends VuexModule {
	private featureFlag: FeatureFlagSet | null = null;

	@Mutation
	setFeaturesFlag(features: FeatureFlagSet): void {
		this.featureFlag = features;
	}

	@Action
	async fetchAndSetFeatureFlags(fiId: string, store: Store<Record<string, unknown>>): Promise<void> {
		const featureService = new FeatureService(store);
		const fiFeatureFlags = await featureService.getFeatures(fiId);
		this.context.commit('setFeaturesFlag', fiFeatureFlags);
	}

	get featureFlags(): FeatureFlagSet | null {
		return this.featureFlag;
	}
}
