import { RecurringPayment } from '@/services/types/loan/RecurringPayment';
import { Metadata } from '@/services/types/loan/Metadata';

export enum LoanStatus {
	PENDING = 'pending',
	ACTIVE = 'active',
	HOLD = 'hold',
	CLOSED = 'closed',
	PAID = 'paid',
	REBUILDING = 'rebuilding',
}

export enum LoanType {
	PERSONAL = 1,
	VEHICLE = 2,
	MORTGAGE = 3,
	STUDENT_LOAN = 4,
	SECURE_PERSONAL = 5,
	BUSINESS = 6,
	INTRODUCTORY_PERIOD_LOAN = 7,
}

export interface General {
	accruedInterest: string;
	availableToWithdraw: string;
	balance: string;
	branch?: string;
	coreTrackingRecordNumber: string;
	dailyInterest: string;
	delinquentDays: number;
	delinquentSinceDate: string;
	description: string;
	extendedStatus: string;
	fiId: number;
	gracePeriod: number;
	hasDeclinedAutomaticPayments: boolean;
	installmentAmount: string;
	isScra: boolean;
	kasasaLoanNumber: string;
	lastStatusChangeDate?: any;
	loanApplicationNumber: string;
	loanAssetStructure: string;
	loanNumber: string;
	loanOfficer?: string;
	loanTypeId: number | LoanType;
	maturityDate: string;
	outstandingFees: string;
	outstandingLateFees: string;
	outstandingReturnedItemFees: string;
	pastDueAmount: string;
	paymentDueAmount: string;
	paymentDueDate: string;
	payoffAmount: string;
	productId: string;
	remainingInstallmentDue: string;
	status: string|LoanStatus;
	takeBackBalance?: string;
	takeBackDailyInterest?: string;
	takeBacksEnabled: boolean;
	tenDayPayoffAmount: string;
	today: string;
	totalDueAmount: string;
	chargedOffBalance: string;
	netChargedOffBalance: string;
	recoveryAmount: string;
}

export interface Terms {
	firstPaymentDate: string;
	initialPrincipal: string;
	interestRate: string;
	paymentDayOfMonth: number;
	startDate: string;
	termInMonths: number;
}

export interface PrimaryTerms {
	accruedInterest: string;
	dailyInterestAmount: string;
	dailyInterestRate: string;
	endDate: string;
	firstPaymentDate: string;
	initialPrincipal: string;
	interestRate: string;
	originalPrincipal: string;
	paymentDayOfMonth: number;
	principalBalance: string;
	startDate: string;
	termInMonths: number;
}

export interface OneTimePayment {
	cutoffDate: string;
	effectiveDate: string;
	enabled: boolean;
	maximum: string;
	minimum: string;
	secondsUntilCutoffDate: number;
}

export interface Withdrawal {
	canBeEnabled: boolean;
	cutoffDate: string;
	effectiveDate: string;
	enabled: boolean;
	maximum: string;
	minimum: string;
	secondsUntilCutoffDate: number;
}

export interface Period {
	actualBalance: string;
	actualPayments: string;
	actualRunningInterest: string;
	actualRunningPayments: string;
	actualWithdrawals: string;
	availableToWithdraw: string;
	endDate: string;
	expectedPaymentAmount: string;
	originalBalance: string;
	originalRunningAddon: string;
	originalRunningInterest: string;
	originalRunningPayments: string;
	paymentScheduleEndDate: string;
	paymentScheduleStartDate: string;
	periodNumber: number;
	startDate: string;
}

export interface Asset {
	make?: string;
	mileage?: number;
	model?: string;
	vehicleType?: string;
	vin?: string;
	year?: number;
}

export interface CollateralAsset {
	asset: Asset;
	createdAt: string;
	id: string;
	loanId: string;
	type: string;
	updatedAt: string;
}

export interface LoanTotals {
	currentYear: string;
	interestPaidLastPeriod: string;
	priorYear: string;
	priorYearInterest: string;
	ytdFees: string;
	ytdInterest: string;
}

export interface PrimaryTotals {
	currentYear: string;
	interestPaidLastPeriod: string;
	principalPaidLastPeriod: string;
	priorYear: string;
	priorYearInterest: string;
	totalInterestPaid: string;
	ytdFees: string;
	ytdInterest: string;
	ytdPrincipalPaid: string;
}

export interface TakeBackTotals {
	currentYear: string;
	interestPaidLastPeriod: string;
	principalPaidLastPeriod: string;
	priorYear: string;
	priorYearInterest: string;
	totalInterestPaid: string;
	ytdFees: string;
	ytdInterest: string;
	ytdPrincipalPaid: string;
}

export interface Loan {
	collateralAsset: CollateralAsset;
	general: General;
	oneTimePayment: OneTimePayment;
	pendingRecurringPayment?: any;
	periods: Period[];
	primaryTerms: PrimaryTerms;
	primaryTotals: PrimaryTotals;
	recurringPayment: RecurringPayment;
	takeBackTerms?: any;
	takeBackTotals: TakeBackTotals;
	terms: Terms;
	totals: LoanTotals;
	withdrawal: Withdrawal;
	metadata: Metadata[];
}
