import { FBaseService } from '@kasasa/fbase-components/lib';
import {
	BorkenLoanResponse,
	GetOptionsResponse,
	LoanDetailResponse,
	SubmitNewLoanPayload,
} from '@/services/types/loan/LoanImport';

export default class LoanImporterService extends FBaseService {
	serverErrorHandler?: () => Promise<void>;

	hanldeServerError(): Promise<void> {
		if (this.serverErrorHandler) {
			return this.serverErrorHandler();
		}
		return super.hanldeServerError();
	}

	setServerErrorHandler(handler: () => Promise<void>): void {
		this.serverErrorHandler = handler;
	}

	async getBrokenLoanList(fiId: string): Promise<BorkenLoanResponse> {
		const response = await this.apiClient.get(`/api/v1/kloans-importer/${fiId}/brokenloan`);
		return response.data.data;
	}

	async getOptions(fiId: string): Promise<GetOptionsResponse> {
		const response = await this.apiClient.get(`/api/v1/kloans-views/${fiId}/loan-importer`);
		return response.data.data;
	}

	async getBrokenLoanDetail(fiId: string, importId: string): Promise<LoanDetailResponse> {
		const response = await this.apiClient.get(`/api/v1/kloans-importer/${fiId}/brokenloan/${importId}`);
		return response.data.data.body;
	}

	async submitNewLoan(fiId: string, payload: SubmitNewLoanPayload): Promise<LoanDetailResponse> {
		const response = await this.apiClient.post(`/api/v1/kloans-loan/${fiId}/loan`, payload);
		return response.data.data;
	}

	async deleteBrokenLoan(fiId: string, brokenLoanId: string): Promise<LoanDetailResponse> {
		return this.apiClient.delete(`/api/v1/kloans-importer/${fiId}/brokenloan/${brokenLoanId}`);
	}
}
