
import KPage from '@/components/KPage.vue';
import KCard from '@/components/KCard.vue';
import { Component, Prop, Provide } from 'vue-property-decorator';
import { KReadOnly, KSpinner } from '@kasasa/fbase-components';
import {
	ExtendedTransaction,
	TransactionStatus,
	TransactionType,
} from '@/services/types/loan/LoanTransaction';
import BaseLoanPage from '@/pages/loan/BaseLoanPage';
import { KCrumb } from '@kasasa/fbase-components/lib';
import TransactionGeneralCard from '@/components/loan/transactions/TransactionGeneralCard.vue';
import TransactionAllocationsCard from '@/components/loan/transactions/TransactionAllocationsCard.vue';
import { handleErrorResponse } from '@/utils/xhr';
import { VKLOANS } from '@/pages/PermissionGroups';
import ReverseOrRefundTransaction from '@/components/loan/transactions/ReverseOrRefundTransaction.vue';
import CommentsService from '@/services/CommentsService';
import { CommentReferenceTypes, Comment } from '@/services/types/loan/Comment';
import CommentsCard from '@/components/loan/comments/CommentsCard.vue';

@Component({
	components: {
		KPage,
		KCard,
		KSpinner,
		KReadOnly,
		TransactionAllocationsCard,
		TransactionGeneralCard,
		ReverseOrRefundTransaction,
		CommentsCard,
	},
	enums: {
		TransactionStatus,
		TransactionType,
		CommentReferenceTypes,
	},
})
export default class LoanTransactionDetailPage extends BaseLoanPage {
	@Provide()
	loanFiId: string = this.fiId;

	@Prop({ required: true })
	transactionId!: string;

	title = 'Transaction Details';

	data: ExtendedTransaction | null = null;

	commentItems: Comment[] = [];

	loadingData: boolean = false;

	showReverseOrRefundTransactionModal = false;

	commentsSvc: CommentsService = new CommentsService(this.$store);

	async created(): Promise<void> {
		this.loadingData = true;
		await Promise.all([
			this.getBankAccounts(),
			this.fetchAndSetLoan(),
			this.getConsumer(),
			this.getTransaction(),
			this.getComments(),
		]);
		this.loadingData = false;

		const crumbs: KCrumb[] = [
			{
				text: 'Kasasa Loans',
			},
			{
				text: 'Borrowers',
				link: true,
				exact: true,
				to: {
					name: 'consumer-list',
					params: { clientId: this.clientId },
				},
			},
			{
				text: this.getConsumersName(),
				exact: true,
				to: {
					name: 'consumer-overview',
					params: {
						consumerId: this.consumerId,
						clientId: this.clientId,
					},
				},
			},
			{
				text: 'Loan Overview',
				exact: true,
				to: {
					name: 'loan-overview',
					params: {
						consumerId: this.consumerId,
						clientId: this.clientId,
						loanId: this.loanId,
					},
				},
			},
			{
				text: 'Transactions',
				exact: true,
				to: {
					name: 'loan-transactions',
					params: {
						consumerId: this.consumerId,
						clientId: this.clientId,
						loanId: this.loanId,
					},
				},
			},
			{
				text: `Transaction ${this.transactionId}`,
				disabled: true,
			},
		];

		this.setBreadcrumbs(crumbs);
	}

	async getTransaction(force: boolean = false): Promise<ExtendedTransaction | null> {
		if (force || this.data === null) {
			try {
				const response = await this.loanSvc.getTransaction(
					this.consumerId,
					this.loanId,
					this.transactionId,
				);
				this.data = response.data.data;
			} catch (error) {
				handleErrorResponse(error as any, this);
			}
		}

		return this.data;
	}

	async getComments(): Promise<void> {
		try {
			const response = await this.commentsSvc.getComments(
				this.loanId,
				undefined,
				undefined,
				this.transactionId,
			);
			this.commentItems = response.data.data;
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		}
	}

	get hasAllocations(): boolean {
		return !!(
			this.data
			&& this.data.status !== this.$enums.TransactionStatus.Reversed
			&& this.data.status !== this.$enums.TransactionStatus.Pending
			&& this.data.transactionType !== this.$enums.TransactionType['Take Back']
		);
	}

	get canView(): boolean {
		return this.canRead(VKLOANS);
	}

	transactionChanged(): void {
		this.showReverseOrRefundTransactionModal = false;

		this.$router.replace({
			name: 'loan-transactions',
			params: {
				consumerId: this.consumerId,
				clientId: this.clientId,
				loanId: this.loanId,
			},
		});
	}

	get pdfLink(): string {
		return `/api/v1/kloans-receipt/fi/${this.fiId}/consumer/${this.consumerId}/loan/${this.loanId}/transaction/${this.transactionId}/pdf`;
	}
}
