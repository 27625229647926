
import {
	Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { Consumer } from '@/services/AchService';
import { Formatters } from '@/utils/formatters';
import { RouteTo } from '@/router/types';

@Component
export default class ConsumerTable extends Vue {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	items!: Consumer[];

	@Prop({ required: true })
	clientId!: string;

	@Prop({ default: '' })
	search!: string;

	@Prop({ default: false })
	loading!: boolean;

	sortDesc: boolean[] = [];

	loadingText: string = 'Loading borrowers...';

	headers: DataTableHeader[] = [
		{
			text: 'Name', value: 'name', width: '30%',
		},
		{
			text: 'Email', value: 'email', width: '30%',
		},
		{
			text: 'Last 4 SSN', value: 'ssnLastFour', width: '20%',
		},
		{
			text: 'Action', value: 'action', width: '20%',
		},

	];

	getConsumerOverviewRoute(item: Consumer): RouteTo {
		return { name: 'consumer-overview', params: { clientId: this.clientId, consumerId: item.id } };
	}

	goToConsumerOverview(item:Consumer):void {
		this.$router.push(this.getConsumerOverviewRoute(item));
	}
}
