import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelope } from '@kasasa/fbase-components/lib';
import { SkipAPayMod, SkipAPayConfig, SkipAPayRawConfig } from '@/services/types/loan/SkipAPay';
import { LoanModification } from '@/services/types/loan/Maintenance';

export default class SkipAPayService extends FBaseService {
	public async getFiSkipAPayConfig(fiId: string): Promise<SkipAPayConfig> {
		const response: AxiosResponse<ResponseEnvelope<SkipAPayRawConfig>> = await this.apiClient.get(
			`/api/v1/kloans-fi/fi/${fiId}/skip-a-pay-config`,
		);

		// The feeMethods property returned with the config object is a string of comma separated values.
		// This should be an array instead, once the API is fixed, we can remove this logic here.
		return {
			...response.data.data,
			feeMethods: response.data.data.feeMethods ? response.data.data.feeMethods.split(',') : [],
		};
	}

	public async submitSkipAPay(
		fiId: string,
		loanId: string,
		payload: SkipAPayMod,
	): Promise<LoanModification> {
		const response: AxiosResponse<ResponseEnvelope<LoanModification>> = await this.apiClient.post(
			`/api/v1/kloans-loan/${fiId}/loan/${loanId}/loan-mod/skip-a-pay-mod`,
			payload,
		);

		return response.data.data;
	}
}
