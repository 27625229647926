
import KPage from '@/components/KPage.vue';
import {
	Component, Inject, Prop, Watch,
} from 'vue-property-decorator';
import BaseFiPage from '@/pages/BaseFiPage';
import { Formatters } from '@/utils/formatters';
import KCard from '@/components/KCard.vue';
import { displayMode } from '@/router/types';
import { DropdownOption } from '@/types/Form';
import LoanImporterService from '@/services/LoanImporterService';
import {
	CoverageType,
	getInsuranceCoverageTypeName,
	InsurancePolicy,
	InsuranceProductCalculationMethod,
	PolicyCoverageSettings,
} from '@/services/types/loan/Insurance';
import moment from 'moment';
import { handleErrorResponse } from '@/utils/xhr';
import { AxiosResponse } from 'axios';
import {
	BorrowerProfileData, BorrowerType, Coverage, LoanInformation,
} from '@/services/types/loan/LoanImport';
import {
	Borrower,
} from '@/services/types/loan/Borrower';
import { LoanType } from '@/services/types/loan/Loan';
import BorrowerSection from '@/components/loanImport/BorrowerSection.vue';
import CollateralSection from '@/components/loanImport/CollateralSection.vue';
import { NoticeClass } from '@kasasa/fbase-components/src/store/modules/types/Notice';
import { Asset } from '@/services/types/loan/Collection';
import { CollateralAssetType } from '@/services/types/loan/Collateral';
import { BankAccountType } from '@kasasa/kloans-common-ui/dist/consumer-app/services/profile-api';

@Component({
	components: {
		KPage,
		KCard,
		BorrowerSection,
		CollateralSection,
	},
})
export default class CreateNewApplicationPage extends BaseFiPage {
	@Inject('format') format!: Formatters;

	@Prop({ default: displayMode.VIEW })
	mode!: displayMode;

	@Prop() readonly importId: string | undefined;

	@Watch('loanInformation.maturityDateToggle')
	onDobPickerOpen(value: boolean): void {
		if (value) {
			setTimeout(() => {
				this.maturityDateActivePicker = 'YEAR';
			});
		}
	}

	@Watch('showLinkedAccount')
	onLinkedAccount(value: boolean): void {
		if (value) {
			this.loanInformation.paymentDetails = {
				...this.loanInformation.paymentDetails,
				accountType: BankAccountType.CHECKING,
			};

			if (!this.loanInformation.paymentDetails.routingNumber) {
				this.showAlert('No routing number found.', NoticeClass.INFO);
			}
		}
	}

	loading: boolean = false;

	submitting: boolean = false;

	maturityDateActivePicker: string = 'YEAR';

	borrowers:BorrowerProfileData[] =[{
		applicantType: BorrowerType.PRIMARY,
		firstName: '',
		middleName: '',
		lastName: '',
		ssn: '',
		ssnConfirm: '',
		dateOfBirth: '',
		dateOfBirthConfirm: '',
		creditScore: '',
		email: '',
		homePhone: '',
		workPhone: '',
		mobilePhone: '',
		streetAddress: '',
		city: '',
		state: '',
		zip: '',
		coreAccountId: '',
		dobToggle: false,
		dobConfirmToggle: false,
	}];

	loanInformation: LoanInformation = {
		loanProduct: '',
		coreTrackingRecordNumber: '',
		coreAccountId: '',
		applicationNumber: '',
		originationDate: '',
		firstPaymentDate: '',
		maturityDate: '',
		interestRate: null,
		originalPrincipal: null,
		installmentAmount: null,
		collateralAssetType: '',
		branch: '',
		loanOfficer: '',
		paymentDetails: {
			accountName: '',
			accountNumber: '',
			accountType: '',
			holderName: '',
			paymentAmount: '',
			routingNumber: '',
		},
		collateralAssetData: {},
		originationDateToggle: false,
		firstPaymentDateToggle: false,
		paymentDateToggle: false,
		maturityDateToggle: false,
	};

	async created(): Promise<void> {
		this.loading = true;

		const options = await this.loanImporterService.getOptions(this.fiId);
		this.options = options;

		this.loanProductTypeDropdownOptions = options.loanProducts.map((product: any) => ({
			value: product.id, text: product.description, type: product.loanType,
		}));

		if (this.loanId && this.mode === displayMode.ADD) {
			const response = await this.loanSvc.getBorrowers(this.fiId, this.loanId, false);

			const prePoputatedBorrowers = response.data.data;

			const matchingBorrower: Borrower | undefined = prePoputatedBorrowers.find(
				(status) => status.consumerId === this.consumerId,
			);
			if (matchingBorrower) {
				this.borrowers[0].firstName = matchingBorrower.firstName;
				this.borrowers[0].middleName = matchingBorrower.middleName;
				this.borrowers[0].lastName = matchingBorrower.lastName;
				this.borrowers[0].coreAccountId = matchingBorrower.coreAccountId;
				this.borrowers[0].ssn = matchingBorrower.ssn;
				this.borrowers[0].ssnConfirm = matchingBorrower.ssn;
				this.borrowers[0].dateOfBirth = matchingBorrower.dob;
				this.borrowers[0].dateOfBirthConfirm = matchingBorrower.dob;
				this.borrowers[0].email = matchingBorrower.email;

				const addressInfo = matchingBorrower.addresses?.find(Boolean);

				this.borrowers[0].streetAddress = addressInfo?.streetAddress;
				this.borrowers[0].city = addressInfo?.city;
				this.borrowers[0].state = addressInfo?.state;
				this.borrowers[0].zip = addressInfo?.zip;
				this.borrowers[0].homePhone = matchingBorrower.phones.home;
				this.borrowers[0].workPhone = matchingBorrower.phones.office;
				this.borrowers[0].mobilePhone = matchingBorrower.phones.mobile;
			}
		}

		if (this.mode === displayMode.EDIT) {
			const loanDetails = await this.loanImporterService.getBrokenLoanDetail(this.fiId, this.brokenLoanId);
			this.borrowers = loanDetails.applicants.map((applicant: any) => ({
				...applicant,
				streetAddress: applicant.address,
				city: applicant.city,
				state: applicant.state,
				zip: applicant.zip,
				dobToggle: false,
				dobConfirmToggle: false,
				dateOfBirthConfirm: applicant.dateOfBirth,
				ssnConfirm: applicant.ssn,
			}));
			this.coverages = loanDetails.coverages;

			this.loanInformation = {
				loanProduct: loanDetails.loanProduct,
				coreTrackingRecordNumber: loanDetails.coreTrackingRecordNumber,
				coreAccountId: loanDetails.coreAccountId,
				applicationNumber: loanDetails.applicationNumber,
				originationDate: loanDetails.originationDate,
				firstPaymentDate: loanDetails.firstPaymentDate,
				maturityDate: loanDetails.maturityDate,
				interestRate: parseFloat(loanDetails.interestRate as string),
				originalPrincipal: parseFloat(loanDetails.originalPrincipal as string),
				installmentAmount: parseFloat(loanDetails.installmentAmount as string),
				collateralAssetType: loanDetails.collateralAssetType,
				branch: loanDetails.branch,
				loanOfficer: loanDetails.loanOfficer,
				paymentDetails: {
					...{
						accountName: '',
						accountNumber: '',
						accountType: '',
						holderName: '',
						paymentAmount: '',
						routingNumber: '',
					},
					...loanDetails.paymentDetails,
				},
				collateralAssetData: loanDetails.collateralAssetData,
			};

			if (this.loanInformation?.paymentDetails?.accountName
			|| this.loanInformation?.paymentDetails?.accountNumber
			|| this.loanInformation?.paymentDetails?.accountType
			|| this.loanInformation?.paymentDetails?.holderName
			|| this.loanInformation?.paymentDetails?.paymentAmount
			|| this.loanInformation?.paymentDetails?.routingNumber) {
				this.showLinkedAccount = true;
			}
			if (loanDetails.error) {
				const response = {
					status: loanDetails.error.code,
					data: {
						error: {
							errors: loanDetails.error.errors,
						},
					},
				};
				handleErrorResponse(response as AxiosResponse<any>, this);
			}
		}

		await this.fetchAndSetRoutingNumber();

		this.loading = false;
	}

	formattedDate(value: string = ''): string {
		return value ? this.format.asDate(value) : '';
	}

	validateFields(): boolean {
		return (this.$refs.loan_importer_form as any).validate();
	}

	isVehicleLoan(selectedLoanProductId: string) : boolean {
		if (this.loanProductTypeDropdownOptions.find((product) =>
			product.value === selectedLoanProductId)?.type?.toString()
		=== LoanType.VEHICLE.toString()) {
			this.loanInformation.collateralAssetType = CollateralAssetType.VEHICLE_ASSET;
			return true;
		}

		this.loanInformation.collateralAssetType = '';

		return false;
	}

	validatePhoneNumbers(): boolean {
		// validate applicants have at least 1 phone number
		return this.borrowers.every((borrower:BorrowerProfileData) =>
			borrower.homePhone || borrower.workPhone || borrower.mobilePhone);
	}

	updateBorrower(data:BorrowerProfileData, index: number): void {
		this.borrowers[index] = data;
	}

	updateCollateral(data:Asset): void {
		this.loanInformation.collateralAssetData = data;
	}

	async submitLoan(): Promise<void> {
		if (!this.validateFields() || !this.validatePhoneNumbers()) {
			this.showAlert('Error: Please review the highlighted fields and resubmit.', NoticeClass.ERROR);
			return;
		}

		this.submitting = true;

		const {
			branch,
			loanOfficer,
			firstPaymentDate,
			installmentAmount,
			interestRate,
			applicationNumber,
			loanProduct,
			maturityDate,
			originalPrincipal,
			originationDate,
			paymentDetails,
			collateralAssetData,
			collateralAssetType,
		} = this.loanInformation;
		try {
			await this.loanImporterService.submitNewLoan(this.fiId, {
				applicants: this.borrowers.map((borrower: BorrowerProfileData) => ({
					applicantType: borrower.applicantType,
					firstName: borrower.firstName,
					middleName: borrower.middleName,
					lastName: borrower.lastName,
					ssn: borrower.ssn.replace(/\D+/g, ''),
					dateOfBirth: borrower.dateOfBirth,
					dateOfBirthConfirm: borrower.dateOfBirthConfirm,
					creditScore: borrower.creditScore,
					email: borrower.email,
					homePhone: borrower.homePhone,
					workPhone: borrower.workPhone,
					mobilePhone: borrower.mobilePhone,
					address: borrower.streetAddress,
					city: borrower.city,
					state: borrower.state,
					zip: borrower.zip,
					coreAccountId: borrower.coreAccountId,
				})) as any,
				coverages: this.coverages,
				branch,
				loanOfficer,
				fiId: this.fiId,
				firstPaymentDate,
				applicationNumber,
				installmentAmount,
				interestRate,
				loanProduct,
				maturityDate,
				originalPrincipal,
				originationDate,
				paymentDetails: this.showLinkedAccount ? paymentDetails : {
					accountName: '',
					accountNumber: '',
					accountType: '',
					holderName: '',
					paymentAmount: '',
					routingNumber: '',
				},
				collateralAssetData,
				collateralAssetType,
			});

			if (this.mode === displayMode.EDIT) {
				await this.deleteBrokenLoan();
			}
			// redirect to success page
			this.$router.replace({ name: 'consumer-list', params: { clientId: this.clientId } });
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.submitting = false;
		}
	}

	today: moment.Moment = moment().startOf('day');

	// eslint-disable-next-line class-methods-use-this
	allowedDob(date: string): boolean {
		const age = moment().diff(date, 'years');
		return age >= 18;
	}

	required = [(value: string): boolean | string => !!value || 'This field is required.'];

	noNegative = [(value: number): boolean | string => value >= 0 || 'Must be positive value.'];

	twoDecimal = [(value: number): boolean | string => {
		if (!value) {
			return true;
		}
		const string = value.toString();
		if (string.indexOf('.') > -1) {
			return string.split('.')[1].length <= 2 || 'Restrict to 2 decimal places';
		}
		return true;
	}];

	fiveDecimal = [(value: number): boolean | string => {
		if (!value) {
			return true;
		}
		const string = value.toString();
		if (string.indexOf('.') > -1) {
			return string.split('.')[1].length <= 5 || 'Restrict to 5 decimal places';
		}
		return true;
	}];

	alphaAndSpace= [(value: string): boolean | string => /^[a-z_ ]*$/i.test(value) || 'Please use characters A-Z and a-z only.'];

	phoneNumberRequirement = (index: number): any => [
		(): boolean | string => (!!this.borrowers[index].mobilePhone || !!this.borrowers[index].homePhone || !!this.borrowers[index].workPhone) || 'At least one phone number is required.',
	];

	ssnMatch = (index: number): any => [
		(): boolean | string => (this.borrowers[index].ssn === this.borrowers[index].ssnConfirm) || 'Please confirm the SSN input.',
	];

	dobMatch = (index: number): any => [
		(): boolean | string => (this.borrowers[index].dateOfBirth === this.borrowers[index].dateOfBirthConfirm) || 'Please confirm the dob input.',
	];

	get maxOriginationDate():string {
		if (this.loanInformation.firstPaymentDate
		&& moment(this.loanInformation.firstPaymentDate).isValid()
		&& moment(this.today).isAfter(this.loanInformation.firstPaymentDate)) {
			return this.format.asDate(this.loanInformation.firstPaymentDate, this.format.ISO_DATE);
		}
		return this.format.asDate(this.today, this.format.ISO_DATE);
	}

	get minFirstPaymentDate(): string {
		return this.format.asDate(moment(this.loanInformation.originationDate).add(1, 'days').format(), this.format.ISO_DATE);
	}

	get minMaturityDate(): string {
		return this.loanInformation.firstPaymentDate
			? this.format.asDate(moment(this.loanInformation.firstPaymentDate).add(1, 'days').format(), this.format.ISO_DATE)
			: this.format.asDate(moment(this.loanInformation.originationDate).add(2, 'days').format(), this.format.ISO_DATE);
	}

	protected loanImporterService: LoanImporterService = new LoanImporterService(this.$store);

	applicantTypeDropdownOptions:DropdownOption[] = [
		{ value: BorrowerType.PRIMARY, text: 'Primary Borrower' },
		{ value: BorrowerType.CO_BORROWER, text: 'Co-borrower' },
		{ value: BorrowerType.CO_SIGNER, text: 'Co-signer' },
		{ value: BorrowerType.GUARANTOR, text: 'Guarantor' },
	];

	options: any = null;

	loanProductTypeDropdownOptions:DropdownOption[] = [];

	get insuranceProductDropDownOptions():DropdownOption[] {
		let options = [];
		options = this.options?.insuranceProducts.map((product: any) => ({
			value: product.id, text: product.name,
		}));
		if (this.borrowers.length > 1) {
			return this.options?.insuranceProducts.filter((option:any) =>
				!([InsuranceProductCalculationMethod.SINGLE_PREMIUM_NET, InsuranceProductCalculationMethod.SINGLE_PREMIUM_GROSS]
					.includes(option.calculationMethod))).map((product: any) => ({
				value: product.id, text: product.name,
			}));
		}
		return options;
	}

	currentInsuranceProductName: string = '';

	currentInsuranceProductOption: any = {
		text: 'Select Policy First',
		value: '',
	};

	// eslint-disable-next-line class-methods-use-this
	getInsuranceCoverageTypeName(type: CoverageType): string {
		return getInsuranceCoverageTypeName(type);
	}

	// eslint-disable-next-line class-methods-use-this
	mapPolicyCoverageSettingsIntoDropdownOptions(
		originalPolicyCoverageSettings: PolicyCoverageSettings[],
	): DropdownOption[] {
		let policyCoverageSettings = originalPolicyCoverageSettings;
		if (this.borrowers.length === 1) {
			policyCoverageSettings = policyCoverageSettings.filter((setting) => !(['J', 'C'].includes(setting.coverageType)));
		}
		return policyCoverageSettings.map((settings: PolicyCoverageSettings) => ({
			text: getInsuranceCoverageTypeName(settings.coverageType),
			value: settings.coverageType,
		}));
	}

	get policyForSelectedCoverage(): InsurancePolicy | undefined {
		return this.options?.insuranceProducts
			.find((policy: any) => policy.id === this.currentInsuranceProductName);
	}

	get coverageDropdownOptions(): DropdownOption[] {
		if (!this.currentInsuranceProductName) {
			return [
				{
					text: 'Select Policy First',
					value: '',
				},
			];
		}

		return [
			{
				text: 'Select Coverage',
				value: '',
			},
			...this.mapPolicyCoverageSettingsIntoDropdownOptions(
				this.policyForSelectedCoverage?.policyCoverageSettings ?? [],
			),
		];
	}

	get brokenLoanId(): string {
		return this.importId || '';
	}

	deleteBorrower(index:number): void {
		this.borrowers.splice(index, 1);
	}

	addBorrower():void {
		this.borrowers.push({
			applicantType: BorrowerType.NONE,
			firstName: '',
			middleName: '',
			lastName: '',
			ssn: '',
			ssnConfirm: '',
			dateOfBirth: '',
			dateOfBirthConfirm: '',
			creditScore: '',
			email: '',
			homePhone: '',
			workPhone: '',
			mobilePhone: '',
			streetAddress: '',
			city: '',
			state: '',
			zip: '',
			coreAccountId: '',
			dobToggle: false,
			dobConfirmToggle: false,
		});
	}

	showLinkedAccount:boolean = false;

	coverages: Coverage[] = [];

	addInsurance(): void {
		this.coverages.push({
			policyNumber: this.policyForSelectedCoverage?.policyNumber || '',
			description: this.policyForSelectedCoverage?.name || '',
			coverageType: this.currentInsuranceProductOption,
			expireDate: this.loanInformation.maturityDate,
		});
		this.currentInsuranceProductName = '';
		this.currentInsuranceProductOption = {
			text: 'Select Policy First',
			value: '',
		};
	}

	removeInsurance(index: number):void {
		this.coverages.splice(index, 1);
	}

	applicationTypeDisplay(index:number):string {
		const text: string | undefined = this.applicantTypeDropdownOptions
			.find((option:DropdownOption) => option.value === this.borrowers[index].applicantType)?.text as string;

		return text ? `(${text})` : '';
	}

	async deleteBrokenLoan(): Promise<void> {
		try {
			this.loanImporterService.deleteBrokenLoan(this.fiId, this.brokenLoanId);
		} catch (err) {
			this.showAlert('Something went wrong while deleting loan from queue.', NoticeClass.ERROR);
		}
	}

	async fetchAndSetRoutingNumber(): Promise<void> {
		try {
			const routingNumbers = await this.consumerSvc.getAllowedRoutingNumbers(this.fiId);
			if (routingNumbers.length) {
				this.loanInformation.paymentDetails = {
					...this.loanInformation.paymentDetails,
					routingNumber: routingNumbers[0],
				};
			}
		} catch (err) {
			this.showAlert('Something went wrong while fetching routing numbers.', NoticeClass.ERROR);
		}
	}
}
