
import KCard from '@/components/KCard.vue';
import {
	Component, Prop, Vue,
} from 'vue-property-decorator';
import {
	Bankruptcy, BankruptcyType, BankruptcyStatus, LoanApplicant,
} from '@/services/types/loan/Bankruptcy';
import { KReadOnly } from '@kasasa/fbase-components';
import { ApplicantType, getApplicantTypeName } from '@/services/types/loan/Borrower';

@Component({
	components: {
		KCard,
		KReadOnly,
	},
	enums: {
		ApplicantType,
		BankruptcyType,
		BankruptcyStatus,
	},
})
export default class OverviewBankruptcyCard extends Vue {
	@Prop({ required: true })
	data!: Bankruptcy;

	get borrowerLabel(): string {
		const label = 'Borrower';

		return this.data.loanApplicants && this.data.loanApplicants.length ? `${label}s` : label;
	}

	get borrowers(): string {
		if (!this.data.loanApplicants) {
			return '';
		}

		return this.data.loanApplicants.map((applicant: LoanApplicant) =>
			`${applicant.applicantName} (${getApplicantTypeName(applicant.applicantType)})`).join(', ');
	}
}
