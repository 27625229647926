
import { RequiredValidation } from '@kasasa/kloans-common-ui/dist/validations';
import {
	Component, Inject, Prop, Watch,
} from 'vue-property-decorator';
import { RuleDecl } from 'vue/types/options.d';
import { Validations } from 'vuelidate-property-decorators';
import moment from 'moment';
import LoanService from '@/services/LoanService';
import { handleErrorResponse } from '@/utils/xhr';
import BaseComponent from '@/components/BaseComponent';
import { KReadOnly, KText } from '@kasasa/fbase-components';
import { Loan } from '@/services/types/loan/Loan';
import PendingModificationCard from '@/components/loan/modifications/PendingModificationCard.vue';
import { LoanModification, LoanModStatus } from '@/services/types/loan/Maintenance';
import ProposedModificationCard, { ProposedLoanModificationType }
	from '@/components/loan/modifications/ProposedModificationCard.vue';
import ModificationCompleteCard from '@/components/loan/modifications/ModificationCompleteCard.vue';
import { Formatters } from '@/utils/formatters';

enum ChangeDueDateStep {
	NEW_DUE_DATE = 1,
	PREPARING_MOD = 2,
	PROPOSAL_REVIEW = 3,
	COMPLETE = 4,
}

@Component({
	components: {
		KReadOnly,
		KText,
		ModificationCompleteCard,
		PendingModificationCard,
		ProposedModificationCard,
	},
	enums: {
		ProposedLoanModificationType,
		ChangeDueDateStep,
	},
})
export default class ChangeDueDateModal extends BaseComponent {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	clientId!: string;

	@Prop({ required: true })
	consumerId!: string;

	@Prop({ required: true })
	fiId!: string;

	@Prop({ required: true })
	loanId!: string;

	@Prop({ required: true })
	show!: boolean;

	@Prop({ required: true })
	loan!: Loan;

	titles: string[] = [
		'Change Due Date',
		'Change Due Date',
		'Review Your Modification Request',
		'Due Date Change Complete',
	];

	nextLabels: string[] = [
		'Next',
		'',
		'Submit Changes',
		'Ok',
	];

	backLabels: string[] = [
		'Cancel',
		'',
		'Back',
		'',
	];

	today: moment.Moment = moment().startOf('day');

	tomorrow: moment.Moment = moment().startOf('day').add(1, 'day');

	step: number = 1;

	changeDueDateMenu: boolean = false;

	get nextDueDateExample(): moment.Moment {
		return moment(this.loan.general.paymentDueDate).add(1, 'month');
	}

	dueDate: string = '';

	@Validations()
	// eslint-disable-next-line class-methods-use-this
	validations(): RuleDecl {
		return {
			dueDate: RequiredValidation.validations(),
		};
	}

	// eslint-disable-next-line class-methods-use-this
	get dueDateErrors(): string[] {
		return RequiredValidation.errors(this, 'dueDate', 'Due Date');
	}

	pendingTimer: any;

	loading: boolean = false;

	backLoading: boolean = false;

	nextButtonLoading: boolean = false;

	loanSvc: LoanService = new LoanService(this.$store);

	@Watch('show', { immediate: true })
	onShowChanged(value: boolean): void {
		if (!value) {
			this.$nextTick(() => {
				this.dueDate = '';
				this.step = ChangeDueDateStep.NEW_DUE_DATE;
				this.loading = false;
				this.$v.$reset();
			});
		}
	}

	@Watch('step', { immediate: true })
	onStepChanged(value: number): void {
		this.loading = (value === ChangeDueDateStep.PREPARING_MOD);
	}

	@Watch('loanModId', { immediate: true })
	onLoanModIdChanged(value: string): void {
		if (value !== '') {
			this.poll();
		}
	}

	loanMod: LoanModification | null = null;

	async poll(): Promise<void> {
		try {
			const loanMod = await this.loanSvc.getLoanModById(this.fiId, this.loanId, this.loanModId);
			if (loanMod.status !== LoanModStatus.PROPOSED) {
				setTimeout(() => {
					this.poll();
				}, 2000);
			} else {
				this.loanMod = loanMod;
				this.forward();
			}
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		}
	}

	async dismiss(): Promise<void> {
		if (this.step === ChangeDueDateStep.NEW_DUE_DATE || this.step === ChangeDueDateStep.COMPLETE) {
			if (this.step === ChangeDueDateStep.COMPLETE) {
				this.$emit('modified');
			}

			this.$emit('modal-closed');
		} else if (this.step === ChangeDueDateStep.PROPOSAL_REVIEW) {
			this.backLoading = true;
			try {
				await this.loanSvc.cancelLoanMod(this.fiId, this.loanId, this.loanModId);
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			} finally {
				this.backLoading = false;
			}

			this.loanMod = null;
			this.loanModId = '';
			this.step = ChangeDueDateStep.NEW_DUE_DATE;
		}
	}

	loanModId: string = '';

	async forward(): Promise<void> {
		clearInterval(this.pendingTimer);
		this.pendingTimer = null;

		if (this.step === ChangeDueDateStep.NEW_DUE_DATE) {
			try {
				this.nextButtonLoading = true;
				const response = await this.loanSvc.postDueDateMod(this.fiId, this.loanId, { nextDueDate: this.dueDate });
				this.loanModId = response.data.data.id;
				this.step++;
			} catch (error) {
				await handleErrorResponse((error as any).response, this);
			} finally {
				this.nextButtonLoading = false;
			}
		} else if (this.step === ChangeDueDateStep.PREPARING_MOD) {
			this.step++;
			// user has 5 minute timeout
			if (!this.pendingTimer) {
				this.pendingTimer = setInterval(() => {
					this.dismiss();
				}, 5 * 60 * 1000);
			}
		} else if (this.step === ChangeDueDateStep.PROPOSAL_REVIEW) {
			this.save();
		} else if (this.step === ChangeDueDateStep.COMPLETE) {
			this.dismiss();
		}
	}

	async save(): Promise<void> {
		try {
			this.nextButtonLoading = true;
			await this.loanSvc.commitLoanMod(this.fiId, this.loanId, this.loanModId);
			this.step++;
			this.$emit('modified');
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.nextButtonLoading = false;
		}
	}

	destroyed(): void {
		clearInterval(this.pendingTimer);
		this.pendingTimer = null;

		if (this.step === ChangeDueDateStep.PROPOSAL_REVIEW) {
			try {
				this.loanSvc.cancelLoanMod(this.fiId, this.loanId, this.loanModId);
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			}
		}
	}
}
