
import { Component, Inject, Prop } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import { Formatters } from '@/utils/formatters';
import BaseComponent from '@/components/BaseComponent';
import { Loan } from '@/services/types/loan/Loan';
import { ApplicantType, Borrower, getApplicantTypeName } from '@/services/types/loan/Borrower';
import {
	CoverageType,
	getInsuranceCoverageTypeName,
	InsuranceCoverage,
	InsuranceModRequest,
	InsuranceModRequestCoverage,
	InsurancePolicy,
} from '@/services/types/loan/Insurance';
import LoanService from '@/services/LoanService';
import { LoanMaintenance, LoanModification, LoanModStatus } from '@/services/types/loan/Maintenance';
import LoanInsuranceService from '@/services/LoanInsuranceService';
import { handleErrorResponse } from '@/utils/xhr';
import PendingModificationCard from '@/components/loan/modifications/PendingModificationCard.vue';
import ProposedModificationCard, { ProposedLoanModificationType, ProposedModAddon }
	from '@/components/loan/modifications/ProposedModificationCard.vue';
import ModificationCompleteCard from '@/components/loan/modifications/ModificationCompleteCard.vue';
import { lastBusinessDateOfMonth } from '@/utils/date';
import moment from 'moment';
import { namespace } from 'vuex-class';
import InsuranceModAttachedCoverage, { InsuranceModRequestCoverageAttached } from './InsuranceModAttachedCoverage.vue';

const loanMaintenanceStoreModule = namespace('maintenance');

enum InsuranceModStep {
	ADD_COVERAGE = 1,
	PREPARING_MOD = 2,
	PROPOSAL_REVIEW = 3,
	COMPLETE = 4,
}

@Component({
	components: {
		ModificationCompleteCard,
		ProposedModificationCard,
		PendingModificationCard,
		KReadOnly,
		InsuranceModAttachedCoverage,
	},
	enums: {
		InsuranceModStep,
		ProposedLoanModificationType,
	},
})
export default class InsuranceModification extends BaseComponent {
	@Inject('format')
	format!: Formatters;

	@Prop({ default: false })
	show!: boolean;

	@Prop({ required: true })
	loan!: Loan;

	@Prop({ required: true })
	borrowers!: Borrower[];

	@Prop({ required: true })
	consumerId!: string;

	@Prop({ required: true })
	loanId!: string;

	@Prop({ required: true })
	fiId!: string;

	private currentStep: InsuranceModStep = InsuranceModStep.ADD_COVERAGE;

	private attachedPolicies: InsuranceModRequestCoverageAttached[] = [] ;

	private nextInsuranceBillingDate: string = '';

	private loanService: LoanService = new LoanService(this.$store);

	private insuranceService: LoanInsuranceService = new LoanInsuranceService(this.$store);

	private loanModProposal: LoanModification = {} as LoanModification;

	private loadingLoanModProposalCommit = false;

	private loadingInsurancePolicies = true;

	private cancelingLoanMod = false;

	private availablePolicies: InsurancePolicy[] = [];

	private proposedInsuranceMod: ProposedModAddon[] = [];

	@loanMaintenanceStoreModule.Getter('getLoanMaintenance')
	loanMaintenance!: LoanMaintenance | null;

	@loanMaintenanceStoreModule.Mutation('setLoanMaintenance')
	setLoanMaintenance!: (loanMaintenance: LoanMaintenance | null) => void;

	async created(): Promise<void> {
		await this.getAvailableInsurancePolicies();
		await this.fetchLoanCurrentInsuranceCoverage();
		this.loadingInsurancePolicies = false;
	}

	get isNextButtonDisabled(): boolean {
		if (this.loadingInsurancePolicies || this.cancelingLoanMod || !this.hasCoverageChanges) {
			return true;
		}

		switch (this.currentStep) {
			case (InsuranceModStep.ADD_COVERAGE):
				return !!this.attachedPolicies.find((policy) => !policy.policyNumber || !policy.coverageType);
			default:
				return false;
		}
	}

	get title(): string {
		switch (this.currentStep) {
			case InsuranceModStep.PROPOSAL_REVIEW:
				return 'Review Your Modification Request';
			case InsuranceModStep.COMPLETE:
				return 'Loan Modification Complete';
			default:
				return 'Insurance Product Changes';
		}
	}

	get showModalTopCloseButton(): boolean {
		return [
			InsuranceModStep.PREPARING_MOD,
			InsuranceModStep.COMPLETE,
		].indexOf(this.currentStep) === -1;
	}

	get nextButtonLabel(): string {
		switch (this.currentStep) {
			case InsuranceModStep.PROPOSAL_REVIEW:
				return 'Submit Changes';
			case InsuranceModStep.COMPLETE:
				return 'Ok';
			default:
				return 'Next';
		}
	}

	get hasCurrentCoverage(): boolean {
		return this.attachedPolicies.find((policy) => policy.isCurrentCoverage) !== undefined;
	}

	get hasCoverageChanges(): boolean {
		return !!this.attachedPolicies.filter((policy) => !policy.isCurrentCoverage
			|| (policy.isCurrentCoverage && policy.isCurrentCoverageBeingRemoved)).length;
	}

	// eslint-disable-next-line class-methods-use-this
	applicantTypeLabel(applicantType: ApplicantType): string {
		return getApplicantTypeName(applicantType);
	}

	onNextStepButtonClick(): void {
		switch (this.currentStep) {
			case InsuranceModStep.ADD_COVERAGE:
				this.submitInsuranceModProposalRequest();
				this.currentStep = InsuranceModStep.PREPARING_MOD;
				break;
			case InsuranceModStep.PROPOSAL_REVIEW:
				this.commitLoanModProposal();
				break;
			case InsuranceModStep.COMPLETE:
				this.$emit('modified');
				break;
			default:
				this.currentStep++;
		}
	}

	async commitLoanModProposal(): Promise<void> {
		try {
			this.loadingLoanModProposalCommit = true;
			await this.loanService.commitLoanMod(this.fiId, this.loanId, this.loanModProposal.id);

			this.currentStep++;
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.loadingLoanModProposalCommit = false;
		}
	}

	async submitInsuranceModProposalRequest(): Promise<void> {
		const insuranceCoverages: InsuranceModRequestCoverage[] = this.attachedPolicies
			.filter((attachedPolicy) => !attachedPolicy.isCurrentCoverageBeingRemoved)
			.map((attachedPolicy: InsuranceModRequestCoverageAttached): InsuranceModRequestCoverage => ({
				coverageType: attachedPolicy.coverageType,
				policyNumber: attachedPolicy.policyNumber,
			}));

		const payload: InsuranceModRequest = {
			insuranceCoverages,
		};

		try {
			const loanMod = await this.insuranceService.postInsuranceMod(this.fiId, this.loanId, payload);

			await this.poll(loanMod.id);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
			this.currentStep = InsuranceModStep.ADD_COVERAGE;
		}
	}

	async onPreviousStepButtonClick(): Promise<void> {
		switch (this.currentStep) {
			case InsuranceModStep.ADD_COVERAGE:
				this.$emit('cancel');
				break;
			case InsuranceModStep.PROPOSAL_REVIEW:
				await this.cancelInsuranceModification();
				this.currentStep = InsuranceModStep.ADD_COVERAGE;
				break;
			default:
				this.currentStep--;
		}
	}

	async fetchLoanCurrentInsuranceCoverage(): Promise<void> {
		try {
			const { data: { data: coverages } } = await this.loanService.getInsurance(this.loanId);

			// Remove aged out and ended coverages
			const filteredCoverages: InsuranceCoverage[] = coverages.filter((coverage) => {
				const isEnded = coverage.endDate
					? moment().startOf('day').isSameOrAfter(coverage.endDate)
					: false;
				return !coverage.agedOutAt && !isEnded;
			});

			filteredCoverages.forEach((coverage: InsuranceCoverage) => {
				const policy: InsurancePolicy | undefined = this.availablePolicies.find(
					(availablePolicy) => availablePolicy.policyNumber === coverage.policyNumber,
				);

				// Missing policy information for current loan insurance coverage.
				if (!policy) {
					return;
				}

				this.attachedPolicies.push({
					coverageType: coverage.coverageType,
					policyNumber: coverage.policyNumber,
					isCurrentCoverage: true,
					isCurrentCoverageBeingRemoved: false,
				});

				this.setNextInsuranceBillingDate(filteredCoverages);
			});
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		}
	}

	setNextInsuranceBillingDate(coverages: InsuranceCoverage[]): void {
		if (!coverages.length) {
			return;
		}

		const { billingDay } = coverages[0];

		if (!billingDay) {
			this.nextInsuranceBillingDate = this.format.asDate(lastBusinessDateOfMonth());
		} else {
			const date = moment().add(1, 'M');

			this.nextInsuranceBillingDate = this.format.asDate(
				`${date.year()}-${date.month() + 1}-${billingDay}`,
			);
		}
	}

	async setLoanMaintenanceInStore(): Promise<void> {
		if (!this.loanMaintenance) {
			try {
				const loanMaintenance: LoanMaintenance = await this.loanService.getLoanMaintenance(
					this.fiId,
					this.consumerId,
					this.loanId,
				);

				this.setLoanMaintenance(loanMaintenance);
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			}
		}
	}

	async getAvailableInsurancePolicies(): Promise<void> {
		await this.setLoanMaintenanceInStore();

		this.availablePolicies = this.loanMaintenance?.products ?? [];
	}

	addNewPolicy(): void {
		this.attachedPolicies.push({
			coverageType: '' as CoverageType,
			policyNumber: '',
			isCurrentCoverage: false,
			isCurrentCoverageBeingRemoved: false,
		});
	}

	removeAttachedPolicy(policy: InsuranceModRequestCoverageAttached): void {
		const attachedIndex = this.attachedPolicies.findIndex(
			(attachedPolicy) => attachedPolicy.policyNumber === policy.policyNumber
				&& attachedPolicy.coverageType === policy.coverageType,
		);

		if (policy.isCurrentCoverage) {
			policy.isCurrentCoverageBeingRemoved = true;
		} else {
			this.attachedPolicies.splice(attachedIndex, 1);
		}
	}

	async poll(loanModId: string): Promise<void> {
		try {
			const loanMod = await this.loanService.getLoanModById(this.fiId, this.loanId, loanModId);

			if (loanMod.status !== LoanModStatus.PROPOSED) {
				setTimeout(() => this.poll(loanModId), 2000);
			} else {
				this.setProposedInsuranceMod();

				this.loanModProposal = loanMod;

				this.currentStep++;
			}
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		}
	}

	setProposedInsuranceMod(): void {
		this.attachedPolicies.forEach((coverage: InsuranceModRequestCoverageAttached) => {
			this.proposedInsuranceMod.push({
				name: this.getPolicyNameForCoverage(coverage),
				statusBefore: coverage.isCurrentCoverage
					? getInsuranceCoverageTypeName(coverage.coverageType) : 'Not Set',
				statusAfter: coverage.isCurrentCoverage && coverage.isCurrentCoverageBeingRemoved
					? 'Removed' : getInsuranceCoverageTypeName(coverage.coverageType),
			});
		});
	}

	getPolicyNameForCoverage(coverage: InsuranceModRequestCoverageAttached): string {
		return this.availablePolicies.find((policy) =>
			policy.policyNumber === coverage.policyNumber)?.name ?? 'Missing Policy';
	}

	async onCloseModalButtonClick(): Promise<void> {
		switch (this.currentStep) {
			case InsuranceModStep.PROPOSAL_REVIEW:
				await this.cancelInsuranceModification();
				this.$emit('cancel');
				break;
			default:
				this.$emit('cancel');
				break;
		}
	}

	async cancelInsuranceModification(): Promise<void> {
		this.cancelingLoanMod = true;

		try {
			await this.loanService.cancelLoanMod(this.fiId, this.loanId, this.loanModProposal.id);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.cancelingLoanMod = false;

			this.proposedInsuranceMod = [];
		}
	}

	// eslint-disable-next-line class-methods-use-this
	getInsuranceCoverageTypeName(value: CoverageType): string {
		return getInsuranceCoverageTypeName(value);
	}
}
