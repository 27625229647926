export enum FeeType {
	'Late fee' = 10,
	'Returned payment fee' = 20,
	Custom = 99,
}

export enum FeeStatus {
	Unsettled = 0,
	Settled = 10,
}

export enum FeeTransactionType {
	Payment = 10,
	Waiver = 20,
	Reversal = 30,
	'Charge Off' = 40,
}

export interface WaivedFee {
	feeId: string;
	feeTransactionId: string;
	feeTypeId: FeeType;
	id: string;
	loanId: string;
	reversedAt?: string | null;
	waivedAmount: string;
	waivedDate: string;
}

export interface LoanFeeTransaction {
	amount: string;
	description: string;
	effectiveDate: string;
	feeId: string;
	feeTransactionType: FeeTransactionType;
	feeTypeId: number;
	id: string;
	loading?: boolean;
	loanId: string;
	loanTransaction?: any;
	loanTransactionId?: string | null;
	referenceTransactionId?: string | null;
	replaying?: boolean | null;
	waivedFee: WaivedFee;
}

export interface Fee {
	amount: string;
	assessmentDate: string;
	assetId: string;
	currentAmountPaid: string;
	currentAmountWaived: string;
	description: string;
	feeBalance: string;
	feeTypeId: FeeType;
	id: string;
	loanFeeTransactions: LoanFeeTransaction[];
	loanId: string;
	paidAmount: string;
	period: number;
	resolutionDate?: string | null;
	status: FeeStatus;
	totalAmountChargedOff: string;
	totalAmountWaived: string;
	transactionId?: string | null;
	waivedAmount: string;
}

export interface ExtendedFee extends Fee {
	loadingReverse: boolean;
	loadingWaived: boolean;
}

export interface WaiveFeePayload {
	amountToWaive: string;
	amountConfirm?: string;
	currentFeeBalance: string;
	feeId: string;
}
