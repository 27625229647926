
import KPage from '@/components/KPage.vue';
import { Component, Inject } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import { Formatters } from '@/utils/formatters';
import BaseFiPage from '@/pages/BaseFiPage';
import KCard from '@/components/KCard.vue';
import { DropdownOption } from '@/types/Form';
import CpiService, {
	CpiSetup,
	AddCpiBillingData,
	CpiRefundData,
	CpiLoanSettingsResponse,
} from '@/services/CpiService';
import { RequiredValidation } from '@kasasa/kloans-common-ui/dist/validations';
import moment from 'moment';
import { Validations } from 'vuelidate-property-decorators';
import { RuleDecl } from 'vue/types/options.d';
import { handleErrorResponse } from '@/utils/xhr';

interface AddCpiBillingFields {
	premiumAddedAmount: boolean;
	adjustedInstallmentAmount: boolean;
	premiumAddedEffectiveDate: boolean;
	installmentRecalculationPeriods: boolean;
	message: string;
}

enum CpiPageStep {
	CPI_SETUP = 0,
	CPI_VALUE_INPUT = 1,
	CPI_INSTRUCTION_RESULT = 2
}

enum CpiActionType {
	ADD_CPI_BILLING = '1',
	CPI_REFUND = '2'
}

enum PremiumAdministrationMethodsEnum {
	BALLOON_PAYMENT = '1',
	INSTALLMENT_CHANGE_SET_TERM = '2',
	INSTALLMENT_CHANGE_RESET_OF_LOAN = '3',
	EXTEND_TERM = '4',
}

@Component({
	components: {
		KPage,
		KReadOnly,
		KCard,
	},
	enums: {
		CpiPageStep,
		CpiActionType,
	},
})
export default class CpiPage extends BaseFiPage {
	@Inject('format')format!: Formatters;

	today: moment.Moment = moment().startOf('day');

	@Validations()
	validations(): RuleDecl {
		const pastOrTodayDate = (value: string) => {
			if (value) {
				return moment(value).isSameOrBefore(this.today);
			}

			return true;
		};

		const addCpiBilling = {
			premiumAddedEffectiveDate: {
				...RequiredValidation.validations(),
				pastOrTodayDate,
			},
		};

		const cpiRefund = {
			premiumAddedEffectiveDate: {
				...RequiredValidation.validations(),
				pastOrTodayDate,
			},
		};

		return { addCpiBilling, cpiRefund };
	}

	private cpiSvc: CpiService = new CpiService(this.$store);

	showModal: boolean = false;

	async created(): Promise<void> {
		try {
			await this.cpiSvc.getCpiInstructions(this.fiId);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		}
	}

	resetData(): void {
		// need to reset form validation before reset data value because reset form will set values to null and cause error;
		this.resetFormValidation();

		this.cpiSetup = {
			instructionType: '',
			loanNumber: '',
		};
		this.addCpiBilling = {
			premiumAddedAmount: '',
			adjustedInstallmentAmount: '',
			premiumAddedEffectiveDate: moment().format('YYYY-MM-DD'),
			installmentRecalculationPeriods: '',
		};
		this.cpiRefund = {
			premiumRefundAmount: '',
			premiumRefundEffectiveDate: moment().format('YYYY-MM-DD'),
			refundType: '',
		};

		this.currentStep = CpiPageStep.CPI_SETUP;
	}

	cpiSetup: CpiSetup = {
		instructionType: '',
		loanNumber: '',
	};

	addCpiBilling: AddCpiBillingData = {
		premiumAddedAmount: '',
		adjustedInstallmentAmount: '',
		premiumAddedEffectiveDate: moment().format('YYYY-MM-DD'),
		installmentRecalculationPeriods: '',
	};

	cpiRefund: CpiRefundData = {
		premiumRefundAmount: '',
		premiumRefundEffectiveDate: moment().format('YYYY-MM-DD'),
		refundType: '',
	};

	currentStep: CpiPageStep = CpiPageStep.CPI_SETUP;

	cpiInstructionTypeDropdownOptions: DropdownOption[] = [
		{ value: '1', text: 'Add CPI Billing' },
		{ value: '2', text: 'CPI Refund' },
	];

	loading: boolean = false;

	isInvalidLoanNumber: boolean = false;

	isNotConfigured: boolean = false;

	effectiveDateMenu: boolean = false;

	effectiveRefundDateMenu: boolean = false;

	premiumAdministrationMethod: string = '';

	required = [(value: string): boolean | string => !!value || 'This field is required.'];

	validateFields(type: number): boolean {
		return (this.$refs[`form_type_${type}`] as any).validate();
	}

	resetFormValidation(): void {
		(this.$refs.form_type_0 as any)?.reset();
		(this.$refs.form_type_1 as any)?.reset();
		(this.$refs.form_type_2 as any)?.reset();
	}

	clearError(): void {
		this.isInvalidLoanNumber = false;
		this.isNotConfigured = false;
	}

	getIsInvalidLoanNumber(): boolean {
		return this.isInvalidLoanNumber;
	}

	getIsNotConfigured(): boolean {
		return this.isNotConfigured;
	}

	get effectiveDateErrorMessages(): string[] {
		const errors: string[] = [];

		if (this.$v.addCpiBilling?.premiumAddedEffectiveDate?.$dirty) {
			if (!this.$v.addCpiBilling.premiumAddedEffectiveDate.pastOrTodayDate) {
				errors.push('Effective Date must be today or in the past.');
			}
		}

		return errors;
	}

	get cpiRefundEffectiveDateErrorMessages(): string[] {
		const errors: string[] = [];

		if (this.$v.cpiRefund?.premiumAddedEffectiveDate?.$dirty) {
			if (!this.$v.cpiRefund.premiumAddedEffectiveDate.pastOrTodayDate) {
				errors.push('Effective Date must be today or in the past.');
			}
		}

		return errors;
	}

	allowedDates(date: string): boolean {
		return moment(date)
			.startOf('day')
			.isSameOrBefore(this.today);
	}

	confirm(): void {
		if (
			this.cpiSetup.instructionType === CpiActionType.ADD_CPI_BILLING
			&& !this.validateFields(1)
		) {
			return;
		}
		if (this.cpiSetup.instructionType === CpiActionType.CPI_REFUND && !this.validateFields(2)) {
			return;
		}

		if (this.cpiLoanNumberInputRules) {
			return;
		}

		if (this.loading) {
			return;
		}
		this.showModal = true;
	}

	async nextStep(): Promise<void> {
		if (!this.validateFields(0)) {
			return;
		}

		if (this.cpiLoanNumberInputRules) {
			return;
		}

		if (this.loading) {
			return;
		}
		this.loading = true;
		try {
			const setting: CpiLoanSettingsResponse = await this.cpiSvc.cpiLoanSettings(
				this.fiId,
				this.cpiSetup.instructionType,
				this.cpiSetup.loanNumber,
			);
			this.premiumAdministrationMethod = setting.premiumAdministrationMethod.toString();
			this.currentStep = CpiPageStep.CPI_VALUE_INPUT;
		} catch (error) {
			switch ((error as any).response.status) {
				case 404:
					this.isInvalidLoanNumber = true;
					break;
				case 422:
					this.isNotConfigured = true;
					break;
				default:
					break;
			}
		} finally {
			this.loading = false;
		}
	}

	async submit(): Promise<void> {
		if (
			this.cpiSetup.instructionType === CpiActionType.ADD_CPI_BILLING
			&& !this.validateFields(1)
		) {
			return;
		}
		if (this.cpiSetup.instructionType === CpiActionType.CPI_REFUND && !this.validateFields(2)) {
			return;
		}

		if (this.cpiLoanNumberInputRules) {
			return;
		}

		if (this.loading) {
			return;
		}
		this.loading = true;
		try {
			await this.cpiSvc.submitCpiInstruction({
				...this.cpiSetup,
				...(this.cpiSetup.instructionType === CpiActionType.ADD_CPI_BILLING
					? this.addCpiBilling
					: this.cpiRefund),
			});
			this.currentStep = CpiPageStep.CPI_INSTRUCTION_RESULT;
		} catch (error) {
			await handleErrorResponse((error as any).response, this);
		} finally {
			this.loading = false;
			this.showModal = false;
		}
	}

	get cpiLoanNumberInputRules(): string {
		// required is handled by :rules="required"
		if (!this.cpiSetup.loanNumber.length) {
			return '';
		}
		if (!/[\d]{5}-[\d]{5}/.test(this.cpiSetup.loanNumber)) {
			return 'Please enter exactly 11 characters.';
		}
		if (this.isNotConfigured) {
			return 'Loan product not Configured for CPI. Please talk with Kasasa support.';
		}
		if (this.isInvalidLoanNumber) {
			return `Invalid Loan Number: ${this.cpiSetup.loanNumber}`;
		}

		return '';
	}

	get addCpiBillingFields(): AddCpiBillingFields {
		let message = '';
		if (this.premiumAdministrationMethod === PremiumAdministrationMethodsEnum.INSTALLMENT_CHANGE_RESET_OF_LOAN) {
			message =	'The Kasasa Loans System will automatically calculate the new installment amount when CPI premium is added.';
		}

		return {
			premiumAddedAmount: true,
			premiumAddedEffectiveDate: true,
			installmentRecalculationPeriods: this.premiumAdministrationMethod
			=== PremiumAdministrationMethodsEnum.INSTALLMENT_CHANGE_SET_TERM,
			adjustedInstallmentAmount: this.premiumAdministrationMethod
			=== PremiumAdministrationMethodsEnum.INSTALLMENT_CHANGE_SET_TERM,
			message,
		};
	}

	get canView(): boolean {
		return this.canRead('vkloans');
	}
}
