import Vue from 'vue';
import Vuex from 'vuex';

import { AuthStore, NoticeStore, pendoPlugin } from '@kasasa/fbase-components/lib';
import NavigationModule from '@/store/modules/navigation';
import LoanMaintenanceModule from '@/store/modules/maintenance';
import LoanModule from '@/store/modules/loan';
import BankAccount from '@/store/modules/bankAccount';
import FiFeatureFlag from '@/store/modules/featureFlag';

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		auth: AuthStore,
		notices: NoticeStore,
		navigation: NavigationModule,
		maintenance: LoanMaintenanceModule,
		loan: LoanModule,
		bankAccount: BankAccount,
		featuresFlag: FiFeatureFlag,
	},
	plugins: [pendoPlugin],
});
