
import KPage from '@/components/KPage.vue';
import KCard from '@/components/KCard.vue';
import { Component } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import BaseFiPage from '@/pages/BaseFiPage';
import CollectionTable from '@/components/CollectionTable.vue';
import { handleErrorResponse } from '@/utils/xhr';
import { ReportDateType, ReportRequestData, ReportStatusData } from '@/services/FiService';
import { DataTableHeader } from 'vuetify';
import moment from 'moment';
import { Validations } from 'vuelidate-property-decorators';
import { RuleDecl } from 'vue/types/options.d';
import { RequiredValidation } from '@kasasa/kloans-common-ui/dist/validations';
import { VKLOANS } from '@/pages/PermissionGroups';
import ReportType from '@/services/types/ReportTypes';

enum FileType {
	CSV = 'CSV',
	XLSX = 'XLSX',
}

interface QueuedReport {
	endDate: string;
	fi: string;
	fileTypes: FileType[];
	isReady: boolean;
	link: string;
	message: string;
	reportId: string;
	reportStatus: string;
	reportStatusDisplay: string;
	reportType: string;
	reportTypeDisplay: string;
	requested: number;
	requestedDisplay: string;
	startDate: string;
	statusCode: number;
}

enum ReportStatus {
	PENDING = 'PENDING',
	DONE = 'DONE',
	IN_PROGRESS = 'IN_PROGRESS',
	FAILED = 'FAILED',
}

const getReportStatusDisplay = (status: string): string => {
	switch (status) {
		case 'PENDING':
			return 'Pending';
		case 'DONE':
			return 'Done';
		case 'IN_PROGRESS':
			return 'In progress';
		case 'FAILED':
			return 'Failed';

		default:
			return 'Pending';
	}
};

const checkHasFileType = (
	report: QueuedReport,
	fileType: FileType,
): boolean => (report.fileTypes ?? []).includes(fileType);

@Component({
	components: {
		KPage,
		KReadOnly,
		CollectionTable,
		KCard,
	},
	enums: {
		ReportDateType,
		FileType,
	},
})
export default class ReportBuilderPage extends BaseFiPage {
	loading: boolean = true;

	reportLoading: boolean = false;

	today: moment.Moment = moment().startOf('day');

	yesterday: moment.Moment = moment().subtract(1, 'days').startOf('day');

	reportRequestData: ReportRequestData = {};

	reportDateType: ReportDateType = ReportDateType.POSTED_DATE;

	reportTypeList: any =[];

	selectedReport: ReportType = { displayName: '', value: '', showEndDate: false };

	checkHasFileType = checkHasFileType;

	@Validations()
	validations(): RuleDecl {
		const pastOrTodayDate = (value: string) => {
			if (value) {
				return moment(value).isSameOrBefore(this.today);
			}

			return true;
		};

		const endDateIsSameOrAfterStartDate = (value: string) => {
			if (value && this.reportRequestData.startDate) {
				return moment(value).isSameOrAfter(this.reportRequestData.startDate);
			}

			return true;
		};

		const startDateIsSameOrBeforeEndDate = (value: string) => {
			if (value && this.reportRequestData.endDate) {
				return moment(value).isSameOrBefore(this.reportRequestData.endDate);
			}

			return true;
		};

		const reportRequestData = {
			startDate: {
				...RequiredValidation.validations(),
				pastOrTodayDate,
				startDateIsSameOrBeforeEndDate,
			},
			endDate: {
				...RequiredValidation.validations(),
				pastOrTodayDate,
				endDateIsSameOrAfterStartDate,
			},
		};

		return { reportRequestData };
	}

	startDateMenu: boolean = false;

	endDateMenu: boolean = false;

	headers: DataTableHeader[] = [
		{
			text: 'Requested',
			value: 'requestedDisplay',
			align: 'center',
		},
		{
			text: 'Type',
			value: 'reportTypeDisplay',
			align: 'center',
		},
		{
			text: 'Start Date',
			value: 'startDate',
			align: 'center',
		},
		{
			text: 'End Date',
			value: 'endDate',
			align: 'center',
		},
		{
			text: 'Status',
			value: 'reportStatusDisplay',
			align: 'center',
		},
		{
			text: 'Actions',
			value: 'reportId',
			align: 'center',
			sortable: false,
		},
	];

	queuedReports: QueuedReport[] = [];

	pollingTimeout: any;

	required = [(value: string): boolean | string => !!value || 'This field is required.'];

	allowedStartDates(date: string): boolean {
		if (this.reportRequestData.endDate) {
			return (
				moment(date).startOf('day').isSameOrBefore(this.today)
				&& moment(date).startOf('day').isSameOrBefore(this.reportRequestData.endDate)
			);
		}
		return moment(date).startOf('day').isSameOrBefore(this.today);
	}

	getDownloadLink(item: QueuedReport, fileType: FileType = FileType.CSV): string {
		return `/api/v1/kloans-reporting/fiId/${this.fiId}/report/${item.reportId}/download?startDate=${
			item.startDate
		}&reportType=${item.reportType}&endDate=${item.endDate || item.startDate}&fileType=${fileType}`;
	}

	allowedEndDates(date: string): boolean {
		return (
			moment(date).startOf('day').isSameOrBefore(this.today)
			&& moment(date).startOf('day').isSameOrAfter(this.reportRequestData.startDate)
		);
	}

	validateFields(): boolean {
		return (this.$refs.report_builder_form as any).validate();
	}

	async created(): Promise<void> {
		if (!localStorage.queuedReports) {
			localStorage.queuedReports = JSON.stringify([]);
		}
		this.loading = false;
		await this.getReportTypeList();
		await this.getQueuedReports();
		await this.pollingReportStatus();
	}

	destroyed(): void {
		if (this.pollingTimeout !== undefined) {
			clearTimeout(this.pollingTimeout);
			this.pollingTimeout = undefined;
		}
	}

	async getReportTypeList(): Promise<void> {
		this.reportTypeList = await this.fiService.getReportTypes(this.fiId);

		[this.selectedReport] = this.reportTypeList.types;

		this.reportRequestData = {
			reportType: this.selectedReport.value,
			startDate: this.yesterday.format('YYYY-MM-DD'),
			endDate: '',
		};
	}

	onReportTypeChange(): void {
		this.reportRequestData.endDate = '';
		this.reportRequestData.reportType = this.selectedReport.value;
	}

	async pollingReportStatus(): Promise<void> {
		if (this.pollingTimeout !== undefined) {
			clearTimeout(this.pollingTimeout);
			this.pollingTimeout = undefined;
			// return;
		}

		let queuedReports: QueuedReport[] = JSON.parse(localStorage.queuedReports).filter(
			(report: QueuedReport) =>
				report.reportStatus !== ReportStatus.DONE && report.reportStatus !== ReportStatus.FAILED,
		);

		if (queuedReports.length <= 0) {
			return;
		}

		try {
			const pendingReportsFromThisFi: QueuedReport[] = JSON.parse(
				localStorage.queuedReports,
			).filter(
				(report: QueuedReport) =>
					report.fi === this.fiId
					&& report.reportStatus !== ReportStatus.DONE
					&& report.reportStatus !== ReportStatus.FAILED,
			);

			if (pendingReportsFromThisFi.length === 0) {
				return;
			}

			const reportStatusResults: ReportStatusData[] = await Promise.all(
				pendingReportsFromThisFi.map(async (report: QueuedReport) =>
					this.fiService.getReportStatus(this.fiId, report.reportId)),
			);
			queuedReports = JSON.parse(localStorage.queuedReports);
			queuedReports = queuedReports.map((report: QueuedReport) => {
				const reportStatusResponse: ReportStatusData | undefined = reportStatusResults.find(
					(status) => status.reportId === report.reportId,
				);
				const result = { ...report };
				if (reportStatusResponse) {
					result.isReady = reportStatusResponse.isReady;
					result.statusCode = reportStatusResponse.statusCode;
					result.reportStatus = reportStatusResponse.reportStatus;
					result.reportStatusDisplay = getReportStatusDisplay(reportStatusResponse.reportStatus);
					result.fileTypes = reportStatusResponse.fileTypes ?? [];
				}
				return result;
			});

			localStorage.queuedReports = JSON.stringify(queuedReports);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.getQueuedReports();

			this.pollingTimeout = setTimeout(() => {
				this.pollingReportStatus();
			}, 3000);
		}
	}

	async onGenerateReport(): Promise<void> {
		if (!this.validateFields()) {
			return;
		}
		this.reportLoading = true;
		try {
			if (this.selectedReport.value === 'EXPANDED_GL') {
				this.reportRequestData = { ...this.reportRequestData, reportDateType: this.reportDateType };
			}

			const reportResponse = await this.fiService.generateReport(this.fiId, this.reportRequestData);
			const timestamp = Date.now();
			const queuedReport: QueuedReport = {
				endDate: this.reportRequestData.endDate as string,
				fi: this.fiId,
				fileTypes: [],
				isReady: false,
				link: '',
				message: '',
				reportId: reportResponse.reportId,
				reportStatus: ReportStatus.PENDING,
				reportStatusDisplay: 'Pending',
				reportType: this.selectedReport.value,
				reportTypeDisplay: this.selectedReport.displayName,
				requested: timestamp,
				requestedDisplay: moment(timestamp).format('MMM Do, hh:mm'),
				startDate: this.reportRequestData.startDate as string,
				statusCode: reportResponse.statusCode,
			};
			this.addReportToQue(queuedReport);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.reportLoading = false;
			this.getQueuedReports();
			this.pollingReportStatus();
		}
	}

	getQueuedReports(): void {
		// reports lasts 24 hour
		const now: number = new Date().getTime();
		const validReports: QueuedReport[] = JSON.parse(localStorage.queuedReports).filter(
			(report: QueuedReport) => now - report.requested < 24 * 3600 * 1000,
		);

		localStorage.queuedReports = JSON.stringify(validReports);

		this.queuedReports = JSON.parse(localStorage.queuedReports).filter(
			(report: QueuedReport) => report.fi === this.fiId,
		);

		this.queuedReports.sort((a, b) => b.requested - a.requested);
	}

	// eslint-disable-next-line class-methods-use-this
	addReportToQue(report: QueuedReport): void {
		const reports = JSON.parse(localStorage.queuedReports);
		reports.push(report);
		localStorage.queuedReports = JSON.stringify(reports);
	}

	// eslint-disable-next-line class-methods-use-this
	removeReportToQue(reportId: string): void {
		let reports = JSON.parse(localStorage.queuedReports);
		reports = reports.filter((report: QueuedReport) => report.reportId !== reportId);
		localStorage.queuedReports = JSON.stringify(reports);
		this.getQueuedReports();
	}

	get canView(): boolean {
		return this.canRead(VKLOANS);
	}
}
