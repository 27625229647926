
import KPage from '@/components/KPage.vue';
import { Component } from 'vue-property-decorator';
import BaseFiPage from '@/pages/BaseFiPage';
import KCard from '@/components/KCard.vue';
import { DataTableHeader } from 'vuetify';
import LoanImporterService from '@/services/LoanImporterService';
import moment from 'moment';
import { BrokenLoan, BrokenLoanItem } from '@/services/types/loan/LoanImport';
import { RouteTo } from '@/router/types';
import { Dialog, NoticeClass, NoticeResponse } from '@kasasa/fbase-components/lib';
import { handleErrorResponse } from '@/utils/xhr';

@Component({
	components: {
		KPage,
		KCard,
	},
})
export default class LoanImportPage extends BaseFiPage {
	async created(): Promise<void> {
		try {
			this.loading = true;
			const data = await this.loanImporterService.getBrokenLoanList(this.fiId);
			this.items = data.body?.map((item:BrokenLoan) => ({
				name: item.consumerName,
				id: item.id,
				submitted: moment.utc(item.timestamp).local().format('MM/DD/YYYY hh:mm A'),
			}));
		} catch (error) {
			await handleErrorResponse((error as any).response, this);
		} finally {
			this.loading = false;
		}
	}

	protected loanImporterService: LoanImporterService = new LoanImporterService();

	loading: boolean = false;

	headers: DataTableHeader[] = [
		{
			text: 'Name',
			value: 'name',
			align: 'start',
		},
		{
			text: 'Submitted',
			value: 'submitted',
			align: 'start',
		},
		{
			text: 'Actions',
			value: 'id',
			width: '5%',
			align: 'center',
			sortable: false,
		},
	];

	items:BrokenLoanItem[] = [];

	getViewLoanImportRoute(item: BrokenLoanItem): RouteTo {
		return {
			name: 'view-loan-import',
			params: { clientId: this.clientId, importId: item.id },
		};
	}

	goToBrokenLoanOverview(item: BrokenLoanItem): void {
		this.$router.push(this.getViewLoanImportRoute(item));
	}

	async showDeleteDialog(item: BrokenLoanItem): Promise<void> {
		const dialog: Dialog = new Dialog(
			'Delete Loan',
			'Are you sure you want to delete this loan?',
			'Yes',
		);
		dialog.setDeclineLabel('Cancel');

		const itemIndex = this.items.map((x) => x.id).indexOf(item.id);
		const response = await this.$store.dispatch('notices/add', dialog);
		switch (response) {
			case NoticeResponse.ACCEPT:
				await this.deleteBrokenLoan(this.fiId, item.id);
				this.items.splice(itemIndex, 1);
				this.showAlert('Loan has been deleted.', NoticeClass.SUCCESS, 5000);
				break;
			case NoticeResponse.DECLINE:
				// eslint-disable-next-line no-fallthrough
			default:
				// Do nothing
				break;
		}
	}

	async deleteBrokenLoan(fiId: string, brokenLoanId: string): Promise<void> {
		try {
			await this.loanImporterService.deleteBrokenLoan(fiId, brokenLoanId);
		} catch (err) {
			await handleErrorResponse((err as any).response, this);
		}
	}
}
