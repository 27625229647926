
import { Component, Provide, Vue } from 'vue-property-decorator';
import { mapState } from 'vuex';
import { namespace } from 'vuex-class';
import {
	KPortalHeader,
	KAppToolbar,
	KAppToolbarBtn,
	KNavigationDrawer,
	KNoticeContainer,
} from '@kasasa/fbase-components';
import { MenuHeading, MenuItem } from '@kasasa/fbase-components/lib';
import formatters, { Formatters } from '@/utils/formatters';
import PendoInit from '@/pendoInit';

const navigation = namespace('navigation');

@Component({
	components: {
		KPortalHeader,
		KAppToolbar,
		KNavigationDrawer,
		KNoticeContainer,
		KAppToolbarBtn,
	},
	computed: {
		...mapState(['auth']),
	},
})
export default class App extends Vue {
	@navigation.State('menuItems')
	menuItems!: (MenuItem | MenuHeading)[];

	drawer: boolean = true;

	@Provide()
	format: Formatters = formatters;

	created(): void {
		this.pendoInit('c7a2fdd1-b816-4234-44a0-7f7ba41d87fd');
	}

	// eslint-disable-next-line class-methods-use-this
	pendoInit(apiKey: string): void {
		PendoInit(apiKey);
	}
}
