
import KPage from '@/components/KPage.vue';
import { Component, Inject } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import AuditLogTable from '@/components/AuditLogTable.vue';
import { AuditLog } from '@/services/types/AuditLog';
import { Formatters } from '@/utils/formatters';

import BaseFiPage from '@/pages/BaseFiPage';
import { handleErrorResponse } from '@/utils/xhr';

@Component({
	components: {
		KPage,
		KReadOnly,
		AuditLogTable,
	},
})
export default class FiAuditLog extends BaseFiPage {
	@Inject('format') format!: Formatters;

	loadingLogs: boolean = true;

	logs: AuditLog[] = [];

	search: string = '';

	total: Number = 0;

	async created(): Promise<void> {
		this.getAuditLogs();
	}

	newPageRequest(page: Number) {
		this.getAuditLogs(true, page);
	}

	async getAuditLogs(force: boolean = false, page: Number = 1): Promise<void> {
		if (force || this.logs.length === 0) {
			try {
				this.loadingLogs = true;
				const response = await this.loanSvc.getAuditLogs(this.fiId, this.fiId, page);
				this.logs = response.data.data;
				this.total = response.data.paging.size;
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			} finally {
				this.loadingLogs = false;
			}
		}
	}

	get canView(): boolean {
		return this.canRead('vkloans');
	}
}
