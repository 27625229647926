
import {
	Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import {
	Transaction,
	TransactionMethod,
	TransactionStatus,
	TransactionType,
} from '@/services/types/loan/LoanTransaction';
import { KReadOnly } from '@kasasa/fbase-components';
import KCard from '@/components/KCard.vue';
import RelatedTransactionCard from '@/components/loan/transactions/RelatedTransactionCard.vue';
import { ExtendedBankAccount } from '@/services/types/BankAccount';

@Component({
	components: {
		KCard,
		KReadOnly,
		RelatedTransactionCard,
	},
	enums: {
		TransactionMethod,
		TransactionStatus,
		TransactionType,
	},
})
export default class TransactionGeneralCard extends Vue {
	@Inject('format')
	format!: object;

	@Prop({ required: true })
	data!: Transaction;

	@Prop({ required: true })
	bankAccounts!: ExtendedBankAccount[];

	@Prop({ required: true })
	consumerId!: string;

	get paymentMethod(): string {
		return this.data.checkNumber ? this.data.checkNumber : this.paymentAccountName;
	}

	get paymentMethodLabel(): string {
		return this.data.checkNumber ? 'Check Number' : 'Payment Account';
	}

	get paymentAccountName(): string {
		if (this.data && this.data.paymentAccountId && this.bankAccounts.length) {
			const account = this.bankAccounts.find(
				(acct) => acct.id === this.data.paymentAccountId,
			) as ExtendedBankAccount;

			if (account) {
				return `${account.institution.name} - ${account.name} (*${account.accountNumberLastFour})`;
			}
		}

		return '';
	}
}
