
import {
	Component, Inject, Prop,
} from 'vue-property-decorator';
import {
	ExtendedTransaction, TransactionStatus, TransactionMethod,
} from '@/services/types/loan/LoanTransaction';
import { DataTableHeader } from 'vuetify';
import BaseComponent from '@/components/BaseComponent';
import { VKLOANS } from '@/pages/PermissionGroups';
import { Formatters } from '@/utils/formatters';

@Component({
	enums: {
		TransactionStatus,
		TransactionMethod,
	},
})
export default class PendingTransactionsTable extends BaseComponent {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	items!: ExtendedTransaction[];

	@Prop({ required: true })
	title!: string;

	@Prop({ default: false })
	loading!: boolean;

	@Prop({ default: false })
	hideDefaultFooter!: boolean;

	sortDesc: boolean[] = [];

	loadingText: string = 'Loading transactions...';

	headers: DataTableHeader[] = [
		{
			text: 'Date', value: 'effectiveDate',
		},
		{
			text: 'Description', value: 'description',
		},
		{
			text: 'Payment Method', value: 'transactionMethod',
		},
		{
			text: 'Amount', value: 'amount',
		},
		{
			text: 'Actions', value: 'actions',
		},
	];

	baseRowClass: string = 'transaction-row';

	rowClass(item: ExtendedTransaction): string {
		return `${this.baseRowClass} status-${item.status}`;
	}

	get canModifyLoan():boolean {
		return this.canWrite(VKLOANS);
	}
}
