import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { ExtendedBankAccount } from '@/services/types/BankAccount';
import { DropdownOption } from '@/types/Form';

export interface BankAccountState {
	bankAccounts: ExtendedBankAccount[];
}

@Module({
	namespaced: true,
	name: 'BankAccount',
})
export default class BankAccount extends VuexModule {
	private bankAccounts: ExtendedBankAccount[] = [];

	@Mutation
	setBankAccounts(accounts: ExtendedBankAccount[]): void {
		this.bankAccounts = accounts;
	}

	get getBankAccounts(): ExtendedBankAccount[] {
		return this.bankAccounts;
	}

	get bankAccountDropdownOptions(): DropdownOption[] {
		return this.bankAccounts.map((bankAccount: ExtendedBankAccount) => ({
			text: bankAccount.verified
				? `${bankAccount.institution.name} - (*${bankAccount.accountNumberLastFour})`
				: `${bankAccount.institution.name} - (*${bankAccount.accountNumberLastFour}) - Pending`,
			value: bankAccount.id,
			disabled: !bankAccount.verified,
		}));
	}
}
