import {
	Component, Vue,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
	Alert,
	AuthGroupManager,
	Dialog,
	NoticeClass,
	NoticeResponse,
	NoticeType,
	SessionData,
} from '@kasasa/fbase-components/lib';
import Notice from '@kasasa/fbase-components/src/store/modules/types/Notice';

const auth = namespace('auth');
const notices = namespace('notices');

@Component
export default class BaseComponent extends Vue {
	@auth.State('authManager')
	authManager!: AuthGroupManager;

	@auth.State('sessionData')
	sessionData!: SessionData;

	@notices.Action('add')
	noticesAdd!: (notice: Notice) => Promise<NoticeResponse>;

	@notices.Action('reset')
	noticesReset!: (notice: NoticeType) => Promise<void>;

	canWrite(group: string): boolean {
		// Yes, we use read to control write access for this group name.
		// writeReadGroups is an array of permissions where READ controls WRITE.
		const writeReadGroups = ['kloansEditProfile', 'kloansSetup'];

		if (writeReadGroups.includes(group)) {
			return this.authManager?.canRead(group);
		}

		return this.authManager?.canWrite(group);
	}

	canRead(group: string): boolean {
		return this.authManager?.canRead(group);
	}

	// By default, duration is -1, meaning stay until dismissed.
	// Any other duration value is treated as microseconds, so 1000 is 1 second.
	async showAlert(message: string, type: NoticeClass, duration?: number): Promise<NoticeResponse> {
		const alert: Alert = new Alert(
			message,
			type,
		);

		if (duration) {
			alert.setTimeout(duration);
		}
		this.noticesReset(NoticeType.ALERT);
		return this.noticesAdd(alert);
	}

	async showDialog(dialog: Dialog): Promise<NoticeResponse> {
		return this.noticesAdd(dialog);
	}
}
