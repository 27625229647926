
import { Component, Inject, Prop } from 'vue-property-decorator';
import {
	Fee,
	FeeStatus,
	FeeType,
	LoanFeeTransaction,
} from '@/services/types/loan/Fee';
import { CommentReferenceTypes, Comment } from '@/services/types/loan/Comment';
import CommentsService from '@/services/CommentsService';
import { handleErrorResponse } from '@/utils/xhr';
import NestedTransactionsTable from '@/components/loan/fees/NestedTransactionsTable.vue';
import { DataTableHeader } from 'vuetify';
import BaseComponent from '@/components/BaseComponent';
import CommentsCard from '../comments/CommentsCard.vue';

@Component({
	components: {
		NestedTransactionsTable,
		CommentsCard,
	},
	enums: {
		FeeStatus,
		FeeType,
		CommentReferenceTypes,
	},
})
export default class OutstandingFeesTable extends BaseComponent {
	@Inject('format')
	format!: object;

	@Prop({ required: true })
	fiId!: string;

	@Prop({ required: true })
	loanId!: string;

	@Prop({ required: true })
	items!: Fee[];

	@Prop({ required: true })
	title!: string;

	@Prop({ default: false })
	loading!: boolean;

	@Prop({ default: false })
	hideDefaultFooter!: boolean;

	@Prop({ default: false })
	canModify!: boolean;

	commentItems: Comment[] = [];

	loadingComments: boolean = true;

	commentsSvc: CommentsService = new CommentsService(this.$store);

	showCommentsCard: boolean = false;

	commentFeeId: string = '';

	get showExpand(): boolean {
		return this.items.some((fee: Fee) => fee.loanFeeTransactions.length > 0);
	}

	onReverseWaiver(item: LoanFeeTransaction): void {
		this.$emit('reverse-waiver', item);
	}

	expanded: Fee[] = [];

	sortDesc: boolean[] = [];

	loadingText: string = 'Loading fees...';

	headers: DataTableHeader[] = [
		{
			text: 'Date',
			value: 'assessmentDate',
		},
		{
			text: 'Fee Type',
			value: 'feeTypeId',
		},
		{
			text: 'Amount',
			value: 'amount',
		},
		{
			text: 'Paid Amount',
			value: 'paidAmount',
		},
		{
			text: 'Waived Amount',
			value: 'waivedAmount',
		},
		{
			text: 'Actions',
			value: 'actions',
		},
	];

	baseRowClass: string = 'fee-row';

	rowClass(): string {
		return `${this.baseRowClass}`;
	}

	async openCommentsCard(fee: Fee) {
		this.commentFeeId = fee.id;
		this.showCommentsCard = true;
		this.loadingComments = true;
		await this.getComments(fee.id);
		this.loadingComments = false;
	}

	async getComments(id: string): Promise<void> {
		try {
			const response = await this.commentsSvc.getComments(
				this.loanId,
				undefined,
				undefined,
				id,
			);
			this.commentItems = response.data.data;
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		}
	}

	dismiss(): void {
		this.showCommentsCard = false;
	}
}
