
import {
	Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import {
	Fee,
	FeeStatus,
	FeeType,
	FeeTransactionType,
	LoanFeeTransaction,
} from '@/services/types/loan/Fee';
import { DataTableHeader } from 'vuetify';

@Component({
	enums: {
		FeeStatus,
		FeeType,
		FeeTransactionType,
	},
})
export default class NestedTransactionsTable extends Vue {
	@Inject('format')
	format!: object;

	@Prop({ required: true })
	items!: LoanFeeTransaction[];

	@Prop({ default: false })
	loading!: boolean;

	@Prop({ default: false })
	canReverse!: boolean;

	title: string = 'Transactions';

	expanded: Fee[] = [];

	sortDesc: boolean[] = [];

	loadingText: string = 'Loading fees...';

	headers: DataTableHeader[] = [
		{
			text: 'Date', value: 'effectiveDate',
		},
		{
			text: 'Type', value: 'feeTransactionType',
		},
		{
			text: 'Amount', value: 'amount',
		},
		{
			text: '', value: 'actions',
		},
	];

	baseRowClass: string = 'fee-transaction-row';

	rowClass(): string {
		return `${this.baseRowClass}`;
	}
}
