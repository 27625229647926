
import KPage from '@/components/KPage.vue';
import { Component } from 'vue-property-decorator';
import { KReadOnly, KSpinner } from '@kasasa/fbase-components';
import BaseLoanPage from '@/pages/loan/BaseLoanPage';
import { Dialog, NoticeClass } from '@kasasa/fbase-components/lib';
import FeatureCheckbox from '@/components/FeatureCheckbox.vue';
import FeatureSwitch from '@/components/FeatureSwitch.vue';
import { LoanModification, Status } from '@/services/types/loan/Maintenance';
import {
	MetadataFlag,
	MetadataPayload,
	Metadata,
} from '@/services/types/loan/Metadata';
import { Dictionary } from 'vue-router/types/router.d';
import { namespace } from 'vuex-class';
import { LoanStatus } from '@/services/types/loan/Loan';
import { DropdownOption } from '@/types/Form';
import SkipAPay from '@/components/loan/modifications/SkipAPay.vue';
import InsuranceModification from '@/components/loan/modifications/InsuranceModification.vue';
import { handleErrorResponse } from '@/utils/xhr';

const loanMaintenanceStoreModule = namespace('maintenance');

interface Feature {
	available: boolean;
	inverted?: boolean;
	name: string;
	label: string;
	state: boolean;
	tooltip: string;
	id: string;
}

enum FeatureName {
	INTEREST_ACCRUAL = 'interestAccrual',
	BYPASS_CREDIT_REPORTING = 'bypassCreditReporting',
	TAKE_BACK_ENABLED = 'takeBackEnabled',
}

@Component({
	components: {
		FeatureCheckbox,
		FeatureSwitch,
		KPage,
		KReadOnly,
		KSpinner,
		SkipAPay,
		InsuranceModification,
	},
	enums: {
		MetadataFlags: MetadataFlag,
	},
})
export default class LoanMaintenancePage extends BaseLoanPage {
	selectedComplianceCode: string = 'IsNull';

	specialComment: string = '';

	status: Status | null = null;

	loadingFeatures: Dictionary<boolean> = {
		interestAccrual: false,
		bypassCreditReporting: false,
		takeBackEnabled: false,
		paymentsEnabled: false,
	};

	loadingAddReportingInfo: boolean = false;

	loanMetadataOptions: Record<MetadataFlag, boolean> = {
		test_loan: false,
		employee_loan: false,
		collateral_repossession: false,
		special_comment: false,
		mla_loan: false,
		executive_loan: false,
		board_member_loan: false,
		compliance_condition_code: false,
	};

	complianceOptions: DropdownOption[] = [
		{
			value: 'IsNull',

			text: 'No Compliance Condition Code applies (default value)',
		},
		{
			value: 'XA',

			text: 'Account closed at consumer’s request',
		},
		{
			value: 'XB',

			text: 'Account information has been disputed by the consumer directly to the data furnisher under the Fair Credit Reporting Act; the data furnisher is conducting an investigation.',
		},
		{
			value: 'XC',

			text: 'Fair Credit Reporting Act direct dispute investigation completed – consumer disagrees with the results of the data furnisher’s investigation.',
		},
		{
			value: 'XD',

			text: 'Account closed at the consumer’s request; and account information disputed by the consumer directly to the data furnisher under the Fair Credit Reporting Act; the data furnisher is conducting its investigation.',
		},
		{
			value: 'XE',

			text: 'Account closed at consumer’s request; and data furnisher has completed its investigation; consumer disagrees with the results of the investigation. (To be used for direct disputes under the Fair Credit Reporting Act or Fair Credit Billing Act disputes)',
		},
		{
			value: 'XF',

			text: 'Account in disputed under Fair Credit Billing Act; the data furnisher is conducting its investigation.',
		},
		{
			value: 'XG',

			text: 'Fair Credit Billing Act dispute investigation completed – consumer disagrees with the results of the data furnisher’s investigation. ',
		},
		{
			value: 'XH',

			text: 'Account previously in dispute; the data furnisher has completed its investigation. (To be used for direct disputes under the Fair Credit Reporting Act, Fair Debt Collection Practices Act, or Fair Credit Billing Act disputes) ',
		},
		{
			value: 'XJ',

			text: 'Account closed at the consumer’s request; and account information disputed by the consumer under Fair Credit Billing Act; the data furnisher is conducting its investigation. ',
		},
		{
			value: 'XR',

			text: 'Removes the most recent reported Compliance Condition Code',
		},
	];

	showInsuranceMod: boolean = false;

	specialCommentOptions: DropdownOption[] = [
		{ text: 'None', value: '0' },
		{
			text: 'Account Payments Managed by Financial Counseling Program',
			value: 'B',
		},
		{ text: 'Paid by Co-Maker or Guarantor', value: 'C' },
		{ text: 'Loan Assumed by Another Party', value: 'H' },
		{ text: 'Election of Remedy', value: 'I' },
		{ text: "Account Closed at Credit Grantor's Request", value: 'M' },
		{ text: 'Account Transferred to Another Lender', value: 'O' },
		{
			text: 'Special Handling - Contact Credit Grantor for Additional Information',
			value: 'S',
		},
		{ text: 'Adjustment Pending', value: 'V' },
		{ text: 'Debt Being Paid Through Insurance', value: 'AB' },
		{ text: 'Paying Under A Partial Payment Agreement', value: 'AC' },
		{ text: 'Purchased By Another Company', value: 'AH' },
		{ text: 'Recalled To Active Military Duty', value: 'AI' },
		{ text: 'Account Payments Assured By Wage Garnishment', value: 'AM' },
		{ text: 'Account Acquired By FDIC/NCUA', value: 'AN' },
		{
			text: 'Voluntarily Surrendered - then Redeemed or Reinstated',
			value: 'AO',
		},
		{ text: 'Credit Line Suspended', value: 'AP' },
		{ text: 'Account Closed Due To Refinance', value: 'AS' },
		{ text: 'Account Closed Due To Transfer', value: 'AT' },
		{
			text: 'Account Paid In Full For Less Than The Full Balance',
			value: 'AU',
		},
		{ text: 'First Payment Never Received', value: 'AV' },
		{ text: 'Affected By Natural or Declared Disaster', value: 'AW' },
		{ text: 'Account Paid From Collateral', value: 'AX' },
		{ text: 'Redeemed or Reinstated Repossession', value: 'AZ' },
		{ text: 'Transferred To Recovery', value: 'BA' },
		{
			text: 'Paid By Company Which Originally Sold the Merchandise',
			value: 'BN',
		},
		{ text: 'Paid Through Insurance', value: 'BP' },
		{ text: 'Principal Deferred/Interest Payment Only', value: 'BT' },
		{ text: 'Guaranteed/Insured', value: 'CH' },
		{ text: 'Account Closed Due to Inactivity', value: 'CI' },
		{
			text: 'Credit Line No Longer Available - in Repayment Phase',
			value: 'CJ',
		},
		{ text: 'Credit Line Reduced Due to Collateral Depreciation', value: 'CK' },
		{
			text: 'Credit Line Suspended Due to Collateral Depreciation',
			value: 'CL',
		},
		{ text: 'Collateral Released by Creditor / Balance Owing', value: 'CM' },
		{ text: 'Loan Modified Under a Federal Government Plan', value: 'CN' },
		{
			text: 'Loan Modified, but Not Under a Federal Government Plan',
			value: 'CO',
		},
		{ text: 'Account in Forbearance', value: 'CP' },
	];

	interestAccrual: boolean = false;

	showSkipAPayModal: boolean = false;

	@loanMaintenanceStoreModule.Getter('isLoanClosed')
	isLoanClosed!: () => boolean;

	@loanMaintenanceStoreModule.Mutation('setLoanMaintenanceStatus')
	setLoanMaintenanceStatus!: (status: Status) => void;

	async loanModCompleted(): Promise<void> {
		this.showInsuranceMod = false;

		// Refresh modified loan data in store
		await this.fetchAndSetLoan(true);
	}

	async onSkipAPayModComplete(): Promise<void> {
		this.showSkipAPayModal = false;
		await this.fetchAndSetLoan(true);
	}

	async setReportingMetadata(metadataKey: MetadataFlag): Promise<void> {
		let value = this.loanMetadataOptions[metadataKey] ? '1' : '0';

		if (metadataKey === MetadataFlag.SPECIAL_COMMENT) {
			value = this.specialComment;
			this.loanMetadataOptions[metadataKey] =	!this.loanMetadataOptions[metadataKey];
		}

		if (metadataKey === MetadataFlag.COMPLIANCE_CODE) {
			value = this.selectedComplianceCode;
			this.loanMetadataOptions[metadataKey] =	!this.loanMetadataOptions[metadataKey];
		}

		const payload: MetadataPayload = {
			key: metadataKey,
			value,
		};

		try {
			this.loadingAddReportingInfo = true;

			await this.loanSvc.postMetadata(this.fiId, this.loanId, payload);
			await this.fetchAndSetLoan(true);
		} catch (error) {
			this.loanMetadataOptions[metadataKey] =	!this.loanMetadataOptions[metadataKey];
			this.showAlert(
				'Error occurred while setting up reporting information. Please try reloading the page.',
				NoticeClass.ERROR,
			);
		} finally {
			this.loadingAddReportingInfo = false;
		}
	}

	async getStatus(force: boolean = false): Promise<Status | null> {
		if (force || this.status === null) {
			try {
				const response = await this.loanSvc.getLoanStatus(this.loanId);
				this.status = response.data.data;
				this.setLoanMaintenanceStatus(response.data.data);
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			}
		}

		if (this.status) {
			if (this.status.status === LoanStatus.HOLD) {
				this.interestAccrual = false;
			}

			if (this.status.status === LoanStatus.ACTIVE) {
				this.interestAccrual = true;
			}
		}

		return this.status;
	}

	activeLoanMods: LoanModification[] = [];

	validateSkipAPayEnable(): void {
		if (this.status?.delinquentDays === 0) {
			this.showSkipAPayModal = true;
		} else {
			const dialog = new Dialog(
				'Skip-A-Pay Not Available ',
				'Cannot Skip-a-pay on a delinquent loan.',
			);
			this.showDialog(dialog);
		}
	}

	async getActiveLoanMods(force: boolean = false): Promise<LoanModification[]> {
		if (force || this.activeLoanMods.length === 0) {
			try {
				const response = await this.loanSvc.getActiveLoanMods(
					this.fiId,
					this.loanId,
				);
				this.activeLoanMods = response.data.data;
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			}
		}

		// This code is used during local dev to automatically remove blocking loan mods to expedite testing.
		// It is located in this page, so that the modification page can be accessed without impacting existing
		// modifications in order to test either state more easily. -- Karl
		// if (this.activeLoanMods.length) {
		// 	this.activeLoanMods.forEach(async (mod: LoanModification) => {
		// 		await this.loanSvc.cancelLoanMod(this.fiId, this.loanId, mod.id);
		// 	});

		// 	this.activeLoanMods = [];
		// }

		return this.activeLoanMods;
	}

	get features(): Feature[] {
		this.getStatus();
		if (this.status) {
			return [
				{
					available: this.status.canPauseResume,
					name: FeatureName.INTEREST_ACCRUAL,
					label: 'Interest Accrual',
					state: this.status?.status !== 'hold',
					tooltip:
						'Toggling this feature will pause or resume interest accrual for this loan.',
					id: 'interestAccrualToggle',
				},
				{
					available: true,
					inverted: !this.isTestLoan,
					name: FeatureName.BYPASS_CREDIT_REPORTING,
					label: 'Credit Bureau Reporting',
					state: this.isTestLoan ? false : this.status.bypassCreditReporting,
					tooltip:
						'Toggling this feature will turn on or off reporting of this loan to credit bureaus.',
					id: 'bypassCreditReportingToggle',
				},
				{
					available: true,
					name: FeatureName.TAKE_BACK_ENABLED,
					label: 'Take-Backs',
					state: this.status.takeBackEnabled,
					tooltip:
						'Toggling this feature will turn on or off Take-Backs for this loan.',
					id: 'takeBackEnabledToggle',
				},
				{
					available: typeof this.status.paymentsEnabled !== 'undefined',
					name: 'paymentsEnabled',
					label: 'Payments',
					state: this.status.paymentsEnabled,
					tooltip:
						'Toggling this feature will enable or disable the ability to make payments on this loan.',
					id: 'paymentsEnabledToggle',
				},
			];
		}

		return [];
	}

	get availableFeatures(): Feature[] {
		return this.features.filter((feature: Feature) => feature.available);
	}

	get isTestLoan(): boolean {
		return this.loanMetadataOptions.test_loan;
	}

	get shouldDisableLoanMods(): boolean {
		return this.activeLoanMods.length > 0 || !this.canModifyLoanMaintenance;
	}

	featureDisabled(featureName: FeatureName): boolean {
		return (
			featureName === FeatureName.BYPASS_CREDIT_REPORTING && this.isTestLoan
		);
	}

	async toggleFeature(name: string): Promise<void> {
		if (this.status) {
			this.loadingFeatures[name] = true;
			if (name === FeatureName.INTEREST_ACCRUAL) {
				await this.loanSvc.toggleInterestAccrual(this.fiId, this.status);
			}

			if (name === FeatureName.BYPASS_CREDIT_REPORTING) {
				await this.loanSvc.toggleCreditReport(this.fiId, this.status);
			}

			if (name === FeatureName.TAKE_BACK_ENABLED) {
				await this.loanSvc.toggleTakeBacks(this.fiId, this.status);
				this.status.takeBackEnabled = !this.status.takeBackEnabled;
				this.loan.general.takeBacksEnabled = this.status.takeBackEnabled;
			}

			await this.getStatus(true);
			this.loadingFeatures[name] = false;
		}
	}

	async toggleSwitch(featureName: string): Promise<void> {
		const dialog: Dialog = this.buildToggleFeatureDialog(featureName);
		dialog.setDeclineLabel('Cancel');

		const response = await this.showDialog(dialog);
		if (response === 'accept') {
			await this.toggleFeature(featureName);
		}
	}

	private buildToggleFeatureDialog(featureName: string): Dialog {
		const feature: Feature = this.availableFeatures.find(
			(feat) => feat.name === featureName,
		) as Feature;
		const onOrOff = ['on', 'off'];

		if (feature.inverted) {
			onOrOff.reverse();
		}

		const action: string = feature.state ? onOrOff[1] : onOrOff[0];
		const title = `Turn ${action} ${feature.label}`;
		const message = `Are you sure you want to turn ${action} ${feature.label} for this loan?`;
		return new Dialog(title, message);
	}

	async created(): Promise<void> {
		await Promise.all([
			this.getBankruptcies(),
			this.getConsumer(),
			this.getStatus(),
			this.getActiveLoanMods(),
			this.getBorrowers(true, true),
		]);
		await this.fetchAndSetFeatureFlags(this.fiId);

		this.loan?.metadata?.forEach((metadata: Metadata) => {
			if (metadata.key === MetadataFlag.SPECIAL_COMMENT) {
				this.specialComment = metadata.value;
			}

			if (metadata.key === MetadataFlag.COMPLIANCE_CODE) {
				this.selectedComplianceCode = metadata.value;
			}

			this.loanMetadataOptions[metadata.key] = parseInt(metadata.value, 10) !== 0;
		});

		this.loading = false;
	}
}
