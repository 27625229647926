import {
	Component, Inject, Prop, Watch,
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
	KCrumb,
	MenuHeading,
	MenuItem,
} from '@kasasa/fbase-components/lib';
import { SettingsService } from '@/services/SettingsService';
import FiService from '@/services/FiService';
import BaseComponent from '@/components/BaseComponent';
import LoanService from '@/services/LoanService';
import { Route, RouteRecord } from 'vue-router';
import { Consumer, ConsumerProfile, ConsumerService } from '@/services/ConsumerService';
import { KLOANS_GENERATE, VKLOANS, KLOANS_COLLECTIONS } from '@/pages/PermissionGroups';
import { handleErrorResponse } from '@/utils/xhr';

const navigation = namespace('navigation');

@Component
export default class BasePage extends BaseComponent {
	@navigation.Mutation('setMenuItems')
	setMenuItems!: (items: (MenuItem | MenuHeading)[]) => void;

	@navigation.Mutation('setBreadcrumbs')
	setBreadcrumbs!: (items: KCrumb[]) => void;

	@navigation.State('breadcrumbs')
	breadcrumbs!: KCrumb[];

	@Watch('$route', { immediate: true, deep: true })
	async onRouteChange(to: Route): Promise<void> {
		const currentRoute = to.path.split('/');

		const routes: RouteRecord[] = to.matched.filter((route) => Boolean(route.meta.breadcrumb));
		const breadcrumbs: KCrumb[] = await Promise.all(routes.map(async (route, index) => {
			const path = route.path.split('/');
			const toRoute = currentRoute.slice(0, path.length).join('/');
			let { text } = route.meta.breadcrumb;
			if (route.meta.replaceConsumerName) {
				await this.getConsumer();
				text = route.meta.replaceConsumerName(this.getConsumersName());
			}
			return {
				...route.meta.breadcrumb,
				to: index === 0 ? null : toRoute,
				link: index !== 0,
				exact: index !== 0,
				disabled: Boolean(index === routes.length - 1) && index !== 0, // last breadcrumb should be disabled
				text,
			};
		}));

		if (this.shouldForfeitUnfufilledPromise) {
			return;
		}
		this.setBreadcrumbs(breadcrumbs);
	}

	@Prop({ required: true })
	fiId!: string;

	@Prop({ required: true })
	clientId!: string;

	@Prop()
	consumerId!: string;

	@Prop()
	loanId!: string;

	@Inject('format')
	format!: object;

	loading: boolean = true;

	protected loanSvc: LoanService = new LoanService(this.$store);

	protected fiService: FiService = new FiService(this.$store);

	protected consumerSvc: ConsumerService = new ConsumerService(this.$store);

	protected settingsSvc: SettingsService = new SettingsService(this.$store);

	menuItems: (MenuItem | MenuHeading)[] = [];

	breadCrumbs: KCrumb[] = [];

	consumer: Consumer | null = null;

	consumerProfile: ConsumerProfile | null = null;

	get canView(): boolean {
		return this.canRead(VKLOANS);
	}

	get canGenerate(): boolean {
		return this.canRead(KLOANS_GENERATE) || this.canWrite(KLOANS_GENERATE);
	}

	get canModifyLoan(): boolean {
		return this.canRead(VKLOANS) && this.canWrite(VKLOANS);
	}

	get canReadCollections(): boolean {
		return this.canRead(KLOANS_COLLECTIONS);
	}

	get canWriteCollections(): boolean {
		return this.canWrite(KLOANS_COLLECTIONS);
	}

	async getConsumer(force: boolean = false): Promise<Consumer | null> {
		if (force || this.consumer === null) {
			try {
				const responseConsumer = await this.consumerSvc.getConsumer(this.fiId, this.consumerId);
				this.consumer = responseConsumer.data.data;
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			}
			try {
				const responseProfile = await this.consumerSvc.getConsumerProfile(this.fiId, this.consumerId);
				this.consumerProfile = responseProfile.data.data;
			} catch (error) {
				// show consumerProfile as unregistered
				this.consumerProfile = {
					username: '',
					phoneNumber: '',
					name: this.consumer?.name ?? '',
					email: this.consumer?.email ?? '',
					id: this.consumerId,
					createdAt: '',
					status: 'Unregistered',
					startDate: '',
				};
			}
		}

		return this.consumer;
	}

	getConsumersName(): string {
		if (this.consumer && this.consumer.name) {
			return this.consumer.name;
		}

		return 'MIA';
	}

	created(): void {
		this.setMenuItems(this.menuItems);
	}

	shouldForfeitUnfufilledPromise: boolean = false;

	destroyed(): void {
		this.shouldForfeitUnfufilledPromise = true;
	}
}
