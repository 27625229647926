
import KPage from '@/components/KPage.vue';
import KCard from '@/components/KCard.vue';
import { Component, Inject } from 'vue-property-decorator';
import BaseLoanPage from '@/pages/loan/BaseLoanPage';
import CommentsCard from '@/components/loan/comments/CommentsCard.vue';
import { Comment, CommentReferenceTypes } from '@/services/types/loan/Comment';
import CommentsService from '@/services/CommentsService';
import { handleErrorResponse } from '@/utils/xhr';
import { KSpinner } from '@kasasa/fbase-components';
import { Formatters } from '@/utils/formatters';

@Component({
	components: {
		KPage,
		CommentsCard,
		KSpinner,
		KCard,
	},
	enums: { CommentReferenceTypes },
})
export default class LoanCommentsPage extends BaseLoanPage {
	@Inject('format')
	format!: Formatters;

	showModal: boolean = false;

	modalMode = '';

	loading: boolean = true;

	commentEditBody: string = '';

	commentId: string = '';

	editCommentObj: Comment = {
		id: '',
		loanId: '',
		body: '',
		metadata: [],
		referenceType: '',
		referenceId: '',
		submittedBy: '',
		submittedById: '',
		lastEditedBy: '',
		lastEditedById: '',
		submittedDate: '',
	};

	commentsSvc: CommentsService = new CommentsService(this.$store);

	commentItems: Comment[] = [];

	async created(): Promise<void> {
		await Promise.all([this.getConsumer(), this.getComments()]);
	}

	get canView(): boolean {
		return this.canRead('vkloans');
	}

	async getComments(beginDate?: string, endDate?: string): Promise<void> {
		this.loading = true;
		try {
			const response = await this.commentsSvc.getComments(
				this.loanId,
				beginDate,
				endDate,
			);
			const formattedComments: Comment[] = response.data.data;

			formattedComments.forEach((comment: Comment): void => {
				if (comment.submittedDate) {
					comment.submittedDate = this.format.asDate(comment.submittedDate);
				}

				if (comment.lastEditedDate) {
					comment.lastEditedDate = this.format.asDate(comment.lastEditedDate);
				}

				if (comment.referenceType === this.$enums.CommentReferenceTypes.LATE_FEE
				|| comment.referenceType === this.$enums.CommentReferenceTypes.NSF_FEE) {
					comment.referenceType = 'fee';
				}
			});
			this.commentItems = response.data.data;
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.loading = false;
		}
	}
}
