
import KPage from '@/components/KPage.vue';
import { Component, Inject } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import {
	Collection,
} from '@/services/ConsumerService';
import BaseFiPage from '@/pages/BaseFiPage';
import CollectionTable from '@/components/CollectionTable.vue';
import { Formatters } from '@/utils/formatters';
import { VKLOANS } from '@/pages/PermissionGroups';
import { handleErrorResponse } from '@/utils/xhr';

@Component({
	components: {
		KPage,
		KReadOnly,
		CollectionTable,
	},
})
export default class CollectionsListPage extends BaseFiPage {
	@Inject('format') format!: Formatters;

	collectionsLoading: boolean = true;

	collections: Collection[] = [];

	search: string = '';

	async created(): Promise<void> {
		this.loadData();
	}

	async loadData(): Promise<void> {
		try {
			this.collectionsLoading = true;
			const response = await this.consumerSvc.getCollectionsForFi(this.fiId);
			this.collections = response.data.data;
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.collectionsLoading = false;
		}
	}

	get canView(): boolean {
		return this.canRead(VKLOANS);
	}
}
