
import KCard from '@/components/KCard.vue';
import {
	Component,
	Prop,
	Vue,
	Watch,
	VModel,
} from 'vue-property-decorator';
import {
	KRadioGroup,
	KReadOnly,
	KSelect,
	KText,
} from '@kasasa/fbase-components';
import { AchAccount } from '@/services/AchService';
import {
	LengthValidation,
	RequiredValidation,
	RoutingNumberValidation,
} from '@kasasa/kloans-common-ui/dist/validations';
import { Validations } from 'vuelidate-property-decorators';
import { displayMode } from '@/router/types';
import { RuleDecl } from 'vue/types/options.d';

@Component({
	components: {
		KCard,
		KRadioGroup,
		KReadOnly,
		KSelect,
		KText,
	},
	enums: {
		displayMode,
	},
})
export default class AchCard extends Vue {
	@VModel()
	data!: AchAccount | null;

	@Prop({ default: false })
	canWrite!: boolean;

	@Prop({ default: displayMode.VIEW })
	mode!: string;

	@Validations()
	// eslint-disable-next-line class-methods-use-this
	validations(): RuleDecl {
		const data = {
			description: LengthValidation.validations(true, 4, 50),
			achAccountNumber: LengthValidation.validations(true, 4, 17),
			achFiRoutingNumber: RoutingNumberValidation.validations(() => true),
			achTransactionType: RequiredValidation.validations(),
			achImmediateOrigin: LengthValidation.validations(true, 9, 9),
			achImmediateOriginName: LengthValidation.validations(true, undefined, 23),
			achImmediateDestination: LengthValidation.validations(true, 9, 9),
			achImmediateDestinationName: LengthValidation.validations(true, undefined, 23),
			achCompanyIdentification: LengthValidation.validations(false, 10, 10),
			achCompanyName: LengthValidation.validations(false, undefined, 17),
			achCompanyDiscretionaryData: LengthValidation.validations(false, undefined, 20),
			achFileIdModifier: LengthValidation.validations(false, undefined, 1),
		};

		return { data };
	}

	@Watch('$v.$invalid', { immediate: true })
	onInvalidChanged(val: boolean): void {
		this.$emit('invalid-changed', val);
	}

	initialLoad: boolean = false;

	transactionItems: { [ key: string ]: string | number | undefined }[] = [
		{
			text: 'Checking Deposit (Credit)',
			value: 22,
		},
		{
			text: 'GL Deposit (Credit)',
			value: 42,
		},
	];

	referenceCodeItems: { [ key: string ]: string }[] = [
		{
			text: 'KASASA',
			value: 'KASASA',
		},
		{
			text: 'BANCVUE',
			value: 'BANCVUE',
		},
	];

	get readonly(): boolean {
		return !this.canWrite || this.mode === this.$enums.displayMode.VIEW;
	}

	get descriptionErrors(): string[] {
		return LengthValidation.errors(this, 'data.description', 4, 50);
	}

	get achAccountNumberErrors(): string[] {
		return LengthValidation.errors(this, 'data.achAccountNumber', 4, 17);
	}

	get achFiRoutingNumberErrors(): string[] {
		return RoutingNumberValidation.errors(this, 'data.achFiRoutingNumber');
	}

	get achTransactionTypeErrors(): string[] {
		return RequiredValidation.errors(this, 'data.achTransactionType');
	}

	get achImmediateOriginErrors(): string[] {
		return LengthValidation.errors(this, 'data.achImmediateOrigin', 9, 9);
	}

	get achImmediateOriginNameErrors(): string[] {
		return LengthValidation.errors(this, 'data.achImmediateOriginName', undefined, 23);
	}

	get achImmediateDestinationErrors(): string[] {
		return LengthValidation.errors(this, 'data.achImmediateDestination', 9, 9);
	}

	get achImmediateDestinationNameErrors(): string[] {
		return LengthValidation.errors(this, 'data.achImmediateDestinationName', undefined, 23);
	}

	get achCompanyIdentificationErrors(): string[] {
		return LengthValidation.errors(this, 'data.achCompanyIdentification', 10, 10, undefined, false);
	}

	get achCompanyNameErrors(): string[] {
		return LengthValidation.errors(this, 'data.achCompanyName', undefined, 17, undefined, false);
	}

	get achCompanyDiscretionaryDataErrors(): string[] {
		return LengthValidation.errors(this, 'data.achCompanyDiscretionaryData', undefined, 20, undefined, false);
	}

	get achFileIdModifierErrors(): string[] {
		return LengthValidation.errors(this, 'data.achFileIdModifier', undefined, 1, undefined, false);
	}
}
