export enum PolicyType {
	CREDIT_LIFE = 'Credit Life',
	ACCIDENT_HEALTH = 'Credit Disability',
	CREDIT_DISABILITY = 'Debt Protection',
	INVOLUNTARY_UNEMPLOYMENT = 'Involuntary Unemployment',
}

export enum CoverageType {
	SINGLE = 'S',
	JOINT = 'J',
	CO_BORROWER = 'C',
	BLENDED = 'B',
}

export const getInsuranceCoverageTypeName = (value: CoverageType): string => {
	switch (value) {
		case CoverageType.SINGLE:
			return 'Single';
		case CoverageType.JOINT:
			return 'Joint';
		case CoverageType.CO_BORROWER:
			return 'Co-Borrower';
		case CoverageType.BLENDED:
			return 'Blended';
		default:
			return 'Invalid Coverage Type';
	}
};

export interface InsuranceCoverage {
	agedOutAt: string | null;
	billingDay: number | null;
	calculatorSettings: string;
	costPerDay: string;
	coverageType: CoverageType;
	description: string;
	endDate: string | null;
	expireDate: string;
	id: string;
	insurancePolicyId: string;
	insuredAmount: string;
	insuredRate: string;
	lastPulledDate: string;
	loanDueDay: string;
	loanId: string;
	policyNumber: string;
	policyType: PolicyType;
	premium: string;
	premiumMode: string;
	startDate: string;
}

export interface InsuranceModRequestCoverage {
	coverageType: CoverageType;
	policyNumber: string;
}

export interface InsuranceModRequest {
	startDate?: string;
	insuranceCoverages: InsuranceModRequestCoverage[];
}

export enum InsuranceProductCalculationMethod {
	NO_INSURANCE = 'NoInsurance',
	SINGLE_PREMIUM_NET = 'SinglePremiumNet',
	SINGLE_PREMIUM_GROSS = 'SinglePremiumGross',
	GROSS_NET_OPTION = 'GrossNetOption',
	MOB = 'MOB',
	AGE_BANDED = 'AgeBanded',
	AGE_BANDED_NET = 'AgeBandedNet',
	DEBT_PROTECTION_SINGLE_PREMIUM = 'DebtProtectionSinglePremium',
	DEBT_PROTECTION_MOB = 'DebtProtectionMOB',
}

export interface PolicyCoverageSettings {
	id: string;
	insurancePolicyId: string;
	insuranceProductCode: string;
	coverageType: CoverageType;
	createdAt: string;
	debtProtectionCode: string;
}

export interface InsurancePolicy {
	id: string;
	fidId: string;
	insuranceIncomeAccountId: string;
	insuranceProviderId: string;
	name: string;
	policyCoverageSettings: PolicyCoverageSettings[];
	policyNumber: string;
	protectedEvents: string;
	calculationMethod: InsuranceProductCalculationMethod;
	calculatorUserCode: string;
	createdAt: string;
	updatedAt: string;
	deletedAt: string;
	startDate: string;
	endDate: string;
	terminationAge: number;
}
