import {
	ExtendedTransaction,
	Transaction,
	TransactionMethod,
	TransactionStatus,
	TransactionType,
} from '@/services/types/loan/LoanTransaction';
import moment from 'moment';
import { BankAccount, ExtendedBankAccount } from '@/services/types/BankAccount';
import { Fee, LoanFeeTransaction } from '@/services/types/loan/Fee';
import { LoanTermMod } from '@/services/types/loan/Maintenance';
import { convertDollarStringToNumberInPlace } from '@kasasa/kloans-common-ui/dist/utils/dollar';

export const formatLoanTermMod = (loanTermMod: LoanTermMod): LoanTermMod => {
	const today = moment().startOf('day');
	loanTermMod.active = loanTermMod.endDate ? moment(loanTermMod.endDate).isSameOrAfter(today) : true;
	return loanTermMod;
};

const transactionMethodsAllowedToBeReversed = [
	TransactionMethod.Cash,
	TransactionMethod.Check,
	TransactionMethod['Direct Posting'],
	TransactionMethod.ACH,
];

export const extendLoanTransaction = (transaction: Transaction): ExtendedTransaction => {
	const isCancelled = transaction.status === TransactionStatus.Canceled;
	const isCpiRefund = transaction.transactionType === TransactionType['CPI Premium Refund'];
	const isDisbursement = transaction.transactionType === TransactionType.Disbursement;
	const isExternal = transaction.transactionMethod === Number(TransactionMethod.ACH);
	const isPastCutOff = moment().isAfter(moment(`${transaction.cutOffTime} GMT-0600`));
	const isPosted = transaction.status === TransactionStatus.Posted;
	const isPrincipalChange = transaction.transactionType === TransactionType['Principal Increase'] || transaction.transactionType === TransactionType['Principal Decrease'];
	const isReversed = transaction.status === TransactionStatus.Reversed;
	const isTakeBack = transaction.transactionType === TransactionType['Take Back'];
	const isPayment = transaction.transactionType === TransactionType.Payment;
	const canCancel = (isExternal && !isPastCutOff && !isPosted && !isCancelled && !isDisbursement && !isPrincipalChange)
		|| (!isExternal && !isPastCutOff);
	const canReverse = (isPosted && isPayment && !isCancelled && !isReversed
		&& transaction.isReversible
		&& transactionMethodsAllowedToBeReversed.includes(transaction.transactionMethod)
	);

	return {
		...transaction,
		canCancel,
		canReverse,
		isCancelled,
		isCpiRefund,
		isDisbursement,
		isExternal,
		isPastCutOff,
		isPosted,
		isPrincipalChange,
		isReversed,
		isTakeBack,
		loadingCancel: false,
		loadingReverse: false,
	} as ExtendedTransaction;
};

export const extendLoanFee = (fee: Fee): Fee => {
	fee.loanFeeTransactions = fee.loanFeeTransactions.map((transaction: LoanFeeTransaction):
	LoanFeeTransaction => {
		transaction.loading = false;
		return transaction;
	});

	return fee;
};

export const extendBankAccount = (bankAccount: BankAccount): ExtendedBankAccount => {
	const result = { ...bankAccount } as ExtendedBankAccount;

	result.loadingDelete = false;
	result.transactionMethod = result.pmtAccountId === null ? TransactionMethod['Direct Posting'] : TransactionMethod.ACH;

	return result;
};

// NOTE: this appears to work really well, but 100% open to discussing differenet pattern.
// I started this method because we know in Vue3 `{ data | filter }` goes away so this avoids
// the pattern we know will not exist soon.

const KASASA_DATE = 'MM/DD/YYYY';
const KASASA_DATE_TIME = 'MM/DD/YYYY h:mm:ss A';

export default {
	asCurrency: (value: string | number, locale = 'us-EN', currencyCode = 'USD'): string =>
		(value ? Number(value).toLocaleString(locale, { style: 'currency', currency: currencyCode }) : ''),
	keyUpDollarFormat: (value: string | number): string => convertDollarStringToNumberInPlace(value).replace(/,/g, ''),
	asPercentage: (value: string | number, digits = 3, locale = 'us-EN'): string =>
		Number(value).toLocaleString(locale, { style: 'percent', minimumFractionDigits: digits, maximumFractionDigits: digits }),
	asDate: (value: string | Date | moment.Moment, format = KASASA_DATE): string =>
		moment(value).format(format),
	asDateTime: (value: string | Date | moment.Moment, format = KASASA_DATE_TIME): string =>
		moment(value).format(format),
	ISO_DATE: 'YYYY-MM-DD',
	KASASA_DATE,
	asPhone: (value: string | number | null, progressive = false): string => {
		if (!value) {
			return '';
		}

		// Convert to string and remove non numeric characters.
		const str = (value || '').toString().replace(/\D/g, '').substr(0, 10);
		const RE = /(\d{0,3})(\d{0,3})(\d{0,4})/;
		const [area, prefix, last4] = (RE.exec(str) || []).slice(1, 4);

		// Strict formatting test, either its a valid phone or its not.
		if (!progressive) {
			return str && str.length === 10 ? `(${area}) ${prefix}-${last4}` : '';
		}

		// Progressive formatting, useful for input masking.
		let result = '';
		if (area.length === 3) {
			result = `(${area}) `;
		} else {
			result = `(${area}`;
		}

		if (prefix.length === 3) {
			result += `${prefix}-`;
		} else {
			result += prefix;
		}

		result += `${last4}`;
		return result;
	},
	asSsn: (value: string | number, progressive = false): string => {
		const str = (value || '').toString().replace(/\D/g, '').substr(0, 9);
		const RE = /(\d{0,3})(\d{0,2})(\d{0,4})/;
		const [three, two, four] = (RE.exec(str) || []).slice(1, 4);
		let output = '';

		// Strict formatting, must be full SSN.
		if (!progressive) {
			output = `${three}-${two}-${four}`;
			return output.length === 11 ? output : '';
		}

		// Progressive formatting, useful for input masking.
		output = three && three.length === 3 ? `${three}-` : three;
		output += two && two.length === 2 ? `${two}-` : two;
		output += four && four;

		return output;
	},
};

export interface Formatters {
	asCurrency: (value: string | number, locale?: string, currencyCode?: string) => string;
	asPercentage: (value: string | number, digits?: number, locale?: string) => string;
	asDate: (value: string | Date | moment.Moment, format?: string) => string;
	asPhone: (value: string | number | null, progressive?: boolean) => string;
	asSsn: (value: string | number, progressive?: boolean) => string;
	keyUpDollarFormat: (value : string | number) => string;
	ISO_DATE: string;
	KASASA_DATE: string;
}
