var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-row',{staticClass:"no-margin-top",attrs:{"id":_vm.id,"cols":"12"}},[_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{class:{
				'current-coverage': _vm.coverage.isCurrentCoverage,
				'current-coverage-removed': _vm.coverage.isCurrentCoverageBeingRemoved,
			},attrs:{"readonly":_vm.coverage.isCurrentCoverage,"disabled":_vm.coverage.isCurrentCoverageBeingRemoved,"items":_vm.policyDropdownOptions,"label":"Insurance Product"},on:{"change":_vm.onPolicySelected},model:{value:(_vm.coverage.policyNumber),callback:function ($$v) {_vm.$set(_vm.coverage, "policyNumber", $$v)},expression:"coverage.policyNumber"}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-select',{class:{
				'current-coverage': _vm.coverage.isCurrentCoverage,
				'current-coverage-removed': _vm.coverage.isCurrentCoverageBeingRemoved,
			},attrs:{"readonly":_vm.coverage.isCurrentCoverage,"disabled":_vm.coverage.isCurrentCoverageBeingRemoved,"items":_vm.coverageDropdownOptions,"label":"Coverage"},model:{value:(_vm.coverage.coverageType),callback:function ($$v) {_vm.$set(_vm.coverage, "coverageType", $$v)},expression:"coverage.coverageType"}})],1),_c('v-col',{attrs:{"cols":"4","align-self":"center"}},[_c('v-btn',{attrs:{"disabled":_vm.coverage.isCurrentCoverageBeingRemoved,"depressed":"","light":"","outlined":"","x-small":"","color":"blue-grey"},on:{"click":function($event){return _vm.$emit('remove', _vm.coverage)}}},[_vm._v(" "+_vm._s(_vm.coverage.isCurrentCoverage ? 'Remove' : 'Cancel')+" ")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }