
/* eslint-disable camelcase */
import {
	Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { Consumer } from '@/services/AchService';
import { Formatters } from '@/utils/formatters';
import { Collection } from '@/services/ConsumerService';
import { RouteTo } from '@/router/types';

@Component
export default class CollectionTable extends Vue {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	items!: Consumer[];

	@Prop({ required: true })
	clientId!: string;

	@Prop({ default: '' })
	search!: string;

	@Prop({ default: false })
	loading!: boolean;

	sortDesc: boolean[] = [];

	loadingText: string = 'Loading collections...';

	headers: DataTableHeader[] = [
		{
			text: 'Name',
			value: 'consumer.name',
			width: '20%',
			align: 'start',
		},
		{
			text: 'Balance',
			value: 'principal_balance',
			align: 'end',
		},
		{
			text: 'Payment',
			value: 'installment_amount',
			align: 'end',
		},
		{
			text: 'Past Due',
			value: 'past_due',
			align: 'end',
		},
		{
			text: 'Days Past Due',
			value: 'delinquent_days',
			width: '15%',
			align: 'end',
		},
		// {
		// 	text: 'Last Note',
		// 	value: 'lastNote',
		// 	width: '10%',
		// 	align: 'center',
		// 	sortable: false,
		// },
		{
			text: 'Action',
			// Note: this is to make kasasa_loan_number seachable
			value: 'kasasa_loan_number',
			width: '5%',
			align: 'center',
			sortable: false,
		},
	];

	getCollectionViewRoute(item: Collection): RouteTo {
		return {
			name: 'loan-collections',
			params: { clientId: this.clientId, consumerId: item.consumer_id, loanId: item.id },
		};
	}

	goToCollectionDetails(item: Collection): void {
		this.$router.push(this.getCollectionViewRoute(item));
	}
}
