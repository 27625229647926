
import { Component, Prop } from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent';
import {
	CoverageType, getInsuranceCoverageTypeName,
	InsuranceModRequestCoverage,
	InsurancePolicy,
	PolicyCoverageSettings,
	InsuranceProductCalculationMethod,
} from '@/services/types/loan/Insurance';
import { DropdownOption } from '@/types/Form';

export interface InsuranceModRequestCoverageAttached extends	InsuranceModRequestCoverage {
	isCurrentCoverage: boolean;
	isCurrentCoverageBeingRemoved: boolean;
}

@Component
export default class InsuranceModAttachedCoverage extends BaseComponent {
	@Prop({ default: 'coverage-id' })
	id!: string;

	@Prop({ required: true })
	coverage!: InsuranceModRequestCoverageAttached;

	@Prop({ default: [] })
	policies!: InsurancePolicy[];

	@Prop({ required: true, default: 0 })
	numberOfBorrowers!: number;

	get policyDropdownOptions(): DropdownOption[] {
		const policyOptions: DropdownOption[] = [
			{
				text: 'Select Policy',
				value: '',
			},
		];

		this.policies.filter((policy) =>
			!([InsuranceProductCalculationMethod.SINGLE_PREMIUM_NET, InsuranceProductCalculationMethod.SINGLE_PREMIUM_GROSS]
				.includes(policy.calculationMethod)))
			.forEach((policy: InsurancePolicy): void => {
				policyOptions.push({
					text: policy.name,
					value: policy.policyNumber,
				});
			});

		return policyOptions;
	}

	get policyForSelectedCoverage(): InsurancePolicy | undefined {
		return this.policies.find((policy) => policy.policyNumber === this.coverage.policyNumber);
	}

	get coverageDropdownOptions(): DropdownOption[] {
		if (!this.coverage.policyNumber) {
			return [
				{
					text: 'Select Policy First',
					value: '',
				},
			];
		}

		return [
			{
				text: 'Select Coverage',
				value: '',
			},
			...this.mapPolicyCoverageSettingsIntoDropdownOptions(
				this.policyForSelectedCoverage?.policyCoverageSettings ?? [],
			),
		];
	}

	// eslint-disable-next-line class-methods-use-this
	mapPolicyCoverageSettingsIntoDropdownOptions(
		originalPolicyCoverageSettings: PolicyCoverageSettings[],
	): DropdownOption[] {
		let policyCoverageSettings = originalPolicyCoverageSettings;
		if (this.numberOfBorrowers === 1) {
			policyCoverageSettings = policyCoverageSettings.filter((setting) => !(['J', 'C'].includes(setting.coverageType)));
		}
		return policyCoverageSettings.map((settings: PolicyCoverageSettings) => ({
			text: getInsuranceCoverageTypeName(settings.coverageType),
			value: settings.coverageType,
		}));
	}

	onPolicySelected(): void {
		this.coverage.coverageType = '' as CoverageType;
	}
}
