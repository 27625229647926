import { Asset } from '@/services/types/loan/Collection';

export enum CollateralAssetType {
	VEHICLE_ASSET = 'VehicleAsset',
}

export default interface CollateralAssetPayload {
	type: CollateralAssetType,
	asset: Asset
}
