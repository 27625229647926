
import {
	Component, Inject, Prop, Watch,
} from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import moment from 'moment';
import LoanService from '@/services/LoanService';
import BaseComponent from '@/components/BaseComponent';
import { Formatters } from '@/utils/formatters';

@Component({
	name: 'payoff-calculator-modal',
	components: {
		KReadOnly,
	},
})
export default class PayoffCalculatorModal extends BaseComponent {
	private loanService: LoanService = new LoanService(this.$store);

	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	loanId!: string;

	@Prop({ required: true })
	show!: boolean;

	days: any = 10;

	data: any;

	private today: moment.Moment = moment().startOf('day');

	selectedDate: any = moment().add(10, 'days').format('YYYY-MM-DD');

	payoffDateToggle: boolean = false;

	async recalculateFromDate(): Promise<void> {
		this.days = this.daysDiff;
		this.loading = true;
		const response = await this.loanService.getPayoffDays(this.loanId, this.daysDiff.toString());
		this.loading = false;
		this.data = response.data.data;
	}

	async recalculateFromInput(): Promise<void> {
		if (this.days > 60) {
			this.days = 60;
		}

		if (this.days < 1) {
			this.days = 1;
		}

		this.selectedDate = moment().add(this.days, 'days').format('YYYY-MM-DD');
		this.loading = true;
		const response = await this.loanService.getPayoffDays(this.loanId, this.days.toString());
		this.loading = false;
		this.data = response.data.data;
	}

	loading: boolean = false;

	loanSvc: LoanService = new LoanService(this.$store);

	formattedDate(value: string = ''): string {
		return value ? this.format.asDate(value) : '';
	}

	dismiss(): void {
		this.data = {};
		this.days = 10;
		this.loading = true;
		this.$emit('modal-closed');
	}

	@Watch('show', { immediate: true })
	async onShow(value: boolean): Promise<void> {
		this.loading = true;
		if (value) {
			const response = await this.loanService.getPayoffDays(this.loanId, this.days.toString());

			this.data = response.data.data;
			this.loading = false;
		}
	}

	get formattedPayoffDate(): string {
		return moment(this.data.payoffDate, 'YY-MM-DD').format('MM/DD/YYYY');
	}

	get maxDate(): string {
		const today = moment().add(60, 'days').startOf('day');

		return this.format.asDate(today, this.format.ISO_DATE);
	}

	onlyNumeric() {
		if (this.days) {
			this.days = this.days.toString().replace(/[^0-9]/g, '');
		}
	}

	get daysDiff(): string {
		const a = moment();
		const b = moment(this.selectedDate);

		return (b.diff(a, 'days') + 1).toString();
	}

	get pdfLink(): string {
		let pdfDays = this.days;
		if (this.days > 60) {
			pdfDays = 60;
		}

		if (this.days < 1) {
			pdfDays = 1;
		}

		return `/api/v1/kloans-receipt/loan/${this.loanId}/days/${pdfDays}/pdf`;
	}
}
