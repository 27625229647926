import { AxiosResponse } from 'axios';
import {
	FBaseService, ResponseEnvelope,
} from '@kasasa/fbase-components/lib';

import FeatureFlagSet from '@/services/types/FeatureFlagSet';

export default class FeatureService extends FBaseService {
	public async getFeatures(fiId: string): Promise<FeatureFlagSet> {
		const response: AxiosResponse<ResponseEnvelope<FeatureFlagSet>> = await this.apiClient.get(
			`/api/kloans-gateway/fiId/${fiId}/feature`,
		);

		return response.data.data;
	}
}
