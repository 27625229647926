const VKLOANS = 'vkloans';
const KLOANS = 'vkloans';
const KLOANS_COLLECTIONS = 'vkloansCollections';
const KLOANS_EDIT_PROFILE = 'vkloansEditProfile';
const KLOANS_GENERATE = 'vkloansGenerate';
const KLOANS_MAINTENANCE = 'vkloansLoanMaintenance';
const KLOANS_NOTES = 'vkloansNotes';

export {
	KLOANS,
	VKLOANS,
	KLOANS_COLLECTIONS,
	KLOANS_EDIT_PROFILE,
	KLOANS_GENERATE,
	KLOANS_MAINTENANCE,
	KLOANS_NOTES,
};
