import {
	Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import { Loan } from '@/services/types/loan/Loan';

export interface LoanState {
	loan: Loan;
  }

@Module({
	namespaced: true,
})
export default class LoanModule extends VuexModule {
	private loan: Loan = {} as Loan;

	@Mutation
	setLoan(loan: Loan): void {
		this.loan = loan;
	}

	get getLoan(): Loan {
		return this.loan;
	}
}
