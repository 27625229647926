
import {
	Component, Inject, Prop,
} from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { LoanModType, LoanTermMod } from '@/services/types/loan/Maintenance';
import BaseComponent from '@/components/BaseComponent';
import formatters from '@/utils/formatters';

@Component({
	enums: {
		LoanModType,
	},
})
export default class LoanHistoryTable extends BaseComponent {
	@Inject('format')
	format!: typeof formatters;

	@Prop({ required: true })
	items!: LoanTermMod[];

	@Prop({ default: false })
	loading!: boolean;

	headers: DataTableHeader[] = [
		{
			text: 'Event', value: 'type',
		},
		{
			text: 'Effective Date', value: 'effectiveDate',
		},
		{
			text: 'End Date', value: 'endDate',
		},
		{
			text: 'Principal Balance', value: 'principalChanges',
		},
		{
			text: 'Installment', value: 'installmentAmount',
		},
		{
			text: 'Interest Rate', value: 'interestRate',
		},
		{
			text: 'Maturity Date', value: 'maturityDate',
		},
		{
			text: 'Note', value: 'notes', sortable: false,
		},
		{
			text: '', value: 'scra', sortable: false,
		},
	];

	// eslint-disable-next-line class-methods-use-this
	rowClass(item: LoanTermMod): string {
		return `history-row ${item.type}`;
	}

	possibleDate(date: string | null): string {
		return date ? this.format.asDate(date) : '---';
	}

	possibleCurrency(value: string | null): string {
		return value ? this.format.asCurrency(value) : '---';
	}

	possiblePercentage(value: string | null): string {
		return value ? this.format.asPercentage(value) : '---';
	}
}
