
import KPage from '@/components/KPage.vue';
import { Component, Inject, Watch } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import BaseLoanPage from '@/pages/loan/BaseLoanPage';
import { Dialog } from '@kasasa/fbase-components/lib';
import { Dictionary } from 'vue-router/types/router.d';
import {
	BankruptcyStatus, BankruptcyType, LoanApplicant, Bankruptcy, isActiveBankruptcy,
} from '@/services/types/loan/Bankruptcy';
import { Borrower } from '@/services/types/loan/Borrower';
import { handleErrorResponse } from '@/utils/xhr';
import BankruptcyCard from '@/components/loan/bankruptcy/BankruptcyCard.vue';
import BankruptcyForm from '@/components/loan/bankruptcy/BankruptcyForm.vue';
import { Formatters } from '@/utils/formatters';

@Component({
	enums: {
		BankruptcyStatus,
		BankruptcyType,
	},
	components: {
		BankruptcyCard,
		BankruptcyForm,
		KPage,
		KReadOnly,
	},
})
export default class LoanBankruptcyPage extends BaseLoanPage {
	@Inject('format')
	format!: Formatters;

	mode: string = 'add';

	title: string = 'Bankruptcy Settings';

	saveLoading: boolean = false;

	invalidBankruptcy: boolean = false;

	onInvalidChanged(value: boolean): void {
		this.invalidBankruptcy = value;
	}

	data: Bankruptcy = {
		fiId: this.fiId,
		loanApplicants: [],
		loanType: 0,
		note: '',
		status: '',
		type: '',
		consumerAccountNumber: null,
	};

	bankruptcyOptions: Dictionary<string>[] = [
		// Can never have 2 primary borrowers, and we do not allow editing applicantType after
		// the fact, so its safe to remove this based on existing functionality. -- Karl
		// { value: 'primary', text: 'Primary Borrower' },
		{ value: '7', text: 'Chapter 7' },
		{ value: '13', text: 'Chapter 13' },
	];

	get bankruptcyStatusOptions(): Dictionary<string>[] {
		return Object.keys(this.$enums.BankruptcyStatus).map((status: string): Dictionary<string> => ({
			text: this.$enums.BankruptcyStatus[status],
			value: status,
		}));
	}

	get activeBankruptcy(): Bankruptcy | null {
		return (this.bankruptcies || []).find(this.isActive) || null;
	}

	@Watch('mode')
	onModeChanged(value: string): void {
		if (value === 'edit' && this.hasActiveBankruptcy) {
			this.data = { ...this.activeBankruptcy } as Bankruptcy;
		}
	}

	applicants: LoanApplicant[] = [];

	async created(): Promise<void> {
		await Promise.all([
			this.getBankruptcies(),
			this.getConsumer(),
			this.getBorrowers(true, true),
		]);

		document.querySelector('.k-card-footer-btn-group.elevation-3')?.classList.remove('elevation-3');

		this.loading = false;
	}

	@Watch('borrowers', { immediate: true })
	onBorrowersChanged(): void {
		this.applicants = this.borrowers.map((borrower: Borrower): LoanApplicant => ({
			applicantId: borrower.id,
			applicantName: `${borrower.firstName} ${borrower.lastName}`,
			applicantType: borrower.applicantType,
		}));
	}

	get canView(): boolean {
		return this.canRead('vkloans');
	}

	async save(): Promise<void> {
		const data = { ...this.data };

		if (!data.serviceOnCore) {
			data.consumerAccountNumber = null;
		}
		// Remove this as its only used by the frontend.
		delete data.serviceOnCore;

		data.loanType = this.loan?.general.loanTypeId as number;

		try {
			this.saveLoading = true;
			if (this.mode === 'add' || (!this.hasActiveBankruptcy && this.mode === 'edit')) {
				await this.loanSvc.postBankruptcy(this.loanId, data);

				if (this.isActive(data)) {
					const dialog = new Dialog('Loan Features Disabled', 'Automatic payments (if enabled), statements, and email notifications have been disabled for this loan.');
					dialog.setDismissable(false);
					this.showDialog(dialog);
				}

				await this.fetchAndSetLoan(true);
			} else {
				const params: Bankruptcy = {
					status: data.status,
					consumerAccountNumber: data.consumerAccountNumber,
					note: data.note,
				};

				await this.loanSvc.updateBankruptcy(this.loanId, this.data.id as number, params);
				if (!this.isActive(data)) {
					const dialog = new Dialog('Loan Features Enabled', 'Statements and email notifications have been enabled for this loan.');
					dialog.setDismissable(false);
					this.showDialog(dialog);
				}
			}

			await this.getBankruptcies(true);
			this.mode = 'add';
			// Reset form values on save.
			this.data.loanApplicants = [];
			this.data.serviceOnCore = false;
			this.data.type = '';
			this.data.status = '';
			this.data.consumerAccountNumber = '';
			this.data.note = '';
		} catch (error) {
			await handleErrorResponse((error as any).response, this);
		} finally {
			this.saveLoading = false;
		}
	}

	// eslint-disable-next-line class-methods-use-this
	isActive(bankruptcy: Bankruptcy): boolean {
		return isActiveBankruptcy(bankruptcy);
	}

	get showSaveButton(): boolean {
		return this.mode === 'edit' || (!this.hasActiveBankruptcy && this.mode === 'add');
	}

	get showFooterActions(): boolean {
		return !this.bankruptcies.length || this.mode === 'edit';
	}
}
