export interface PrimaryAsset {
	interest: string;
	principal: string;
	fees: string;
}

export interface TakeBackAsset {
	interest: string;
	principal: string;
	fees: string;
}

export interface TransactionDetailTotal {
	primaryAsset: PrimaryAsset;
	takeBackAsset: TakeBackAsset;
	returnedItemFees: string;
}

export enum TransactionStatus {
	Pending = 1,
	Posted = 2,
	Submitted = 3,
	Canceled = 10,
	Declined = 11,
	Failed = 12,
	Reversing = 98,
	Reversed = 99,
}

export enum TransactionType {
	Disbursement = 0,
	Payment = 1,
	'Take Back' = 2,
	Refund = 3,
	Correction = 4,
	'Charge Off' = 5,
	'Insurance Premium' = 6,
	'SCRA Interest Refund' = 7,
	'CPI Insurance Premium' = 8,
	'CPI Premium Refund' = 9,
	'Principal Increase' = 10,
	'Principal Decrease' = 11,
	'Payment Refund' = 13
}

export enum TransactionMethod {
	LinkedAccount = -1,
	Disbursement = 0,
	Cash = 1,
	Check = 2,
	'Direct Posting' = 3,
	ACH = 4,
	'Interest Adjustment' = 10,
	ChargeOff = 11,
	'Insurance Premium' = 12,
}

export enum TransactionSource {
	FI = 1,
	Consumer = 2,
	Automatic = 3,
}

export interface Transaction {
	id: string;
	loanId: string;
	paymentAccountId: string;
	source: TransactionSource;
	transactionType: TransactionType;
	amount: string;
	creditOrDebit: string | null;
	description: string;
	status: TransactionStatus;
	isAutomatic: boolean;
	effectiveDate: string;
	automaticProcessDate: string;
	reversedAt?: string;
	endingBalance?: string;
	endingTakeBackBalance?: string;
	endingAvailableToTakeBack?: string;
	createdAt: string;
	updatedAt: string;
	transactionMethod: TransactionMethod;
	checkNumber?: string;
	addonReferenceId?: string;
	postedAt?: string;
	postedDate?: string;
	sourceSystemTransactionId?: string;
	transactionDetailTotals: TransactionDetailTotal;
	principal: string;
	interest: string;
	fees: string;
	principalBalance?: string;
	interestToDate: string;
	availableToWithdraw?: string;
	settledDate: string;
	cutOffTime: string;
	isReversible: boolean;
	nestedTransaction?: Object;
}

export interface ExtendedTransaction extends Transaction {
	canCancel: boolean;
	canReverse: boolean;
	isCancelled: boolean;
	isCpiRefund: boolean;
	isDisbursement: boolean;
	isExternal: boolean;
	isPastCutOff: boolean;
	isPrincipalChange: boolean;
	isPosted: boolean;
	isReversed: boolean;
	isTakeBack: boolean;
	loadingCancel: boolean;
	loadingReverse: boolean;
}

export interface TransactionPayload {
	amount: string;
	amountConfirm: string;
	checkNumber?: string;
	effectiveDate: string;
	transactionMethod: TransactionMethod;
	paymentAccountId?: string;
	description?: string;
}

export interface TransactionRefundPayload {
	account: string;
	transactionMethod: TransactionMethod;
	description?: string;
}
