
import KPage from '@/components/KPage.vue';
import { Component } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import BaseLoanPage from '@/pages/loan/BaseLoanPage';
import { NoticeClass } from '@kasasa/fbase-components/lib';
import OverviewGeneralCard from '@/components/loan/OverviewGeneralCard.vue';
import OverviewMonthlyPaymentCard from '@/components/loan/OverviewMonthlyPaymentCard.vue';
import OverviewTermsCard from '@/components/loan/OverviewTermsCard.vue';
import OverviewInsuranceCard from '@/components/loan/OverviewInsuranceCard.vue';
import OverviewBankruptcyCard from '@/components/loan/OverviewBankruptcyCard.vue';
import RecordTransactionModal from '@/components/loan/transactions/RecordTransactionModal.vue';
import { InsuranceCoverage } from '@/services/types/loan/Insurance';
import LoanAutoPayService from '@/services/LoanAutoPayService';
import {
	RecurringPaymentFrequency,
	RecurringPaymentLatest,
} from '@/services/types/loan/RecurringPayment';
import { Borrower } from '@/services/types/loan/Borrower';
import { LoanMaintenance } from '@/services/types/loan/Maintenance';
import { handleErrorResponse } from '@/utils/xhr';
import { namespace } from 'vuex-class';
import LoanService from '@/services/LoanService';
import { VKLOANS } from '@/pages/PermissionGroups';
import {
	ExtendedTransaction,
	TransactionStatus,
} from '@/services/types/loan/LoanTransaction';
import { BankruptcyStatus } from '@/services/types/loan/Bankruptcy';

const loanMaintenanceStoreModule = namespace('maintenance');

@Component({
	components: {
		KPage,
		KReadOnly,
		OverviewBankruptcyCard,
		OverviewGeneralCard,
		OverviewMonthlyPaymentCard,
		OverviewTermsCard,
		OverviewInsuranceCard,
		RecordTransactionModal,
	},
})
export default class LoanOverviewPage extends BaseLoanPage {
	private autoPayService: LoanAutoPayService = new LoanAutoPayService(
		this.$store,
	);

	loadingBranch: boolean = false;

	transactionsLoading: boolean = false;

	loadingLoanOfficer: boolean = false;

	loadingLoanApplicationNumber: boolean = false;

	loadingApplicant: boolean = false;

	loadingCoreTrackingRecordNumber: boolean = false;

	showModal: boolean = false;

	transactions: ExtendedTransaction[] = [];

	insuranceCoverage: InsuranceCoverage[] = [];

	semiMonthlyRecurringPaymentInfo: RecurringPaymentLatest =
		{} as RecurringPaymentLatest;

	private loanService: LoanService = new LoanService(this.$store);

	@loanMaintenanceStoreModule.Getter('getLoanMaintenance')
	loanMaintenance!: LoanMaintenance | null;

	@loanMaintenanceStoreModule.Mutation('setLoanMaintenance')
	setLoanMaintenance!: (loanMaintenance: LoanMaintenance | null) => void;

	async created(): Promise<void> {
		await Promise.all([
			this.getBankruptcies(),
			this.getInsuranceCoverage(),
			this.getConsumer(),
			this.getBankAccounts(),
			this.getBorrowers(),
			this.setLoanMaintenanceInStore(),
			this.getTransactions(),
		]);

		await this.getCurrentRecurringPaymentInformation();

		this.loading = false;
	}

	async getInsuranceCoverage(): Promise<void> {
		const insuranceResponse = await this.loanSvc.getInsurance(this.loanId);
		this.insuranceCoverage = insuranceResponse.data.data;
	}

	async getCurrentRecurringPaymentInformation(): Promise<void> {
		if (
			this.loan
			&& this.loan?.recurringPayment
			&& this.loan?.recurringPayment?.frequency
				=== RecurringPaymentFrequency.SEMIMONTHLY
		) {
			try {
				this.semiMonthlyRecurringPaymentInfo = await this.autoPayService.getLatestRecurringPayment(
					this.consumerId,
					this.loanId,
				);
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			}
		}
	}

	get canView(): boolean {
		return this.canRead(VKLOANS);
	}

	get payoffAmount(): string {
		if (this.loan && this.loan.general) {
			return this.loan.general.payoffAmount;
		}
		return '';
	}

	get takeBackEnabled(): boolean {
		return this.loan?.general?.takeBacksEnabled;
	}

	get activeApplicant(): Borrower {
		return (
			this.borrowers.find(({ consumerId }) => consumerId === this.consumerId)
			|| ({} as Borrower)
		);
	}

	get pendingTransactions(): ExtendedTransaction[] {
		return this.transactions.filter(
			(t) => Number(t.status) === TransactionStatus.Pending,
		);
	}

	get displayBankruptcies(): boolean {
		if (this.bankruptcies.length) {
			return (
				this.bankruptcies.length > 0
				&& this.bankruptcies[0].status.toLowerCase()
					=== BankruptcyStatus.petitioned.toLowerCase()
			);
		}

		return false;
	}

	async onBranchInput(value: string): Promise<void> {
		try {
			this.loadingBranch = true;
			await this.loanSvc.updateLoan(this.fiId, this.loanId, { branch: value });
			this.showAlert(
				'Branch has been successfully updated.',
				NoticeClass.SUCCESS,
				5000,
			);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.loadingBranch = false;
		}
	}

	async onLoanApplicationNumberInput(value: string): Promise<void> {
		try {
			this.loadingLoanApplicationNumber = true;
			await this.loanSvc.updateLoan(this.fiId, this.loanId, {
				applicationNumber: value,
			});
			await this.fetchAndSetLoan(true);
			this.showAlert(
				'Loan Application Number has been successfully updated.',
				NoticeClass.SUCCESS,
				5000,
			);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.loadingLoanApplicationNumber = false;
		}
	}

	async onLoanOfficerInput(value: string): Promise<void> {
		try {
			this.loadingLoanOfficer = true;
			await this.loanSvc.updateLoan(this.fiId, this.loanId, {
				loanOfficer: value,
			});
			await this.fetchAndSetLoan(true);
			this.showAlert(
				'Loan Officer has been successfully updated.',
				NoticeClass.SUCCESS,
				5000,
			);
		} catch (error) {
			this.showAlert('An error occurred, please try again.', NoticeClass.ERROR);
		} finally {
			this.loadingLoanOfficer = false;
		}
	}

	async setLoanMaintenanceInStore(): Promise<void> {
		if (!this.loanMaintenance) {
			try {
				const loanMaintenance: LoanMaintenance = await this.loanService.getLoanMaintenance(
					this.fiId,
					this.consumerId,
					this.loanId,
				);

				this.setLoanMaintenance(loanMaintenance);
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			}
		}
	}

	async onApplicantCoreAccountUpdate(coreAccount: string): Promise<void> {
		try {
			this.loadingApplicant = true;
			const updateApplicant = { ...this.activeApplicant, coreAccount };
			await this.loanSvc.updateBorrower(
				this.fiId,
				this.loanId,
				this.activeApplicant.id,
				updateApplicant,
			);
			await this.getBorrowers(true);
			this.showAlert(
				'CIF/Member Number value has been successfully updated.',
				NoticeClass.SUCCESS,
				5000,
			);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.loadingApplicant = false;
		}
	}

	async onLoanCoreTrackingRecordUpdate(value: string): Promise<void> {
		try {
			this.loadingCoreTrackingRecordNumber = true;
			await this.loanSvc.updateLoan(this.fiId, this.loanId, {
				coreTrackingRecordNumber: value,
			});
			await this.fetchAndSetLoan(true);
			this.showAlert(
				'Core Loan ID has been successfully updated.',
				NoticeClass.SUCCESS,
				5000,
			);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.loadingCoreTrackingRecordNumber = false;
		}
	}

	async getTransactions(): Promise<void> {
		this.transactionsLoading = true;

		try {
			const response = await this.loanSvc.getTransactions(
				this.consumerId,
				this.loanId,
			);
			this.transactions = response.data.data;
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.transactionsLoading = false;
		}
	}
}
