const isNullOrEmpty = (
	val?: number | string | null | Array<any> | Record<any, any>,
): boolean => (
	val === ''
		|| val === undefined
		|| val === 'undefined'
		|| val === null
		|| (Array.isArray(val) && val.length === 0)
		|| (val.constructor === Object && Object.keys(val).length === 0)
);

export {
	// eslint-disable-next-line import/prefer-default-export
	isNullOrEmpty,
};
