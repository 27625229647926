
import { ExtendedBankAccount } from '@/services/types/BankAccount';
import {
	ConfirmValidation,
	RequiredValidation,
} from '@kasasa/kloans-common-ui/dist/validations';
import { Component, Inject, Prop } from 'vue-property-decorator';
import { RuleDecl } from 'vue/types/options.d';
import { Validations } from 'vuelidate-property-decorators';
import { requiredIf } from 'vuelidate/lib/validators';
import moment from 'moment';
import LoanService from '@/services/LoanService';
import { handleErrorResponse } from '@/utils/xhr';
import BaseComponent from '@/components/BaseComponent';
import {
	TransactionMethod,
	TransactionPayload,
} from '@/services/types/loan/LoanTransaction';
import { Formatters } from '@/utils/formatters';
import { NoticeClass } from '@kasasa/fbase-components/lib';
import { namespace } from 'vuex-class';
import { ConsumerService } from '@/services/ConsumerService';
import CommentsCard from '@/components/loan/comments/CommentsCard.vue';
import {
	CommentPayload,
	CommentReferenceTypes,
} from '@/services/types/loan/Comment';
import CommentsService from '@/services/CommentsService';

const bankAccountStoreModule = namespace('bankAccount');

export enum TransactionTypeSelect {
	PAYMENT = 'payment',
	TAKE_BACK = 'takeback',
}

export enum TransactionDefaultDescription {
	PAYMENT = 'One-Time Payment',
	TAKE_BACK = 'Take-Back',
}

@Component({
	enums: {
		TransactionTypeSelect,
		TransactionMethod,
		TransactionDefaultDescription,
	},
	components: {
		CommentsCard,
	},
})
export default class RecordTransactionModal extends BaseComponent {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	clientId!: string;

	@Prop({ required: true })
	consumerId!: string;

	@Prop({ required: true })
	fiId!: string;

	@Prop({ required: true })
	loanId!: string;

	@Prop({ required: true })
	payoffAmount!: string;

	@Prop({ required: true })
	show!: boolean;

	@Prop({ required: true, default: true })
	takeBacksEnabled!: boolean;

	@Prop({ required: true, default: false })
	pendingTransactions!: boolean;

	@bankAccountStoreModule.Getter('getBankAccounts')
	bankAccounts!: ExtendedBankAccount[];

	@bankAccountStoreModule.Mutation('setBankAccounts')
	setBankAccounts!: (accounts: ExtendedBankAccount[]) => void;

	@bankAccountStoreModule.Getter('bankAccountDropdownOptions')
	bankAccountOptions!: (accounts: ExtendedBankAccount[]) => void;

	bankAccountsLoading = false;

	today: moment.Moment = moment().startOf('day');

	transactionType: TransactionTypeSelect = TransactionTypeSelect.PAYMENT;

	transactionComment: string = '';

	consumerService = new ConsumerService(this.$store);

	commentsSvc = new CommentsService(this.$store);

	async created(): Promise<void> {
		await this.getBankAccounts();
	}

	@Validations()
	validations(): RuleDecl {
		const pastOrTodayDate = (value: string) => {
			if (value) {
				return moment(value).isSameOrBefore(this.today);
			}

			return true;
		};

		const data = {
			amount: RequiredValidation.validations(),
			amountConfirm: ConfirmValidation.validations('amount'),
			checkNumber: {
				required: requiredIf((model) => model.transactionMethod === '2'),
			},
			effectiveDate: {
				...RequiredValidation.validations(),
				pastOrTodayDate,
			},
			transactionMethod: RequiredValidation.validations(),
			paymentAccountId: {
				required: requiredIf((model) => model.transactionMethod === '3'),
			},
		};

		return { data };
	}

	loading: boolean = false;

	loanSvc: LoanService = new LoanService(this.$store);

	get amountErrorMessages(): string[] {
		return RequiredValidation.errors(this, 'data.amount', 'Amount');
	}

	get amountConfirmErrorMessages(): string[] {
		return ConfirmValidation.errors(
			this,
			'data.amountConfirm',
			'amount',
			'Amount',
		);
	}

	get checkNumberErrorMessages(): string[] {
		return RequiredValidation.errors(this, 'data.checkNumber', 'Check Number');
	}

	get paymentAccountErrorMessages(): string[] {
		return RequiredValidation.errors(
			this,
			'data.paymentAccountId',
			'Payment Account',
		);
	}

	get effectiveDateErrorMessages(): string[] {
		const errors: string[] = [
			...RequiredValidation.errors(
				this,
				'data.paymentAccountId',
				'Payment Account',
			),
		];

		if (this.$v?.data?.effectiveDate?.$dirty) {
			if (!this.$v.data.effectiveDate.pastOrTodayDate) {
				errors.push('Effective Date must be today or in the past.');
			}
		}

		return errors;
	}

	effectiveDateMenu: boolean = false;

	data: TransactionPayload = {
		amount: '',
		amountConfirm: '',
		effectiveDate: moment().format('YYYY-MM-DD'),
		transactionMethod: TransactionMethod.Cash,
		description: TransactionDefaultDescription.PAYMENT,
	};

	allowedDates(date: string): boolean {
		return moment(date).startOf('day').isSameOrBefore(this.today);
	}

	dismiss(): void {
		this.$emit('modal-closed');
	}

	// We need to convert transactionMethod to proper internal/external value if LinkedAccount is selected.
	get transactionData(): TransactionPayload {
		const data = { ...this.data };
		if (data.transactionMethod === TransactionMethod.LinkedAccount) {
			const bankAccount = this.bankAccounts.find(
				(account: ExtendedBankAccount) => account.id === data.paymentAccountId,
			);

			if (bankAccount) {
				data.transactionMethod = bankAccount.transactionMethod;
			}
		}

		return data;
	}

	get fullName(): string {
		return `${this.sessionData.firstName} ${this.sessionData.lastName}`.trim();
	}

	updateComment(comment: string) {
		this.transactionComment = comment;
	}

	async save(): Promise<void> {
		try {
			this.loading = true;
			let response;
			switch (this.transactionType) {
				case TransactionTypeSelect.PAYMENT:
					if (!this.transactionData.description) {
						this.transactionData.description = TransactionDefaultDescription.PAYMENT;
					}
					response = await this.loanSvc.postPayment(
						this.fiId,
						this.loanId,
						this.transactionData,
					);
					break;
				case TransactionTypeSelect.TAKE_BACK:
					if (!this.transactionData.description) {
						this.transactionData.description = TransactionDefaultDescription.TAKE_BACK;
					}
					response = await this.loanSvc.postTakeBack(
						this.fiId,
						this.loanId,
						this.transactionData,
					);
					break;
				default:
					throw new Error(
						'This type of transaction is not allowed at this time.',
					);
			}

			const { id } = response;

			if (this.transactionComment) {
				const data: CommentPayload = {
					userId: `${this.sessionData.id}`,
					user: this.fullName,
					body: this.transactionComment,
					referenceType: CommentReferenceTypes.TRANSACTIONS,
					referenceId: id,
					loanId: this.loanId,
				};

				try {
					await this.commentsSvc.postComment(this.loanId, data);
				} catch (error) {
					await handleErrorResponse((error as any).response, this, NoticeClass.WARN,
						'The transaction has been submitted but the comment was not saved. Please try again.');
				}
			}

			this.dismiss();
			this.$emit('transaction-processed');
			this.showAlert(
				'Transaction completed successfully.',
				NoticeClass.SUCCESS,
				5000,
			);
			await this.$router.push({
				name: 'loan-transaction-details',
				params: {
					clientId: this.clientId,
					consumerId: this.consumerId,
					loanId: this.loanId,
					transactionId: id,
				},
			});
		} catch (error) {
			await handleErrorResponse((error as any).response, this);
		} finally {
			this.loading = false;
		}
	}

	async getBankAccounts(): Promise<void> {
		if (this.bankAccounts.length) {
			return;
		}

		try {
			this.bankAccountsLoading = true;

			const response = await this.consumerService.getBankAccounts(
				this.consumerId,
				this.fiId,
			);

			this.setBankAccounts(response.data.data);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.bankAccountsLoading = false;
		}
	}

	transactionTypeChanged() {
		if (this.transactionType === TransactionTypeSelect.PAYMENT) {
			this.data.description = TransactionDefaultDescription.PAYMENT;
		}

		if (this.transactionType === TransactionTypeSelect.TAKE_BACK) {
			this.data.description = TransactionDefaultDescription.TAKE_BACK;
		}
	}
}
