import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelope, ResponseEnvelopeCollection } from '@kasasa/fbase-components/lib';
import { Comment, CommentPayload } from '@/services/types/loan/Comment';

export default class CommentsService extends FBaseService {
	public async getComments(
		loanId: string, gte?: string, lte?: string, referenceId?: string,
	): Promise<AxiosResponse<ResponseEnvelopeCollection<Comment>>> {
		let params = '?';
		if (gte && lte) {
			params = `?between=submitted_date;${gte};${lte}`;
		} else if (gte && !lte) {
			params = `?gte=submitted_date;${gte}`;
		} else if (lte && !gte) {
			params = `?lte=submitted_date;${lte}`;
		}

		if (referenceId) {
			params += `&reference_id=${referenceId}`;
		}

		const response = await this.apiClient.get(
			`/api/kloans-comment/v1/loan/${loanId}/comments${params}`,
		);

		return response;
	}

	public async getComment(
		loanId: string, commentId: string,
	): Promise<AxiosResponse<ResponseEnvelopeCollection<Comment>>> {
		const response = await this.apiClient.get(
			`/api/kloans-comment/v1/loan/${loanId}/comments/${commentId}`,
		);

		return response;
	}

	async postComment(loanId: string, data: CommentPayload):
		Promise<AxiosResponse<ResponseEnvelope<Comment>>> {
		return this.apiClient.post(`/api/kloans-comment/v1/loan/${loanId}/comments`, data);
	}

	async putComment(loanId: string, data: CommentPayload):
		Promise<AxiosResponse<ResponseEnvelope<Comment>>> {
		return this.apiClient.put(`/api/kloans-comment/v1/loan/${loanId}/comments/${data.id}`, data);
	}

	public async deleteComment(
		loanId: string, commentId: string,
	): Promise<AxiosResponse<ResponseEnvelopeCollection<Comment>>> {
		const response = await this.apiClient.delete(
			`/api/kloans-comment/v1/loan/${loanId}/comments/${commentId}`,
		);

		return response;
	}
}
