export enum MetadataFlag {
	TEST_LOAN = 'test_loan',
	EMPLOYEE_LOAN = 'employee_loan',
	COLLATERAL_REPOSSESSION = 'collateral_repossession',
	SPECIAL_COMMENT = 'special_comment',
	MLA_LOAN = 'mla_loan',
	EXECUTIVE_LOAN = 'executive_loan',
	BOARD_MEMBER_LOAN = 'board_member_loan',
	COMPLIANCE_CODE= 'compliance_condition_code'
}

export interface Metadata {
	loanId: string,
	key: MetadataFlag,
	value: string,
}

export interface MetadataPayload {
	key: MetadataFlag;
	value : string;
}
