
import {
	Component, Inject, Prop, VModel, Watch,
} from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router.d';
import {
	BankruptcyStatus, BankruptcyType, LoanApplicant, Bankruptcy,
} from '@/services/types/loan/Bankruptcy';
import { Validations } from 'vuelidate-property-decorators';
import { RuleDecl } from 'vue/types/options.d';
import { LengthValidation, RequiredValidation } from '@kasasa/kloans-common-ui/dist/validations';
import { requiredIf } from 'vuelidate/lib/validators';
import BaseComponent from '@/components/BaseComponent';
import { Formatters } from '@/utils/formatters';

@Component({
	enums: {
		BankruptcyStatus,
		BankruptcyType,
	},
})
export default class BankruptcyForm extends BaseComponent {
	@Inject('format')
	format!: Formatters;

	@Prop()
	applicants!: LoanApplicant[];

	@Prop()
	status!: string;

	@Prop({ type: Boolean, default: false })
	readonly!: boolean;

	@Prop({ default: 'add' })
	mode!: string;

	@Prop({ type: Boolean, default: false })
	isActive!: boolean;

	validateBorrowerSelection = false;

	@Validations()
	// eslint-disable-next-line class-methods-use-this
	validations(): RuleDecl {
		const data = {
			status: RequiredValidation.validations(),
			type: RequiredValidation.validations(),
			note: LengthValidation.validations(false, 0, 255),
			consumerAccountNumber: requiredIf('data.serviceOnCore'),
			loanApplicants: RequiredValidation.validations(),
		};

		return { data	};
	}

	@VModel()
	data!: Bankruptcy;

	@Watch('$v.$invalid', { immediate: true })
	onInvalidChanged(val: boolean): void {
		this.$emit('invalid-changed', val);
	}

	get loanApplicantsErrors(): string[] {
		if (!this.validateBorrowerSelection) {
			return [];
		}

		const errors = [];

		if (!this.$v.data?.loanApplicants?.required) {
			errors.push('At least one borrower must be selected.');
		}

		return errors;
	}

	get bankruptcyTypeErrors(): string[] {
		return RequiredValidation.errors(this, 'data.type', 'Bankruptcy Type');
	}

	get bankruptcyStatusErrors(): string[] {
		return RequiredValidation.errors(this, 'data.status', 'Bankruptcy Status');
	}

	get noteErrors(): string[] {
		return LengthValidation.errors(this, 'data.note', 0, 255, 'Note', false);
	}

	bankruptcyOptions: Dictionary<string | number>[] = [
		// Can never have 2 primary borrowers, and we do not allow editing applicantType after
		// the fact, so its safe to remove this based on existing functionality. -- Karl
		// { value: 'primary', text: 'Primary Borrower' },
		{ value: 7, text: 'Chapter 7' },
		{ value: 13, text: 'Chapter 13' },
	];

	get bankruptcyStatusOptions(): Dictionary<string>[] {
		const chapter13 = ['petitioned', 'request_dismissed', 'request_withdrawn', 'closed_terminated'];
		const { type } = this.data;
		const options = Object.keys(this.$enums.BankruptcyStatus).map((status: string): Dictionary<string> => ({
			text: this.$enums.BankruptcyStatus[status],
			value: status,
		}));

		if (!type) {
			return [];
		}

		return type === 7 ? options : options.filter((option) => chapter13.includes(option.value));
	}

	// eslint-disable-next-line class-methods-use-this
	applicantLabel(applicant: LoanApplicant): string {
		return `${applicant.applicantName} (${applicant.applicantType})`;
	}

	get canView(): boolean {
		return this.canRead('vkloans');
	}
}
