import Vue from 'vue';
import VueRouter, { Route, RouteConfig } from 'vue-router';
import Collections from '@/pages/CollectionsPage.vue';
import ConsumerListPage from '@/pages/ConsumerListPage.vue';
import ConsumerOverviewPage from '@/pages/consumer/ConsumerOverviewPage.vue';
import ConsumerLinkedAccountsPage from '@/pages/consumer/ConsumerLinkedAccountsPage.vue';
import LinkedAccountPage from '@/pages/consumer/LinkedAccountPage.vue';
import ConsumerAuditLogPage from '@/pages/consumer/ConsumerAuditLogPage.vue';
import HomePage from '@/pages/HomePage.vue';
import AchPage from '@/pages/settings/ach/AchPage.vue';
import { Dictionary } from 'vue-router/types/router.d';
import { displayMode } from '@/router/types';
import { Fi } from '@kasasa/fbase-components/src/services/Auth/types';
import LoanBorrowersPage from '@/pages/loan/LoanBorrowersPage.vue';
import LoanMaintenancePage from '@/pages/loan/LoanMaintenancePage.vue';
import LoanModificationsPage from '@/pages/loan/LoanModificationsPage.vue';
import LoanBankruptcy from '@/pages/loan/LoanBankruptcyPage.vue';
import LoanFeesPage from '@/pages/loan/LoanFeesPage.vue';
import LoanCommentsPage from '@/pages/loan/LoanCommentsPage.vue';
import LoanCollectionsPage from '@/pages/loan/LoanCollectionsPage.vue';
import LoanOverviewPage from '@/pages/loan/LoanOverviewPage.vue';
import LoanTransactionsPage from '@/pages/loan/LoanTransactionsPage.vue';
import LoanTransactionDetailPage from '@/pages/loan/LoanTransactionDetailPage.vue';
import LoanStatementsPage from '@/pages/loan/LoanStatementsPage.vue';
import LoanPaymentSchedulePage from '@/pages/loan/LoanPaymentSchedulePage.vue';
import LoanAuditLogPage from '@/pages/loan/LoanAuditLogPage.vue';
import LoanChargeOffPage from '@/pages/loan/LoanChargeOffPage.vue';
import LoanAutoPay from '@/pages/loan/LoanAutoPay.vue';
import LoanCollateralPage from '@/pages/loan/LoanCollateralPage.vue';
import FiAuditLogPage from '@/pages/FiAuditLogPage.vue';
import CpiPage from '@/pages/CpiPage.vue';
import LoanImport from '@/pages/loanImport/LoanImportPage.vue';
import LoanImportDetails from '@/pages/loanImport/LoanImportDetailsPage.vue';
import RouterViewPage from '@/pages/RouterViewPage.vue';
import HistoricalReportsPage from '@/pages/reports/HistoricalReportsPage.vue';
import ReportBuilderPage from '@/pages/reports/ReportBuilderPage.vue';

Vue.use(VueRouter);

type RouteProperty = string | Fi;

const routeToProps = (route: Route): Dictionary<RouteProperty> => {
	const props: Dictionary<RouteProperty> = {};

	if (route?.meta?.fi) {
		props.fiId = route.meta.fi.fiId;
		props.fi = route.meta.fi;
	}
	return { ...props, ...route.params };
};

const viewLinkedAccountRoutes: RouteConfig[] = [
	{
		name: 'view-linked-account',
		path: '',
		component: LinkedAccountPage,
		props: (route: Route): object => {
			const props: Dictionary<RouteProperty> = routeToProps(route);

			return { ...props, mode: displayMode.VIEW };
		},
	}, {
		name: 'edit-linked-account',
		path: 'edit',
		component: LinkedAccountPage,
		props: (route: Route): object => {
			const props: Dictionary<RouteProperty> = routeToProps(route);

			return { ...props, mode: displayMode.EDIT };
		},
	}, {
		path: '*',
		redirect: { name: 'view-linked-account' },
	},
];

const consumerLinkedAccountsRoutes: RouteConfig[] = [
	{
		name: 'consumer-linked-accounts',
		path: '',
		component: ConsumerLinkedAccountsPage,
		props: routeToProps,
		meta: {
			breadcrumb: {
				text: 'Linked Accounts',
			},
		},
	},
	{
		name: 'add-linked-account',
		path: 'add',
		component: LinkedAccountPage,

		props: (route: Route): object => {
			const props: Dictionary<RouteProperty> = routeToProps(route);

			return { ...props, mode: displayMode.ADD };
		},
	},
	{
		path: ':accountId',
		component: RouterViewPage,
		children: viewLinkedAccountRoutes,
	},
	{
		path: '*',
		redirect: { name: 'consumer-linked-accounts' },
	},
];

const LoanMaintenanceRoutes: RouteConfig[] = [
	{
		name: 'loan-maintenance',
		path: '',
		component: LoanMaintenancePage,
		props: routeToProps,
	},
	{
		name: 'loan-modifications',
		path: 'modifications',
		component: LoanModificationsPage,
		props: routeToProps,
		meta: {
			breadcrumb: {
				text: 'Loan Modifications',
			},
		},
	},
	{
		name: 'loan-charge-off',
		path: 'charge-off',
		component: LoanChargeOffPage,
		props: routeToProps,
		meta: {
			breadcrumb: {
				text: 'Loan Charge-off',
			},
		},
	},
	{
		name: 'loan-autopay',
		path: 'autopay',
		component: LoanAutoPay,
		props: routeToProps,
		meta: {
			breadcrumb: {
				text: 'Autopay Settings',
			},
		},

	},
	{
		name: 'loan-bankruptcy',
		path: 'bankruptcy',
		component: LoanBankruptcy,
		props: routeToProps,
		meta: {
			breadcrumb: {
				text: 'Bankruptcy Settings',
			},
		},
	},
	{
		path: '*',
		redirect: { name: 'loan-maintenance' },
	},
];

const loanOverviewRoutes: RouteConfig[] = [
	{
		name: 'loan-overview',
		path: 'overview',
		component: LoanOverviewPage,
		props: routeToProps,
		meta: {
			breadcrumb: {
				text: 'Consumer Name', // this can be anything because it will be replaced by replaceConsumerName method
			},
			replaceConsumerName: (name: string) => `${name}'s Loan Overview`,
		},
	},
	{
		path: 'transactions',
		component: RouterViewPage,
		props: routeToProps,
		meta: {
			breadcrumb: {
				text: 'Consumer Name',
			},
			replaceConsumerName: (name: string) => `${name}'s Loan Overview`,
		},
		children: [
			{
				name: 'loan-transactions',
				path: '',
				component: LoanTransactionsPage,
				props: routeToProps,
				meta: {
					breadcrumb: {
						text: 'Transactions',
					},
				},
			},
			{
				name: 'loan-transaction-details',
				path: 'transactions/:transactionId',
				component: LoanTransactionDetailPage,
				props: routeToProps,
			},
			{
				path: '*',
				redirect: { name: 'loan-transactions' },
			},
		],
	},
	{
		name: 'loan-statements',
		path: 'statements',
		component: LoanStatementsPage,
		props: routeToProps,
		meta: {
			breadcrumb: {
				text: 'Statements',
			},
		},
	},
	{
		name: 'loan-collections',
		path: 'collections',
		component: LoanCollectionsPage,
		props: routeToProps,
		meta: {
			breadcrumb: {
				text: 'Collections',
			},
		},
	},
	{
		name: 'loan-fees',
		path: 'fees',
		component: LoanFeesPage,
		props: routeToProps,
		meta: {
			breadcrumb: {
				text: 'Fees',
			},
		},
	},
	{
		name: 'loan-payment-schedule',
		path: 'payment',
		component: LoanPaymentSchedulePage,
		props: routeToProps,
		meta: {
			breadcrumb: {
				text: 'Payment Schedule',
			},
		},
	},
	{
		name: 'loan-borrowers',
		path: 'borrowers',
		component: LoanBorrowersPage,
		props: routeToProps,
		meta: {
			breadcrumb: {
				text: 'Borrowers',
			},
		},
	},
	{
		name: 'loan-collateral',
		path: 'collateral',
		component: LoanCollateralPage,
		props: routeToProps,
		meta: {
			breadcrumb: {
				text: 'Collateral',
			},
		},
	},
	{
		name: 'loan-comments',
		path: 'comments',
		component: LoanCommentsPage,
		props: routeToProps,
		meta: {
			breadcrumb: {
				text: 'Comments',
			},
		},
	},
	{
		name: 'loan-audit-log',
		path: 'audit-log',
		component: LoanAuditLogPage,
		props: routeToProps,
		meta: {
			breadcrumb: {
				text: 'Audit Log',
			},
		},
	},
	{
		path: 'maintenance',
		component: RouterViewPage,
		props: routeToProps,
		children: LoanMaintenanceRoutes,
		meta: {
			breadcrumb: {
				text: 'Loan Maintenance',
			},
		},
	},
	{
		name: 'loan-overview-redirect_0',
		path: '',
		redirect: { name: 'loan-overview' },
	},
	{
		name: 'loan-overview-redirect_1',
		path: '*',
		redirect: { name: 'loan-overview' },
	},
];

const consumerRoutes: RouteConfig[] = [
	{
		name: 'consumer-overview',
		path: 'overview',
		component: ConsumerOverviewPage,
		props: routeToProps,
	}, {
		name: 'consumer-audit-log',
		path: 'audit-log',
		component: ConsumerAuditLogPage,
		props: routeToProps,
		meta: {
			breadcrumb: {
				text: 'Audit Log',
			},
		},
	}, {
		path: 'linked-accounts',
		component: RouterViewPage,
		children: consumerLinkedAccountsRoutes,
	},
	{
		path: 'loan/:loanId',
		component: RouterViewPage,
		props: routeToProps,
		children: loanOverviewRoutes,
	},
	{
		name: 'consumer-overview-redirect_0',
		path: '',
		redirect: { name: 'consumer-overview' },
	},
	{
		name: 'consumer-overview-redirect_1',
		path: '*',
		redirect: { name: 'consumer-overview' },
	},
];

export const routes: RouteConfig[] = [
	{
		name: 'Home',
		path: '/',
		component: HomePage,
	},
	{
		path: '/fi/:clientId',
		component: RouterViewPage,
		meta: {
			breadcrumb: {
				text: 'Kasasa Loans',
			},
		},
		children: [
			{
				path: 'borrowers',
				component: RouterViewPage,
				props: routeToProps,
				meta: {
					breadcrumb: {
						text: 'Borrowers',
					},
				},
				children: [
					{
						name: 'consumer-list',
						path: '',
						component: ConsumerListPage,
						props: routeToProps,
					},
					{
						path: '/fi/:clientId/borrowers/:consumerId',
						component: RouterViewPage,
						children: consumerRoutes,
						meta: {
							breadcrumb: {
								text: 'Consumer Name', // this can be anything because it will be replaced by replaceConsumerName method
							},
							replaceConsumerName: (name: string) => name,
						},
					},
					{
						path: '*',
						redirect: { name: 'consumer-list' },
					},
				],
			},
			{
				name: 'collection-list',
				path: 'collections',
				component: Collections,
				props: routeToProps,
				meta: {
					breadcrumb: {
						text: 'Collections',
					},
				},
			},
			{
				path: 'reports',
				component: RouterViewPage,
				props: routeToProps,
				children: [
					{
						name: 'historical-reports',
						path: 'historical',
						component: HistoricalReportsPage,
						props: routeToProps,
						meta: {
							breadcrumb: {
								text: 'Historical Reports',
							},
						},
					},
					{
						name: 'report-builder',
						path: 'builder',
						component: ReportBuilderPage,
						props: routeToProps,
						meta: {
							breadcrumb: {
								text: 'Report Builder',
							},
						},
					},
					{
						path: '',
						redirect: { name: 'historical-reports' },
					},
					{
						path: '*',
						redirect: { name: 'historical-reports' },
					},
				],
			},
			{
				path: 'queue',
				props: routeToProps,
				component: RouterViewPage,
				meta: {
					breadcrumb: {
						text: 'Loan Import',
					},
				},
				children: [
					{
						name: 'loan-import',
						path: '',
						props: routeToProps,
						component: LoanImport,
					},
					{
						name: 'create-new-import',
						path: 'import',
						props: (route: Route): any => {
							const props: Dictionary<RouteProperty> = routeToProps(route);
							return { ...props, mode: displayMode.ADD };
						},
						component: LoanImportDetails,
						meta: {
							breadcrumb: {
								text: 'New Import',
							},
						},
					},
					{
						name: 'view-loan-import',
						path: ':importId',
						props: (route: Route): any => {
							const props: Dictionary<RouteProperty> = routeToProps(route);
							return { ...props, mode: displayMode.EDIT };
						},
						component: LoanImportDetails,
						meta: {
							breadcrumb: {
								text: 'Loan Import Details',
							},
						},
					},
					{
						path: '*',
						redirect: { name: 'loan-import' },
					},
				],
			},
			{
				name: 'fi-audit',
				path: 'audit',
				component: FiAuditLogPage,
				props: routeToProps,
				meta: {
					breadcrumb: {
						text: 'Audit Log',
					},
				},
			},
			{
				name: 'cpi-page',
				path: 'cpi',
				component: CpiPage,
				props: routeToProps,
				meta: {
					breadcrumb: {
						text: 'Collateral Protection Insurance',
					},
				},
			},
			{
				path: 'add',
				name: 'ach-add',
				component: AchPage,
				meta: {
					breadcrumb: {
						text: 'ACH Account',
					},
				},
				props: (route: Route): object => {
					const props: Dictionary<RouteProperty> = routeToProps(route);

					return { ...props, mode: displayMode.ADD };
				},
			},
			{
				path: ':achId',
				component: RouterViewPage,
				children: [
					{
						path: '',
						name: 'ach-view',
						component: AchPage,
						props: (route: Route): object => {
							const props: Dictionary<RouteProperty> = routeToProps(route);

							return { ...props, mode: displayMode.VIEW };
						},
					},
					{
						path: 'edit',
						name: 'ach-edit',
						component: AchPage,
						props: (route: Route): object => {
							const props: Dictionary<RouteProperty> = routeToProps(route);
							return { ...props, mode: displayMode.EDIT };
						},
					}],
			},
			{
				name: 'fi-consumer-list-redirect',
				path: '',
				redirect: { name: 'consumer-list' },
			},
			{
				path: '*',
				redirect: { name: 'consumer-list' },
			},
		],
	},
	// This is here as a fall through to redirect /clientId to 'consumer-list' route which
	// ends with /borrowers so that the route is distinct for the navigation highliting.
	{
		name: 'fi-consumer-list-redirect-0',
		// this makes sure the router doesn't treat fi as a :clientId
		path: '/fi',
		redirect: { name: 'Home' },
	},
	{
		name: 'fi-consumer-list-redirect-1',
		path: '/:clientId',
		redirect: { name: 'consumer-list' },
	},

	{
		path: '*',
		redirect: { name: 'Home' },
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
});

router.beforeResolve((to: Route, _: Route, next: Function): void => {
	if (to.params.clientId && !to.meta?.fi?.fiId) {
		// redirect to Fi select page if fi is missing
		next('/');
	} else {
		next();
	}
});

export default router;
