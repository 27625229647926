
import KCard from '@/components/KCard.vue';
import {
	Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import { ExtendedBankAccount } from '@/services/types/BankAccount';
import {
	getRecurringPaymentFrequencyName,
	RecurringPayment,
	RecurringPaymentFrequency, RecurringPaymentLatest,
} from '@/services/types/loan/RecurringPayment';
import { Formatters } from '@/utils/formatters';
import { Route } from 'vue-router';
import moment from 'moment';
import { weekDayLabel } from '@/utils/date';

@Component({
	components: {
		KCard,
		KReadOnly,
	},
})

export default class OverviewMonthlyPaymentCard extends Vue {
	@Prop({ required: true })
	data!: RecurringPayment;

	@Prop({ default: false })
	canModify!: boolean;

	@Prop({ required: true })
	bankAccounts!: ExtendedBankAccount[];

	@Prop()
	currentRecurringPayment!: RecurringPaymentLatest;

	@Inject('format')
	format!: Formatters;

	semiMonthlyPaymentData!: RecurringPaymentLatest;

	created(): void {
		this.semiMonthlyPaymentData = { ...this.currentRecurringPayment };
	}

	get activeAccountName(): string {
		if (this.data && this.data.currentAccountId && this.bankAccounts.length) {
			const account = this.bankAccounts.find((acct) => acct.id === this.data.currentAccountId) as ExtendedBankAccount;

			if (!account) {
				return '';
			}

			return `${account.institution.name} - ${account.name} (*${account.accountNumberLastFour})`;
		}

		return '';
	}

	get frequencyPaymentLabel(): string {
		return `${this.frequency} Payment`;
	}

	get frequency(): string {
		return getRecurringPaymentFrequencyName(this.data.frequency);
	}

	get isMonthlyPaymentFrequency(): boolean {
		return this.data.frequency !== RecurringPaymentFrequency.MONTHLY;
	}

	get getWeekDayPaymentLabel(): string {
		return weekDayLabel(moment(this.data.nextRecurringPaymentDate).day());
	}

	get semiMonthlyFrequencyLabel(): string {
		if (!Object.keys(this.semiMonthlyPaymentData).length) {
			return '';
		}
		return `${getRecurringPaymentFrequencyName(this.data.frequency)} - ${moment(this.data.nextRecurringPaymentDate).format('Do')} and ${moment(this.semiMonthlyPaymentData.draftDateTwo).format('Do')} of every month.`;
	}

	get paymentFrequencyLabel(): string {
		const frequencyText = getRecurringPaymentFrequencyName(this.data.frequency);
		switch (this.data.frequency) {
			case RecurringPaymentFrequency.WEEKLY:
				return `${frequencyText} - every ${this.getWeekDayPaymentLabel}`;
			case RecurringPaymentFrequency.BIWEEKLY:
				return `${frequencyText} - every other ${this.getWeekDayPaymentLabel}`;
			case RecurringPaymentFrequency.SEMIMONTHLY:
				return this.semiMonthlyFrequencyLabel;
			case RecurringPaymentFrequency.MONTHLY:
			default:
				return frequencyText;
		}
	}

	takeUserToSetupAutopay(): Promise<Route> {
		return this.$router.push({
			name: 'loan-autopay',
		});
	}
}
