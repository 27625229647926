
import KPage from '@/components/KPage.vue';
import { Component, Inject } from 'vue-property-decorator';
import BaseLoanPage from '@/pages/loan/BaseLoanPage';
import { KSpinner } from '@kasasa/fbase-components';
import { handleErrorResponse } from '@/utils/xhr';
import PaymentScheduleTable from '@/components/loan/paymentSchedule/PaymentScheduleTable.vue';
import { PaymentSchedule } from '@/services/types/loan/PaymentSchedule';
import { RouteTo } from '@/router/types';
import { Formatters } from '@/utils/formatters';

@Component({
	components: {
		KPage,
		PaymentScheduleTable,
		KSpinner,
	},
})
export default class LoanPaymentSchedulePage extends BaseLoanPage {
	loading: boolean = true;

	data: PaymentSchedule[] = [];

	@Inject('format')
	format!: Formatters;

	async created(): Promise<void> {
		await this.getPaymentScheduleData();
	}

	routeToTransactionDetails(transactionId: string) {
		this.$router.push(this.getLoanTransactionDetailsRoute(transactionId));
	}

	getLoanTransactionDetailsRoute(transactionId: string): RouteTo {
		return {
			name: 'loan-transaction-details',
			params: {
				loanId: this.loanId,
				transactionId,
			},
		};
	}

	async getPaymentScheduleData(): Promise<void> {
		try {
			const response = await this.loanSvc.getPaymentSchedule(this.loanId);

			const formattedPaymentSchedule: PaymentSchedule[] = response.data.data;

			// remove time from datetime fields
			formattedPaymentSchedule.forEach(
				(paymentSchedule: PaymentSchedule): void => {
					paymentSchedule.startDate = this.format.asDate(
						paymentSchedule.startDate,
					);
					paymentSchedule.endDate = this.format.asDate(
						paymentSchedule.endDate,
					);
					paymentSchedule.statementDate = this.format.asDate(
						paymentSchedule.statementDate,
					);
					paymentSchedule.lateFeeAssessmentDate = this.format.asDate(
						paymentSchedule.lateFeeAssessmentDate,
					);
				},
			);

			this.data = formattedPaymentSchedule;
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.loading = false;
		}
	}
}
