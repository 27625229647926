import {
	InsuranceCoverage,
} from '@/services/types/loan/Insurance';
import { Asset } from '@/services/types/loan/Collection';
import { LoanProduct } from '@/services/types/loan/LoanProduct';

export interface BrokenLoan {
	consumerName: string
	id: string
	loanProduct: string
	submittedTimestamp: string
	timestamp: number
}

export interface BorkenLoanResponse {
	body: BrokenLoan[]
}

export enum BorrowerType {
	PRIMARY = 'primary',
	CO_BORROWER = 'co-borrower',
	CO_SIGNER = 'co-signer',
	GUARANTOR = 'guarantor',
	NONE = ''
}

export interface BorrowerProfileData {
	applicantType: BorrowerType;
	firstName: string;
	middleName?: string;
	lastName: string;
	ssn: string;
	dateOfBirth: string;
	creditScore?: string;
	email: string;
	homePhone: string | null;
	workPhone: string | null;
	mobilePhone: string | null;
	coreAccountId: string;
	ssnConfirm?: string;
	dateOfBirthConfirm?: string;
	dobToggle?: boolean;
	dobConfirmToggle?: boolean;
	phones?: {
		home: string
		mobile: string
		office: string
	},
	address?: {
		streetAddress: string
		city: string
		state: string
		zip: string
	},
	streetAddress?: string
	city?: string
	state?: string
	zip?: string
}
export interface BorrowerProfileResponseData {
	applicantType: BorrowerType;
	firstName: string;
	middleName?: string;
	lastName: string;
	ssn: string;
	dateOfBirth: string;
	creditScore?: string;
	email: string;
	homePhone?: string;
	workPhone?: string;
	mobilePhone?: string;
	coreAccountId: string;
	ssnConfirm?: string;
	dateOfBirthConfirm?: string;
	dobToggle?: boolean;
	dobConfirmToggle?: boolean;
	phones?: {
		home: string
		mobile: string
		office: string
	},
	address: string
	city: string
	state: string
	zip: string
}

export interface Coverage {
	policyNumber: string;
	coverageType: string;
	description?: string;
	expireDate?: string;
}

export interface PaymentDetails {
	accountName?: string;
	accountNumber?: string;
	accountType?: string;
	holderName?: string;
	paymentAmount?: string;
	routingNumber?: string;
}

export interface LoanInformation {
	loanProduct?: string;
	coreTrackingRecordNumber?: string;
	coreAccountId?: string;
	applicationNumber?: string;
	originationDate?: string;
	firstPaymentDate?: string;
	paymentDate?: string;
	maturityDate?: string;
	interestRate?: number | string | null;
	originalPrincipal?: number | string | null;
	installmentAmount?: number | string | null;
	collateralAssetType?: string;
	originationDateToggle?: boolean;
	firstPaymentDateToggle?: boolean;
	paymentDateToggle?: boolean;
	maturityDateToggle?: boolean;
	terms?: number
	branch?: string;
	loanOfficer?: string;
	paymentDetails?: PaymentDetails
	collateralAssetData: Asset
}

/* eslint-disable camelcase */
interface GlAccount {
	account_number: string
	account_type: number
	created_at: string
	deleted_at: string
	description: string
	fi_id: number
	id: string
	is_disbursement_account: boolean
	is_finance_charge: boolean
	updated_at: string
}

export interface GetOptionsResponse {
	glAccounts: GlAccount[]
	insuranceProducts: InsuranceCoverage[]
	loanProducts: LoanProduct[]
}

export interface SubmitNewLoanPayload extends LoanInformation {
	applicants: BorrowerProfileData[];
	coverages: Coverage[];
	fiId: string;
}

export interface LoanDetailResponse extends LoanInformation {
	applicants: BorrowerProfileData[];
	coverages: Coverage[];
	fiId: string;
	error?: {
		code: number;
		errors: {
			code: string
			field: string
			message: string
		}[];
		message: string
	}
}

export interface BrokenLoanItem {
	name: string
	id: string
	submitted: string
}
