import { Dictionary } from 'vue-router/types/router.d';

export enum displayMode {
	VIEW = 1,
	EDIT = 2,
	ADD = 3,
}

export interface RouteTo {
	name: string;
	params?: Dictionary<string>;
	query?: Dictionary<string | (string | null)[]>;
}
