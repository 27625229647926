import { AxiosResponse } from 'axios';
import { FBaseService } from '@kasasa/fbase-components/lib';

export interface LastBatchReportDateResponseData {
	date: string;
	message: string;
}

export interface ReportResponseData {
	displayName: string;
	reportId: string;
	reportType: string;
}

export interface ReportResponseType {
	displayName: string;
	id: Number;
	showEndDate: boolean;
	value: string;
}

export interface ReportResponseTypeResponse {
	reports: ReportResponseType[];
}

export interface ReportResponseDataResponse {
	reports: ReportResponseData[];
}

export interface GenerateReportResponseData {
	reportId: string;
	statusCode: number;
}

export enum ReportDateType {
	POSTED_DATE = 'POSTED_DATE',
	EFFECTIVE_DATE = 'EFFECTIVE_DATE',
}

export interface ReportRequestData {
	reportType?: string;
	startDate?: string;
	endDate?: string;
	reportDateType?: ReportDateType;
}

enum FileType {
	CSV = 'CSV',
	XLSX = 'XLSX',
}

export interface ReportStatusData {
	isReady: boolean;
	message: string;
	reportStatus: string;
	statusCode: number;
	reportId: string;
	fileTypes: FileType[];
}

export default class FiService extends FBaseService {
	serverErrorHandler?: () => Promise<void>;

	hanldeServerError(): Promise<void> {
		if (this.serverErrorHandler) {
			return this.serverErrorHandler();
		}
		return super.hanldeServerError();
	}

	setServerErrorHandler(handler: () => Promise<void>): void {
		this.serverErrorHandler = handler;
	}

	async getLastBatchReportDate(fiId: string): Promise<LastBatchReportDateResponseData> {
		const response: AxiosResponse<LastBatchReportDateResponseData> = await this.apiClient.get(
			`/api/v1/kloans-reporting/fiId/${fiId}/last-batch-report-date`,
		);
		return response.data;
	}

	async getReportData(fiId: string, date: string): Promise<ReportResponseDataResponse> {
		const response: AxiosResponse<ReportResponseDataResponse> = await this.apiClient.get(
			`/api/v1/kloans-reporting/fiId/${fiId}/date/${date}/report`,
		);
		return response.data;
	}

	async getReportTypes(fiId: string): Promise<ReportResponseTypeResponse> {
		const response: AxiosResponse<ReportResponseTypeResponse> = await this.apiClient.get(
			`/api/v1/kloans-reporting/fi/${fiId}/report-types`,
		);
		return response.data;
	}

	async generateReport(
		fiId: string,
		reportRequestDate: ReportRequestData,
	): Promise<GenerateReportResponseData> {
		const response: AxiosResponse<GenerateReportResponseData> = await this.apiClient.post(
			`/api/v1/kloans-reporting/${fiId}/report/generate`,
			{ ...reportRequestDate, fiId },
		);
		return response.data;
	}

	async getReportStatus(fiId: string, reportId: string): Promise<ReportStatusData> {
		const response: AxiosResponse<ReportStatusData> = await this.apiClient.get(
			`/api/v1/kloans-reporting/fiId/${fiId}/report/${reportId}/status`,
		);
		return { ...response.data, reportId };
	}
}
