
import KPage from '@/components/KPage.vue';
import { Component, Provide } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import {
	ExtendedTransaction,
	TransactionStatus,
} from '@/services/types/loan/LoanTransaction';
import BaseLoanPage from '@/pages/loan/BaseLoanPage';
import HistoricalTransactionsTable from '@/components/loan/transactions/HistoricalTransactionsTable.vue';
import PendingTransactionsTable from '@/components/loan/transactions/PendingTransactionsTable.vue';
import { Dialog, NoticeClass } from '@kasasa/fbase-components/lib';
import { handleErrorResponse } from '@/utils/xhr';
import RecordTransactionModal from '@/components/loan/transactions/RecordTransactionModal.vue';

@Component({
	components: {
		KPage,
		KReadOnly,
		HistoricalTransactionsTable,
		PendingTransactionsTable,
		RecordTransactionModal,
	},
})
export default class LoanTransactionsPage extends BaseLoanPage {
	@Provide()
	loanFiId: string = this.fiId;

	transactionsLoading: boolean = true;

	search: string = '';

	data: ExtendedTransaction[] = [];

	showModal: boolean = false;

	get payoffAmount(): string {
		if (this.loan && this.loan.general) {
			return this.loan.general.payoffAmount;
		}
		return '';
	}

	get pendingTransactions(): ExtendedTransaction[] {
		return this.data.filter(
			(t) => Number(t.status) === TransactionStatus.Pending,
		);
	}

	get historicalTransactions(): ExtendedTransaction[] {
		return this.data.filter(
			(t) => Number(t.status) !== TransactionStatus.Pending,
		);
	}

	async created(): Promise<void> {
		await Promise.all([
			this.fetchAndSetLoan(),
			this.getConsumer(),
			this.getBankAccounts(),
			this.getTransactions(),
		]);
	}

	async getTransactions(): Promise<void> {
		this.transactionsLoading = true;

		try {
			const response = await this.loanSvc.getTransactions(
				this.consumerId,
				this.loanId,
			);
			this.data = response.data.data;
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.transactionsLoading = false;
		}
	}

	get shouldAllowCreateTransaction(): boolean {
		return this.canModifyLoan;
	}

	async confirmCancelTransaction(item: ExtendedTransaction): Promise<void> {
		const dialog = new Dialog(
			'Confirm Cancel Transaction',
			'Are you sure you want to cancel this transaction?',
		);
		dialog.setDeclineLabel('Cancel');
		const result = await this.showDialog(dialog);

		if (result === 'accept') {
			try {
				item.loadingCancel = true;
				await this.loanSvc.cancelTransaction(this.fiId, this.loanId, item.id);

				this.showAlert(
					'Transaction successfully cancelled.',
					NoticeClass.SUCCESS,
					5000,
				);

				await this.refreshTransactions();
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			} finally {
				item.loadingCancel = false;
			}
		}
	}

	async refreshTransactions(): Promise<void> {
		this.data = [];
		await this.getTransactions();
	}

	get fullName(): string {
		return `${this.sessionData.firstName} ${this.sessionData.lastName}`.trim();
	}

	get isTakeBackEnabled(): boolean {
		return this.loan?.general?.takeBacksEnabled;
	}
}
