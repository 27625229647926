import { LoanStatus } from '@/services/types/loan/Loan';
import { CoverageType, InsurancePolicy } from '@/services/types/loan/Insurance';

export interface Status {
	id: string;
	loanId: string;
	paymentPeriod: number;
	status: string|LoanStatus;
	canPauseResume: boolean;
	canChargeOff: number;
	delinquentDays: number;
	totalPayments: string;
	totalWithdrawals: string;
	feesPaid: string;
	feesDue: string;
	interestPaid: string;
	principalPaid: string;
	principalBalance: string;
	chargedOffBalance: string;
	takeBackBalance: string;
	pastDue: string;
	systemTakeBackEnabled: boolean;
	takeBackEnabled: boolean;
	bypassCreditReporting: boolean;
	lastPaymentDate?: any;
	lastTransactionDate: string;
	interestCarriedOver: string;
	loanFeesPaid: string;
	loanFeesDue: string;
	paymentsEnabled: boolean;
	primaryAssetFeesPaid: string;
	primaryAssetFeesDue: string;
	takeBackFeesPaid: string;
	takeBackFeesDue: string;
	takeBackInterestPaid: string;
	takeBackPrincipalPaid: string;
	takeBackPrincipalBalance: string;
	takeBackChargedOffBalance: string;
	takeBackInterestCarriedOver: string;
	takeBackStatus?: any;
	currentTakeBackAssetId?: any;
	effectiveStart: string;
	effectiveEnd?: any;
	overpaymentAmount: string;
	chargedOffAt?: any;
	paidOffAt?: any;
	createdAt: string;
	updatedAt: string;
	accruedInterest: string;
	payoffAmount: string;
}

export interface DueDatePayLoad {
	nextDueDate: string;
}

export interface LoanModResultTerms {
	nextDueDate: string;
	maturityDate: string;
	principalBalance: string;
	interestRate: string;
	installmentAmount: string;
	outstandingLateFees: string;
	outstandingReturnedItemFees: string;
	totalAmountFinanced?: string;
	coverages?: { name: string, value: CoverageType }[];
	term: number|null;
}

export interface LoanModResults {
	new: LoanModResultTerms;
	old: LoanModResultTerms;
}

export enum LoanModStatus {
	PENDING = 'pending',
	PROPOSED = 'proposed',
	COMMITTED = 'committed',
	CANCELLED = 'cancelled',
}

export interface LoanModification {
	id: string;
	loanId: string;
	status: LoanModStatus;
	expireAt: string;
	submittedAt: string;
	submittedBy: string;
	cancelledAt: string|null;
	cancelledReason: string|null;
	loanModType: string;
	loanModId?: string;
	loanModResult?: LoanModResults;
	updatedAt?: string;
	deletedAt?: string;
}

export type LoanModificationKeys = keyof LoanModResultTerms;

export interface ChargeOffPayLoad {
	sourceSystemTransactionId?: string;
	recoveryAmount? : string;
	recoveryDescription? : string;
}

export enum LoanModType {
	'due_date_mod' = 'Due Date Modification',
	'insurance_mod' = 'Insurance Modification',
	'loan_init_mod' = 'Loan Initialization',
	'loan_term_mod' = 'Modification',
	'scra_loan_term_mod' = 'SCRA Loan Term Modification',
	'end_scra_mod' = 'End SCRA Modification',
}

export interface LoanTermMod {
	type: LoanModType;
	loanModId: string;
	effectiveDate?: string;
	endDate?: string;
	installmentAmount: string;
	interestRate: string;
	maturityDate: string;
	scra: boolean;
	notes: string;
	principalChanges?: string;
	active?: boolean;
}

export interface InterestTerm {
	rate: string;
	effectiveDate?: string;
	endDate?: string;
}

export interface InstallmentTerm {
	amount: string;
	effectiveDate?: string;
	endDate?: string;
}

export enum PrincipalChangeTermType {
	NONE = '0',
	INCREASE = '1',
	DECREASE = '2',
}

export interface PrincipalChangeTerm {
	amount: string | number;
	type: PrincipalChangeTermType;
	effectiveDate: string;
	userNote: string;
}

export interface LoanTermModPayload {
	effectiveDate?: string;
	endDate?: string;
	interestTerm?: InterestTerm;
	installmentTerm?: InstallmentTerm;
	maturityDate?: string;
	notes?: string;
	overPaymentRefundMethod?: string;
	principalChangeTerm?: PrincipalChangeTerm;
}

export interface LoanMaintenance {
	products: InsurancePolicy[];
}
