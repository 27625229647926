
import KPage from '@/components/KPage.vue';
import KCard from '@/components/KCard.vue';
import { Component, Inject, Watch } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import BaseFiPage from '@/pages/BaseFiPage';
import CollectionTable from '@/components/CollectionTable.vue';
import { Formatters } from '@/utils/formatters';
import moment from 'moment';
import { Validations } from 'vuelidate-property-decorators';
import { RuleDecl } from 'vue/types/options.d';
import { RequiredValidation } from '@kasasa/kloans-common-ui/dist/validations';
import { DataTableHeader } from 'vuetify';
import { VKLOANS } from '@/pages/PermissionGroups';
import { handleErrorResponse } from '@/utils/xhr';

interface FiReport {
	displayName:string;
	reportId: string;
	reportType:string;
}

enum FileType {
	CSV = 'CSV',
	XLSX = 'XLSX',
}

@Component({
	components: {
		KPage,
		KReadOnly,
		CollectionTable,
		KCard,
	},
	enums: {
		FileType,
	},
})
export default class HistoricalReportsPage extends BaseFiPage {
	@Inject('format') format!: Formatters;

	@Watch('reportDate', { immediate: true })
	async onReportDateChange(date: string): Promise<void> {
		if (moment(date).isValid()) {
			await this.getReportList();
		}
	}

	today: moment.Moment = moment().startOf('day');

	@Validations()
	validations(): RuleDecl {
		const pastOrTodayDate = (value: string) => {
			if (value) {
				return moment(value).isSameOrBefore(this.today);
			}

			return true;
		};

		const	reportDate = {
			...RequiredValidation.validations(),
			pastOrTodayDate,
		};

		return { reportDate };
	}

	headers: DataTableHeader[] = [
		{
			text: 'Description',
			value: 'displayName',
			width: '50%',
			align: 'start',
		},
		{
			text: '',
			value: 'reportId',
			width: '50%',
			align: 'start',
			sortable: false,

		},
	];

	reports: FiReport[] = [];

	loading: boolean = false;

	reportDateMenu: boolean = false;

	reportDate:string = '';

	required = [(value: string): boolean | string => !!value || 'This field is required.'];

	getDownloadLink(item:FiReport, endDate?:string, fileType: FileType = FileType.CSV): string {
		return `/api/v1/kloans-reporting/fiId/${this.fiId}/report/${item.reportId}/download?startDate=${this.reportDate}&reportType=${item.reportType}&endDate=${endDate
			|| this.reportDate}&fileType=${fileType}`;
	}

	allowedDates(date: string): boolean {
		return moment(date)
			.startOf('day')
			.isSameOrBefore(this.today);
	}

	async created(): Promise<void> {
		this.loading = true;
		try {
			const response = await this.fiService.getLastBatchReportDate(this.fiId);
			this.reportDate = response.date;
		} catch (error) {
			this.reportDate = '';
			handleErrorResponse((error as any).response, this);
		} finally {
			this.loading = false;
		}
	}

	async getReportList(): Promise<void> {
		this.loading = true;
		try {
			const response = await this.fiService.getReportData(this.fiId, this.reportDate);
			this.reports = response.reports.filter((report) => !!report.reportId);
		} catch (error) {
			this.reports = [];
		} finally {
			this.loading = false;
		}
	}

	get canView(): boolean {
		return this.canRead(VKLOANS);
	}
}
