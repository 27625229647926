
import KPage from '@/components/KPage.vue';
import { Component, Inject } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import BaseLoanPage from '@/pages/loan/BaseLoanPage';
import HistoricalTransactionsTable from '@/components/loan/transactions/HistoricalTransactionsTable.vue';
import PendingTransactionsTable from '@/components/loan/transactions/PendingTransactionsTable.vue';
import { Borrower, newBorrower } from '@/services/types/loan/Borrower';
import BorrowerCard from '@/components/loan/BorrowerCard.vue';
import { Formatters } from '@/utils/formatters';

@Component({
	components: {
		BorrowerCard,
		KPage,
		KReadOnly,
		HistoricalTransactionsTable,
		PendingTransactionsTable,
	},
})
export default class LoanBorrowersPage extends BaseLoanPage {
	@Inject('format')
	format!: Formatters;

	async created(): Promise<void> {
		await this.getBorrowers();
	}

	get canView(): boolean {
		return this.canRead('vkloans');
	}

	get canAddBorrower(): boolean {
		return this.borrowers.every((borrower: Borrower) => borrower.id !== '');
	}

	addBorrower(): void {
		if (this.canAddBorrower) {
			this.borrowers.push(newBorrower(this.borrowers[0]));
		}

		this.$nextTick(() => {
			const borrowerElement = document.querySelector('.new-borrower');

			if (borrowerElement) {
				borrowerElement.scrollIntoView();
			}
		});
	}

	onBorrowerCancelAdd(idx: number): void {
		this.$delete(this.borrowers, idx);
	}

	onBorrowerSave(): void {
		this.getBorrowers();
	}
}
