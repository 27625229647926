
import KPage from '@/components/KPage.vue';
import { Component, Inject, Prop } from 'vue-property-decorator';
import KCard from '@/components/KCard.vue';
import BankAccountForm from '@/components/BankAccountForm.vue';
import { NoticeClass } from '@kasasa/fbase-components/lib';
import BaseConsumerPage from '@/pages/consumer/BaseConsumerPage';
import { displayMode } from '@/router/types';
import { KText } from '@kasasa/fbase-components';
import { Fi } from '@kasasa/fbase-components/src/services/Auth/types';
import { handleErrorResponse } from '@/utils/xhr';
import { BankAccountPayload, ExtendedBankAccount } from '@/services/types/BankAccount';
import { VKLOANS } from '@/pages/PermissionGroups';

@Component({
	components: {
		BankAccountForm,
		KCard,
		KText,
		KPage,
	},
	enums: {
		displayMode,
	},
})
export default class LinkedAccountPage extends BaseConsumerPage {
	@Inject('format') format!: object;

	@Prop({ default: null })
	accountId!: string | null;

	@Prop({ required: true })
	consumerId!: string;

	@Prop({ required: true })
	clientId!: string;

	@Prop({ required: true })
	fi!: Fi;

	@Prop({ required: true })
	mode!: displayMode;

	data: BankAccountPayload = { } as BankAccountPayload;

	private allowedRoutingNumbers: string[] = [];

	get title(): string {
		if (this.mode === displayMode.ADD) {
			return 'Add New Account';
		}

		if (this.mode === displayMode.EDIT) {
			return 'Edit Linked Account';
		}

		return 'Linked Account';
	}

	loading: boolean = true;

	loadingSave: boolean = false;

	getBreadcrumbTitle(): string {
		if (this.mode === this.$enums.displayMode.ADD) {
			return 'New Account';
		}

		if (this.mode === this.$enums.displayMode.VIEW) {
			return `${this.data.name} (*${this.data.accountNumber.substr(-4)})`;
		}

		return `Editing ${this.data.name} (*${this.data.accountNumber.substr(-4)})`;
	}

	async fetchAndSetRoutingNumbers(): Promise<void> {
		try {
			this.allowedRoutingNumbers = await this.consumerSvc.getAllowedRoutingNumbers(this.fiId);
		} catch (error) {
			throw new Error(error as any);
		}
	}

	checkRoutingAllowed(routingNum: string): boolean {
		return this.allowedRoutingNumbers.includes(routingNum);
	}

	async created(): Promise<void> {
		this.loading = true;
		await Promise.all([
			await this.getConsumer(),
			await this.fetchAndSetRoutingNumbers(),
		]);
		this.loading = false;

		if (this.mode === this.$enums.displayMode.ADD) {
			this.data = { holderName: this.consumer?.name, accountType: 'checking' } as BankAccountPayload;
		} else {
			await this.getAccount(true);
		}
	}

	// eslint-disable-next-line class-methods-use-this
	formatPayload(account: ExtendedBankAccount): BankAccountPayload {
		return {
			holderName: account.holderName as string,
			accountNumber: account.accountNumber,
			accountType: account.accountType,
			routingNumber: account.routingNumber as string,
			institutionName: account.institution.name,
			name: account.name,
			external: account.external,
		};
	}

	async getAccount(force: boolean = false): Promise<BankAccountPayload> {
		if (this.canView) {
			if (force || !this.data.accountNumber) {
				try {
					this.loading = true;
					const response = await this.consumerSvc.getBankAccount(this.consumerId, this.accountId as string);
					this.data = this.formatPayload(response.data.data);
				} catch (error) {
					handleErrorResponse((error as any).response, this);
				} finally {
					this.loading = false;
				}
			}
		}

		return this.data;
	}

	invalidForm: boolean = false;

	accountInvalidChanged(invalid: boolean): void {
		this.invalidForm = invalid;
	}

	get canView(): boolean {
		return this.canRead(VKLOANS);
	}

	get readonly(): boolean {
		return this.canView && this.mode === displayMode.VIEW;
	}

	async save(): Promise<void> {
		// so far only one routing number per FI
		const payload: BankAccountPayload = {
			...this.data, institutionName: this.fi.name, routingNumber: this.allowedRoutingNumbers[0],
		};

		try {
			this.loadingSave = true;
			if (this.mode === displayMode.ADD) {
				await this.consumerSvc.postBankAccount(this.consumerId, payload);
				this.showAlert('Account added successfully.', NoticeClass.SUCCESS, 5000);
			} else {
				await this.consumerSvc.putBankAccount(this.consumerId, this.accountId as string, payload);
				this.showAlert('Account updated successfully.', NoticeClass.SUCCESS, 5000);
			}

			this.$router.replace({ name: 'consumer-linked-accounts', params: { clientId: this.clientId, consumerId: this.consumerId } });
		} catch (error) {
			await handleErrorResponse((error as any).response, this);
		} finally {
			this.loadingSave = false;
		}
	}
}
