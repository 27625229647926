
import KPage from '@/components/KPage.vue';
import { Component, Inject, Watch } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import {
	Consumer,
} from '@/services/AchService';
import BaseFiPage from '@/pages/BaseFiPage';
import ConsumerTable from '@/components/ConsumerTable.vue';
import { Formatters } from '@/utils/formatters';
import { handleErrorResponse } from '@/utils/xhr';

@Component({
	components: {
		KPage,
		KReadOnly,
		ConsumerTable,
	},
})
export default class ConsumerListPage extends BaseFiPage {
	@Inject('format') format!: Formatters;

	@Watch('clientId')
	onSwitchFi(to: string, from: string): void {
		if (to !== from) {
			this.setPageData();
		}
	}

	consumersLoading: boolean = true;

	consumers: Consumer[] = [];

	search: string = '';

	async setPageData(): Promise<void> {
		this.consumers = [];
		this.loadData();
	}

	async created(): Promise<void> {
		await this.setPageData();
	}

	async loadData(): Promise<void> {
		try {
			this.consumersLoading = true;
			const response = await this.consumerSvc.getConsumersForFi(this.fiId);
			this.consumers = response.data.data;
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.consumersLoading = false;
		}
	}

	get canView(): boolean {
		return this.canRead('vkloans');
	}
}
