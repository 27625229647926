export interface Phones {
	home: string | null;
	mobile: string | null;
	office: string | null;
}

export interface Address {
	id: string;
	loanApplicantId?: string;
	addressType: string;
	streetAddress: string;
	streetAddress2?: string | null | undefined;
	city: string;
	state: string;
	zip: string;
	createdAt?: string;
	updatedAt?: string;
	deletedAt?: string;
}

export enum ApplicantType {
	PRIMARY = 'primary',
	CO_BORROWER = 'co-borrower',
	CO_SIGNER = 'co-signer',
	GUARANTOR = 'guarantor',
}

export const getApplicantTypeName = (applicantType: ApplicantType): string => {
	switch (applicantType) {
		case ApplicantType.PRIMARY:
			return 'Primary Borrower';
		case ApplicantType.CO_BORROWER:
			return 'Co-Borrower';
		case ApplicantType.CO_SIGNER:
			return 'Co-Signer';
		case ApplicantType.GUARANTOR:
			return 'Guarantor';
		default:
			return 'Unknown Borrower Type';
	}
};

export interface Borrower {
	addresses?: Address[];
	applicantType: ApplicantType;
	consumerId: string;
	cif: string;
	coreAccountId: string;
	creditScore?: string;
	dob: string;
	editing?: boolean;
	email: string;
	employer?: string;
	expanded?: boolean;
	firstName: string;
	id: string;
	lastName: string;
	loading?: boolean;
	loanId: string;
	maritalStatus?: string;
	middleName?: string;
	occupation?: string;
	phones: Phones;
	preferredContactMethod?: string;
	ssn: string;
	ssnLastFour: string;
	stateOfResidency?: string;
	supervisorName?: string;
}
export interface BorrowerSectionData {
	applicantType: ApplicantType;
	consumerId: string;
	cif: string;
	coreAccountId: string;
	creditScore?: string;
	dateOfBirth: string;
	dateOfBirthConfirm: string;
	email: string;
	firstName: string;
	id: string;
	lastName: string;
	loading?: boolean;
	loanId: string;
	middleName?: string;
	ssn: string;
	ssnConfirm: string;
	homePhone?: string
	workPhone?: string
	mobilePhone?: string
	streetAddress: string
	city: string
	state: string
	zip: string
}

export const extendBorrower = (borrower: Borrower): Borrower => {
	borrower.loading = false;
	borrower.editing = false;
	borrower.expanded = true;
	return borrower;
};

export const BlankBorrower = {
	addresses: [
		{
			addressType: 'Current',
			city: '',
			id: '',
			LoanApplicantId: '',
			state: '',
			streetAddress: '',
			streetAddress2: null,
			zip: '',
			createdAt: '',
			updatedAt: '',
		},
	],
	applicantType: ApplicantType.CO_BORROWER,
	consumerId: '',
	cif: '',
	coreAccountId: '',
	creditScore: '',
	dob: '',
	editing: true,
	email: '',
	employer: '',
	firstName: '',
	id: '',
	lastName: '',
	loading: false,
	loanId: '',
	maritalStatus: '',
	middleName: '',
	occupation: '',
	phones: {
		home: '',
		mobile: '',
		office: '',
	},
	preferredContactMethod: '',
	ssn: '',
	ssnLastFour: '',
	stateOfResidency: '',
	supervisorName: '',
};

export const newBorrower = (template?: Borrower): Borrower => {
	const borrower = { ...BlankBorrower } as Borrower;

	borrower.editing = true;
	borrower.expanded = true;
	borrower.loanId = template?.loanId || '';

	return borrower;
};
