
import {
	Component, Inject, Prop, Watch,
} from 'vue-property-decorator';
import { RuleDecl } from 'vue/types/options.d';
import { Validations } from 'vuelidate-property-decorators';
import LoanService from '@/services/LoanService';
import { handleErrorResponse } from '@/utils/xhr';
import BaseComponent from '@/components/BaseComponent';
import { KReadOnly, KText } from '@kasasa/fbase-components';
import { Loan, Period } from '@/services/types/loan/Loan';
import PendingModificationCard from '@/components/loan/modifications/PendingModificationCard.vue';
import {
	InstallmentTerm,
	InterestTerm,
	LoanModification,
	LoanModStatus,
	LoanTermMod,
	LoanTermModPayload,
	PrincipalChangeTerm,
	PrincipalChangeTermType,
} from '@/services/types/loan/Maintenance';
import ProposedModificationCard, { ProposedLoanModificationType } from '@/components/loan/modifications/ProposedModificationCard.vue';
import ModificationCompleteCard from '@/components/loan/modifications/ModificationCompleteCard.vue';
import LoanHistoryTable from '@/components/loan/modifications/LoanHistoryTable.vue';
import moment from 'moment';
import {
	helpers, maxValue, minValue, required, ValidationParams, ValidationRule,
} from 'vuelidate/lib/validators';
import formatters, { Formatters } from '@/utils/formatters';
import resolvePath from '@/utils/resolvePath';
import { ValidationProperties } from 'vue/types/vue.d';
import { DropdownOption } from '@/types/Form';

const dateBefore = (when: string | Date | moment.Moment, inclusive = false, label = 'Field'): ValidationRule => {
	const params = { date: when, inclusive, label };
	return helpers.withParams(
		{ type: 'before', value: params },
		(model: string | Date | moment.Moment): boolean => {
			const check = moment(model);
			const test = moment(when);

			if (!model) {
				return true;
			}

			return inclusive ? check.isSameOrBefore(test) : check.isBefore(test);
		},
	);
};

const dateAfter = (when: string | Date | moment.Moment, inclusive = false, label = 'Field'): ValidationRule => {
	const params = { date: when, inclusive, label };
	return helpers.withParams(
		{ type: 'after', value: params },
		(model: string | Date | moment.Moment): boolean => {
			const check = moment(model);
			const test = moment(when);

			if (!model) {
				return true;
			}

			return inclusive ? check.isSameOrAfter(test) : check.isAfter(test);
		},
	);
};

const dateErrors = (key: string, $validators: ValidationProperties<any>): string[] => {
	const $v = resolvePath(key, $validators);
	const errors: string[] = [];
	if ($v) {
		const params = $v.$flattenParams();

		params.forEach((validator: ValidationParams): void => {
			// We only care if the validation method is invalid.
			if (!$v[validator.name]) {
				const $params = validator.params;
				const { type } = $params;
				const options = $params.value;

				if (options && options.date) {
					options.label = options.label || 'Field';

					if (validator.name === 'required') {
						errors.push(`${options.label} is required.`);
					} else {
						let when = moment(options.date);
						if (options.inclusive) {
							when = when.add(type === 'before' ? 1 : -1, 'day');
						}
						errors.push(`${options.label} must be ${type} ${formatters.asDate(when)}.`);
					}
				}
			}
		});
	}
	return errors;
};

enum TermsModStep {
	LOAN_HISTORY = 1,
	MODIFY_TERMS = 2,
	PREPARING_MOD = 3,
	PROPOSAL_REVIEW = 4,
	COMPLETE = 5,
}

@Component({
	components: {
		KReadOnly,
		KText,
		LoanHistoryTable,
		ModificationCompleteCard,
		PendingModificationCard,
		ProposedModificationCard,
	},
	enums: {
		PrincipalChangeTermType,
		TermsModStep,
	},
})
export default class ModifyTermsModal extends BaseComponent {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	clientId!: string;

	@Prop({ required: true })
	consumerId!: string;

	@Prop({ required: true })
	fiId!: string;

	@Prop({ required: true })
	loanId!: string;

	@Prop({ required: true })
	show!: boolean;

	@Prop({ required: true })
	loan!: Loan;

	private today: moment.Moment = moment().startOf('day');

	private isEndingScra: boolean = false;

	private isScraModification: boolean = false;

	private currentStep: TermsModStep = TermsModStep.LOAN_HISTORY;

	private adjustmentItemOptions: DropdownOption[] = [
		{
			text: 'None',
			value: PrincipalChangeTermType.NONE,
		},
		{
			text: 'Increase',
			value: PrincipalChangeTermType.INCREASE,
		},
		{
			text: 'Decrease',
			value: PrincipalChangeTermType.DECREASE,
		},
	];

	private effectiveDatePrincipalMenu: boolean = false;

	private effectiveDateInstallmentMenu: boolean = false;

	private endDateInstallmentMenu: boolean = false;

	private effectiveDateInterestRateMenu: boolean = false;

	private endDateInterestRateMenu: boolean = false;

	private maturityDateMenu: boolean = false;

	private activeMaturityDatePicker: string|null = null;

	private effectiveDateScraMenu: boolean = false;

	private endDateScraMenu: boolean = false;

	private endDate: string = '';

	private overPaymentRefundMethod: string = 'InBranch';

	private notes: string = '';

	private interestTerm: InterestTerm = {
		rate: '',
		effectiveDate: '',
		endDate: '',
	};

	private installmentTerm: InstallmentTerm = {
		amount: '',
		effectiveDate: '',
		endDate: '',
	};

	private loading: boolean = false;

	private loadingHistory: boolean = false;

	private nextButtonLoading: boolean = false;

	private loanSvc: LoanService = new LoanService(this.$store);

	private history: LoanTermMod[] = [];

	private maturityDate: string = '';

	private effectiveDate: string = '';

	private principalChangeTerm: PrincipalChangeTerm = {
		amount: '',
		type: PrincipalChangeTermType.NONE,
		effectiveDate: '',
		userNote: '',
	};

	private cancelingLoanMod: boolean = false;

	rules = [(v:any) => v.length <= 50 || 'Max 50 characters'];

	// Trying this as a way to prevent required error messages from being displayed even before the
	// user has interacted with the UI element. Because of the way we are currently using Vuelidate
	// and displaying errors with computed properties. Eventually we should create a team standard.
	private elementHasBeenInteractedWith: Record<keyof LoanTermModPayload, boolean> = {
		effectiveDate: false,
		endDate: false,
		interestTerm: false,
		installmentTerm: false,
		maturityDate: false,
		notes: false,
		overPaymentRefundMethod: false,
		principalChangeTerm: false,
	};

	installmentEndDateTooltipMessage = 'The end date must be the last day of a period.';

	installmentEffectiveDateTooltipMessage = 'The effective date must be the first day of a period.';

	periodStartDates: string[] = [];

	get isNonScraMod(): boolean {
		return !this.isEndingScra && !this.isScraModification;
	}

	get proposedLoanModType(): ProposedLoanModificationType {
		return this.isNonScraMod ? ProposedLoanModificationType.TERMS : ProposedLoanModificationType.SCRA;
	}

	resetElementInteractionState(): void {
		Object.keys(this.elementHasBeenInteractedWith).forEach((key: string) => {
			this.elementHasBeenInteractedWith[key as keyof LoanTermModPayload] = false;
		});

		this.principalChangeTerm.amount = '';
		this.principalChangeTerm.effectiveDate = '';
		this.principalChangeTerm.type = PrincipalChangeTermType.NONE;
		this.installmentTerm.amount = '';
		this.installmentTerm.effectiveDate = '';
		this.installmentTerm.endDate = '';
		this.interestTerm.rate = '';
		this.interestTerm.effectiveDate = '';
		this.interestTerm.endDate = '';
		this.maturityDate = '';
		this.effectiveDate = '';
		this.endDate = '';

		this.isEndingScra = false;
		this.isScraModification = false;
	}

	private loanModProposal: LoanModification = {} as LoanModification;

	async created(): Promise<void> {
		await this.getHistory();
		this.getPeriodStartDates();
	}

	get title(): string {
		switch (this.currentStep) {
			case TermsModStep.LOAN_HISTORY:
				return 'Modify Loan Terms';
			case TermsModStep.PROPOSAL_REVIEW:
				return 'Review Your Modification Request';
			case TermsModStep.COMPLETE:
				return 'Loan Modification Complete';
			default:
				return this.isScraModification ? 'SCRA Modification' : 'Modify Loan Terms';
		}
	}

	get nextButtonLabel(): string {
		switch (this.currentStep) {
			case TermsModStep.LOAN_HISTORY:
				return 'Start New Modification';
			case TermsModStep.PROPOSAL_REVIEW:
				return 'Submit Changes';
			case TermsModStep.COMPLETE:
				return 'Ok';
			default:
				return 'Next';
		}
	}

	get showModalTopCloseButton(): boolean {
		return [
			TermsModStep.PREPARING_MOD,
			TermsModStep.COMPLETE,
		].indexOf(this.currentStep) === -1;
	}

	get isNextButtonDisabled(): boolean {
		if (this.loadingHistory || this.cancelingLoanMod) {
			return true;
		}

		if (this.currentStep === TermsModStep.LOAN_HISTORY) {
			return false;
		}

		if (this.isEndingScra && this.endDate && !this.endDateErrors.length) {
			return false;
		}

		const noFieldWasFilled = !this.principalChangeTerm.amount
			&& !this.principalChangeTerm.effectiveDate
			&& !this.installmentTerm.amount
			&& !this.maturityDate
			&& !this.interestTerm.rate
			&& !this.effectiveDate
			&& !this.endDate;

		if (noFieldWasFilled) {
			return true;
		}

		const missingRequiredScraFields = !this.installmentTerm.amount
			|| !this.interestTerm.rate
			|| !this.effectiveDate;

		if (this.isScraModification && missingRequiredScraFields) {
			return true;
		}

		const requireAmountAndEffectiveDateOnPrincipalChange = this.principalChangeTerm.type
			!== PrincipalChangeTermType.NONE
			&& (!this.principalChangeTerm.amount || !this.principalChangeTerm.effectiveDate);

		if (requireAmountAndEffectiveDateOnPrincipalChange) {
			return true;
		}

		if (this.isNonScraMod && this.installmentTerm.amount && !this.installmentTerm.effectiveDate) {
			return true;
		}

		if (this.isNonScraMod && this.interestTerm.rate && !this.interestTerm.effectiveDate) {
			return true;
		}

		return this.effectiveDateInstallmentErrors.length > 0
			|| this.principalAmountErrors.length > 0
			|| this.installmentTermAmountErrors.length > 0
			|| this.effectiveDateInterestRateErrors.length > 0
			|| this.effectiveDatePrincipalErrors.length > 0
			|| this.effectiveDateScraErrors.length > 0
			|| this.endDateErrors.length > 0
			|| this.endDateInstallmentErrors.length > 0
			|| this.interestTermRateErrors.length > 0;
	}

	effectiveDateInstallmentAllowedDates(value: string): boolean {
		return this.periodStartDates.includes(value);
	}

	endDateInstallmentAllowedDates(date: string): boolean {
		if (moment(this.installmentTerm.effectiveDate).isSameOrAfter(date)) {
			return false;
		}

		return this.isPeriodEndDate(date);
	}

	get effectiveDateScraErrors(): string[] {
		if (this.isScraModification
			&& this.elementHasBeenInteractedWith.effectiveDate
			&& !this.effectiveDate) {
			return ['Effective date is required'];
		}

		return [];
	}

	getPeriodStartDates() {
		this.periodStartDates = [];

		this.loan.periods.forEach((period: Period): void => {
			this.periodStartDates.push(period.paymentScheduleStartDate);
		});
	}

	isPeriodEndDate(value: string): boolean {
		return !!this.loan.periods.find((period: Period): boolean => (
			moment(value).startOf('day').isSame(period.paymentScheduleEndDate)));
	}

	get currentPeriod(): Period | undefined {
		return this.loan.periods.find((period: Period) =>
			this.today.isSameOrAfter(period.startDate) && this.today.isSameOrBefore(period.endDate));
	}

	effectiveDateRules(): RuleDecl {
		if (this.isScraModification) {
			return {
				required,
				dateBefore: dateBefore(this.today, false, 'Effective Date'),
				dateAfter: dateAfter(this.loan.terms.startDate, true, 'Effective Date'),
			};
		}

		return {};
	}

	endDateRules(): RuleDecl {
		if (this.isScraModification && this.effectiveDate) {
			return {
				dateAfter: dateAfter(this.effectiveDate, false, 'End Date'),
				dateBefore: dateBefore(this.loan.general.maturityDate, true, 'End Date'),
			};
		}

		return {};
	}

	onPrincipalChangeTypeSelection(): void {
		this.principalChangeTerm.amount = '';
		this.principalChangeTerm.effectiveDate = '';
	}

	principalChangeTermRules(): RuleDecl {
		let type;
		let amount;
		let effectiveDate;
		type = {};
		amount = {};
		effectiveDate = {};

		if (this.isScraModification) {
			return {
				type,
				amount,
				effectiveDate,
			};
		}

		// Non SCRA validations beyond this point.
		type = {} as RuleDecl;
		if (this.principalChangeTerm.type !== PrincipalChangeTermType.NONE) {
			effectiveDate = {
				required,
				dateBefore: dateBefore(this.today, true, 'Effective Date'),
				dateAfter: dateAfter(this.loan.terms.startDate, true, 'Effective Date'),
			};

			if (this.principalChangeTerm?.type === PrincipalChangeTermType.DECREASE) {
				amount = {
					required,
					maxValue: maxValue(Number(this.loan.general.balance)),
					minValue: minValue(0.01),
				};
			} else {
				amount = {
					required,
					minValue: minValue(0.01),
				};
			}
		}

		return {
			amount,
			type,
			effectiveDate,
			userNote: {},
		};
	}

	interestTermRules(): RuleDecl {
		let rate: RuleDecl = {} as RuleDecl;

		if (this.isScraModification) {
			rate = {
				maxValue: maxValue(6),
				required,
			};

			return {
				rate,
			};
		}

		// Non SCRA validations beyond this point.
		let effectiveDate;
		let endDate;
		effectiveDate = {} as RuleDecl;
		endDate = {};

		rate.maxValue = maxValue(99.99999);
		if (this.interestTerm?.rate !== '') {
			effectiveDate = {
				dateAfter: dateAfter(this.loan.terms.startDate, true, 'Effective Date'),
				daterBefore: dateBefore(this.today, true, 'End Date'),
				required,
			};

			if (this.interestTerm && this.interestTerm.effectiveDate) {
				endDate = {
					dateAfter: dateAfter(this.interestTerm?.effectiveDate, false, 'End Date'),
				};
			} else {
				endDate = {};
			}
		}

		return {
			rate,
			effectiveDate,
			endDate,
		};
	}

	installmentTermRules(): RuleDecl {
		let amount: RuleDecl = {} as RuleDecl;

		if (this.isScraModification) {
			amount = {
				maxValue: maxValue(Number(this.loan.general.installmentAmount)),
				required,
			};

			return {
				amount,
			};
		}

		// Non SCRA validations beyond this point.
		let effectiveDate;
		let endDate;
		effectiveDate = {} as RuleDecl;
		endDate = {} as RuleDecl;

		if (this.installmentTerm?.effectiveDate) {
			endDate = {
				dateAfter: dateAfter(this.installmentTerm?.effectiveDate, false, 'End Date'),
				dateBefore: dateBefore(this.loan.general.maturityDate, true, 'End Date'),
			};
		}

		if (this.installmentTerm?.amount !== '') {
			amount = {
				minValue: minValue(this.interestByDays(30)),
			};

			effectiveDate = {
				required,
			};

			if (this.currentPeriod && this.loan.periods.indexOf(this.currentPeriod) === 0) {
				amount = {
					maxValue: maxValue(Number(this.loan.general.installmentAmount)),
					minValue: minValue(this.interestByDays(30)),
				};
			}

			return {
				amount,
				effectiveDate,
				endDate,
			};
		}

		amount = {
			minValue: minValue(this.interestByDays(30)),
			maxValue: maxValue(Number(this.loan.general.installmentAmount)),
		};

		return {
			amount,
			effectiveDate: {},
			endDate: {},
		};
	}

	get installmentTermEffectiveDateNext(): string {
		const { effectiveDate } = this.installmentTerm;

		return effectiveDate
			? this.format.asDate(moment(effectiveDate).add(1, 'day'), this.format.ISO_DATE)
			: '';
	}

	@Validations()
	validations(): RuleDecl {
		if (this.isEndingScra) {
			return {
				endDate: { required },
			};
		}

		if (this.isScraModification) {
			return {
				interestTerm: this.interestTermRules(),
				installmentTerm: this.installmentTermRules(),
				effectiveDate: this.effectiveDateRules(),
				endDate: this.endDateRules(),
			};
		}

		return {
			principalChangeTerm: this.principalChangeTermRules(),
			interestTerm: this.interestTermRules(),
			installmentTerm: this.installmentTermRules(),
		};
	}

	interestByDays(days: number): number {
		return Number((Number(this.loan.general.dailyInterest) * days).toFixed(2));
	}

	get endDateErrors(): string[] {
		return dateErrors('endDate', this.$v);
	}

	get principalAmountErrors(): string[] {
		const errors = [];
		const $principalChangeTerm = this.$v.principalChangeTerm;

		if (this.principalChangeTerm.type !== PrincipalChangeTermType.NONE
			&& this.elementHasBeenInteractedWith.principalChangeTerm
			&& !this.principalChangeTerm.amount) {
			errors.push('Principal Amount is required.');
		}

		if (typeof $principalChangeTerm?.amount?.minValue !== 'undefined' && !$principalChangeTerm?.amount?.minValue) {
			const { min } = $principalChangeTerm.amount.$params.minValue;
			errors.push(`Principal Amount cannot be less than $${this.format.asCurrency(min)}.`);
		}

		if (typeof $principalChangeTerm?.amount?.maxValue !== 'undefined' && !$principalChangeTerm?.amount?.maxValue) {
			const { max } = $principalChangeTerm.amount.$params.maxValue;
			errors.push(`Principal Amount cannot be more than $${this.format.asCurrency(max)}.`);
		}

		return errors;
	}

	get effectiveDatePrincipalErrors(): string[] {
		if (this.isScraModification || this.isEndingScra) {
			return [];
		}

		if (this.elementHasBeenInteractedWith.principalChangeTerm
			&& this.principalChangeTerm.type !== PrincipalChangeTermType.NONE
			&& !this.principalChangeTerm.effectiveDate) {
			return ['Effective Date is required.'];
		}

		return dateErrors('principalChangeTerm.effectiveDate', this.$v);
	}

	get installmentTermAmountErrors(): string[] {
		const errors: string[] = [];
		const { installmentTerm } = this.$v;

		if (!this.elementHasBeenInteractedWith.installmentTerm) {
			return [];
		}

		if (!installmentTerm?.amount?.minValue && installmentTerm?.amount?.$params.minValue) {
			const min = this.format.asCurrency(installmentTerm?.amount.$params.minValue.min);
			errors.push(`Installment Amount must be at least ${min}.`);
		}

		if (this.isScraModification) {
			if (!this.installmentTerm.amount) {
				errors.push('Installment Amount is required.');
			}

			if (Number(this.installmentTerm.amount) > Number(this.loan.general.installmentAmount)) {
				errors.push('Installment amount cannot be increased with an SCRA modification.');
			}
		}

		return errors;
	}

	get isLoanInFirstPeriod(): boolean {
		return !!this.currentPeriod && !!this.loan.periods.findIndex((period: Period) =>
			period.periodNumber === this.currentPeriod?.periodNumber);
	}

	get effectiveDateInstallmentErrors(): string[] {
		if (!this.isNonScraMod) {
			return [];
		}

		if (this.elementHasBeenInteractedWith.installmentTerm
			&& this.installmentTerm.amount
			&& !this.installmentTerm.effectiveDate) {
			return ['Effective Date is required.'];
		}

		return dateErrors('installmentTerm.effectiveDate', this.$v);
	}

	get endDateInstallmentErrors(): string[] {
		return dateErrors('installmentTerm.endDate', this.$v);
	}

	get interestTermRateErrors(): string[] {
		const errors: string[] = [];
		const { interestTerm } = this.$v;

		if (!this.elementHasBeenInteractedWith.interestTerm) {
			return [];
		}

		if (!interestTerm?.rate?.minValue && interestTerm?.rate?.$params.minValue) {
			const { min } = interestTerm?.rate.$params.minValue;
			errors.push(`Interest Rate must be at least ${min}%.`);
		}

		if (!interestTerm?.rate?.maxValue && interestTerm?.rate?.$params.maxValue) {
			const { max } = interestTerm?.rate.$params.maxValue;

			errors.push(`Interest Rate must be less than ${max}%.`);
		}

		if (this.isScraModification
			&& this.elementHasBeenInteractedWith.interestTerm
			&& !this.interestTerm.rate) {
			errors.push('Interest Rate is required.');
		}

		return errors;
	}

	get effectiveDateInterestRateErrors(): string[] {
		if (!this.isNonScraMod) {
			return [];
		}

		if (this.elementHasBeenInteractedWith.interestTerm
			&& this.interestTerm.rate
			&& !this.interestTerm.effectiveDate) {
			return ['Effective Date is required.'];
		}

		return dateErrors('interestTerm.effectiveDate', this.$v);
	}

	get endDateInterestRateErrors(): string[] {
		return dateErrors('interestTerm.endDate', this.$v);
	}

	get maxWidth(): string {
		if ([
			TermsModStep.PREPARING_MOD,
			TermsModStep.COMPLETE,
		].indexOf(this.currentStep) !== -1) {
			return '800px';
		}

		return '1200px';
	}

	// Only display dates where the day is the same as the current maturity date day
	allowedMaturityDates(date: string): boolean {
		const currentMaturityDateDay = Number(moment(this.loan.general.maturityDate).format('D'));
		const calendarDateDay = Number(moment(date).format('D'));

		// Account for situations where the current maturity date day is the 31st and the month for the
		// date ends on the 30th. Also for February, 29, 30 and 31 should fallback to 28.
		const lastDayOfMonth = Number(moment(date).endOf('month').format('D'));

		if (lastDayOfMonth < currentMaturityDateDay) {
			return calendarDateDay === lastDayOfMonth;
		}

		return currentMaturityDateDay === calendarDateDay;
	}

	onEndScra(item: LoanTermMod): void {
		this.endDate = item.endDate ? this.format.asDate(item.endDate, this.format.ISO_DATE) : '';
		this.isScraModification = true;
		this.isEndingScra = true;
		this.currentStep++;
	}

	async getHistory(): Promise<LoanTermMod[]> {
		try {
			this.loadingHistory = true;
			const response = await this.loanSvc.getLoanTermModHistory(this.fiId, this.loanId);
			this.history = response.data.data;
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.loadingHistory = false;
		}

		return this.history;
	}

	async poll(loanModId: string): Promise<void> {
		try {
			const loanMod = await this.loanSvc.getLoanModById(this.fiId, this.loanId, loanModId);

			if (loanMod.status !== LoanModStatus.PROPOSED) {
				setTimeout(() => this.poll(loanModId), 2000);
			} else {
				this.loanModProposal = loanMod;

				this.currentStep++;
			}
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		}
	}

	async onPreviousStepButtonClick(): Promise<void> {
		switch (this.currentStep) {
			case TermsModStep.LOAN_HISTORY:
				this.$emit('cancel');
				break;
			case TermsModStep.MODIFY_TERMS:
				this.resetElementInteractionState();
				this.currentStep--;
				break;
			case TermsModStep.PROPOSAL_REVIEW:
				await this.cancelLoanTermsModification();
				this.currentStep = TermsModStep.MODIFY_TERMS;
				break;
			default:
				this.currentStep--;
		}
	}

	async onCloseModalButtonClick(): Promise<void> {
		if (this.currentStep === TermsModStep.PROPOSAL_REVIEW) {
			await this.cancelLoanTermsModification();
		}

		this.$emit('cancel');
	}

	preventNav(event: any) {
		event.preventDefault();
		event.returnValue = '';
		this.cancelLoanTermsModification();
	}

	destroyed(): void {
		if (this.currentStep === TermsModStep.PROPOSAL_REVIEW) {
			this.cancelLoanTermsModification();
		}
	}

	async cancelLoanTermsModification(): Promise<void> {
		this.cancelingLoanMod = true;

		try {
			await this.loanSvc.cancelLoanMod(this.fiId, this.loanId, this.loanModProposal.id);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.cancelingLoanMod = false;
		}
	}

	get postData(): LoanTermModPayload {
		const data = {} as LoanTermModPayload;

		if (this.notes) {
			data.notes = this.notes;
		}

		if (this.isEndingScra) {
			data.endDate = this.endDate;

			return data;
		}

		if (this.interestTerm.rate) {
			data.interestTerm = this.interestTerm;
		}

		if (this.installmentTerm.amount) {
			data.installmentTerm = this.installmentTerm;
		}

		if (this.isScraModification) {
			data.effectiveDate = this.effectiveDate;

			if (this.endDate) {
				data.endDate = this.endDate;
			}

			data.overPaymentRefundMethod = this.overPaymentRefundMethod;

			return data;
		}

		if (this.principalChangeTerm.amount) {
			data.principalChangeTerm = this.principalChangeTerm;
		}

		if (this.maturityDate) {
			data.maturityDate = this.maturityDate;
		}

		return data;
	}

	async submitTermsModProposalRequest(): Promise<void> {
		try {
			let response;

			if (this.isEndingScra) {
				response = await this.loanSvc.postEndScraMod(this.fiId, this.loanId, this.postData);
			} else if (this.isScraModification) {
				response = await this.loanSvc.postScraLoanTermMod(this.fiId, this.loanId, this.postData);
			} else {
				response = await this.loanSvc.postLoanTermMod(this.fiId, this.loanId, this.postData);
			}

			const loanMod: LoanModification = response.data.data;

			await this.poll(loanMod.id);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
			this.currentStep--;
		}
	}

	onNextStepButtonClick(): void {
		switch (this.currentStep) {
			case TermsModStep.MODIFY_TERMS:
				this.currentStep = TermsModStep.PREPARING_MOD;
				this.submitTermsModProposalRequest();
				window.addEventListener('beforeunload', this.preventNav);
				break;
			case TermsModStep.PROPOSAL_REVIEW:
				this.commitLoanModProposal();
				break;
			case TermsModStep.COMPLETE:
				this.$emit('modified');
				break;
			default:
				this.currentStep++;
		}
	}

	async commitLoanModProposal(): Promise<void> {
		try {
			this.nextButtonLoading = true;
			await this.loanSvc.commitLoanMod(this.fiId, this.loanId, this.loanModProposal.id);
			this.currentStep++;
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.nextButtonLoading = false;
		}
	}

	formattedDate(value: string|null): string {
		return value ? this.format.asDate(value) : '';
	}

	@Watch('maturityDateMenu')
	onMaturityDatePickerMenuChange(value: boolean): void {
		// eslint-disable-next-line no-unused-expressions
		value && setTimeout(() => {
			this.activeMaturityDatePicker = 'YEAR';
		});
	}
}
