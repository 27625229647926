import { FBaseService } from '@kasasa/fbase-components/lib';
import { InsuranceModRequest } from './types/loan/Insurance';
import { LoanModification } from './types/loan/Maintenance';

export default class LoanInsuranceService extends FBaseService {
	async postInsuranceMod(
		fiId: string,
		loanId: string,
		payload: InsuranceModRequest,
	): Promise<LoanModification> {
		const response = await this.apiClient.post(
			`/api/v1/kloans-loan/${fiId}/loan/${loanId}/loan-mod/insurance-mod`,
			payload,
		);

		return response.data.data;
	}
}
