
import KPage from '@/components/KPage.vue';
import {
	Component, Inject, Prop,
} from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import {
	BankruptcyStatus, BankruptcyType, LoanApplicant, Bankruptcy, StatusHistory,
} from '@/services/types/loan/Bankruptcy';
import BaseComponent from '@/components/BaseComponent';
import BankruptcyNote from '@/components/loan/bankruptcy/BankruptcyNote.vue';
import { getApplicantTypeName } from '@/services/types/loan/Borrower';
import { Formatters } from '@/utils/formatters';
import { VKLOANS } from '@/pages/PermissionGroups';

@Component({
	enums: {
		BankruptcyStatus,
		BankruptcyType,
	},
	components: {
		BankruptcyNote,
		KPage,
		KReadOnly,
	},
})
export default class BankruptcyCard extends BaseComponent {
	@Inject('format')
	format!: Formatters;

	@Prop()
	applicants!: LoanApplicant[];

	@Prop({ required: true })
	data!: Bankruptcy;

	@Prop({ type: Boolean, default: false })
	isActive!: boolean;

	mode: string = 'add';

	// eslint-disable-next-line class-methods-use-this
	applicantLabel(applicant: LoanApplicant): string {
		return `${applicant.applicantName} (${getApplicantTypeName(applicant.applicantType)})`;
	}

	get statusHistory(): StatusHistory {
		let result = {} as StatusHistory;
		if (this.data && this.data.statusHistory) {
			result = this.data.statusHistory[this.data.statusHistory.length - 1];
		}

		return result;
	}

	get canView(): boolean {
		return this.canRead(VKLOANS);
	}
}
