const roundDecimalAmount = (amount: number | string): number => {
	const numberAmount = typeof amount === 'string' ? Number(amount) : amount;

	return Math.round((numberAmount * 100)) / 100;
};

const roundDecimalAmountToString = (amount: number | string): string =>
	roundDecimalAmount(amount).toFixed(2);

export {
	roundDecimalAmount,
	roundDecimalAmountToString,
};
