
import {
	Component, Inject, Prop, Watch,
} from 'vue-property-decorator';
import {
	ExtendedTransaction,
	TransactionStatus,
	TransactionType,
} from '@/services/types/loan/LoanTransaction';
import { DataTableHeader } from 'vuetify';
import { Formatters } from '@/utils/formatters';
import { RouteTo } from '@/router/types';
import { CommentModalModes, CommentReferenceTypes, Comment } from '@/services/types/loan/Comment';

import CommentsService from '@/services/CommentsService';
import { handleErrorResponse } from '@/utils/xhr';
import BaseComponent from '@/components/BaseComponent';
import ReverseOrRefundTransaction from './ReverseOrRefundTransaction.vue';
import CommentsCard from '../comments/CommentsCard.vue';

@Component({
	enums: {
		TransactionStatus,
		TransactionType,
		CommentModalModes,
		CommentReferenceTypes,
	},
	components: {
		ReverseOrRefundTransaction,
		CommentsCard,
	},
})
export default class HistoricalTransactionsTable extends BaseComponent {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	items!: ExtendedTransaction[];

	@Prop({ required: true })
	disableInitiateTransactionBtn!: boolean;

	@Prop({ required: true })
	title!: string;

	@Prop({ default: false })
	loading!: boolean;

	@Prop({ default: false })
	hideDefaultFooter!: boolean;

	@Prop({ required: true })
	clientId!: string;

	@Prop({ required: true })
	consumerId!: string;

	@Prop({ required: true })
	loanId!: string;

	@Prop({ required: true })
	fiId!: string;

	@Prop({})
	search!: string;

	@Prop({ required: true })
	canModify!: boolean;

	commentItems: Comment[] = [];

	expanded: any[] = [];

	loadingComments: boolean = true;

	commentsSvc: CommentsService = new CommentsService(this.$store);

	sortDesc: boolean[] = [];

	loadingText: string = 'Loading transactions...';

	commentTransactionId: string = '';

	showCommentsCard: boolean = false;

	headers: DataTableHeader[] = [
		{ text: '', value: 'expand', align: 'end' },
		{
			text: 'Date',
			value: 'effectiveDate',
		},
		{
			text: 'Status',
			value: 'status',
		},
		{
			text: 'Description',
			value: 'description',
		},
		{
			text: 'Amount',
			value: 'amount',
		},
		{
			text: 'Balance',
			value: 'principalBalance',
		},
		{
			text: 'Available to Take-Back',
			value: 'availableToWithdraw',
		},
		{
			text: 'Actions',
			value: 'actions',
		},
	];

	baseRowClass: string = 'transaction-row';

	showReverseOrRefundTransactionModal = false;

	selectedTransaction: ExtendedTransaction = {} as ExtendedTransaction;

	reverseOrRefundTransaction(transaction: ExtendedTransaction): void {
		this.selectedTransaction = transaction;
		this.showReverseOrRefundTransactionModal = true;
	}

	@Watch('items', { immediate: true, deep: true })
	onItemsChange(items: ExtendedTransaction[]): void {
		items.forEach((item: ExtendedTransaction) => {
			if (
				item.sourceSystemTransactionId
				&& item.transactionType === TransactionType['Payment Refund']
			) {
				const originalTransaction = items.find((i) => i.id === item.sourceSystemTransactionId);

				if (originalTransaction !== undefined) {
					item.nestedTransaction = this.items.splice(this.items.indexOf(originalTransaction), 1);
				}
			}
		});
	}

	async openCommentsCard(transaction: ExtendedTransaction) {
		this.commentTransactionId = transaction.id;
		this.showCommentsCard = true;
		this.loadingComments = true;
		await this.getComments(transaction.id);
		this.loadingComments = false;
	}

	rowClass(item: ExtendedTransaction): string {
		return `${this.baseRowClass} status-${item.status}`;
	}

	getLoanTransactionDetailsRoute(item: ExtendedTransaction): RouteTo {
		return {
			name: 'loan-transaction-details',
			params: {
				clientId: this.clientId,
				consumerId: this.consumerId,
				loanId: this.loanId,
				transactionId: item.id,
			},
		};
	}

	transactionChanged(): void {
		this.showReverseOrRefundTransactionModal = false;
		this.$emit('refresh');
		this.getComments(this.commentTransactionId);
	}

	goToTransactionDetails(item: ExtendedTransaction): void {
		this.$router.push(this.getLoanTransactionDetailsRoute(item));
	}

	async getComments(id: string): Promise<void> {
		try {
			const response = await this.commentsSvc.getComments(this.loanId, undefined, undefined, id);
			this.commentItems = response.data.data;
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		}
	}

	dismiss(): void {
		this.showCommentsCard = false;
	}
}
