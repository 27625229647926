
import KCard from '@/components/KCard.vue';
import KPage from '@/components/KPage.vue';
import AchCard from '@/components/AchCard.vue';
import BaseFiPage from '@/pages/BaseFiPage';
import { KReadOnly } from '@kasasa/fbase-components';
import { Component, Prop } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import {
	AuthGroupManager,
	Dialog,
	MenuHeading,
	MenuItem,
	NoticeResponse,
	NoticeClass,
} from '@kasasa/fbase-components/lib';
import { AchService, AchAccount } from '@/services/AchService';
import { handleErrorResponse } from '@/utils/xhr';
import { displayMode } from '@/router/types';

const navigation = namespace('navigation');
const auth = namespace('auth');

@Component({
	components: {
		AchCard,
		KCard,
		KPage,
		KReadOnly,
	},
	enums: {
		displayMode,
	},
})
export default class AchPage extends BaseFiPage {
	@Prop()
	achId?: string;

	@Prop({ default: displayMode.VIEW })
	mode!: string;

	@navigation.Mutation('setMenuItems')
	setMenuItems!: (items: (MenuItem | MenuHeading)[]) => void;

	@auth.State('authManager')
	authManager!: AuthGroupManager;

	achSvc: AchService = new AchService(this.$store);

	achAccount: AchAccount = {
		id: '',
		fiId: '',
		description: '',
		referenceCode: 'KASASA',
		achFiRoutingNumber: '',
		achAccountNumber: '',
		achTransactionType: 22,
		achImmediateOrigin: '',
		achImmediateOriginName: '',
		achImmediateDestination: '',
		achImmediateDestinationName: '',
		achCompanyIdentification: '',
		achCompanyName: '',
		achCompanyDiscretionaryData: '',
		achFileIdModifier: '',
		pmtHubId: '',
	};

	achAccountCopy?: AchAccount;

	title: string = 'ACH Account';

	loadingSave: boolean = false;

	invalidForm: boolean = false;

	achAccountInvalidChanged(invalid: boolean): void {
		this.invalidForm = invalid;
	}

	cancelAction(): void {
		if (this.mode === this.$enums.displayMode.EDIT) {
			if (this.achAccount !== this.achAccountCopy) {
				this.achAccount = { ...this.achAccountCopy };
			}

			this.$router.push({
				name: 'ach-view',
				params: { clientId: this.clientId, achId: this.achId as string },
			});
		}
	}

	async getAccount(): Promise<void> {
		try {
			const response = await this.achSvc.getAchAccount(
				this.$route?.meta?.fi.fiId,
				this.achId as string,
			);
			this.achAccount = response.data.data;
			this.achAccountCopy = { ...this.achAccount };
		} catch (error) {
			await this.$router.push({
				name: 'ach-settings-list',
				params: { clientId: this.clientId },
			});
		} finally {
			this.loading = false;
		}
	}

	async saveAccount(): Promise<void> {
		this.loadingSave = true;
		try {
			const response = await this.achSvc.updateAchAccount(
				this.$route?.meta?.fi.fiId,
				this.achId as string,
				this.achAccount,
			);
			this.achAccount = response.data.data;
			this.achAccountCopy = { ...this.achAccount };
			await this.$router.push({
				name: 'ach-view',
				params: { clientId: this.clientId, achId: this.achId as string },
			});
		} catch (error) {
			// Error stuff here
		} finally {
			this.loadingSave = false;
		}
	}

	async addAchAccount(): Promise<void> {
		this.loadingSave = true;
		try {
			const response = await this.achSvc.addAchAccount(
				this.fiId,
				this.achAccount,
			);
			const { id } = response.data.data;
			await this.$router.push({
				name: 'ach-view',
				params: { clientId: this.clientId, achId: id as string },
			});
		} catch (error) {
			await handleErrorResponse((error as any).response, this);
		} finally {
			this.loadingSave = false;
		}
	}

	save(): void {
		if (!this.invalidForm) {
			if (this.mode === this.$enums.displayModee.ADD) {
				this.addAchAccount();
			} else {
				this.saveAccount();
			}
		}
	}

	async deleteAccount(): Promise<void> {
		this.loading = true;
		try {
			await this.achSvc.deleteAchAccount(
				this.fiId,
				this.achId as string,
			);
			this.loading = false;
			this.showAlert('Account has been deleted.', NoticeClass.SUCCESS, 5000);
			await this.$router.replace({
				name: 'ach-settings-list',
				params: { clientId: this.clientId },
			});
		} catch (error) {
			if (error.message.indexOf('409')) {
				await this.showDialog(
					new Dialog(
						'ACH Account Deletion Conflict',
						`The ACH Account is associated with a loan product. Please change\n the ACH account association
						with the loan product before deleting the account.`,
					),
				);
			} else {
				await this.showAlert('Application error, try again.', NoticeClass.ERROR);
			}
		} finally {
			this.loading = false;
		}
	}

	async showDeleteDialog(): Promise<void> {
		const dialog: Dialog = new Dialog(
			'Delete ACH Confirmation',
			'Are you sure you want to Delete this ACH Account?\nThis action will remove the ACH. Loan products will not be able to link with this action.',
			'Yes',
		);
		dialog.setDeclineLabel('Cancel');
		const response = await this.$store.dispatch('notices/add', dialog);
		switch (response) {
			case NoticeResponse.ACCEPT:
				await this.deleteAccount();
				break;
			case NoticeResponse.DECLINE:
				// eslint-disable-next-line no-fallthrough
			default:
				// Do nothing
				break;
		}
	}

	created(): void {
		this.expandMenu();
	}

	mounted(): void {
		// The route automatically sets add as value since params = true in the router
		if (this.mode !== this.$enums.displayMode.ADD) {
			this.getAccount();
		} else {
			this.loading = false;
		}
	}

	get canView(): boolean {
		return this.canRead('kloans');
	}
}
