
import {
	Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { OverviewLoan } from '@/services/ConsumerService';
import { RouteTo } from '@/router/types';
import { LoanStatus } from '@/services/types/loan/Loan';

@Component
export default class ConsumerLoansTable extends Vue {
	@Inject('format')
	format!: object;

	@Prop({ required: true })
	clientId!: string;

	@Prop({ required: true })
	consumerId!: string;

	@Prop({ required: true })
	items!: OverviewLoan[];

	@Prop({ required: true })
	title!: string;

	@Prop({ default: false })
	loading!: boolean;

	@Prop({ default: false })
	canWrite!: boolean;

	@Prop({ default: false })
	canGenerate!: boolean;

	sortDesc: boolean[] = [];

	loadingText: string = 'Loading loans...';

	headers: DataTableHeader[] = [
		{
			text: 'Loan Name', value: 'loanName',
		},
		{
			text: 'Type', value: 'type', width: '80px',
		},
		{
			text: 'Borrower', value: 'borrowerType', width: '120px',
		},
		{
			text: 'Status', value: 'status', width: '80px',
		},
		{
			text: 'Current Balance', value: 'currentBalance', width: '150px',
		},
		{
			text: 'Loan Number', value: 'loanNumber', width: '125px',
		},
		{
			text: 'Action', value: 'action', width: '75px', sortable: false,
		},
	];

	getDetailsLink(item: OverviewLoan): RouteTo {
		const route = {
			name: 'loan-overview',
			params: { clientId: this.clientId, consumerId: this.consumerId, loanId: item.id },
		};

		return route;
	}

	goToLoanOverview(item: OverviewLoan): void {
		if (item.status !== LoanStatus.PENDING) {
			this.$router.push(this.getDetailsLink(item));
		}
	}

	createNewLoansLink(loanId: string): RouteTo {
		const routeName: string = 'create-new-import';
		const route = {
			name: routeName,
			params: { clientId: this.clientId, consumerId: this.consumerId, loanId },
		};

		return route;
	}

	// eslint-disable-next-line class-methods-use-this
	rowClass() {
		return 'loan-row';
	}

	createNewImport(): void {
		if (this.items[0]) {
			this.$router.push(this.createNewLoansLink(this.items[0].id));
		} else {
			this.$router.push(this.createNewLoansLink(''));
		}
	}
}
