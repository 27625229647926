
import {
	Component, Prop, Vue, Model, Watch,
} from 'vue-property-decorator';
import { Dictionary } from 'vue-router/types/router.d';

@Component({
	inheritAttrs: false,
})
export default class Featurecheckbox extends Vue {
	@Model('change', { type: Boolean })
	checked!: boolean;

	@Prop({ default: '' })
	name!: string;

	@Prop({ default: '' })
	tooltip!: string;

	@Prop({ default: false })
	invert!: boolean;

	@Prop({ default: false })
	loading!: boolean;

	@Watch('checked', { immediate: true })
	onCheckChanged(value: boolean): void {
		this.value = value;
	}

	value: boolean = false;

	get classObject(): Dictionary<boolean> {
		const value: boolean = this.invert ? !this.value as boolean : this.value as boolean;

		return {
			'checkbox-status-on': value,
			'checkbox-status-off': !value,
		};
	}
}
