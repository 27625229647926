
import { Component, Inject, Prop } from 'vue-property-decorator';
import { KReadOnly, KSpinner, KText } from '@kasasa/fbase-components';
import { Formatters } from '@/utils/formatters';
import BaseComponent from '@/components/BaseComponent';
import { Loan, Period } from '@/services/types/loan/Loan';
import LoanService from '@/services/LoanService';
import { LoanMaintenance, LoanModification, LoanModStatus } from '@/services/types/loan/Maintenance';
import { handleErrorResponse } from '@/utils/xhr';
import PendingModificationCard from '@/components/loan/modifications/PendingModificationCard.vue';
import ProposedModificationCard, { ProposedLoanModificationType, ProposedModAddon }
	from '@/components/loan/modifications/ProposedModificationCard.vue';
import ModificationCompleteCard from '@/components/loan/modifications/ModificationCompleteCard.vue';
import { namespace } from 'vuex-class';
import SkipAPayService from '@/services/SkipAPayService';
import {
	SkipAPayConfig, SkipAPayMod, skipAPayFeeMethodLabel, SkipAPayFeeMethod,
} from '@/services/types/loan/SkipAPay';
import moment from 'moment';
import { NoticeClass } from '@kasasa/fbase-components/lib/index';
import {
	getRecurringPaymentFrequencyName,
	LoanRecurringPaymentAvailableDates,
	RecurringPaymentFrequency,
} from '@/services/types/loan/RecurringPayment';
import LoanAutoPayService from '@/services/LoanAutoPayService';
import { RuleDecl } from 'vue/types/options.d';
import { RequiredValidation } from '@kasasa/kloans-common-ui/dist/validations';
import { Validations } from 'vuelidate-property-decorators';
import {
	decimal, numeric, or, required,
} from 'vuelidate/lib/validators';

const loanMaintenanceStoreModule = namespace('maintenance');

enum SkipAPayStep {
	DETAILS = 1,
	AUTO_PAY_CHANGES = 2,
	PREPARING_MOD = 3,
	PROPOSAL_REVIEW = 4,
	COMPLETE = 5,
}

enum AutoPayChangeOption {
	TURN_OFF_AUTOPAY = 'turn-off-autopay',
	CHANGE_DRAFT_DATE = 'change-autopay-draft-date',
}

@Component({
	components: {
		ModificationCompleteCard,
		ProposedModificationCard,
		PendingModificationCard,
		KReadOnly,
		KSpinner,
		KText,
	},
	enums: {
		SkipAPayStep,
		ProposedLoanModificationType,
		AutoPayChangeOption,
	},
})
export default class SkipAPay extends BaseComponent {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	loan!: Loan;

	// This is required because the loan object doesn't have its id.
	@Prop({ required: true })
	loanId!: string;

	@Prop({ required: true })
	fiId!: string;

	@Prop()
	consumerId!: string;

	private currentStep: SkipAPayStep = SkipAPayStep.DETAILS;

	private loanService: LoanService = new LoanService(this.$store);

	private skipAPayService: SkipAPayService = new SkipAPayService(this.$store);

	private autoPayService: LoanAutoPayService = new LoanAutoPayService(this.$store);

	private loanModProposal: LoanModification = {} as LoanModification;

	private loadingLoanModProposalCommit = false;

	private cancelingLoanMod = false;

	private skipAPayConfig: SkipAPayConfig = {
		fiId: this.fiId,
		feeMethods: [],
		feeAmount: '',
	};

	private skipAPayMod: SkipAPayMod = {
		feeAmount: '',
		feeMethod: '',
		disableAutoPay: false,
		draftDateOne: '',
		draftDateTwo: '',
	};

	@Validations()
	// eslint-disable-next-line class-methods-use-this
	validations(): RuleDecl {
		const skipAPayMod = {
			feeAmount: {
				required,
				money: or(numeric, decimal),
			},
		};

		return { skipAPayMod };
	}

	private fetchingSkipAPayConfig = false;

	private selectedAutoPayChangeOption: AutoPayChangeOption | null = null;

	private displayDraftDateOnePicker = false;

	private displayDraftDateTwoPicker = false;

	private proposedSkipAPayModAddon: ProposedModAddon[] = [];

	private autoPayAvailableDates!: LoanRecurringPaymentAvailableDates;

	@loanMaintenanceStoreModule.Getter('getLoanMaintenance')
	loanMaintenance!: LoanMaintenance | null;

	@loanMaintenanceStoreModule.Mutation('setLoanMaintenance')
	setLoanMaintenance!: (loanMaintenance: LoanMaintenance | null) => void;

	async created(): Promise<void> {
		await this.fetchFiSkipAPayConfig();

		this.autoPayAvailableDates = await this.autoPayService.getAvailableDates(this.loanId, this.consumerId);
	}

	get feeAmountErrors(): string[] {
		if (this.$v?.skipAPayMod.feeAmount && !this.$v.skipAPayMod.feeAmount.money) {
			return ['Fee Amount is invalid.'];
		}
		return RequiredValidation.errors(this, 'skipAPayMod.feeAmount', 'Fee Amount');
	}

	get isNextButtonDisabled(): boolean {
		if (this.fetchingSkipAPayConfig || this.cancelingLoanMod) {
			return true;
		}

		switch (this.currentStep) {
			case (SkipAPayStep.DETAILS):
				return !this.skipAPayMod.feeAmount || !this.skipAPayMod.feeMethod;
			case (SkipAPayStep.AUTO_PAY_CHANGES):
				if (!this.selectedAutoPayChangeOption) {
					return true;
				}

				if (this.selectedAutoPayChangeOption === AutoPayChangeOption.TURN_OFF_AUTOPAY) {
					return false;
				}

				// Draft date being changed. Validate calendar selections.
				return this.showTwoDatePickers
					? !(!!this.skipAPayMod.draftDateOne && !!this.skipAPayMod.draftDateTwo)
					: !this.skipAPayMod.draftDateOne;
			default:
				return false;
		}
	}

	get title(): string {
		switch (this.currentStep) {
			case SkipAPayStep.PROPOSAL_REVIEW:
				return 'Review Your Modification Request';
			case SkipAPayStep.COMPLETE:
				return 'Loan Modification Complete';
			default:
				return 'Skip-a-pay';
		}
	}

	get showModalTopCloseButton(): boolean {
		return [
			SkipAPayStep.PREPARING_MOD,
			SkipAPayStep.COMPLETE,
		].indexOf(this.currentStep) === -1;
	}

	get nextButtonLabel(): string {
		switch (this.currentStep) {
			case SkipAPayStep.PROPOSAL_REVIEW:
				return 'Submit Changes';
			case SkipAPayStep.COMPLETE:
				return 'Ok';
			default:
				return 'Next';
		}
	}

	get nextPaymentPeriod(): Period {
		const currentPeriod: Period|undefined = this.loan.periods.find((period: Period) =>
			moment().startOf('day').isSameOrAfter(period.startDate)
			&& moment().startOf('day').isSameOrBefore(period.endDate));

		if (!currentPeriod) {
			this.showAlert('Error loading loan details.', NoticeClass.ERROR);
			throw new Error('Missing loan payment periods.');
		}

		return this.loan.periods[currentPeriod.periodNumber];
	}

	get isLoanAutoPayEnabled(): boolean {
		return !!this.loan.recurringPayment.active;
	}

	get showTwoDatePickers(): boolean {
		return this.loan.recurringPayment.frequency === RecurringPaymentFrequency.SEMIMONTHLY;
	}

	get formattedDraftDateOne(): string {
		return this.skipAPayMod.draftDateOne ? this.format.asDate(this.skipAPayMod.draftDateOne) : '';
	}

	get formattedDraftDateTwo(): string {
		return this.skipAPayMod.draftDateTwo ? this.format.asDate(this.skipAPayMod.draftDateTwo) : '';
	}

	get currentAutoPayFrequencyName(): string {
		return getRecurringPaymentFrequencyName(this.loan.recurringPayment.frequency);
	}

	autoPayDraftDateOneAllowedDates(date: string): boolean {
		if (!this.isLoanAutoPayEnabled) {
			return false;
		}

		let dates: string[] = [];

		if (this.loan.recurringPayment.frequency === RecurringPaymentFrequency.BIWEEKLY) {
			dates = this.autoPayAvailableDates[RecurringPaymentFrequency.BIWEEKLY];
		}

		if (this.loan.recurringPayment.frequency === RecurringPaymentFrequency.MONTHLY) {
			dates = this.autoPayAvailableDates[RecurringPaymentFrequency.MONTHLY];
		}

		if (this.loan.recurringPayment.frequency === RecurringPaymentFrequency.SEMIMONTHLY) {
			dates = this.autoPayAvailableDates[RecurringPaymentFrequency.SEMIMONTHLY];
		}

		if (this.loan.recurringPayment.frequency === RecurringPaymentFrequency.WEEKLY) {
			dates = this.autoPayAvailableDates[RecurringPaymentFrequency.WEEKLY];
		}

		return !!dates.find((d) => d === date);
	}

	autoPayDraftDateTwoAllowedDates(date: string): boolean {
		if (!this.isLoanAutoPayEnabled) {
			return false;
		}

		const draftDateOne = moment(this.skipAPayMod.draftDateOne).toDate();
		const draftDateTwo = moment(date, 'YYYY-MM-DD').toDate();
		const isAfterDraftOne = draftDateTwo > draftDateOne;

		if (!isAfterDraftOne) {
			return false;
		}

		if (this.loan.recurringPayment.frequency === RecurringPaymentFrequency.SEMIMONTHLY) {
			const dates = this.autoPayAvailableDates[RecurringPaymentFrequency.SEMIMONTHLY];

			return !!dates.find((d) => d === date);
		}

		return false;
	}

	onFeeAmountChange(value: string): void {
		// TODO: Cleanup and format
		this.skipAPayMod.feeAmount = value;
	}

	onNextStepButtonClick(): void {
		switch (this.currentStep) {
			case SkipAPayStep.DETAILS:
				if (this.isLoanAutoPayEnabled) {
					this.currentStep++;
				} else {
					this.submitModProposalRequest();
					this.currentStep = SkipAPayStep.PREPARING_MOD;
				}
				break;
			case SkipAPayStep.AUTO_PAY_CHANGES:
				this.submitModProposalRequest();
				this.currentStep++;
				break;
			case SkipAPayStep.PROPOSAL_REVIEW:
				this.commitLoanModProposal();
				break;
			case SkipAPayStep.COMPLETE:
				this.$emit('modified');
				break;
			default:
				this.currentStep++;
		}
	}

	async commitLoanModProposal(): Promise<void> {
		try {
			this.loadingLoanModProposalCommit = true;
			await this.loanService.commitLoanMod(this.fiId, this.loanId, this.loanModProposal.id);

			this.currentStep++;
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.loadingLoanModProposalCommit = false;
		}
	}

	async submitModProposalRequest(): Promise<void> {
		this.skipAPayMod.disableAutoPay = this.selectedAutoPayChangeOption
			=== AutoPayChangeOption.TURN_OFF_AUTOPAY;

		try {
			const loanMod = await this.skipAPayService.submitSkipAPay(
				this.fiId,
				this.loanId,
				this.skipAPayMod,
			);

			await this.poll(loanMod.id);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
			this.currentStep = SkipAPayStep.DETAILS;
		}
	}

	async onPreviousStepButtonClick(): Promise<void> {
		switch (this.currentStep) {
			case SkipAPayStep.DETAILS:
				this.$emit('cancel');
				break;
			case SkipAPayStep.PROPOSAL_REVIEW:
				await this.cancelSkipAPayModification();
				this.currentStep = SkipAPayStep.DETAILS;
				break;
			default:
				this.currentStep--;
		}
	}

	async fetchFiSkipAPayConfig(): Promise<void> {
		this.fetchingSkipAPayConfig = true;

		try {
			this.skipAPayConfig = await this.skipAPayService.getFiSkipAPayConfig(this.fiId);

			// Default the fee amount based on the FI config
			this.skipAPayMod.feeAmount = this.skipAPayConfig.feeAmount;
			// eslint-disable-next-line prefer-destructuring
			this.skipAPayMod.feeMethod = this.skipAPayConfig.feeMethods[0];
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.fetchingSkipAPayConfig = false;
		}
	}

	async poll(loanModId: string): Promise<void> {
		try {
			const loanMod = await this.loanService.getLoanModById(this.fiId, this.loanId, loanModId);

			if (loanMod.status !== LoanModStatus.PROPOSED) {
				setTimeout(() => this.poll(loanModId), 2000);
			} else {
				this.setProposedSkipAPayModAddon();

				this.loanModProposal = loanMod;

				this.currentStep++;
			}
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		}
	}

	async onCloseModalButtonClick(): Promise<void> {
		switch (this.currentStep) {
			case SkipAPayStep.PROPOSAL_REVIEW:
				await this.cancelSkipAPayModification();
				this.$emit('cancel');
				break;
			default:
				this.$emit('cancel');
				break;
		}
	}

	async cancelSkipAPayModification(): Promise<void> {
		this.cancelingLoanMod = true;

		try {
			await this.loanService.cancelLoanMod(this.fiId, this.loanId, this.loanModProposal.id);
			this.proposedSkipAPayModAddon = [];
			this.skipAPayMod.draftDateOne = '';
			this.skipAPayMod.draftDateTwo = '';
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.cancelingLoanMod = false;
		}
	}

	// eslint-disable-next-line class-methods-use-this
	skipAPayFeeMethodRadioLabel(method: SkipAPayFeeMethod): string {
		return skipAPayFeeMethodLabel(method);
	}

	setProposedSkipAPayModAddon(): void {
		this.proposedSkipAPayModAddon.push({
			name: 'Next AutoPay Draft Date',
			statusBefore: this.isLoanAutoPayEnabled ? this.format.asDate(this.loan.recurringPayment.nextRecurringPaymentDate) : 'n/a',
			statusAfter: this.isLoanAutoPayEnabled && this.skipAPayMod.draftDateOne ? this.format.asDate(this.skipAPayMod.draftDateOne) : 'n/a',
		});
	}
}
