
import { Component, Inject } from 'vue-property-decorator';
import { KReadOnly, KText } from '@kasasa/fbase-components';
import formatters, { Formatters } from '@/utils/formatters';
import {
	LoanRecurringPaymentAvailableDates,
	getRecurringPaymentFrequencyName,
	RecurringPayment,
	RecurringPaymentFrequency,
	RecurringPaymentRequest,
} from '@/services/types/loan/RecurringPayment';
import { NoticeClass } from '@kasasa/fbase-components/lib/index';
import LoanAutoPayService from '@/services/LoanAutoPayService';
import { DropdownOption } from '@/types/Form';
import { ExtendedBankAccount } from '@/services/types/BankAccount';
import moment from 'moment';
import { Period } from '@/services/types/loan/Loan';
import KPage from '@/components/KPage.vue';
import KCard from '@/components/KCard.vue';
import { handleErrorResponse } from '@/utils/xhr';
import { roundDecimalAmountToString } from '@/utils/decimal';
import { namespace } from 'vuex-class';
import BaseLoanPage from './BaseLoanPage';

const bankAccountStoreModule = namespace('bankAccount');

@Component({
	components: {
		KPage,
		KCard,
		KReadOnly,
		KText,
	},
	enums: {
		RecurringPaymentFrequency,
	},
})
export default class LoanAutoPay extends BaseLoanPage {
	@Inject('format')
	format!: Formatters;

	private autoPayService: LoanAutoPayService = new LoanAutoPayService(this.$store);

	public autoPayAvailableDates!: LoanRecurringPaymentAvailableDates;

	private viewingCurrentAutoPay = false;

	// Existing auto pay, retrieved from the loan details object (if available)
	private autoPay: RecurringPayment = {
		active: false,
		currentAccountId: 'Select an account',
		frequency: RecurringPaymentFrequency.MONTHLY,
		nextRecurringPaymentDate: 'N/A',
		paymentAmount: 'N/A',
		recurrence: 'N/A',
	} as RecurringPayment;

	// Payload structure used when creating a new automatic payment or changing an existing one
	private autoPaySetup: RecurringPaymentRequest = {
		accountId: '',
		draftDateOne: '',
		draftDateTwo: '',
		frequency: RecurringPaymentFrequency.MONTHLY,
		paymentTotal: '',
		paymentTotalByFrequency: '',
	};

	private processingAutoPay = false;

	private paymentFrequencyDropdownOptions: DropdownOption[] = [
		{
			text: getRecurringPaymentFrequencyName(RecurringPaymentFrequency.MONTHLY),
			value: RecurringPaymentFrequency.MONTHLY,
		},
		{
			text: getRecurringPaymentFrequencyName(RecurringPaymentFrequency.WEEKLY),
			value: RecurringPaymentFrequency.WEEKLY,
		},
		{
			text: getRecurringPaymentFrequencyName(RecurringPaymentFrequency.BIWEEKLY),
			value: RecurringPaymentFrequency.BIWEEKLY,
		},
		{
			text: getRecurringPaymentFrequencyName(RecurringPaymentFrequency.SEMIMONTHLY),
			value: RecurringPaymentFrequency.SEMIMONTHLY,
		},
	];

	private showDisableAutoPayConfirmationModal = false;

	private displayDraftDateOnePicker = false;

	private displayDraftDateTwoPicker = false;

	private nextPaymentPeriod: Period = {} as Period;

	@bankAccountStoreModule.Getter('bankAccountDropdownOptions')
	bankAccountOptions!: (accounts: ExtendedBankAccount[]) => void;

	async created(): Promise<void> {
		await this.fetchAndSetLoan();

		// Check if consumer has an existing auto pay
		if (this.loan.recurringPayment.active) {
			this.autoPay = this.loan.recurringPayment;
			this.viewingCurrentAutoPay = true;
		} else {
			this.autoPaySetup.paymentTotal = this.loan.general.installmentAmount ?? '0.00';
			this.roundAndFormatAutoPaySetupAmount();
		}

		// Get the next payment period from the loan details to be used with the draft date pickers
		const currentPeriod: Period|undefined = this.loan.periods.find((period: Period) =>
			moment().startOf('day').isSameOrAfter(period.startDate)
			&& moment().startOf('day').isSameOrBefore(period.endDate));

		if (this.loan.general.status === 'closed') {
			this.showAlert('This loan is closed. Autopay cannot be added to this loan.', NoticeClass.ERROR);
			throw new Error('This loan is closed. Autopay cannot be added to this loan.');
		}

		if (!currentPeriod) {
			this.showAlert('This loan has past its maturity date. Autopay cannot be added to this loan.', NoticeClass.ERROR);
			throw new Error('Missing loan payment periods.');
		}

		this.nextPaymentPeriod = this.loan.periods[currentPeriod.periodNumber];

		await this.getBankAccounts();

		this.autoPayAvailableDates = await this.autoPayService.getAvailableDates(this.loanId, this.consumerId);

		this.loading = false;
	}

	get consumerHasBankAccount(): Boolean {
		return this.bankAccounts.length === 0;
	}

	get bankAccountDropdownErrors(): string[] {
		if (!this.consumerHasBankAccount) {
			return [
				'Consumer has no bank accounts in the system.',
			];
		}

		return [];
	}

	get paymentAmountErrors(): string[] {
		if (!this.loan.general?.installmentAmount) {
			return [];
		}

		if (!/^[\d\s,.]+$/.test(this.autoPaySetup.paymentTotal)) {
			return [
				'Invalid amount',
			];
		}

		const minPaymentAmount = Number(this.loan.general.installmentAmount);
		const maxPaymentAmount = minPaymentAmount * 3;

		const paymentInputNumericValue = this.autoPaySetup.paymentTotal.replace(/[^\d-.,]/g, '');
		const paymentAmount = Number(paymentInputNumericValue);

		if (paymentAmount < minPaymentAmount) {
			return [
				`Minimum payment amount is ${formatters.asCurrency(minPaymentAmount)}`,
			];
		}

		if (paymentAmount > maxPaymentAmount) {
			return [
				`Maximum payment amount is ${formatters.asCurrency(maxPaymentAmount)}`,
			];
		}

		return [];
	}

	get renderInstallmentAmount(): string {
		return formatters.asCurrency(this.loan.general.installmentAmount) || '0.00';
	}

	async submitAutoPay(): Promise<void> {
		this.processingAutoPay = true;

		try {
			await this.autoPayService.setupAutoPay(this.consumerId, this.loanId, this.autoPaySetup);

			this.showAlert('New Automatic Payment Added Successfully.', NoticeClass.SUCCESS, 3000);

			await this.fetchAndSetLoan(true);

			this.autoPay = this.loan.recurringPayment;
			this.viewingCurrentAutoPay = true;
		} catch (error) {
			await handleErrorResponse((error as any).response, this);
		} finally {
			this.processingAutoPay = false;
		}
	}

	routeToLoanMaintenancePage(): void {
		this.$router.push(
			{
				params: {
					fiId: this.fiId, clientId: this.clientId, consumerId: this.consumerId, loanId: this.loanId,
				},
				name: 'loan-maintenance',
			},
		);
	}

	async disableAutoPay(): Promise<void> {
		this.processingAutoPay = true;

		try {
			await this.autoPayService.disableAutoPay(this.consumerId, this.loanId);

			// Refresh loan with disabled recurring payment
			await this.fetchAndSetLoan(true);

			this.showAlert('Automatic payment disabled.', NoticeClass.SUCCESS, 3000);

			this.routeToLoanMaintenancePage();
		} catch (error) {
			this.showAlert('Error turning off auto pay.', NoticeClass.ERROR);
		} finally {
			this.processingAutoPay = false;
		}
	}

	editExistingAutoPay(): void {
		this.autoPaySetup.frequency = this.autoPay.frequency;
		this.autoPaySetup.paymentTotal = this.autoPay.currentTotalPeriodPaymentAmount;
		this.autoPaySetup.paymentTotalByFrequency = this.autoPay.paymentAmount;
		this.autoPaySetup.accountId = this.autoPay.currentAccountId;
		this.autoPaySetup.draftDateOne = this.autoPay.nextRecurringPaymentDate;
		this.autoPaySetup.draftDateTwo = moment(this.autoPaySetup.draftDateOne)
			.add(15, 'd')
			.toISOString();

		this.viewingCurrentAutoPay = false;
	}

	get isSubmitAutoPayButtonDisabled(): boolean {
		const dateFieldsFilled = this.autoPaySetup.frequency === RecurringPaymentFrequency.SEMIMONTHLY
			? (!!this.autoPaySetup.draftDateOne && !!this.autoPaySetup.draftDateTwo)
			: !!this.autoPaySetup.draftDateOne;

		return this.processingAutoPay
			|| this.paymentAmountErrors.length > 0
			|| !this.autoPaySetup.accountId
			|| !dateFieldsFilled;
	}

	get showTwoDatePickers(): boolean {
		return this.autoPaySetup.frequency === RecurringPaymentFrequency.SEMIMONTHLY;
	}

	get formattedDraftDateOne(): string {
		return this.autoPaySetup.draftDateOne ? this.format.asDate(this.autoPaySetup.draftDateOne) : '';
	}

	get formattedDraftDateTwo(): string {
		return this.autoPaySetup.draftDateTwo ? this.format.asDate(this.autoPaySetup.draftDateTwo) : '';
	}

	paymentFrequencyChange(): void {
		this.autoPaySetup.draftDateOne = '';
		this.autoPaySetup.draftDateTwo = '';
		this.roundAndFormatAutoPaySetupAmount();
	}

	autoPayDraftDateOneAllowedDates(date: string): boolean {
		let dates: string[] = [];

		if (this.autoPaySetup.frequency === RecurringPaymentFrequency.BIWEEKLY) {
			dates = this.autoPayAvailableDates[RecurringPaymentFrequency.BIWEEKLY];
		}

		if (this.autoPaySetup.frequency === RecurringPaymentFrequency.MONTHLY) {
			dates = this.autoPayAvailableDates[RecurringPaymentFrequency.MONTHLY];
		}

		if (this.autoPaySetup.frequency === RecurringPaymentFrequency.SEMIMONTHLY) {
			dates = this.autoPayAvailableDates[RecurringPaymentFrequency.SEMIMONTHLY];
		}

		if (this.autoPaySetup.frequency === RecurringPaymentFrequency.WEEKLY) {
			dates = this.autoPayAvailableDates[RecurringPaymentFrequency.WEEKLY];
		}

		return !!dates.find((d) => d === date);
	}

	autoPayDraftDateTwoAllowedDates(date: string): boolean {
		const draftDateOne = moment(this.autoPaySetup.draftDateOne).toDate();
		const draftDateTwo = moment(date, 'YYYY-MM-DD').toDate();
		const isAfterDraftOne = draftDateTwo > draftDateOne;

		if (!isAfterDraftOne) {
			return false;
		}

		if (this.autoPaySetup.frequency === RecurringPaymentFrequency.SEMIMONTHLY) {
			const dates = this.autoPayAvailableDates[RecurringPaymentFrequency.SEMIMONTHLY];

			return !!dates.find((d) => d === date);
		}

		return false;
	}

	get currentAutoPayFrequencyName(): string {
		return getRecurringPaymentFrequencyName(this.autoPay.frequency);
	}

	get currentAutoPayBankAccountName(): string {
		const currentAccount = this.bankAccounts.find((account: ExtendedBankAccount) =>
			account.id === this.autoPay.currentAccountId);

		return currentAccount && currentAccount.name ? currentAccount.name : '';
	}

	get selectedRecurringPaymentFrequencyName(): string {
		return getRecurringPaymentFrequencyName(this.autoPaySetup.frequency);
	}

	paymentTotalByFrequency(paymentTotal: string): string {
		let paymentTotalByFrequency: number;

		switch (this.autoPaySetup.frequency) {
			case RecurringPaymentFrequency.WEEKLY:
				paymentTotalByFrequency = Number(paymentTotal) / 4;
				break;
			case RecurringPaymentFrequency.BIWEEKLY: case RecurringPaymentFrequency.SEMIMONTHLY:
				paymentTotalByFrequency = Number(paymentTotal) / 2;
				break;
			default:
				paymentTotalByFrequency = Number(paymentTotal);
		}

		return roundDecimalAmountToString(paymentTotalByFrequency);
	}

	roundAndFormatAutoPaySetupAmount(): void {
		if (this.paymentAmountErrors.length) {
			this.autoPaySetup.paymentTotalByFrequency = '0.00';
			return;
		}

		this.autoPaySetup.paymentTotalByFrequency = this.paymentTotalByFrequency(this.autoPaySetup.paymentTotal);

		let totalPaymentAmount: number;

		switch (this.autoPaySetup.frequency) {
			case RecurringPaymentFrequency.WEEKLY:
				totalPaymentAmount = Number(this.autoPaySetup.paymentTotalByFrequency) * 4;
				break;
			case RecurringPaymentFrequency.BIWEEKLY: case RecurringPaymentFrequency.SEMIMONTHLY:
				totalPaymentAmount = Number(this.autoPaySetup.paymentTotalByFrequency) * 2;
				break;
			default:
				totalPaymentAmount = Number(this.autoPaySetup.paymentTotalByFrequency);
		}

		this.autoPaySetup.paymentTotal = totalPaymentAmount.toFixed(2);
	}
}
