
import KPage from '@/components/KPage.vue';
import { Component, Inject, Prop } from 'vue-property-decorator';
import ConsumerLoansTable from '@/components/consumer/ConsumerLoansTable.vue';
import AccountDetailsCard from '@/components/consumer/AccountDetailsCard.vue';
import { Dialog, NoticeClass } from '@kasasa/fbase-components/lib';
import BaseConsumerPage from '@/pages/consumer/BaseConsumerPage';
import { OverviewLoanRow } from '@/services/ConsumerService';
import { handleErrorResponse } from '@/utils/xhr';
import { Formatters } from '@/utils/formatters';
import { namespace } from 'vuex-class';
import { Loan } from '@/services/types/loan/Loan';
import {
	KLOANS_EDIT_PROFILE, VKLOANS,
} from '@/pages/PermissionGroups';
import { ExtendedBankAccount } from '@/services/types/BankAccount';
import FeatureService from '@/services/FeatureService';

const loanStoreModule = namespace('loan');
const loanMaintenanceStoreModule = namespace('maintenance');
const bankAccountStoreModule = namespace('bankAccount');

@Component({
	components: {
		AccountDetailsCard,
		KPage,
		ConsumerLoansTable,
	},
})
export default class ConsumerOverviewPage extends BaseConsumerPage {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	consumerId!: string;

	@loanStoreModule.Mutation('setLoan')
	setLoan!: (loan: Loan) => void;

	@loanMaintenanceStoreModule.Mutation('resetMaintenanceStoreData')
	resetMaintenanceStoreData!: () => void;

	@bankAccountStoreModule.Mutation('setBankAccounts')
	setBankAccounts!: (accounts: ExtendedBankAccount[]) => void;

	consumersLoading: boolean = true;

	title: string = '';

	search: string = '';

	loading: boolean = true;

	loadingResend: boolean = false;

	loadingEmail: boolean = false;

	async onResendInvitation(): Promise<void> {
		try {
			this.loadingResend = true;
			await this.consumerSvc.resendInvite(this.fiId, this.consumerId);
			this.showAlert('Welcome email has been successfully resent.', NoticeClass.SUCCESS, 5000);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.loadingResend = false;
		}
	}

	async onEmailInput(value: string): Promise<void> {
		try {
			this.loadingEmail = true;
			await this.consumerSvc.updateConsumerEmail(this.consumerId, { email: value });
			this.showAlert('Email address has been successfully updated.', NoticeClass.SUCCESS, 5000);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.loadingEmail = false;
		}
	}

	async created(): Promise<void> {
		await (new FeatureService(this.$store)).getFeatures(this.fiId);

		this.loading = true;
		await this.getConsumer();
		this.title = `${this.getConsumersName()} Overview`;
		this.loading = false;
		await this.getLoans(true);

		// Clean the store to prevent cached loan data from leaking between different loans
		this.setLoan({} as Loan);
		this.resetMaintenanceStoreData();
		this.setBankAccounts([]);
	}

	loans: OverviewLoanRow[] = [];

	loadingLoans: boolean = false;

	async getLoans(force: boolean = false): Promise<OverviewLoanRow[]> {
		if (force || this.loans.length === 0) {
			try {
				this.loadingLoans = true;
				const response = await this.consumerSvc.getLoansForConsumer(this.fiId, this.consumerId);
				this.loans = response.data.data.map((loan: OverviewLoanRow) => {
					loan.loadingDelete = false;
					return loan;
				});
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			} finally {
				this.loadingLoans = false;
			}
		}

		return this.loans;
	}

	get canView(): boolean {
		return this.canRead(VKLOANS);
	}

	get canUpdate(): boolean {
		return this.canWrite(VKLOANS);
	}

	get canModify(): boolean {
		return this.canRead(VKLOANS) && this.canWrite(VKLOANS);
	}

	get canModifyProfile(): boolean {
		return this.canWrite(KLOANS_EDIT_PROFILE) || this.canRead(KLOANS_EDIT_PROFILE);
	}

	get canEditProfile(): boolean {
		return this.canModify || this.canModifyProfile;
	}

	async onDeleteLoan(item: OverviewLoanRow): Promise<void> {
		const dialog = new Dialog('Confirm Action', `This action cannot be undone. This will permanently delete the loan named '${item.loanName}' and all information attached to it.`);
		dialog.setAcceptLabel('DELETE');
		dialog.setDeclineLabel('Cancel');
		const result = await this.showDialog(dialog);
		if (result !== 'accept') {
			return;
		}

		try {
			item.loadingDelete = true;
			await this.consumerSvc.deleteLoan(this.fiId, item.id);
			this.loans = this.loans.filter((loan) => loan.id !== item.id);
			await this.showAlert('Loan successfully deleted.', NoticeClass.SUCCESS, 5000);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			item.loadingDelete = false;
		}
	}
}
