import { FBaseService } from '@kasasa/fbase-components/lib';
import {
	LoanRecurringPaymentAvailableDates,
	RecurringPaymentLatest,
	RecurringPaymentRequest,
} from '@/services/types/loan/RecurringPayment';

export default class LoanAutoPayService extends FBaseService {
	async disableAutoPay(consumerId: string, loanId: string): Promise<void> {
		await this.apiClient.patch(
			`/api/v1/kloans-loan/consumer/${consumerId}/loan/${loanId}/disable-automatic-payments`,
		);
	}

	async setupAutoPay(
		consumerId: string,
		loanId: string,
		data: RecurringPaymentRequest,
	): Promise<void> {
		await this.apiClient.post(
			`/api/v1/kloans-loan/consumer/${consumerId}/loan/${loanId}/recurring-payment`,
			data,
		);
	}

	async getLatestRecurringPayment(consumerId: string, loanId: string): Promise<RecurringPaymentLatest> {
		const response = await this.apiClient.get(
			`/api/v1/kloans-loan/consumer/${consumerId}/loan/${loanId}/recurring-payment`,
		);
		return response.data.data;
	}

	async getAvailableDates(loanId: string, consumerId: string): Promise<LoanRecurringPaymentAvailableDates> {
		const response = await this.apiClient.get(
			`/api/v1/kloans-loan/consumer/${consumerId}/loan/${loanId}/recurring-payment/available-dates`,
		);

		return response.data.data;
	}
}
