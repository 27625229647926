
import KCard from '@/components/KCard.vue';
import {
	Component, Inject, Prop, Watch,
} from 'vue-property-decorator';
import { KText } from '@kasasa/fbase-components';
import {
	CommentReferenceTypes,
	Comment,
	CommentPayload,
} from '@/services/types/loan/Comment';
import LoanService from '@/services/LoanService';
import CommentsService from '@/services/CommentsService';
import BaseComponent from '@/components/BaseComponent';
import { handleErrorResponse } from '@/utils/xhr';
import { NoticeClass } from '@kasasa/fbase-components/lib';
import CommentsTable from '@/components/loan/comments/CommentsTable.vue';
import { Formatters } from '@/utils/formatters';
import { KLOANS_NOTES } from '@/pages/PermissionGroups';

@Component({
	components: {
		KCard,
		KText,
		CommentsTable,
	},
	enums: { CommentReferenceTypes },
})
export default class CommentsCard extends BaseComponent {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	fiId!: string;

	@Prop({ required: true })
	loanId!: string;

	@Prop({ default: true })
	standalone!: boolean;

	@Prop({ default: false })
	showTable!: boolean;

	@Prop({ default: true })
	showTitle!: boolean;

	@Prop({ default: true })
	showFilter!: boolean;

	@Prop()
	editBody!: string;

	@Prop()
	customTitle!: string;

	@Prop({ default: CommentReferenceTypes.LOAN })
	referenceType!: CommentReferenceTypes;

	@Prop({ default: '' })
	referenceId!: string;

	@Prop()
	commentItems!: Comment[];

	@Prop()
	hintText!: string;

	@Prop()
	persistHint!: boolean;

	items!: Comment[];

	loading: boolean = false;

	loanSvc: LoanService = new LoanService(this.$store);

	commentsSvc: CommentsService = new CommentsService(this.$store);

	commentBody: string = '';

	showModal: boolean = false;

	modalMode = '';

	commentEditBody: string = '';

	commentId: string = '';

	editCommentObj: Comment = {
		id: '',
		loanId: '',
		body: '',
		metadata: [],
		referenceType: '',
		referenceId: '',
		submittedBy: '',
		submittedById: '',
		lastEditedBy: '',
		lastEditedById: '',
		submittedDate: '',
	};

	get fullName(): string {
		return `${this.sessionData.firstName} ${this.sessionData.lastName}`.trim();
	}

	get shouldDisableAddCommentBtn(): boolean {
		return !this.commentBody || this.commentBody.length > 500 || this.loading;
	}

	get canModifyComments():boolean {
		return this.canWrite(KLOANS_NOTES);
	}

	showModalEdit(item: Comment) {
		this.editCommentObj = item;
		this.modalMode = this.$enums.CommentModalModes.EDIT;
		this.showModal = true;
		this.commentEditBody = item.body;
	}

	showModalDelete(item: Comment) {
		this.modalMode = this.$enums.CommentModalModes.DELETE;
		this.commentId = item.id;
		this.showModal = true;
	}

	closeModal(): void {
		this.commentEditBody = '';
		this.showModal = false;
	}

	getComments(beginDate: string, endDate: string) {
		this.$emit('update-items', beginDate, endDate);
	}

	async addComment(): Promise<void> {
		const data: CommentPayload = {
			userId: `${this.sessionData.id}`,
			user: this.fullName,
			body: this.commentBody,
			referenceType: this.referenceType,

			loanId: this.loanId,
		};

		if (this.referenceId) {
			data.referenceId = this.referenceId;
		}

		try {
			this.loading = true;
			await this.commentsSvc.postComment(this.loanId, data);
			this.commentBody = '';
			this.$emit('update-items');
			this.showAlert('Comment saved successfully.', NoticeClass.SUCCESS, 5000);
		} catch (error) {
			await handleErrorResponse((error as any).response, this);
		} finally {
			this.loading = false;
		}
	}

	async deleteComment(commentId: string) {
		try {
			await this.commentsSvc.deleteComment(this.loanId, commentId);
			const position = this.items.findIndex((addr) => addr.id === commentId);
			this.items.splice(position, 1);
			this.showAlert(
				'Comment deleted successfully.',
				NoticeClass.SUCCESS,
				5000,
			);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		}

		this.showModal = false;
	}

	async editComment(commentBody: string) {
		const data: CommentPayload = {
			userId: `${this.sessionData.id}`,
			user: this.fullName,
			body: commentBody,
			id: this.editCommentObj.id,
			loanId: this.editCommentObj.loanId,
			referenceType: this.editCommentObj.referenceType,
			referenceId: this.referenceId,
		};

		try {
			await this.commentsSvc.putComment(this.loanId, data);
			this.showAlert('Comment edited successfully.', NoticeClass.SUCCESS, 5000);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		}
		this.editCommentObj = {
			id: '',
			loanId: '',
			body: '',
			metadata: [],
			referenceType: '',
			referenceId: '',
			submittedBy: '',
			submittedById: '',
			lastEditedBy: '',
			lastEditedById: '',
			submittedDate: '',
		};
		this.showModal = false;
	}

	emitKeyup(commentBody: string) {
		this.$emit('comment-change', commentBody);
	}

	@Watch('editBody', { immediate: true })
	onModeChanged(value: string): void {
		this.commentBody = value;
	}

	@Watch('commentItems', { immediate: true, deep: true })
	onCommentItemsChange(value: []): void {
		this.items = value;
	}
}
