
import {
	Component, Inject, ModelSync, Prop, Vue,
} from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { Document } from '@/services/types/Document';
import { Dictionary } from 'vue-router/types/router.d';
import LoanService from '@/services/LoanService';
import { Formatters } from '@/utils/formatters';

@Component
export default class DocumentsTable extends Vue {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	consumerId!: string;

	@Prop({ required: true })
	loanId!: string;

	@Prop({ required: true })
	items!: Document[];

	@Prop({ required: true })
	title!: string;

	@Prop({ default: false })
	loading!: boolean;

	@Prop({ required: true })
	type!: string;

	@Prop({ required: true })
	availableYears!: string[];

	@Prop({ required: true })
	filterYear!: string;

	@Prop({ required: true, type: Object })
	service!: LoanService;

	@ModelSync('filterYear', 'filter-changed')
	filterValue!: string;

	sortDesc: boolean[] = [];

	loadingText: string = 'Loading documents...';

	headers: DataTableHeader[] = [
		{
			text: 'Date', value: 'datePosted', width: '40%',
		},
		{
			text: 'View or Print (PDF)', value: 'name', width: '60%',
		},
	];

	loadingStatus: Dictionary<boolean> = {};

	isLoading(document: Document): boolean {
		const file = document.filename;
		const status = this.loadingStatus[file] || false;
		// Allow for reactivity in template to be triggered.
		this.$set(this.loadingStatus, file, status);
		return status;
	}

	async onDocumentClick(document: Document): Promise<void> {
		let link: string = '';
		const file = document.filename;
		this.loadingStatus[file] = true;

		try {
			const response = await this.service.getDocumentLink(
				this.consumerId,
				this.loanId,
				document.filename,
			);

			link = response.data.data.link;
			window.open(link);
		} catch (error) {
			this.$emit('document-error', error);
		} finally {
			this.loadingStatus[file] = false;
		}
	}

	rowClass(item: Document): string {
		return `document-row ${this.type} ${item.filename}`;
	}
}
