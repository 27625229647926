import { AxiosResponse } from 'axios';
import { DropdownOption } from '@/types/Form';
import { FBaseService, ResponseEnvelope } from '@kasasa/fbase-components/lib';

export interface CpiLoanSettingsResponse {
	fiId: number
	instructionType: string
	loanNumber: string
	premiumAdministrationMethod: number

}

export interface CpiSetup {
	instructionType: string;
	loanNumber: string;
}

export interface AddCpiBillingData {
	premiumAddedAmount: string;
	adjustedInstallmentAmount: string;
	premiumAddedEffectiveDate: string;
	installmentRecalculationPeriods: string
}

export interface CpiRefundData {
	premiumRefundAmount: string
	premiumRefundEffectiveDate: string
	refundType?: string
}

interface DropdownOptionResponseData {
	id: string;
	value: string
}

export type AddCpiBillingPayload = CpiSetup & AddCpiBillingData;

export type CpiRefundPayload = CpiSetup & CpiRefundData;

export type CpiInstructionPayload = AddCpiBillingPayload | CpiRefundPayload;

export default class CpiService extends FBaseService {
	serverErrorHandler?: () => Promise<void>;

	hanldeServerError(): Promise<void> {
		if (this.serverErrorHandler) {
			return this.serverErrorHandler();
		}
		return super.hanldeServerError();
	}

	setServerErrorHandler(handler: () => Promise<void>): void {
		this.serverErrorHandler = handler;
	}

	private fiId: string = '';

	cpiRefundTypes: DropdownOption[] = [];

	premiumAdministrationMethods: DropdownOption[] = [];

	async submitCpiInstruction(payload: CpiInstructionPayload): Promise<any> {
		const response = await this.apiClient.post(`/api/v1/kloans-cpi/fi/${this.fiId}/instruction`, payload);
		return response.data.data;
	}

	async cpiLoanSettings(fiId: string, instructionType: string, loanNumber: string):
		Promise<CpiLoanSettingsResponse> {
		const response: AxiosResponse<ResponseEnvelope<CpiLoanSettingsResponse>> = await this.apiClient.post(`/api/v1/kloans-cpi/fi/${fiId}/loan-settings`, {
			instructionType,
			loanNumber,
		});
		return response.data.data;
	}

	async getCpiInstructions(fiId: string): Promise<void> {
		if (this.fiId === fiId) {
			return;
		}
		this.fiId = fiId;
		await this.getCpiRefundTypes();
		await this.getCpiPremiumAdministrationMethods();
	}

	private async getCpiRefundTypes(): Promise<void> {
		const response: AxiosResponse<ResponseEnvelope<DropdownOptionResponseData[]>> = await this.apiClient.get('/api/v1/kloans-cpi/refund-types');
		const refundType: DropdownOption[] = [{ value: '', text: 'Select...' }];

		refundType.push(...(response.data.data.map(({ id, value }) => ({
			value: id,
			text: value,
		}))));

		this.cpiRefundTypes = refundType;
	}

	private async getCpiPremiumAdministrationMethods(): Promise<void> {
		const response: AxiosResponse<ResponseEnvelope<DropdownOptionResponseData[]>> = await this.apiClient.get('/api/v1/kloans-cpi/premium-administration-methods');
		this.premiumAdministrationMethods = response.data.data.map(({ id, value }) => ({
			value: id,
			text: value,
		}));
	}
}
