
import KPage from '@/components/KPage.vue';
import { Component } from 'vue-property-decorator';
import { KReadOnly, KSpinner } from '@kasasa/fbase-components';
import BaseLoanPage from '@/pages/loan/BaseLoanPage';
import ResolvedFeesTable from '@/components/loan/fees/ResolvedFeesTable.vue';
import OutstandingFeesTable from '@/components/loan/fees/OutstandingFeesTable.vue';
import { Dialog, NoticeClass } from '@kasasa/fbase-components/lib';
import { handleErrorResponse } from '@/utils/xhr';
import {
	Fee,
	FeeStatus,
	FeeType,
	FeeTransactionType,
	LoanFeeTransaction,
} from '@/services/types/loan/Fee';
import WaiveFeeModal from '@/components/loan/fees/WaiveFeeModal.vue';
import {
	CommentPayload,
	CommentReferenceTypes,
} from '@/services/types/loan/Comment';
import CommentsService from '@/services/CommentsService';

@Component({
	components: {
		KPage,
		KReadOnly,
		OutstandingFeesTable,
		ResolvedFeesTable,
		KSpinner,
		WaiveFeeModal,
	},
	enums: {
		FeeStatus,
		FeeType,
		FeeTransactionType,
	},
})
export default class LoanFeesPage extends BaseLoanPage {
	feesLoading: boolean = true;

	data: Fee[] = [];

	feeToWaive: Fee | null = null;

	showWaiveModal: boolean = false;

	commentsSvc: CommentsService = new CommentsService(this.$store);

	get outstandingFees(): Fee[] {
		return this.data.filter(
			(fee) => fee.status === this.$enums.FeeStatus.Unsettled,
			this,
		);
	}

	get resolvedFees(): Fee[] {
		return this.data.filter(
			(fee) => fee.status === this.$enums.FeeStatus.Settled,
			this,
		);
	}

	async created(): Promise<void> {
		await Promise.all([this.getConsumer(), this.getFees()]);
	}

	async getFees(): Promise<void> {
		this.feesLoading = true;
		try {
			const response = await this.loanSvc.getFees(this.fiId, this.loanId);
			this.data = response.data.data;
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.feesLoading = false;
		}
	}

	get canView(): boolean {
		return this.canRead('vkloans');
	}

	async confirmWaiveFee(item: Fee): Promise<void> {
		this.feeToWaive = item;
		this.showWaiveModal = true;
	}

	async confirmReverseWaiver(item: LoanFeeTransaction): Promise<void> {
		item.loading = true;
		const dialog = new Dialog(
			'Confirm Reverse Waived Fee',
			'Are you sure you want to reverse this waiver?',
		);
		dialog.setDeclineLabel('Cancel');
		const result = await this.showDialog(dialog);

		if (result === 'accept') {
			try {
				await this.loanSvc.reverseWaivedFee(
					this.fiId,
					this.loanId,
					item.feeId,
					item.waivedFee.id,
				);
				this.showAlert(
					'Waiver successfully reversed.',
					NoticeClass.SUCCESS,
					5000,
				);
				this.data = [];
				await this.getFees();
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			} finally {
				item.loading = false;
			}
		} else {
			item.loading = false;
		}
	}

	get fullName(): string {
		return `${this.sessionData.firstName} ${this.sessionData.lastName}`.trim();
	}

	async saveComment(
		commentBody: string,
		commentFeeId: string,
	): Promise<void> {
		const data: CommentPayload = {
			userId: `${this.sessionData.id}`,
			user: this.fullName,
			body: commentBody,
			referenceType: CommentReferenceTypes.LATE_FEE,
			referenceId: commentFeeId,
			loanId: this.loanId,
		};

		try {
			await this.commentsSvc.postComment(this.loanId, data);
			this.showAlert('Comment saved successfully.', NoticeClass.SUCCESS, 5000);
		} catch (error) {
			await handleErrorResponse((error as any).response, this);
		}
	}

	onFeeWaived(): void {
		this.getFees();
		this.fetchAndSetLoan(true);
	}
}
