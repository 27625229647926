
import KPage from '@/components/KPage.vue';
import { Component } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import BaseLoanPage from '@/pages/loan/BaseLoanPage';
import ChangeDueDateModal from '@/components/loan/modifications/ChangeDueDateModal.vue';
import ModifyTermsModal from '@/components/loan/modifications/ModifyTermsModal.vue';

@Component({
	components: {
		ChangeDueDateModal,
		KPage,
		KReadOnly,
		ModifyTermsModal,
	},
})
export default class LoanModificationsPage extends BaseLoanPage {
	showChangeDueDate: boolean = false;

	showModifyTerms: boolean = false;

	async created(): Promise<void> {
		await Promise.all([
			this.getConsumer(),
		]);

		this.loading = false;
	}

	async loanModCompleted(): Promise<void> {
		this.showChangeDueDate = false;
		this.showModifyTerms = false;

		// Refresh modified loan data in store
		await this.fetchAndSetLoan(true);
	}
}
