import moment, { Moment } from 'moment';

enum WeekDay {
	SUNDAY = 0,
	MONDAY = 1,
	TUESDAY = 2,
	WEDNESDAY = 3,
	THURSDAY = 4,
	FRIDAY = 5,
	SATURDAY = 6,
}

const nextBusinessDateFrom = (date: string | Moment): Moment => {
	const dateObject = moment(date);

	let nextBusinessDate: Moment;

	if (dateObject.day() === WeekDay.FRIDAY) {
		nextBusinessDate = dateObject.add(3, 'd');
	} else if (dateObject.day() === WeekDay.SATURDAY) {
		nextBusinessDate = dateObject.add(2, 'd');
	} else {
		nextBusinessDate = dateObject.add(1, 'd');
	}

	return nextBusinessDate;
};

const lastBusinessDateOfMonthFrom = (date: string | Moment): Moment => {
	const dateObject = moment(date);
	const lastDayOfMonth = dateObject.endOf('month');

	let lastBusinessDate: Moment;

	if (lastDayOfMonth.day() === WeekDay.SUNDAY) {
		lastBusinessDate = dateObject.subtract(2, 'd');
	} else if (lastDayOfMonth.day() === WeekDay.SATURDAY) {
		lastBusinessDate = dateObject.subtract(1, 'd');
	} else {
		lastBusinessDate = lastDayOfMonth;
	}

	return lastBusinessDate;
};

const nextBusinessDate = (): Moment => nextBusinessDateFrom(moment());

const lastBusinessDateOfMonth = (): Moment => lastBusinessDateOfMonthFrom(moment());

const businessWeekDays = [
	WeekDay.MONDAY,
	WeekDay.TUESDAY,
	WeekDay.WEDNESDAY,
	WeekDay.THURSDAY,
	WeekDay.FRIDAY,
];

const isBusinessDay = (date: string | Moment): boolean => {
	const dateObject = moment(date);

	return businessWeekDays.indexOf(dateObject.day()) !== -1;
};

const weekDayLabel = (weekDayNumber: number): string => {
	switch (weekDayNumber) {
		case 0:
			return 'Sunday';
		case 1:
			return 'Monday';
		case 2:
			return 'Tuesday';
		case 3:
			return 'Wednesday';
		case 4:
			return 'Thursday';
		case 5:
			return 'Friday';
		case 6:
			return 'Saturday';
		default:
			return 'Invalid weekday';
	}
};

export {
	WeekDay,
	nextBusinessDate,
	nextBusinessDateFrom,
	lastBusinessDateOfMonth,
	lastBusinessDateOfMonthFrom,
	isBusinessDay,
	weekDayLabel,
};
