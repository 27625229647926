
import KPage from '@/components/KPage.vue';
import { Component, Inject } from 'vue-property-decorator';
import BaseLoanPage from '@/pages/loan/BaseLoanPage';
import KCard from '@/components/KCard.vue';
import { KReadOnly } from '@kasasa/fbase-components';
import { namespace } from 'vuex-class';
import { NoticeClass } from '@kasasa/fbase-components/lib/index';
import { ChargeOffPayLoad, Status } from '@/services/types/loan/Maintenance';
import { Formatters } from '@/utils/formatters';
import { ErrorEnvelope } from '@kasasa/kloans-common-ui/dist/consumer-app/services/rest/types';
import { Error } from '@kasasa/kloans-common-ui/src/consumer-app/services/rest/types/index';

const loanMaintenanceStoreModule = namespace('maintenance');

@Component({
	components: {
		KPage,
		KCard,
		KReadOnly,
	},
})
export default class LoanChargeOffPage extends BaseLoanPage {
	@Inject('format')
	format!: Formatters;

	private principalBalance = '$0.00';

	private interest = '$0.00';

	private fees = '$0.00';

	private addRecovery = false;

	private totalChargeOffAmount = '$0.00';

	private recoveryAmount = '0.00';

	private recoveryDescription = 'Recovery Transaction';

	private processingChargeOff = false;

	private showChargeOffConfirmationModal = false;

	private effectiveDate = (new Date()).toLocaleDateString();

	private validateRecoveryAmount = false;

	@loanMaintenanceStoreModule.Getter('getLoanMaintenanceStatus')
	loanMaintenanceStatus!: Status|null;

	@loanMaintenanceStoreModule.Mutation('setLoanMaintenanceStatus')
	setLoanMaintenanceStatus!: (status: Status) => void;

	async created(): Promise<void> {
		await this.fetchAndSetLoanMaintenanceStatus();

		this.principalBalance = this.loanMaintenanceStatus?.principalBalance
			? this.format.asCurrency(this.loanMaintenanceStatus.principalBalance)
			: '$0.00';
		this.interest = this.loan.general?.accruedInterest
			? this.format.asCurrency(this.loan.general.accruedInterest)
			: '$0.00';
		this.fees = this.loanMaintenanceStatus?.feesDue
			? this.format.asCurrency(this.loanMaintenanceStatus.feesDue)
			: '$0.00';
		this.totalChargeOffAmount = this.loanMaintenanceStatus?.principalBalance
			? this.format.asCurrency(this.loanMaintenanceStatus.principalBalance)
			: '$0.00';

		this.loading = false;
	}

	get netChargeOffAmount(): string {
		if (this.addRecovery) {
			const recoveryAmount = this.recoveryAmount.replace(/[^\d.]/g, '');

			const total = Number(this.loanMaintenanceStatus?.principalBalance) - Number(recoveryAmount);

			return this.format.asCurrency(total);
		}

		return this.totalChargeOffAmount;
	}

	get loanLastFourNumbers(): string {
		return this.loan?.general ? this.loan.general.kasasaLoanNumber.slice(-4) : 'N/A';
	}

	async chargeOffLoan(): Promise<void> {
		this.processingChargeOff = true;

		const validRecoveryAmount = Number(this.recoveryAmount.replace(/[^\d.-]/g, '')) > 0;
		const payload = validRecoveryAmount ? {
			recoveryAmount: this.recoveryAmount,
			recoveryDescription: this.recoveryDescription,
		} : {} as ChargeOffPayLoad;

		try {
			await this.loanSvc.postChargeOff(this.fiId, this.loanId, payload);

			this.showAlert('Loan charged-off successfully.', NoticeClass.SUCCESS);

			await this.$router.push({
				name: 'loan-maintenance',
				params: {
					clientId: this.clientId,
					consumerId: this.consumerId,
					loanId: this.loanId,
				},
			});

			await this.fetchAndSetLoan(true);
		} catch (exception) {
			if (exception.code === 422) {
				const response: ErrorEnvelope|null = exception.response?.data
					? exception.response.data as ErrorEnvelope
					: null;

				const error = response && response.data.error instanceof Error
					? response.data.error as Error
					: { errors: [{ message: 'Invalid request data.' }] } as Error;

				this.showAlert(
					error.errors[0].message,
					NoticeClass.WARN,
				);
			} else {
				this.showAlert(
					'An error occurred when processing the loan charge-off. Please try again.',
					NoticeClass.ERROR,
				);
			}
		} finally {
			this.processingChargeOff = false;
		}
	}

	async fetchAndSetLoanMaintenanceStatus(): Promise<void> {
		if (this.loanMaintenanceStatus !== null) {
			// Already available in data store. Prevent unnecessary http call.
			return;
		}

		try {
			const response = await this.loanSvc.getLoanStatus(this.loanId);
			this.setLoanMaintenanceStatus(response.data.data);
		} catch (error) {
			await this.showAlert(
				'An error occurred when retrieving the loan status data. Please try reloading the page.',
				NoticeClass.ERROR,
			);
		}
	}

	get recoveryAmountErrors(): string[] {
		if (!this.validateRecoveryAmount) {
			return [];
		}

		const recoveryAmount = Number(this.recoveryAmount.replace(/[^\d.-]/g, ''));

		if (recoveryAmount < 0) {
			return ['Recovery amount must be greater than zero.'];
		}

		if (recoveryAmount > Number(this.loan.general.balance)) {
			return ['Recovery amount cannot be greater than the principal balance.'];
		}

		return [];
	}

	validateAndFormatRecoveryAmount(): void {
		this.recoveryAmount = this.recoveryAmount.replace(/[^\d.-]/g, '');

		this.validateRecoveryAmount = true;
	}
}
