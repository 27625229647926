export enum RecurringPaymentFrequency {
	MONTHLY = 'monthly',
	WEEKLY = 'weekly',
	BIWEEKLY = 'biWeekly',
	SEMIMONTHLY = 'semiMonthly',
}

export const getRecurringPaymentFrequencyName = (value: RecurringPaymentFrequency): string => {
	switch (value) {
		case RecurringPaymentFrequency.MONTHLY:
			return 'Monthly';
		case RecurringPaymentFrequency.WEEKLY:
			return 'Weekly';
		case RecurringPaymentFrequency.BIWEEKLY:
			return 'Bi-Weekly';
		case RecurringPaymentFrequency.SEMIMONTHLY:
			return 'Semi-Monthly';
		default:
			return 'Invalid Frequency';
	}
};

export interface RecurringPaymentAvailableFrequencyDates {
	endDate: string;
	startDate: string;
}

export interface RecurringPaymentAvailableFrequency {
	biWeekly: RecurringPaymentAvailableFrequencyDates;
	monthly: RecurringPaymentAvailableFrequencyDates;
	weekly: RecurringPaymentAvailableFrequencyDates;
}

export interface RecurringPaymentRequest {
	accountId: string;
	draftDateOne: string;
	draftDateTwo?: string|null;
	frequency: RecurringPaymentFrequency;
	paymentTotal: string;
	paymentTotalByFrequency: string;
}

export interface RecurringPayment {
	active: boolean;
	availableFrequency: RecurringPaymentAvailableFrequency;
	current: string;
	currentAccountId: string;
	currentTotalPeriodPaymentAmount: string;
	cutoffDate: string;
	effectiveDate: string;
	enabled: boolean;
	frequency: RecurringPaymentFrequency;
	maximum: string;
	minimum: string;
	nextRecurringPaymentDate: string;
	paymentAmount: string;
	recurrence: string;
	secondsUntilCutoffDate: number;
	updatedAt: string;
}

export interface RecurringPaymentLatest {
	frequency: RecurringPaymentFrequency;
	effectiveDate: string;
	draftDateOne: string;
	draftDateTwo: string|null;
	totalPeriodPaymentAmount: string;
	paymentAmount: string,
	accountId: string;
	consumerId: string;
	loanId: string;
	updatedAt: string,
	loanInstallmentAmount: string
}

export type LoanRecurringPaymentAvailableDates = {
	[key in RecurringPaymentFrequency]: string[];
} & { cutOffDateTime: string };
