
import { Component } from 'vue-property-decorator';
import KPage from '@/components/KPage.vue';
import { KText, KReadOnly, KSpinner } from '@kasasa/fbase-components';
import BaseLoanPage from '@/pages/loan/BaseLoanPage';
import KCard from '@/components/KCard.vue';
import { Asset, LoanType } from '@/services/types/loan/Loan';
import CollateralAssetPayload, {
	CollateralAssetType,
} from '@/services/types/loan/Collateral';
import { handleErrorResponse } from '@/utils/xhr';
import CollateralAssetCard from '@/components/loan/collateral/CollateralAssetCard.vue';

@Component({
	components: {
		KPage,
		KCard,
		KText,
		KReadOnly,
		KSpinner,
		CollateralAssetCard,

	},
})
export default class LoanCollateralPage extends BaseLoanPage {
	// Data
	private loanAsset: Asset = {} as Asset;

	private processingCollateral: boolean = false;

	// Hooks
	async created(): Promise<void> {
		await Promise.all([this.getConsumer(), this.getBorrowers()]);

		this.loanAsset = this.loan?.collateralAsset?.asset
			? { ...this.loan.collateralAsset.asset }
			: {};

		this.loading = false;
	}

	get isAutoLoan(): boolean {
		return this.loan?.general?.loanTypeId === LoanType.VEHICLE;
	}

	// Methods
	async saveCollateralAsset(asset: Asset): Promise<void> {
		const payload: CollateralAssetPayload = {
			type: CollateralAssetType.VEHICLE_ASSET,
			asset,
		};

		try {
			this.processingCollateral = true;
			await this.loanSvc.setLoanCollateralAsset(
				this.consumerId,
				this.loanId,
				payload,
			);
			await this.fetchAndSetLoan(true);
		} catch (error) {
			await handleErrorResponse((error as any).response, this);
		} finally {
			this.processingCollateral = false;
		}
	}
}
