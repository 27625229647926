
import KCard from '@/components/KCard.vue';
import {
	Component, Inject, Prop, Watch,
} from 'vue-property-decorator';
import {
	KText, KReadOnly, KCardFooter, KCardFooterBtn,
} from '@kasasa/fbase-components';
import {
	ApplicantType, BorrowerSectionData, getApplicantTypeName,
} from '@/services/types/loan/Borrower';
import { Formatters } from '@/utils/formatters';
import { Validations } from 'vuelidate-property-decorators';
import { RuleDecl } from 'vue/types/options.d';
import { RequiredValidation } from '@kasasa/kloans-common-ui/dist/validations';
import {
	numeric, required, requiredIf, maxLength, minLength,
} from 'vuelidate/lib/validators';
import resolvePath from '@/utils/resolvePath';
import moment from 'moment';
import LoanService from '@/services/LoanService';
import { Dictionary } from 'vue-router/types/router.d';
import BaseComponent from '@/components/BaseComponent';
import { DropdownOption } from '@/types/Form';
import statesUS from '@/utils/states';
import KValidate from '@kasasa/fbase-components/src/components/Input/KInputValidate';

// A simple validiator that takes n args of strings, which are properties on the validators model.
// If every property is blank or not set, each field with the same args, will be flagged as required.
const requireOneOf = (...properties: string[]) =>
	requiredIf((model: object): boolean => properties.every((path: string) => {
		const result: string = (resolvePath(path, model) || '').toString();

		return result === '' || !result;
	}));

const isDigit = (value: string) => /^\d$/.test(value);

@Component({
	components: {
		KCard,
		KCardFooter,
		KCardFooterBtn,
		KText,
		KReadOnly,
	},
	enums: {
		ApplicantType,
	},
})
export default class BorrowerSection extends BaseComponent {
	@Prop({ required: true })
	index!:Number;

	@Prop({ required: true })
	isLast!:Boolean;

	@Prop({ required: true })
	onDataChange!:(data: BorrowerSectionData, index: Number)=>void;

	@Prop({ required: true })
	deleteBorrower!:(index: number)=>void;

	@Prop({ required: true })
	addBorrower!:()=>void;

	@Prop({ required: true })
	borrower!: BorrowerSectionData;

	@Prop({ default: false })
	hasWrite!: boolean;

	@Prop({ required: true })
	fiId!: string;

	@Prop({ required: true })
	applicantTypeDropdownOptions!: DropdownOption[];

	@Inject('format')
	format!: Formatters;

	data: BorrowerSectionData = {} as BorrowerSectionData;

	loanSvc: LoanService = new LoanService(this.$store);

	dobMenu: boolean = false;

	dobConfirmMenu: boolean = false;

	activePicker:string = 'YEAR';

	dobConfirmActivePicker:string = 'YEAR';

	applicantOptions: Dictionary<string>[] = [
		// Can never have 2 primary borrowers, and we do not allow editing applicantType after
		// the fact, so its safe to removeo this based on existing functionality. -- Karl
		// { value: 'primary', text: 'Primary Borrower' },
		{ value: ApplicantType.CO_BORROWER, text: getApplicantTypeName(ApplicantType.CO_BORROWER) },
		{ value: ApplicantType.CO_SIGNER, text: getApplicantTypeName(ApplicantType.CO_SIGNER) },
		{ value: ApplicantType.GUARANTOR, text: getApplicantTypeName(ApplicantType.GUARANTOR) },
	];

	stateOptions: DropdownOption[] = statesUS;

	@Validations()
	// eslint-disable-next-line class-methods-use-this
	validations(): RuleDecl {
		const data = {
			firstName: RequiredValidation.validations(),
			lastName: RequiredValidation.validations(),
			// used for resetting not required form fields
			notRequiredFields: {},
			ssn: {
				...RequiredValidation.validations(),
				ssn(value: string) {
					const RE = /^\d{3}\d{2}\d{4}$/;
					return RE.test(value);
				},
			},
			dateOfBirth: {
				...RequiredValidation.validations(),
				isoDate(value: string) {
					const RE = /^\d{4}-\d{2}-\d{2}$/;

					return RE.test(value);
				},
			},
			applicantType: RequiredValidation.validations(),
			email: RequiredValidation.validations(),
			creditScore: {
				numeric,
				maxLength: maxLength(3),
				minLength: minLength(3),
			},
			streetAddress: RequiredValidation.validations(),
			city: RequiredValidation.validations(),
			state: {
				required: requireOneOf(this.stateOptions.flatMap((element) => element.value).join(',')),
			},
			zip: {
				required,
				numeric,
				maxLength: maxLength(5),
				minLength: minLength(5),
			},
			homePhone: {
				required: requireOneOf('homePhone', 'mobilePhone', 'workPhone'),
				phone: this.phoneValidator,
			},
			mobilePhone: {
				required: requireOneOf('homePhone', 'mobilePhone', 'workPhone'),
				phone: this.phoneValidator,
			},
			workPhone: {
				required: requireOneOf('homePhone', 'mobilePhone', 'workPhone'),
				phone: this.phoneValidator,
			},
		};

		return {
			data,
		};
	}

	phoneValidator(value: string): boolean {
		if (value) {
			return this.format.asPhone(value) !== '';
		}

		return true;
	}

	@Watch('borrower', { immediate: true, deep: true })
	onBorrowerChange(value: BorrowerSectionData): void {
		if (value && value.dateOfBirth && value.dateOfBirth.length > 10) {
			value.dateOfBirth = this.format.asDate(value.dateOfBirth, this.format.ISO_DATE);
		}

		if (value && value.dateOfBirthConfirm && value.dateOfBirthConfirm.length > 10) {
			value.dateOfBirthConfirm = this.format.asDate(value.dateOfBirthConfirm, this.format.ISO_DATE);
		}

		this.data = { ...value };
	}

	@Watch('data', { immediate: true, deep: true })
	onInput(value: BorrowerSectionData): void {
		this.onDataChange(value, this.index);
	}

	@Watch('dobMenu')
	onDobPickerOpen(value: boolean): void {
		if (value) {
			setTimeout(() => {
				this.activePicker = 'YEAR';
			});
		}
	}

	@Watch('dobConfirmMenu')
	onDobConfirmPickerOpen(value: boolean): void {
		if (value) {
			setTimeout(() => {
				this.dobConfirmActivePicker = 'YEAR';
			});
		}
	}

	required = [(value: string): boolean | string => !!value || 'This field is required.'];

	get dobMaxDate(): string {
		const today = moment().startOf('day');

		if (this.borrower.applicantType === ApplicantType.PRIMARY) {
			return this.format.asDate(today.subtract(18, 'year'), this.format.ISO_DATE);
		}
		return this.format.asDate(today, this.format.ISO_DATE);
	}

	get dobMinDate(): string {
		return this.format.asDate(moment().subtract(100, 'year').startOf('day'), this.format.ISO_DATE);
	}

	canEdit: boolean = true;

	get borrowerType(): string {
		return getApplicantTypeName(this.data.applicantType);
	}

	get firstNameErrorMessages(): string[] {
		return RequiredValidation.errors(this, 'data.firstName', 'First Name');
	}

	get lastNameErrorMessages(): string[] {
		return RequiredValidation.errors(this, 'data.lastName', 'Last Name');
	}

	get ssnErrorMessages(): string[] {
		const field = 'Social Security Number';
		const errors = RequiredValidation.errors(this, 'data.ssn', field);

		const validationObject = this.$v.data.ssn;

		if (validationObject) {
			if (!validationObject.$dirty) {
				return [];
			}

			if (!validationObject.ssn) {
				errors.push(`${field} must be 9 digits.`);
			}
		}

		return errors;
	}

	get ssnConfirmErrorMessages(): string[] {
		const errors = [];
		if (this.data.ssn !== this.data.ssnConfirm?.replace(/[\D]+/ig, '')) {
			errors.push('Please confirm the SSN input.');
		}
		return errors;
	}

	get dobErrorMessages(): string[] {
		if (this.$v && this.$v.data && !this.$v.data?.dateOfBirth?.isoDate) {
			return ['Invalid Date.'];
		}
		return RequiredValidation.errors(this, 'data.dateOfBirth', 'Date of Birth');
	}

	get emailErrorMessages(): string[] {
		return RequiredValidation.errors(this, 'data.email', 'Email Address');
	}

	get homePhoneErrorMessages(): string[] {
		const errors = RequiredValidation.errors(this, 'data.homePhone', 'Home Phone');

		if (errors.length) {
			errors[0] = 'At least one phone number is required.';
		}

		if (this.$v && this.$v.data) {
			if (!this.$v.data.homePhone?.phone) {
				errors.push('Phone number must be a 10 digit phone number.');
			}
		}

		return errors;
	}

	get mobilePhoneErrorMessages(): string[] {
		const errors = RequiredValidation.errors(this, 'data.mobilePhone', 'Mobile Phone');

		if (errors.length) {
			errors[0] = 'At least one phone number is required.';
		}

		if (this.$v && this.$v.data) {
			if (!this.$v.data.mobilePhone?.phone) {
				errors.push('Phone number must be a 10 digit phone number.');
			}
		}

		return errors;
	}

	get workPhoneErrorMessages(): string[] {
		const errors = RequiredValidation.errors(this, 'data.workPhone', 'Work Phone');

		if (errors.length) {
			errors[0] = 'At least one phone number is required.';
		}

		if (this.$v && this.$v.data) {
			if (!this.$v.data.workPhone?.phone) {
				errors.push('Phone number must be a 10 digit phone number.');
			}
		}

		return errors;
	}

	zipRules = [(value: string): boolean | string => /\d+/.test(value) || 'Zip Code must be a number.'];

	get creditScoreErrorMessages(): string[] {
		if (this.$v?.data?.creditScore && !this.$v.data.creditScore.numeric) {
			return ['Credit Score must be a number.'];
		}

		if (!this.$v?.data?.creditScore?.maxLength || !this.$v?.data?.creditScore?.minLength) {
			return [`Credit Score should be a ${this.$v.data?.creditScore?.$params.maxLength.max} digits number.`];
		}
		return [];
	}

	get zipErrorMessages(): string[] {
		if (this.$v?.data?.zip && !this.$v.data.zip.numeric) {
			return ['Zip Code must be a number.'];
		}

		if (!this.$v?.data?.zip?.maxLength || !this.$v?.data?.zip?.minLength) {
			return [`Zip Code should be a ${this.$v.data?.zip?.$params.maxLength.max} digits number.`];
		}
		return RequiredValidation.errors(this, 'data.zip', 'Zip');
	}

	get dobConfirmMessages(): string[] {
		const messages = [];
		if (this.data.dateOfBirth !== this.data.dateOfBirthConfirm) {
			messages.push('Please confirm the Date of Birth input.');
		}
		return messages;
	}

	get phoneRules(): any {
		return (!this.data.homePhone && !this.data.mobilePhone && !this.data.workPhone)
			? [KValidate.required('At least one phone number is required.')]
			: [];
	}

	formattedDob(value: string = ''): string {
		return value ? this.format.asDate(value) : '';
	}

	// eslint-disable-next-line class-methods-use-this
	onKeypressSsn($event: unknown): boolean {
		const { value } = ($event as any).target;
		const { key } = ($event as any);

		if (value.length >= 11 || !isDigit(key)) {
			($event as any).preventDefault();
			return false;
		}

		return true;
	}

	onInputSsn(value: string): void {
		if (this.$v && this.$v.data && this.$v.data.ssn) {
			this.data.ssn = value.replace(/-/g, '');
			this.$v.data.ssn.$touch();
		}
	}

	// eslint-disable-next-line class-methods-use-this
	onPhoneKeyPress($event: unknown): boolean {
		if (($event as any).target.value.length >= 14) {
			($event as any).preventDefault();
			return false;
		}

		return true;
	}

	onPhoneInput(key: string, value: string): void {
		if (this.$v && this.$v.data) {
			this.$set(this.data, key, value);
			this.$v.data.homePhone?.$touch();
			this.$v.data.mobilePhone?.$touch();
			this.$v.data.workPhone?.$touch();
		}
	}

	onDatePickedChange(date:string): void {
		this.dobMenu = !this.dobMenu;
		this.data.dateOfBirth = date;
		this?.$v?.data?.dateOfBirth?.$touch();
	}

	onDateConfirmPickedChange(date:string): void {
		this.dobConfirmMenu = !this.dobConfirmMenu;
		this.data.dateOfBirthConfirm = date;
		this?.$v?.data?.dateOfBirthConfirm?.$touch();
	}

	onPhoneBlur(): void {
		if (this.$v && this.$v.data) {
			this.$v.data.homePhone?.$touch();
			this.$v.data.mobilePhone?.$touch();
			this.$v.data.workPhone?.$touch();
		}
	}
}
