
import {
	Component, Inject, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { Formatters } from '@/utils/formatters';
import { AuditLog } from '@/services/types/AuditLog';

@Component
export default class AuditLogTable extends Vue {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	items!: AuditLog[];

	@Prop({ required: true })
	clientId!: string;

	@Prop({ required: true })
	total!: Number;

	@Prop({ default: '' })
	search!: string;

	@Prop({ default: false })
	loading!: boolean;

	currentPage = 1;

	pageSize = 10;

	totalPages: Number = 0;

	sortDesc: boolean[] = [];

	loadingText: string = 'Loading Audit Logs...';

	headers: DataTableHeader[] = [
		{
			text: 'Action',
			value: 'message',
			width: '60%',
		},
		{
			text: 'IP Address',
			value: 'ip',
			width: '20%',
		},
		{
			text: 'When',
			value: 'time',
			width: '20%',
		},
	];

	handlePageChange(value: any) {
		this.currentPage = value;
		this.$emit('new-page-request', this.currentPage);
	}

	@Watch('total', { immediate: true })
	onModeChanged(): void {
		this.totalPages = Math.ceil(Number(this.total) / this.pageSize) - 1;
	}
}
