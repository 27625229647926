
import KPage from '@/components/KPage.vue';
import { Component, Inject } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import BaseLoanPage from '@/pages/loan/BaseLoanPage';
import { Document } from '@/services/types/Document';
import { Formatters } from '@/utils/formatters';
import { NoticeClass } from '@kasasa/fbase-components/lib';
import { handleErrorResponse } from '@/utils/xhr';
import moment from 'moment';
import { VKLOANS } from '@/pages/PermissionGroups';
import DocumentsTable from '@/components/DocumentsTable.vue';

@Component({
	components: {
		KPage,
		KReadOnly,
		DocumentsTable,
	},
})
export default class LoanStatementsPage extends BaseLoanPage {
	@Inject('format')
	format!: Formatters;

	documentsLoading: boolean = false;

	filterOptions: string[] = [];

	filterYear: string = '';

	onFilterChanged(value: string): void {
		if (value) {
			// As of right now, back-end is not filtering the documents results by year, therefore, we
			// don't need to call the getStatements method here. If things change in the future, we can
			// add this here and remove the filteredStatements getter / computed property.
			this.filterYear = value;
		}
	}

	onDocumentError(error: unknown): void {
		this.showAlert((error as any).response.data.error.message, NoticeClass.ERROR, 5000);
	}

	async created(): Promise<void> {
		await this.getConsumer();

		const { loan } = this;
		if (loan) {
			const startYear: number = Number(this.format.asDate(loan.terms.firstPaymentDate, 'YYYY'));
			const endYear: number = moment().year();
			const currentYear = Number(this.format.asDate(new Date(), 'YYYY'));
			const options: string[] = [];
			let year: number = startYear;

			while (year <= endYear) {
				if (year > currentYear) {
					break;
				}
				options.push(year.toString());
				year++;
			}
			this.filterOptions = options.reverse();
			this.filterYear = Math.min(currentYear, endYear).toString();
		}

		this.loading = false;

		await this.getStatements();
	}

	statements: Document[] = [];

	async getStatements(): Promise<void> {
		this.documentsLoading = true;
		this.statements = [];

		try {
			const response = await this.loanSvc.getDocuments(
				this.consumerId,
				this.loanId,
				this.filterYear,
				'statement',
			);

			this.statements = response.data.data;
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.documentsLoading = false;
		}
	}

	get canView(): boolean {
		return this.canRead(VKLOANS);
	}

	get filteredStatements(): Document[] {
		return this.statements.filter((statement: Document) =>
			moment(statement.datePosted).year().toString() === this.filterYear);
	}
}
