
import {
	Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import {
	Transaction,
	TransactionMethod,
	TransactionStatus,
	ExtendedTransaction,
	TransactionType,
} from '@/services/types/loan/LoanTransaction';
import { KReadOnly, KSpinner } from '@kasasa/fbase-components';
import KCard from '@/components/KCard.vue';
import { ExtendedBankAccount } from '@/services/types/BankAccount';
import LoanService from '@/services/LoanService';

@Component({
	components: {
		KCard,
		KReadOnly,
		KSpinner,
	},
	enums: {
		TransactionMethod,
		TransactionStatus,
		TransactionType,
	},
})
export default class RelatedTransactionCard extends Vue {
	@Inject('format')
	format!: object;

	@Prop({ required: true })
	bankAccounts!: ExtendedBankAccount[];

	@Prop({ required: true })
	data!: Transaction;

	@Prop({ required: true })
	consumerId!: string;

	relatedData: ExtendedTransaction | null = null;

	loanSvc: LoanService = new LoanService(this.$store);

	mounted(): void {
		this.getRelatedTransaction(this.consumerId, this.data.loanId, this.data.sourceSystemTransactionId);
	}

	async getRelatedTransaction(consumerId:string = '',
		loanId: string = '', sourceSystemTransactionId: string = ''): Promise<ExtendedTransaction | null> {
		const response = await this.loanSvc.getTransaction(
			consumerId, loanId, sourceSystemTransactionId,
		);
		this.relatedData = response.data.data;

		return this.relatedData;
	}

	get paymentMethod(): string {
		return this.relatedData?.checkNumber ? this.relatedData?.checkNumber : this.paymentAccountName;
	}

	get paymentMethodLabel(): string {
		return this.relatedData?.checkNumber ? 'Check Number' : 'Payment Account';
	}

	get paymentAccountName(): string {
		if (this.relatedData && this.relatedData.paymentAccountId && this.bankAccounts.length) {
			const account = this.bankAccounts.find(
				(acct) => acct.id === this.relatedData?.paymentAccountId,
			) as ExtendedBankAccount;

			if (account) {
				return `${account.institution.name} - ${account.name} (*${account.accountNumberLastFour})`;
			}
		}

		return '';
	}
}
