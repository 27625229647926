
import KCard from '@/components/KCard.vue';
import {
	Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import { KReadOnly, KText } from '@kasasa/fbase-components';
import { General } from '@/services/types/loan/Collection';

@Component({
	components: {
		KCard,
		KReadOnly,
		KText,
	},
})
export default class CollectionGeneralCard extends Vue {
	@Prop({ required: true })
	data!: General;

	@Inject('format')
	format!: object;
}
