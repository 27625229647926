import { ApplicantType } from '@/services/types/loan/Borrower';

export interface LoanApplicant {
	applicantId: string;
	applicantName: string;
	applicantType: ApplicantType;
}

// inactive bankruptcies by type.
// chapter 7: discharged, dismissed, withdrawn, reaffirmation of debt, closed/terminated
// chapter 13: dismissed, withdrawn, closed/terminated
export enum BankruptcyStatus {
	'petitioned' = 'Petitioned', // 77 1313
	'debt_discharged' = 'Debt Discharged', // 7 - 1313
	'request_dismissed' = 'Request Dismissed', // 7 13
	'request_withdrawn' = 'Request Withdrawn', // 7 13
	'reaffirmation_of_debt' = 'Reaffirmation Of Debt', // 7 - 1313
	'reaffirmation_of_debt_rescinded' = 'Reaffirmation Of Debt Rescinded', // 77 1313
	'closed_terminated' = 'Bankruptcy Closed/Terminated', // 7 13
}

export interface StatusHistory {
	status: BankruptcyStatus;
	effectiveAt: string;
}

export enum BankruptcyType {
	'Chapter 7' = 7,
	'Chapter 13' = 13,
}

export interface Bankruptcy {
	id?: number;
	fiId?: string | number;
	loanId?: string;
	loanType?: number;
	type?: BankruptcyType | '';
	status: BankruptcyStatus | '';
	serviceOnCore?: boolean | null;
	consumerAccountNumber?: string | null;
	note?: string;
	submittedAt?: string;
	reportedAt?: any;
	loanApplicants?: LoanApplicant[];
	statusHistory?: StatusHistory[];
}

export const activeStatuses = {
	7: [
		'petitioned',
		'reaffirmation_of_debt_rescinded',
	],
	13: [
		'petitioned',
		'debt_discharged',
		'reaffirmation_of_debt',
		'reaffirmation_of_debt_rescinded',
	],
};

export const isActiveBankruptcy = (bankruptcy: Bankruptcy): boolean => {
	const { type, status } = bankruptcy;

	if (type && status) {
		return activeStatuses[type].includes(status);
	}

	return false;
};
