import { render, staticRenderFns } from "./TransactionAllocationsCard.vue?vue&type=template&id=0f051e29&scoped=true&"
import script from "./TransactionAllocationsCard.vue?vue&type=script&lang=ts&"
export * from "./TransactionAllocationsCard.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0f051e29",
  null
  
)

export default component.exports