
import {
	Component, Inject, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { BankAccountPayload } from '@/services/types/BankAccount';
import { Validations } from 'vuelidate-property-decorators';
import { RequiredValidation } from '@kasasa/kloans-common-ui/dist/validations';
import { helpers } from 'vuelidate/lib/validators';
import { KText } from '@kasasa/fbase-components';
import { RuleDecl } from 'vue/types/options.d';
import { Formatters } from '@/utils/formatters';

@Component({
	components: {
		KText,
	},
})
export default class BankAccountForm extends Vue {
	@Inject('format')
	format!: Formatters;

	@Prop({ default: false })
	readonly!: boolean;

	@Prop({ required: true })
	data!: BankAccountPayload;

	@Prop({ default: false })
	loading!: boolean;

	@Validations()
	// eslint-disable-next-line class-methods-use-this
	validations(): RuleDecl {
		const alphaAndSpace = helpers.regex('alphaAndSpace', /^[a-z ]*$/i);

		const data = {
			holderName: { ...RequiredValidation.validations(), alphaAndSpace },
			name: { ...RequiredValidation.validations(), alphaAndSpace },
			accountType: RequiredValidation.validations(),
			accountNumber: RequiredValidation.validations(),
		};

		return { data };
	}

	@Watch('$v.$invalid', { immediate: true })
	onInvalidChanged(val: boolean): void {
		this.$emit('invalid-changed', val);
	}

	get holderNameErrors(): string[] {
		if (this.$v?.data?.holderName && !this.$v.data.holderName.alphaAndSpace) {
			return ['Please use characters A-Z and a-z only.'];
		}

		return RequiredValidation.errors(this, 'data.holderName', 'Account Holder');
	}

	get nameErrors(): string[] {
		if (this.$v?.data?.name && !this.$v.data.name.alphaAndSpace) {
			return ['Please use characters A-Z and a-z only.'];
		}
		return RequiredValidation.errors(this, 'data.name', 'Account Name');
	}

	get accountTypeErrors(): string[] {
		return RequiredValidation.errors(this, 'data.accountType', 'Account Type');
	}

	get accountNumberErrors(): string[] {
		return RequiredValidation.errors(this, 'data.accountNumber', 'Account Number');
	}
}
