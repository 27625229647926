export interface Comment {
    id: string,
    loanId: string,
    referenceType:string,
    referenceId: string,
    submittedBy: string,
    submittedById: string,
    lastEditedBy: string | null,
    lastEditedById: string | null,
    body: string,
    metadata: [],
    submittedDate: string,
    lastEditedDate?: string | null,
    updatedAt?: string | null,
    deletedAt?: string | null,

}

export interface CommentPayload {
	userId?: string;
	user?: string;
	body: string;
    loanId: string;
    metadata?: object;
    referenceType: string;
    referenceId?: string;
    id?: string
}

export enum CommentModalModes {
	ADD = 1,
	EDIT = 2,
	DELETE = 3,
}

export enum CommentReferenceTypes {

    LOAN = 'loan',
    TRANSACTIONS = 'transaction',
    LATE_FEE = 'lateFee',
    NSF_FEE = 'nsfFee',
    COLLECTION = 'collection'
}
