
import KCard from '@/components/KCard.vue';
import {
	Component, Emit, Inject, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { KReadOnly, KText } from '@kasasa/fbase-components';
import { ConsumerProfile, ConsumerService } from '@/services/ConsumerService';
import { namespace } from 'vuex-class';
import { HeaderData } from '@kasasa/fbase-components/lib';
import App from '@kasasa/fbase-components/src/services/Auth/types/App';
import { Validations } from 'vuelidate-property-decorators';
import { emailValidation, TenDigitPhoneValidation } from '@kasasa/kloans-common-ui/dist/validations';
import { RuleDecl } from 'vue/types/options.d';
import { Formatters } from '@/utils/formatters';

const auth = namespace('auth');

@Component({
	components: {
		KCard,
		KReadOnly,
		KText,
	},
})
export default class AccountDetailsCard extends Vue {
	@Prop({ required: true })
	profile!: ConsumerProfile;

	@Prop({ default: false })
	canWrite!: boolean;

	@Prop({ required: true })
	loadingResend!: boolean;

	@Prop({ required: true })
	loadingEmail!: boolean;

	@Prop({ default: false })
	canEditProfile!: boolean;

	@auth.State('headerData')
	headerData!: HeaderData;

	@Inject('format')
	format!: Formatters;

	editEmail: boolean = false;

	loading: boolean = false;

	profileAdminApp: App = {} as App;

	cachedEmail: string = this.profile.email;

	protected consumerSvc: ConsumerService = new ConsumerService(this.$store);

	get userProfileLink(): string {
		return `${this.profileAdminApp?.url}/#/detail/${this.profile?.email}`;
	}

	get createdAtValue(): string {
		return this.profile.createdAt	? this.format.asDate(this.profile.createdAt, 'MMM D, YYYY h:mm a') : '';
	}

	get editEmailAllowed(): boolean {
		return this.profile.status === 'Unregistered' && !this.editEmail && this.canEditProfile;
	}

	@Emit('resend-invitation')
	// eslint-disable-next-line class-methods-use-this
	resendInvidation(): void {
		// resend event.
	}

	get canEdit(): boolean {
		return this.profile.status !== 'active' && this.canWrite;
	}

	created(): void {
		this.profileAdminApp = this.headerData.appManager.find((app) => app.slug === 'kcas-profile-admin') as App;
	}

	cancelEdit(): void {
		this.editEmail = !this.editEmail;
		this.profile.email = this.cachedEmail;
	}

	async submitEmail(): Promise<void> {
		this.loading = true;
		try {
			await this.consumerSvc.updateConsumerEmail(this.profile.id, { email: this.profile.email });
			this.cachedEmail = this.profile.email;
		} catch (error) {
			// do nothing
		} finally {
			this.editEmail = !this.editEmail;
			this.loading = false;
		}
	}

	@Validations()
	// eslint-disable-next-line class-methods-use-this
	validations(): RuleDecl {
		const profile = {
			email: emailValidation.validations,
		};

		return { profile };
	}

	get emailErrors(): string[] {
		return emailValidation.errors(this, 'profile.email', 'E-mail');
	}

	get mobilePhoneErrors(): string[] {
		return TenDigitPhoneValidation.errors(this, 'profile.phoneNumber', 'Mobile phone');
	}

	// eslint-disable-next-line class-methods-use-this
	onEmailInput(event: unknown): void {
		const valObj = this.$v.profile.email;
		if (valObj) {
			valObj.$touch();

			if (valObj.$dirty && !valObj.$invalid && ((event as any).code === 'Tab' || (event as any).code === 'Enter')) {
				this.$emit('email-input', (event as any).target.value);
			}
		}
	}

	@Watch('loadingEmail')
	onLoadingEmail(value: boolean): void {
		if (value === false && this.emailErrors.length === 0) {
			this.editEmail = false;
		}
	}
}
