
import { Component, Inject, Prop } from 'vue-property-decorator';

import { DataTableHeader } from 'vuetify';
import { Formatters } from '@/utils/formatters';
import VueJsonToCsv from 'vue-json-to-csv';
import {
	CommentReferenceTypes,
	Comment,
	CommentModalModes,
	CommentPayload,
} from '@/services/types/loan/Comment';
import CommentsModal from '@/components/loan/comments/CommentsModal.vue';
import { handleErrorResponse } from '@/utils/xhr';
import CommentsService from '@/services/CommentsService';
import { NoticeClass } from '@kasasa/fbase-components/lib';
import BaseComponent from '@/components/BaseComponent';
import { KLOANS_NOTES } from '@/pages/PermissionGroups';

@Component({
	enums: { CommentReferenceTypes, CommentModalModes },
	components: {
		VueJsonToCsv,
		CommentsModal,
	},
})
export default class CommentsTable extends BaseComponent {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	items!: Comment[];

	@Prop({ required: true })
	fiId!: string;

	@Prop({ required: true })
	loanId!: string;

	@Prop()
	referenceId!: string;

	loading: boolean = false;

	modalWait: boolean = false;

		@Prop({ default: true })
	showFilter!: boolean;

	sortDesc: boolean[] = [];

	commentsSvc: CommentsService = new CommentsService(this.$store);

	itemLabels = {
		referenceType: { title: 'Comment Type' },
		submittedDate: { title: 'Created At' },
		body: { title: 'Comment' },
		submittedBy: { title: 'User' },
		lastEditedBy: { title: 'Last Edited By' },
		lastEditedDate: { title: 'Last Edited Date' },
		loanId: { title: 'Loan ID' },
	};

	showModal: boolean = false;

	modalMode = '';

	commentEditBody: string = '';

	commentId: string = '';

	editCommentObj: Comment = {
		id: '',
		loanId: '',
		body: '',
		metadata: [],
		referenceType: '',
		referenceId: '',
		submittedBy: '',
		submittedById: '',
		lastEditedBy: '',
		lastEditedById: '',
		submittedDate: '',
	};

	private beginDateMenu: boolean = false;

	private beginDate: string = '';

	private endDateMenu: boolean = false;

	private endDate: string = '';

	formattedDate(value: string | null): string {
		return value ? this.format.asDate(value) : '';
	}

	getComments() {
		this.$emit('update-items', this.beginDate, this.endDate);
	}

	showModalEdit(item: Comment) {
		this.editCommentObj = item;
		this.modalMode = this.$enums.CommentModalModes.EDIT;
		this.showModal = true;
		this.commentEditBody = item.body;
	}

	showModalDelete(item: Comment) {
		this.modalMode = this.$enums.CommentModalModes.DELETE;
		this.commentId = item.id;
		this.showModal = true;
	}

	closeModal(): void {
		this.commentEditBody = '';
		this.showModal = false;
	}

	emitKeyup(commentBody: string) {
		this.$emit('comment-change', commentBody);
	}

	get canModifyComments():boolean {
		return this.canWrite(KLOANS_NOTES);
	}

	async deleteComment(commentId: string) {
		try {
			this.modalWait = true;
			await this.commentsSvc.deleteComment(this.loanId, commentId);
			const position = this.items.findIndex(
				(comment) => comment.id === commentId,
			);
			this.items.splice(position, 1);
			this.showAlert(
				'Comment deleted successfully.',
				NoticeClass.SUCCESS,
				5000,
			);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		}
		this.modalWait = false;
		this.showModal = false;
	}

	get fullName(): string {
		return `${this.sessionData.firstName} ${this.sessionData.lastName}`.trim();
	}

	async editComment(commentBody: string) {
		const data: CommentPayload = {
			userId: `${this.sessionData.id}`,
			user: this.fullName,
			body: commentBody,
			id: this.editCommentObj.id,
			loanId: this.editCommentObj.loanId,
			referenceType: this.editCommentObj.referenceType,
		};

		if (this.referenceId) {
			data.referenceId = this.referenceId;
		}

		try {
			this.modalWait = true;
			await this.commentsSvc.putComment(this.loanId, data);
			this.showAlert('Comment edited successfully.', NoticeClass.SUCCESS, 5000);
			this.getComments();
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		}
		this.editCommentObj = {
			id: '',
			loanId: '',
			body: '',
			metadata: [],
			referenceType: '',
			referenceId: '',
			submittedBy: '',
			submittedById: '',
			lastEditedBy: '',
			lastEditedById: '',
			submittedDate: '',
		};
		this.modalWait = false;
		this.showModal = false;
	}

	headers: DataTableHeader[] = [
		{
			text: 'Comment Type',
			value: 'referenceType',
		},

		{
			text: 'Comment',
			value: 'body',
		},
		{
			text: 'Created At',
			value: 'submittedDate',
		},

		{
			text: 'User',
			value: 'submittedBy',
		},
		{
			text: 'Actions',
			value: 'actions',
		},
	];
}
