
import {
	Component, Inject, Prop, Watch,
} from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent';
import { Formatters } from '@/utils/formatters';
import { CommentModalModes } from '@/services/types/loan/Comment';
import { KText } from '@kasasa/fbase-components';

@Component({
	name: 'comments-modal',

	enums: {
		KText,
		CommentModalModes,
	},
})
export default class CommentsModal extends BaseComponent {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	show!: boolean;

	@Prop({ required: true })
	mode!: Number;

	@Prop({ required: true })
	fiId!: string;

	@Prop({ required: true })
	loanId!: string;

	@Prop()
	commentId!: string;

	@Prop()
	items!: Comment[];

	@Prop({ default: false })
	loading!: boolean;

	@Prop()
	editBody!: string;

	commentBody: string = '';

	dismiss(): void {
		this.commentBody = '';
		this.$emit('modal-closed');
	}

	get disableSave(): boolean {
		return !this.commentBody || this.commentBody.length > 500 || this.loading;
	}

	save(): void {
		if (this.mode === CommentModalModes.ADD) {
			this.$emit('save-comment', this.commentBody);
		} else {
			this.$emit('edit-comment', this.commentBody);
		}
	}

	deleteComment(): void {
		this.$emit('delete-comment', this.commentId);
	}

	updateComment(comment: string) {
		this.commentBody = comment;
	}

	emitKeyup(commentBody: string) {
		this.$emit('comment-change', commentBody);
	}

	@Watch('editBody', { immediate: true })
	onModeChanged(value: string): void {
		this.commentBody = value;
	}

	@Watch('loading', { immediate: true })
	onLoading(value: boolean): void {
		this.loading = value;
	}
}
