import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelope } from '@kasasa/fbase-components/lib';

export interface SftpConnection {
	host: string | null;
	username: string | null;
	password: string | null;
	passwordConfirm?: string | null;
}

export enum DateFileFormat {
	'YYYY-MM-DD' = 'YYYY-MM-DD',
    YYYYMMDD = 'YYYYMMDD',
    'MM-DD-YYYY' = 'MM-DD-YYYY',
    MMDDYYYY = 'MMDDYYYY',
}

export enum PostingDateOffset {
	TODAY = 0,
	TOMORROW = 1,
}

export enum FileDeliveryMethodType {
	FIT = 'FIT',
	SFTP = 'SFTP',
	MANUAL = 'MANUAL',
}

export enum TransformationService {
	POSTING_SERVICE = 'posting_service',
    FUNDING_ADMIN = 'funding_admin',
}
export interface PostingConfig {
	fiId: number | string;
	fileDeliveryMethod: FileDeliveryMethodType;
	sftpConnection: SftpConnection;
	dateFileFormat?: DateFileFormat;
	postingDateOffset: PostingDateOffset;
	excludeFriday: boolean;
	transformationService: TransformationService;
}

export class SettingsService extends FBaseService {
	serverErrorHandler?: () => Promise<void>;

	hanldeServerError(): Promise<void> {
		if (this.serverErrorHandler) {
			return this.serverErrorHandler();
		}
		return super.hanldeServerError();
	}

	setServerErrorHandler(handler: () => Promise<void>): void {
		this.serverErrorHandler = handler;
	}

	getPostingConfig(clientId: string):
	Promise<AxiosResponse<ResponseEnvelope<PostingConfig>>> {
		return this.apiClient.get(`/api/v1/kloans-fi/fi/${clientId}/posting-config`);
	}

	postPostingConfig(clientId: string, config: PostingConfig):
	Promise<AxiosResponse<ResponseEnvelope<PostingConfig>>> {
		return this.apiClient.post(`/api/v1/kloans-fi/fi/${clientId}/posting-config`, config);
	}
}
