
import {
	Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import {
	Transaction, TransactionMethod, TransactionStatus, TransactionType,
} from '@/services/types/loan/LoanTransaction';
import { KReadOnly } from '@kasasa/fbase-components';
import KCard from '@/components/KCard.vue';

@Component({
	components: {
		KCard,
		KReadOnly,
	},
	enums: {
		TransactionMethod,
		TransactionStatus,
		TransactionType,
	},
})
export default class TransactionAllocationsCard extends Vue {
	@Inject('format')
	format!: object;

	@Prop({ required: true })
	data!: Transaction;
}
