import {
	Module, Mutation, VuexModule,
} from 'vuex-module-decorators';
import { LoanMaintenance, Status } from '@/services/types/loan/Maintenance';
import { LoanStatus } from '@/services/types/loan/Loan';

export interface LoanMaintenanceState {
	loanMaintenanceStatus: Status;
	loanMaintenance: LoanMaintenance
  }

@Module({
	namespaced: true,
})
export default class LoanMaintenanceModule extends VuexModule {
	private loanMaintenanceStatus: Status | null = null;

	private loanMaintenance: LoanMaintenance | null = null;

	@Mutation
	setLoanMaintenanceStatus(status: Status): void {
		this.loanMaintenanceStatus = status;
	}

	@Mutation
	setLoanMaintenance(maintenance: LoanMaintenance): void {
		this.loanMaintenance = maintenance;
	}

	@Mutation
	resetMaintenanceStoreData(): void {
		this.loanMaintenance = null;
		this.loanMaintenanceStatus = null;
	}

	get getLoanMaintenanceStatus(): Status | null {
		return this.loanMaintenanceStatus;
	}

	get getLoanMaintenance(): LoanMaintenance | null {
		return this.loanMaintenance;
	}

	get isLoanClosed(): boolean {
		return [
			LoanStatus.CLOSED,
			LoanStatus.PAID,
		].includes(this.loanMaintenanceStatus?.status as LoanStatus);
	}
}
