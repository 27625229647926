
import {
	Component, Inject, Prop,
} from 'vue-property-decorator';
import BaseComponent from '@/components/BaseComponent';
import {
	LoanModification, LoanModificationKeys,
} from '@/services/types/loan/Maintenance';
import { Formatters } from '@/utils/formatters';

export enum ProposedLoanModificationType {
	DUE_DATE = 1,
	TERMS = 2,
	INSURANCE = 3,
	SCRA = 4,
	SKIP_A_PAY = 5,
}

/**
 * Use this to receive data form previous mod components (data not available in back-end mod object)
 */
export interface ProposedModAddon {
	name: string;
	statusBefore: string;
	statusAfter: string;
}

interface LoanModRowDetail {
	key: LoanModificationKeys;
	label: string;
	formatter?: string;
}

@Component({
	enums: {
		ProposedLoanModificationType,
	},
})
export default class ProposedModificationCard extends BaseComponent {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	data!: LoanModification;

	@Prop({ required: true })
	loanModType!: ProposedLoanModificationType;

	@Prop({ default: () => [] })
	proposedModAddon!: ProposedModAddon[];

	hasChanged(key: LoanModificationKeys): boolean {
		if (this.data && this.data.loanModResult) {
			const old = this.data.loanModResult.old[key];
			const current = this.data.loanModResult.new[key];
			return old !== current;
		}

		return false;
	}

	get rows(): LoanModRowDetail[] {
		switch (this.loanModType) {
			case ProposedLoanModificationType.INSURANCE:
				return this.insuranceModRows;
			case ProposedLoanModificationType.DUE_DATE:
				return this.dueDateModRows;
			case ProposedLoanModificationType.SCRA:
				return this.scraModRows;
			case ProposedLoanModificationType.TERMS:
				return this.termsModRows;
			case ProposedLoanModificationType.SKIP_A_PAY:
				return this.skipAPayModRows;
			default:
				return [];
		}
	}

	insuranceModRows: LoanModRowDetail[] = [
		{
			formatter: 'asCurrency',
			key: 'totalAmountFinanced',
			label: 'Total Amount Financed',
		},
		{
			formatter: 'asCurrency',
			key: 'principalBalance',
			label: 'Principal Balance',
		},
		{
			formatter: 'asCurrency',
			key: 'installmentAmount',
			label: 'Installment Amount',
		},
	];

	dueDateModRows: LoanModRowDetail[] = [
		{
			formatter: 'asDate',
			key: 'nextDueDate',
			label: 'Next Payment Due Date',
		},
		{
			formatter: 'asDate',
			key: 'maturityDate',
			label: 'Maturity Date',
		},
		{
			formatter: 'asCurrency',
			key: 'principalBalance',
			label: 'Principal Balance',
		},
		{
			formatter: 'asCurrency',
			key: 'outstandingLateFees',
			label: 'Outstanding Late Fees',
		},
	];

	scraModRows: LoanModRowDetail[] = [
		{
			formatter: 'asCurrency',
			key: 'principalBalance',
			label: 'Principal Balance',
		},
		{
			formatter: 'asCurrency',
			key: 'installmentAmount',
			label: 'Installment Amount',
		},
		{
			formatter: 'asPercentage',
			key: 'interestRate',
			label: 'Interest Rate',
		},
		{
			key: 'term',
			label: 'Term',
		},
		{
			formatter: 'asDate',
			key: 'maturityDate',
			label: 'Maturity Date',
		},
	];

	termsModRows: LoanModRowDetail[] = [
		{
			formatter: 'asCurrency',
			key: 'principalBalance',
			label: 'Principal Balance',
		},
		{
			formatter: 'asCurrency',
			key: 'installmentAmount',
			label: 'Installment Amount',
		},
		{
			formatter: 'asPercentage',
			key: 'interestRate',
			label: 'Interest Rate',
		},
		{
			key: 'term',
			label: 'Term',
		},
		{
			formatter: 'asDate',
			key: 'maturityDate',
			label: 'Maturity Date',
		},
	];

	skipAPayModRows: LoanModRowDetail[] = [
		{
			formatter: 'asDate',
			key: 'nextDueDate',
			label: 'Next Payment Due Date',
		},
		{
			formatter: 'asDate',
			key: 'maturityDate',
			label: 'Maturity Date',
		},
		{
			formatter: 'asCurrency',
			key: 'principalBalance',
			label: 'Principal Balance',
		},
	];
}
