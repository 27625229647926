import Vue from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import vuetify from '@/plugins/vuetify';
import '@/plugins/vuelidate';
import '@/plugins/enums';
import { fiLockDown, appModeCheck } from '@kasasa/fbase-components/lib';
import { VueMaskDirective } from 'v-mask';

Vue.directive('mask', VueMaskDirective);

Vue.config.productionTip = false;

// use following if you want /:clientId lockdown
router.beforeEach(fiLockDown(store));

// use following if you do NOT want /:clientId lockdown
// store.dispatch('auth/initializePortalSession', store);

// assumes fbase-portalapp proxy of /api/session/portalapp-status
router.beforeEach(appModeCheck(store));

const vue = new Vue({
	router,
	store,
	vuetify,
	render: (h) => h(App),
}).$mount('#app');

window.addEventListener('beforeunload', () => {
	vue.$destroy();
});
