
import KCard from '@/components/KCard.vue';
import {
	Component, Prop,
} from 'vue-property-decorator';
import {
	KText,
	KReadOnly,
	KCardFooter,
	KCardFooterBtn,
} from '@kasasa/fbase-components';
import BaseComponent from '@/components/BaseComponent';
import { Asset } from '@/services/types/loan/Collection';

@Component({
	components: {
		KCard,
		KCardFooter,
		KCardFooterBtn,
		KText,
		KReadOnly,
	},
})
export default class CollateralSection extends BaseComponent {
	@Prop()
	collateralAsset!: Asset;

	@Prop({ required: true })
	onDataChange!:(data: Asset)=>void;

	data: Asset = {} as Asset;

	created(): void {
		this.data = this.collateralAsset;
	}

	emitChange(): void {
		this.onDataChange(this.data);
	}
}
