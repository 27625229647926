
import {
	Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import {
	InsuranceCoverage, getInsuranceCoverageTypeName, InsurancePolicy,
} from '@/services/types/loan/Insurance';
import moment from 'moment';
import { Formatters } from '@/utils/formatters';

@Component({
	components: {
		KReadOnly,
	},
})
export default class InsuranceCoverageDisplay extends Vue {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	coverage!: InsuranceCoverage;

	@Prop({ required: true })
	availablePolicies!: InsurancePolicy[];

	get coverageTypeName(): string {
		return getInsuranceCoverageTypeName(this.coverage.coverageType);
	}

	get insurancePolicyForCoverage(): InsurancePolicy | undefined {
		return this.availablePolicies.find(
			(availablePolicy) => availablePolicy.policyNumber === this.coverage.policyNumber,
		);
	}

	get policyName(): string {
		return this.insurancePolicyForCoverage?.name ?? 'N/A';
	}

	get policyProtectedEvents(): string {
		return this.insurancePolicyForCoverage?.protectedEvents ?? '--';
	}

	get coverageIsNotActive(): boolean {
		const isEnded = this.coverage.endDate
			? moment().startOf('day').isSameOrAfter(this.coverage.endDate)
			: false;
		return !!this.coverage.agedOutAt || isEnded;
	}
}
