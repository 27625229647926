
import {
	ConfirmValidation,
	RequiredValidation,
} from '@kasasa/kloans-common-ui/dist/validations';
import {
	Component, Inject, Prop, Watch,
} from 'vue-property-decorator';
import { RuleDecl } from 'vue/types/options.d';
import { Validations } from 'vuelidate-property-decorators';
import moment from 'moment';
import LoanService from '@/services/LoanService';
import { handleErrorResponse } from '@/utils/xhr';
import BaseComponent from '@/components/BaseComponent';
import { NoticeClass } from '@kasasa/fbase-components/lib';
import { KReadOnly } from '@kasasa/fbase-components';
import { Fee, FeeType, WaiveFeePayload } from '@/services/types/loan/Fee';
import { Formatters } from '@/utils/formatters';
import CommentsCard from '@/components/loan/comments/CommentsCard.vue';
import {
	CommentPayload,
	CommentReferenceTypes,
} from '@/services/types/loan/Comment';
import CommentsService from '@/services/CommentsService';

@Component({
	components: {
		KReadOnly,
		CommentsCard,
	},
	enums: {
		FeeType,
	},
})
export default class WaiveFeeModal extends BaseComponent {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	clientId!: string;

	@Prop({ required: true })
	consumerId!: string;

	@Prop({ required: true })
	fiId!: string;

	@Prop({ required: true })
	loanId!: string;

	@Prop({ required: true })
	show!: boolean;

	@Prop({ required: true })
	fee!: Fee;

	feeComment: string = '';

	today: moment.Moment = moment().startOf('day');

	@Validations()
	// eslint-disable-next-line class-methods-use-this
	validations(): RuleDecl {
		const data = {
			amountToWaive: {
				...RequiredValidation.validations(),
				validAmount: () =>
					(Number(this.data.amountToWaive) <= Number(this.fee.feeBalance) && Number(this.data.amountToWaive) > 0)
					|| (Number(this.data.amountToWaive) <= Number(this.fee.paidAmount) && Number(this.data.amountToWaive) > 0),
			},
			amountConfirm: ConfirmValidation.validations('amountToWaive'),
		};

		return { data };
	}

	loading: boolean = false;

	loanSvc: LoanService = new LoanService(this.$store);

	commentsSvc = new CommentsService(this.$store);

	get amountToWaiveErrorMessages(): string[] {
		const errors = RequiredValidation.errors(
			this,
			'data.amountToWaive',
			'Amount',
		);

		if (
			this.$v.data.amountToWaive?.$dirty
			&& !this.$v.data?.amountToWaive?.validAmount
		) {
			errors.push('Amount cannot exceed Remaining Fee Balance and must be greater than zero.');
		}

		return errors;
	}

	get amountConfirmErrorMessages(): string[] {
		const errors = ConfirmValidation.errors(
			this,
			'data.amountConfirm',
			'amountToWaive',
		);

		errors.forEach((error: string, idx: number) => {
			if (error === 'Field must equal to AmountToWaive field.') {
				errors[idx] = 'Field must be equal to Amount field.';
			}
		});

		return errors;
	}

	data: WaiveFeePayload = {
		amountToWaive: '',
		currentFeeBalance: '',
		feeId: '',
	};

	@Watch('show', { immediate: true })
	onShowChanged(value: boolean): void {
		if (value === true) {
			this.data = {
				amountToWaive: this.fee.feeBalance,
				amountConfirm: '',
				currentFeeBalance: this.fee.feeBalance,
				feeId: this.fee.id,
			};

			// if no fee balance we must be waiving a paid fee
			if (this.fee.feeBalance === '0.00') {
				this.data.amountToWaive = this.fee.paidAmount;
			}
		}
	}

	dismiss(): void {
		this.feeComment = '';
		this.$v.$reset();
		this.$emit('modal-closed');
	}

	// We need to convert transactionMethod to proper internal/external value if LinkedAccount is selected.
	get waiveData(): WaiveFeePayload {
		const data = {
			amountToWaive: this.data.amountToWaive,
			currentFeeBalance: this.data.currentFeeBalance,
			feeId: this.fee.id,
		};

		return data;
	}

	get fullName(): string {
		return `${this.sessionData.firstName} ${this.sessionData.lastName}`.trim();
	}

	updateComment(comment: string) {
		this.feeComment = comment;
	}

	async save(): Promise<void> {
		try {
			this.loading = true;
			await this.loanSvc.waiveFee(
				this.fiId,
				this.loanId,
				this.fee.id,
				this.waiveData,
			);

			if (this.feeComment) {
				const data: CommentPayload = {
					userId: `${this.sessionData.id}`,
					user: this.fullName,
					body: this.feeComment,
					referenceType: CommentReferenceTypes.LATE_FEE,
					referenceId: this.fee.id,
					loanId: this.loanId,
				};

				try {
					await this.commentsSvc.postComment(this.loanId, data);
				} catch (error) {
					await handleErrorResponse(
						(error as any).response,
						this,
						NoticeClass.WARN,
						'The fee has been waived but the comment was not saved. Please try again.',
					);
				}
			}

			this.dismiss();
			this.showAlert(
				'Waiver completed successfully.',
				NoticeClass.SUCCESS,
				5000,
			);
			this.$emit('fee-waived', this.fee);
		} catch (error) {
			await handleErrorResponse((error as any).response, this);
		} finally {
			this.loading = false;
		}
	}
}
