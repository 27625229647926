import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { KCrumb, MenuItem, MenuHeading } from '@kasasa/fbase-components/lib';

export interface NavigationState {
	menuItems: (MenuItem | MenuHeading)[];
	breadcrumbs: KCrumb[]
  }
@Module({
	namespaced: true,
})
export default class NavigationModule extends VuexModule {
	menuItems: (MenuItem | MenuHeading)[] = [];

	breadcrumbs: KCrumb[] = [];

	@Mutation
	setMenuItems(items: (MenuItem | MenuHeading)[]): void {
		this.menuItems = items;
	}

	@Mutation
	setBreadcrumbs(items: KCrumb[]): void {
		this.breadcrumbs = items;
	}
}
