
import KPage from '@/components/KPage.vue';
import { Component, Inject, Prop } from 'vue-property-decorator';
import BankAccountsTable from '@/components/BankAccountsTable.vue';
import { Dialog, NoticeClass } from '@kasasa/fbase-components/lib';
import BaseConsumerPage from '@/pages/consumer/BaseConsumerPage';
import { handleErrorResponse } from '@/utils/xhr';
import { ExtendedBankAccount, ProductsPreventingDelete } from '@/services/types/BankAccount';
import { KLOANS } from '@/pages/PermissionGroups';

@Component({
	components: {
		BankAccountsTable,
		KPage,
	},
})
export default class ConsumerLinkedAccountsPage extends BaseConsumerPage {
	@Inject('format') format!: object;

	@Prop({ required: true })
	consumerId!: string;

	title: string = 'Linked Accounts';

	search: string = '';

	loading: boolean = true;

	showDialogReasons: boolean = false;

	itemsPreventingDelete: ProductsPreventingDelete[] = [];

	private allowedRoutingNumbers: string[] = [];

	async created(): Promise<void> {
		this.loading = true;
		await Promise.all([
			await this.getConsumer(),
			await this.getAccounts(true),
			await this.fetchAndSetRoutingNumbers(),
		]);
		this.loading = false;
	}

	accounts: ExtendedBankAccount[] = [];

	loadingAccounts: boolean = false;

	async getAccounts(force: boolean = false): Promise<ExtendedBankAccount[]> {
		if (this.canView) {
			if (force || this.accounts.length === 0) {
				try {
					this.loadingAccounts = true;
					const response = await this.consumerSvc.getBankAccounts(this.consumerId, this.fiId);
					// Decorate with loading value of false so reactivity works.
					this.accounts = response.data.data.map((account: ExtendedBankAccount) => {
						account.loadingDelete = false;
						return account;
					});
				} catch (error) {
					handleErrorResponse((error as any).response, this);
				} finally {
					this.loadingAccounts = false;
				}
			}
		}

		return this.accounts;
	}

	async fetchAndSetRoutingNumbers(): Promise<void> {
		try {
			this.allowedRoutingNumbers = await this.consumerSvc.getAllowedRoutingNumbers(this.fiId);
		} catch (error) {
			throw new Error(error as any);
		}
	}

	// eslint-disable-next-line class-methods-use-this
	async onDeleteAccountClicked(item: ExtendedBankAccount): Promise<void> {
		if (item.canDelete) {
			const dialog = new Dialog('Confirm Action', `This will delete the consumer's linked ${item.accountType} account named '${item.name}' with the account number: '${item.accountNumber}'.`);
			dialog.setAcceptLabel('DELETE');
			dialog.setDeclineLabel('Cancel');
			const result = await this.showDialog(dialog);
			if (result !== 'accept') {
				return;
			}

			try {
				item.loadingDelete = true;
				await this.consumerSvc.deleteBankAccount(this.consumerId, item.id);
				this.accounts = this.accounts.filter((account) => account.id !== item.id);
				await this.showAlert('Account successfully deleted.', NoticeClass.SUCCESS, 5000);
			} catch (error) {
				await handleErrorResponse((error as any).response, this);
			} finally {
				item.loadingDelete = false;
			}
		} else {
			this.itemsPreventingDelete = item.productsPreventingDelete;
			this.showDialogReasons = true;
		}
	}

	get canView(): boolean {
		return this.canRead(KLOANS);
	}

	get canModify(): boolean {
		return this.canWrite(KLOANS);
	}
}
