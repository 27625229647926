
/**
 * KPage was created to act as a reusable component for a "page", and is
 * currently used by NewVersionPage. Itneeds more refactoring to achieve
 * the robustness to be used by other pages, and eventually should be moved to
 * the shared fbase componentslibrary. If progression is made, please update,
 * or when appropriate, delete this comment.
 * @author James Hester <james.hester@kasasa.com>
* */

import {
	Component,
	Prop,
	Vue,
} from 'vue-property-decorator';
import {
	KCardHeader,
	KCardHeaderBtn,
	KCardFooter,
	KCardFooterBtn,
	KSpinner,
} from '@kasasa/fbase-components';
import KButtonModel from '@/components/KButtonModel';
import { KCrumb } from '@kasasa/fbase-components/lib';

@Component({
	components: {
		KCardHeader,
		KCardHeaderBtn,
		KCardFooter,
		KCardFooterBtn,
		KSpinner,
	},
})
export default class KPage extends Vue {
	@Prop()
	cancelAction!: () => void;

	@Prop()
	footerButtons!: KButtonModel[];

	@Prop({ default: false })
	loading!: boolean;

	@Prop({ default: 'Kasasa Loans' })
	title!: string;

	@Prop({ default: () => [] })
	breadcrumbs!: KCrumb[];

	@Prop({ default: () => [] })
	headerButtons!: KCardHeaderBtn[];

	@Prop()
	canView!: boolean;
}
