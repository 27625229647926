
import KCard from '@/components/KCard.vue';
import {
	Component, Inject, Prop, Vue, Watch,
} from 'vue-property-decorator';
import { KReadOnly, KText } from '@kasasa/fbase-components';
import { General, LoanStatus } from '@/services/types/loan/Loan';
import { InsuranceCoverage } from '@/services/types/loan/Insurance';
import { Borrower } from '@/services/types/loan/Borrower';
import PayoffCalculatorModal from '@/components/loan/payoffCalculatorModal/payoffCalculatorModal.vue';

@Component({
	components: {
		KCard,
		KReadOnly,
		KText,
		PayoffCalculatorModal,
	},
})
export default class OverviewGeneralCard extends Vue {
	@Prop({ required: true })
	canWrite!: boolean;

	@Prop({ required: true })
	data!: General;

	@Prop({ required: true })
	loanId!: string;

	@Prop({ default: () => [] })
	insuranceCoverage!: InsuranceCoverage[];

	@Prop({ required: true })
	loadingBranch!: boolean;

	@Prop({ required: true })
	loadingLoanOfficer!: boolean;

	@Prop({ required: true })
	loadingLoanApplicationNumber!: boolean;

	@Prop({ required: true })
	loadingCoreTrackingRecordNumber!: boolean;

	@Prop({ required: true })
	loadingApplicant!: boolean;

	@Prop({ required: true })
	activeApplicant!: Borrower;

	@Inject('format')
	format!: object;

	editBranch: boolean = false;

	editLoanOfficer: boolean = false;

	editLoanApplicationNumber: boolean = false;

	editCoreAccountId: boolean = false;

	editCoreTrackingRecordNumber: boolean = false;

	showModal: boolean = false;

	get hasInsurance(): string {
		if (this.insuranceCoverage.length) {
			let foundActiveCoverage = false;

			this.insuranceCoverage.forEach((coverage: InsuranceCoverage) => {
				if (coverage.endDate == null) {
					foundActiveCoverage = true;
				}
			});

			if (foundActiveCoverage) {
				return 'Yes';
			}
		}

		return 'No';
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	onBranchInput(event: any): void {
		if (event.code === 'Tab' || event.code === 'Enter') {
			this.$emit('branch-input', event.target.value);
		}
	}

	@Watch('loadingBranch')
	onLoadingBranch(value: boolean): void {
		if (!value) {
			this.editBranch = false;
		}
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	onLoanApplicationNumberInput(event: any): void {
		if (event.code === 'Tab' || event.code === 'Enter') {
			this.$emit('loan-application-number-input', event.target.value);
		}
	}

	@Watch('loadingLoanApplicationNumber')
	onLoadingLoanApplicationNumber(value: boolean): void {
		if (!value) {
			this.editLoanApplicationNumber = false;
		}
	}

	launchPayoffCalculator(): void {
		this.showModal = true;
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	onLoanCoreTrackingRecordInput(event: any): void {
		if (event.code === 'Tab' || event.code === 'Enter') {
			this.$emit('loan-core-tracking-record-update', event.target.value);
		}
	}

	@Watch('loadingCoreTrackingRecordNumber')
	onLoadingCoreTrackingRecordNumber(value: boolean): void {
		if (!value) {
			this.editCoreTrackingRecordNumber = false;
		}
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	onLoanOfficerInput(event: any): void {
		if (event.code === 'Tab' || event.code === 'Enter') {
			this.$emit('loan-officer-input', event.target.value);
		}
	}

	@Watch('loadingLoanOfficer')
	onLoadingLoanOfficer(value: boolean): void {
		if (!value) {
			this.editLoanOfficer = false;
		}
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	onCoreAccountIdInput(event: any): void {
		if (event.code === 'Tab' || event.code === 'Enter') {
			this.$emit('applicant-core-account-update', event.target.value);
		}
	}

	@Watch('loadingApplicant')
	onLoadingApplicant(value: boolean): void {
		if (!value) {
			this.editCoreAccountId = false;
		}
	}

	get isLoanClosed(): boolean {
		return this.data.status === LoanStatus.CLOSED;
	}
}
