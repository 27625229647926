
import KCard from '@/components/KCard.vue';
import {
	Component, Inject, Prop,
} from 'vue-property-decorator';
import { KText } from '@kasasa/fbase-components';
import BaseComponent from '@/components/BaseComponent';
import { BankruptcyStatus, StatusHistory } from '@/services/types/loan/Bankruptcy';

@Component({
	components: {
		KCard,
		KText,
	},
	enums: {
		BankruptcyStatus,
	},
})
export default class BankruptcyNote extends BaseComponent {
	@Prop({ required: true })
	status!: StatusHistory;

	@Prop({ required: true })
	note!: string;

	@Inject('format')
	format!: object;
}
