import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelope, ResponseEnvelopeCollection } from '@kasasa/fbase-components/lib';
import { Dictionary } from 'vue-router/types/router.d';
import { AuditLog } from '@/services/types/AuditLog';
import { extendLoanFee, extendLoanTransaction, formatLoanTermMod } from '@/utils/formatters';
import { Fee, WaiveFeePayload } from '@/services/types/loan/Fee';
import { Document, DocumentLink } from '@/services//types/Document';
import {
	TransactionPayload,
	ExtendedTransaction,
	TransactionRefundPayload,
} from '@/services/types/loan/LoanTransaction';
import { Borrower, extendBorrower } from '@/services/types/loan/Borrower';
import changeCase from 'change-object-case';
import {
	CollateralAsset, Collection, Note, NotePayload,
} from '@/services/types/loan/Collection';
import {
	ChargeOffPayLoad,
	DueDatePayLoad,
	LoanMaintenance,
	LoanModification,
	LoanTermMod,
	LoanTermModPayload,
	Status,
} from '@/services/types/loan/Maintenance';
import { MetadataPayload } from '@/services/types/loan/Metadata';
import { Bankruptcy } from '@/services/types/loan/Bankruptcy';
import { LoanProduct } from '@/services/types/loan/LoanProduct';
import { Loan } from '@/services/types/loan/Loan';
import { InsuranceCoverage } from '@/services/types/loan/Insurance';
import CollateralAssetPayload from '@/services/types/loan/Collateral';
import { PaymentSchedule } from '@/services/types/loan/PaymentSchedule';
import { PayoffDays } from '@/services/types/loan/PayoffDays';

export default class LoanService extends FBaseService {
	serverErrorHandler?: () => Promise<void>;

	hanldeServerError(): Promise<void> {
		if (this.serverErrorHandler) {
			return this.serverErrorHandler();
		}
		return super.hanldeServerError();
	}

	setServerErrorHandler(handler: () => Promise<void>): void {
		this.serverErrorHandler = handler;
	}

	async getLoan(consumerId: string, loanId: string): Promise<Loan> {
		const response: AxiosResponse<ResponseEnvelope<Loan>> = await this.apiClient.get(
			`/api/v1/kloans-loan/consumer/${consumerId}/loan/${loanId}/detail`,
		);

		return response.data.data;
	}

	getLoanProduct(fiId: string, productId: string): Promise<AxiosResponse<ResponseEnvelope<LoanProduct>>> {
		return this.apiClient.get(`/api/v1//kloans-fi/${fiId}/loan-product/${productId}`);
	}

	// This API returns a unique Loan response that we don't use anywhere else, so it is not defined
	// as an interface to avoid creating confusion.
	async updateLoan(fiId: string, loanId: string, data: Dictionary<string>): Promise<void> {
		await this.apiClient.patch(`/api/v1/kloans-loan/${fiId}/loan/${loanId}`, data);
	}

	getInsurance(loanId: string): Promise<AxiosResponse<ResponseEnvelopeCollection<InsuranceCoverage>>> {
		return this.apiClient.get(`/api/v1/kloans-insurance/loan/${loanId}/insurance-coverage`);
	}

	async getTransactions(consumerId: string, loanId: string):
		Promise<AxiosResponse<ResponseEnvelopeCollection<ExtendedTransaction>>> {
		const response = await this.apiClient.get(`/api/v1/kloans-loan/consumer/${consumerId}/loan/${loanId}/transaction`);

		response.data.data = response.data.data.map(extendLoanTransaction);
		return response;
	}

	async getTransaction(consumerId: string, loanId: string, transactionId: string):
		Promise<AxiosResponse<ResponseEnvelope<ExtendedTransaction>>> {
		const response = await this.apiClient.get(`/api/v1/kloans-loan/consumer/${consumerId}/loan/${loanId}/transaction/${transactionId}`);

		response.data.data = extendLoanTransaction(response.data.data);

		return response;
	}

	async postPayment(fiId: string, loanId: string, data: TransactionPayload):
		Promise<ExtendedTransaction> {
		// TODO: API doesn't follow standards so we have to convert camelCase back to snakeCase here. -- Karl
		const response = await this.apiClient.post(`/api/v1/kloans-loan/${fiId}/loan/${loanId}/payment`, changeCase.snakeKeys(data));

		response.data.data = extendLoanTransaction(response.data.data);
		return response.data.data;
	}

	async postTakeBack(fiId: string, loanId: string, data: TransactionPayload):
		Promise<ExtendedTransaction> {
		// TODO: API doesn't follow standards so we have to convert camelCase back to snakeCase here. -- Karl
		const response = await this.apiClient.post(`/api/v1/kloans-loan/${fiId}/loan/${loanId}/withdrawal`, changeCase.snakeKeys(data));

		response.data.data = extendLoanTransaction(response.data.data);
		return response.data.data;
	}

	async reverseTransaction(
		fiId: string,
		loanId: string,
		transactionId: string,
		waiveFee: boolean = false,
	): Promise<void> {
		const payload = {
			waiveFee,
		};

		await this.apiClient.put(
			`/api/v1/kloans-loan/${fiId}/loan/${loanId}/transaction/${transactionId}/reversal`,
			payload,
		);
	}

	async refundTransaction(
		fiId: string,
		loanId: string,
		transactionId: string,
		payload: TransactionRefundPayload,
	): Promise<void> {
		await this.apiClient.post(
			`/api/v1/kloans-loan/${fiId}/loan/${loanId}/transaction/${transactionId}/reversal/refund`,
			payload,
		);
	}

	async cancelTransaction(fiId: string, loanId: string, transactionId: string): Promise<void> {
		await this.apiClient.post(
			`/api/v1/kloans-loan/${fiId}/loan/${loanId}/transaction/${transactionId}/cancel`,
		);
	}

	async getBankruptcies(loanId: string):
		Promise<AxiosResponse<ResponseEnvelopeCollection<Bankruptcy>>> {
		const response = await this.apiClient.get(`/api/v1/kloans-bankruptcy/loan/${loanId}/bankruptcy`);

		// Invert the array because active bankruptcies are always last, but we need to display them first.
		response.data.data = response.data.data.reverse().map((bankruptcy: Bankruptcy) => {
			// Frontend uses this determine if consumerACcountNumber is shown, so decorate every bankruptcy with a proper
			// boolean state.
			bankruptcy.serviceOnCore = !!(bankruptcy.consumerAccountNumber && bankruptcy.consumerAccountNumber.length);
			return bankruptcy;
		});
		return response;
	}

	postBankruptcy(loanId: string, data: Bankruptcy):
		Promise<AxiosResponse<ResponseEnvelopeCollection<Bankruptcy>>> {
		return this.apiClient.post(`/api/v1/kloans-bankruptcy/loan/${loanId}/bankruptcy`, data);
	}

	updateBankruptcy(loanId: string, bankruptcyId: number, data: Bankruptcy):
		Promise<AxiosResponse<ResponseEnvelopeCollection<Bankruptcy>>> {
		return this.apiClient.patch(`/api/v1/kloans-bankruptcy/loan/${loanId}/bankruptcy/${bankruptcyId}`, data);
	}

	getAuditLogs(fiId: string, loanId: string, page: Number):
	Promise<AxiosResponse<ResponseEnvelopeCollection<AuditLog>>> {
		return this.apiClient.get(`/api/v1/kloans-audit/fi/${fiId}/audit?targetId=${loanId}&page=${page}`);
	}

	getDocuments(consumerId: string, loanId: string, year: string, type: string):
		Promise<AxiosResponse<ResponseEnvelopeCollection<Document>>> {
		return this.apiClient.get(`/api/v1/consumer/${consumerId}/loan/${loanId}/document/${year}?documentType=${type}`);
	}

	async getDocumentLink(consumerId: string, loanId: string, filename: string):
		Promise<AxiosResponse<ResponseEnvelope<DocumentLink>>> {
		return this.apiClient.get(
			`/api/v1/consumer/${consumerId}/loan/${loanId}/document/view/${filename}/link`,
		);
	}

	async getFees(fiId: string, loanId: string):
		Promise<AxiosResponse<ResponseEnvelopeCollection<Fee>>> {
		const response = await this.apiClient.get(`/api/v1/kloans-loan/${fiId}/loan/${loanId}/fee`);

		response.data.data = response.data.data.map(extendLoanFee);
		return response;
	}

	async waiveFee(fiId: string, loanId: string, feeId: string, data: WaiveFeePayload): Promise<void> {
		await this.apiClient.post(
			`/api/v1/kloans-loan/${fiId}/loan/${loanId}/fee/${feeId}/waive`, changeCase.snakeKeys(data),
		);
	}

	async reverseWaivedFee(
		fiId: string, loanId: string, feeId: string, waiverId: string,
	): Promise<void> {
		await this.apiClient.put(
			`/api/v1/kloans-loan/${fiId}/loan/${loanId}/fee/${feeId}/waiver/${waiverId}/reversal`,
		);
	}

	async getBorrowers(fiId: string, loanId: string, excludeAddresses: boolean = false):
		Promise<AxiosResponse<ResponseEnvelopeCollection<Borrower>>> {
		let url = `/api/v1/kloans-loan/${fiId}/loan/${loanId}/applicant`;

		if (!excludeAddresses) {
			url += '?expand=addresses';
		}

		const response = await this.apiClient.get(url);

		// Add loading property for UI needs.
		response.data.data = response.data.data.map(extendBorrower);

		return response;
	}

	async addBorrower(fiId: string, loanId: string, data: Borrower):
		Promise<AxiosResponse<ResponseEnvelope<Borrower>>> {
		const response = await this.apiClient.post(`/api/v1/kloans-loan/${fiId}/loan/${loanId}/applicant`, data);

		response.data.data = extendBorrower(response.data.data);

		return response;
	}

	async updateBorrower(fiId: string, loanId: string, applicantId: string, data: Borrower):
		Promise<AxiosResponse<ResponseEnvelope<Borrower>>> {
		const response = await this.apiClient.put(`/api/v1/kloans-loan/${fiId}/loan/${loanId}/applicant/${applicantId}`, data);

		response.data.data = extendBorrower(response.data.data);

		return response;
	}

	// TODO: API doesn't follow standards on camelCase response, so we camelCase it here. -- Karl
	async getCollectionDetails(fiId: string, consumerId: string, loanId: string):
		Promise<AxiosResponse<ResponseEnvelope<Collection>>> {
		const response = await this.apiClient.get(`/api/v1/kloans-collections/${fiId}/consumer/${consumerId}/loan/${loanId}/details`);
		response.data.data = changeCase.camelKeys(response.data.data, { recursive: true, arrayRecursive: true });

		return response;
	}

	// TODO: API doesn't follow standards so we have to convert camelCase back to snakeCase here. -- Karl
	// TODO: API doesn't follow standards on camelCase response, so we camelCase it here. -- Karl
	async addCollectionNote(fiId: string, loanId: string, data: NotePayload):
		Promise<AxiosResponse<ResponseEnvelope<Note>>> {
		const response = await this.apiClient.post(`/api/v1/kloans-collections/${fiId}/loan/${loanId}/note`, changeCase.snakeKeys(data));
		response.data.data = changeCase.camelKeys(response.data.data, { recursive: true, arrayRecursive: true });

		return response;
	}

	async getLoanStatus(loanId: string):
		Promise<AxiosResponse<ResponseEnvelope<any>>> {
		return this.apiClient.get(`/api/v1/kloans-loan/loan/${loanId}/status`);
	}

	async getLoanMaintenance(
		fiId: string, consumerId: string, loanId: string,
	): Promise<LoanMaintenance> {
		const response: AxiosResponse<ResponseEnvelope<LoanMaintenance>> = await this.apiClient.get(
			`/api/v1/kloans-views/${fiId}/consumer/${consumerId}/loan/${loanId}/loan-maintenance`,
		);

		return response.data.data;
	}

	async toggleInterestAccrual(fiId: string, status: Status):
		Promise<AxiosResponse<ResponseEnvelope<any>>> {
		let url: string;

		if (status.status === 'active') {
			url = `/api/v1/kloans-loan/${fiId}/loan/${status.loanId}/status/hold`;
		} else if (status.status === 'hold') {
			url = `/api/v1/kloans-loan/${fiId}/loan/${status.loanId}/status/active`;
		} else {
			throw new Error('An invalid loan state was passed in.');
		}

		return this.apiClient.post(url);
	}

	async toggleCreditReport(fiId: string, status: Status): Promise<void> {
		// Convert the payload back to snake_case because reasons...
		const data = changeCase.snakeKeys({ bypassCreditReporting: !status.bypassCreditReporting });
		await this.apiClient.post(`/api/v1/kloans-loan/${fiId}/loan/${status.loanId}/credit-report`, data);
	}

	async toggleTakeBacks(fiId: string, status: Status): Promise<void> {
		// Convert the payload back to snake_case because reasons...
		const data = changeCase.snakeKeys({ takebacksEnabled: !status.takeBackEnabled });
		await this.apiClient.post(`/api/v1/kloans-loan/${fiId}/loan/${status.loanId}/take-backs`, data);
	}

	async getLoanModById(fiId: string, loanId: string, loanModId: string): Promise<LoanModification> {
		const response = await this.apiClient.get(
			`/api/v1/kloans-loan/${fiId}/loan/${loanId}/loan-mod/${loanModId}`,
		);

		return response.data.data;
	}

	async getActiveLoanMods(fiId: string, loanId: string):
		Promise<AxiosResponse<ResponseEnvelopeCollection<LoanModification>>> {
		const response = await this.apiClient.get(
			`/api/v1/kloans-loan/${fiId}/loan/${loanId}/active-modifications`,
		);

		return response;
	}

	async getPaymentSchedule(loanId: string):
		Promise<AxiosResponse<ResponseEnvelopeCollection<PaymentSchedule>>> {
		const response = await this.apiClient.get(
			`/api/v1/kloans-loan/loan/${loanId}/payment-schedule`,
		);

		return response;
	}

	async getPayoffDays(loanId: string, days: string):
		Promise<AxiosResponse<ResponseEnvelopeCollection<PayoffDays>>> {
		const response = await this.apiClient.get(
			`/api/v1/kloans-loan/loan/${loanId}/payoff-days/${days}`,
		);

		return response;
	}

	async postLoanMod(fiId: string, loanId: string, loanModId: string):
		Promise<AxiosResponse<ResponseEnvelope<LoanModification>>> {
		return this.apiClient.post(`/api/v1/kloans-loan/${fiId}/loan/${loanId}/loan-mod/${loanModId}/commit`);
	}

	async cancelLoanMod(fiId: string, loanId: string, loanModId: string): Promise<void> {
		await this.apiClient.post(
			`/api/v1/kloans-loan/${fiId}/loan/${loanId}/loan-mod/${loanModId}/cancel`,
		);
	}

	async commitLoanMod(fiId: string, loanId: string, loanModId: string): Promise<void> {
		await this.apiClient.post(
			`/api/v1/kloans-loan/${fiId}/loan/${loanId}/loan-mod/${loanModId}/commit`,
		);
	}

	async extendLoanModExpiration(fiId: string, loanId: string, loanModId: string): Promise<void> {
		await this.apiClient.post(
			`/api/v1/kloans-loan/${fiId}/loan/${loanId}/loan-mod/${loanModId}/extend-expiration`,
		);
	}

	async postDueDateMod(fiId: string, loanId: string, data: DueDatePayLoad):
		Promise<AxiosResponse<ResponseEnvelope<LoanModification>>> {
		return this.apiClient.post(`/api/v1/kloans-loan/${fiId}/loan/${loanId}/loan-mod/due-date-mod`, data);
	}

	async postChargeOff(fiId: string, loanId: string, data: ChargeOffPayLoad): Promise<void> {
		await this.apiClient.post(`/api/v1/kloans-loan/${fiId}/loan/${loanId}/status/charge-off`, data);
	}

	async getLoanTermModHistory(fiId: string, loanId: string):
		Promise<AxiosResponse<ResponseEnvelopeCollection<LoanTermMod>>> {
		const response = await this.apiClient.get(`/api/v1/kloans-loan/${fiId}/loan/${loanId}/loan-mod/loan-term-mod/history`);

		response.data.data = response.data.data.map(formatLoanTermMod);
		return response;
	}

	async postLoanTermMod(fiId: string, loanId: string, data: LoanTermModPayload):
		Promise<AxiosResponse<ResponseEnvelope<LoanModification>>> {
		return this.apiClient.post(`/api/v1/kloans-loan/${fiId}/loan/${loanId}/loan-mod/loan-term-mod`, data);
	}

	async postScraLoanTermMod(fiId: string, loanId: string, data: LoanTermModPayload):
		Promise<AxiosResponse<ResponseEnvelope<LoanModification>>> {
		return this.apiClient.post(`/api/v1/kloans-loan/${fiId}/loan/${loanId}/loan-mod/scra-loan-term-mod`, data);
	}

	async postEndScraMod(fiId: string, loanId: string, data: LoanTermModPayload):
		Promise<AxiosResponse<ResponseEnvelope<LoanModification>>> {
		return this.apiClient.post(`/api/v1/kloans-loan/${fiId}/loan/${loanId}/loan-mod/end-scra`, data);
	}

	async postMetadata(fiId: string, loanId: string, data: MetadataPayload): Promise<void> {
		return this.apiClient.post(`/api/v1/kloans-loan/${fiId}/loan/${loanId}/meta-data`, data);
	}

	async setLoanCollateralAsset(consumerId: string, loanId: string, data: CollateralAssetPayload):
		Promise<AxiosResponse<ResponseEnvelope<CollateralAsset>>> {
		return this.apiClient.put(
			`/api/v1/kloans-loan/consumer/${consumerId}/loan/${loanId}/collateral`,
			data,
		);
	}
}
