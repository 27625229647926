
import KCard from '@/components/KCard.vue';
import {
	Component, Prop, Vue,
} from 'vue-property-decorator';
import { InsuranceCoverage, InsurancePolicy } from '@/services/types/loan/Insurance';
import { LoanMaintenance } from '@/services/types/loan/Maintenance';
import InsuranceCoverageDisplay from './InsuranceCoverageDisplay.vue';

@Component({
	components: {
		InsuranceCoverageDisplay,
		KCard,
	},
})
export default class OverviewInsuranceCard extends Vue {
	@Prop({ required: true })
	data!: InsuranceCoverage[];

	@Prop({ required: true })
	loanMaintenance!: LoanMaintenance | null;

	get availablePolicies(): InsurancePolicy[] {
		return this.loanMaintenance?.products ?? [];
	}
}
