import { render, staticRenderFns } from "./BankruptcyForm.vue?vue&type=template&id=673b22fa&scoped=true&"
import script from "./BankruptcyForm.vue?vue&type=script&lang=ts&"
export * from "./BankruptcyForm.vue?vue&type=script&lang=ts&"
import style0 from "./BankruptcyForm.vue?vue&type=style&index=0&id=673b22fa&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "673b22fa",
  null
  
)

export default component.exports