import { AxiosResponse } from 'axios';
import BaseComponent from '@/components/BaseComponent';

import {
	Error, NoticeResponse, NoticeClass, Dialog,
} from '@kasasa/fbase-components/lib';

export const handleErrorResponse = (response: AxiosResponse,
	context: BaseComponent, noticeClass?: NoticeClass, customError?: string): Promise<NoticeResponse> => {
	if (response) {
		const { error } = response.data;

		let nClass = NoticeClass.ERROR;
		if (noticeClass) {
			nClass = noticeClass;
		}

		if (response.status === 401) {
			window.location.href = '';
		}

		if (response.status === 403 && error.message) {
			return context.showAlert(error.message, nClass);
		}

		if (response.status === 412 && error.message) {
			return context.showAlert(error.message, nClass);
		}

		if (response.status === 422 && error.errors !== undefined) {
			const title = error.message;
			const message = error.errors.map((err: Error) => `${err.message}`).join('\n');

			if (context.showDialog) {
				return context.showDialog(new Dialog(title, message));
			}
		}

		if (customError) {
			return context.showAlert(customError, nClass);
		}

		return context.showAlert('The website has encountered an error. Please contact Kasasa support at http://support.kasasa.com', nClass);
	}

	return context.showAlert('The website has encountered an error. Please contact Kasasa support at http://support.kasasa.com', NoticeClass.ERROR);
};

export const A = 1;
