
import {
	Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';

import { ExtendedBankAccount } from '@/services/types/BankAccount';
import { Dictionary } from 'vue-router/types/router.d';
import { Formatters } from '@/utils/formatters';
import { RouteTo } from '@/router/types';

@Component
export default class BankAccountsTable extends Vue {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	clientId!: string;

	@Prop({ required: true })
	consumerId!: string;

	@Prop({ required: true })
	items!: ExtendedBankAccount[];

	@Prop({ required: true })
	title!: string;

	@Prop({ required: true })
	allowedRoutingNumbers!: string[];

	@Prop({ default: false })
	loading!: boolean;

	@Prop()
	canDelete!: boolean;

	@Prop({ default: false })
	canWrite!: boolean;

	sortDesc: boolean[] = [];

	loadingText: string = 'Loading loans...';

	headers: DataTableHeader[] = [
		{
			text: 'Institution', value: 'institution.name',
		},
		{
			text: 'Account Type', value: 'accountType',
		},
		{
			text: 'Account No. Last 4', value: 'accountNumberLastFour',
		},
		{
			text: 'Routing No.', value: 'routingNumber',
		},
		{
			text: 'Verified', value: 'verified',
		},
		{
			text: 'External', value: 'external',
		},
		{
			text: 'Action', value: 'action',
		},
	];

	// eslint-disable-next-line class-methods-use-this
	rowClass(item: ExtendedBankAccount): string {
		return `account-row ${item.accountType}`;
	}

	onDeleteClicked(item: ExtendedBankAccount): void {
		if (item.canDelete) {
			this.$emit('delete-account', item);
		}
	}

	checkRoutingAllowed(routingNum: string): boolean {
		return this.allowedRoutingNumbers.includes(routingNum);
	}

	viewLinkedAccountParams(item: ExtendedBankAccount): Dictionary<string> {
		return { clientId: this.clientId, consumerId: item.consumerId, accountId: item.id };
	}

	getAccountDetailsRoute(item: ExtendedBankAccount): RouteTo {
		return {
			name: 'view-linked-account',
			params: {
				clientId: this.clientId,
				consumerId: this.consumerId,
				accountId: item.id,
			},
		};
	}

	goToAccountDetails(item: ExtendedBankAccount): void {
		if (!item.external) {
			this.$router.push(this.getAccountDetailsRoute(item));
		}
	}
}
