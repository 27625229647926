
import { Component, Inject, Prop } from 'vue-property-decorator';
import {
	ExtendedTransaction,
	TransactionMethod,
	TransactionRefundPayload,
} from '@/services/types/loan/LoanTransaction';
import { Formatters } from '@/utils/formatters';
import { Dialog, NoticeClass } from '@kasasa/fbase-components/lib/index';
import { handleErrorResponse } from '@/utils/xhr';
import BaseComponent from '@/components/BaseComponent';
import LoanService from '@/services/LoanService';
import { ExtendedBankAccount } from '@/services/types/BankAccount';
import { ConsumerService } from '@/services/ConsumerService';
import { namespace } from 'vuex-class';

const bankAccountStoreModule = namespace('bankAccount');

enum TransactionChangeType {
	NOT_SELECTED = '',
	REVERSE = 'reverse',
	REFUND = 'refund'
}

enum ReverseFeeWaiveOptions {
	NOT_SELECTED = '',
	APPLY_FEE = 'apply',
	WAIVE_FEE = 'waive'
}

enum RefundMethodOptions {
	NOT_SELECTED = '',
	CASH = 'cash',
	CHECK = 'check',
	LINKED_ACCOUNT = 'linked_account'
}

@Component({
	enums: {
		TransactionChangeType,
		ReverseFeeWaiveOptions,
		RefundMethodOptions,
	},
})
export default class ReverseOrRefundTransaction extends BaseComponent {
	@Inject('format')
	format!: Formatters;

	@Inject('loanFiId')
	fiId!: string;

	@Prop({ required: true })
	loanId!: string;

	@Prop({ required: true })
	consumerId!: string;

	@Prop({ required: true })
	transaction!: ExtendedTransaction;

	@bankAccountStoreModule.Getter('getBankAccounts')
	bankAccounts!: ExtendedBankAccount[];

	@bankAccountStoreModule.Mutation('setBankAccounts')
	setBankAccounts!: (accounts: ExtendedBankAccount[]) => void;

	@bankAccountStoreModule.Getter('bankAccountDropdownOptions')
	bankAccountOptions!: (accounts: ExtendedBankAccount[]) => void;

	private showReverseOrRefundSelectionModal = true;

	private selectedReverseOrRefund = TransactionChangeType.NOT_SELECTED;

	private reverseTransactionFeeWaiverSelection = ReverseFeeWaiveOptions.NOT_SELECTED;

	private showReverseTransactionModal = false;

	private showRefundTransactionModal = false;

	private loanService: LoanService = new LoanService(this.$store);

	private processingChange = false;

	private refundMethod: RefundMethodOptions = RefundMethodOptions.NOT_SELECTED;

	private consumerService = new ConsumerService(this.$store);

	private refundPaymentAccountId = '';

	private refundDescription = '';

	private bankAccountsLoading = false;

	get transactionMethod(): string {
		return TransactionMethod[this.transaction.transactionMethod];
	}

	get isNextButtonDisabled(): boolean {
		return this.selectedReverseOrRefund === TransactionChangeType.NOT_SELECTED;
	}

	get isSubmitReverseButtonDisabled(): boolean {
		return this.reverseTransactionFeeWaiverSelection === ReverseFeeWaiveOptions.NOT_SELECTED;
	}

	get isSubmitRefundButtonDisabled(): boolean {
		if (this.refundMethod === RefundMethodOptions.NOT_SELECTED) {
			return true;
		}

		return this.refundMethod === RefundMethodOptions.LINKED_ACCOUNT && !this.refundPaymentAccountId;
	}

	async created(): Promise<void> {
		// For reversible ACH transactions, the user only has the option to refund the transaction
		if (this.transaction.transactionMethod === TransactionMethod.ACH) {
			this.showReverseOrRefundSelectionModal = false;
			this.showRefundTransactionModal = true;
		}

		// Default refund method to original transaction payment method
		switch (this.transaction.transactionMethod) {
			case TransactionMethod.Cash:
				this.refundMethod = RefundMethodOptions.CASH;
				break;
			case TransactionMethod.Check:
				this.refundMethod = RefundMethodOptions.CHECK;
				break;
			case TransactionMethod.ACH:
			case TransactionMethod['Direct Posting']:
				this.refundMethod = RefundMethodOptions.LINKED_ACCOUNT;
				break;
			default:
				this.refundMethod = RefundMethodOptions.NOT_SELECTED;
		}

		await this.getBankAccounts();

		// Default original payment account and the refund account id (if available)
		const originalPaymentAccount = this.bankAccounts.find(
			(account) => account.id === this.transaction.paymentAccountId,
		);

		if (originalPaymentAccount) {
			this.refundPaymentAccountId = originalPaymentAccount.id;
		}
	}

	renderReverseOrRefundTransactionModal(): void {
		this.showReverseOrRefundSelectionModal = false;

		if (this.selectedReverseOrRefund === TransactionChangeType.REVERSE) {
			this.showReverseTransactionModal = true;
		} else {
			this.showRefundTransactionModal = true;
		}
	}

	async submitReverseTransaction(): Promise<void> {
		const dialog = new Dialog(
			'Confirm Reverse Transaction',
			'Are you sure you want to reverse this transaction?',
		);
		dialog.setDeclineLabel('Cancel');

		const result = await this.showDialog(dialog);

		if (result !== 'accept') {
			return;
		}

		this.processingChange = true;

		try {
			await this.loanService.reverseTransaction(
				this.fiId,
				this.loanId,
				this.transaction.id,
				this.reverseTransactionFeeWaiverSelection === ReverseFeeWaiveOptions.WAIVE_FEE,
			);

			this.showAlert('Transaction successfully reversed.', NoticeClass.SUCCESS, 5000);

			this.$emit('transaction-changed');
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.processingChange = false;
		}
	}

	async submitRefundTransaction(): Promise<void> {
		const dialog = new Dialog(
			'Confirm Refund Transaction',
			'Are you sure you want to refund this transaction?',
		);
		dialog.setDeclineLabel('Cancel');

		const result = await this.showDialog(dialog);

		if (result !== 'accept') {
			return;
		}

		// Mapping refund method to transaction method
		let transactionRefundMethod = this.transaction.transactionMethod;
		let selectedAccount = null;
		switch (this.refundMethod) {
			case RefundMethodOptions.CASH:
				transactionRefundMethod = TransactionMethod.Cash;
				break;
			case RefundMethodOptions.CHECK:
				transactionRefundMethod = TransactionMethod.Check;
				break;
			case RefundMethodOptions.LINKED_ACCOUNT:
				selectedAccount = this.bankAccounts.find((acc) => acc.id === this.refundPaymentAccountId);
				transactionRefundMethod = selectedAccount?.external
					? TransactionMethod.ACH
					: TransactionMethod['Direct Posting'];
				break;
			default:
				this.refundMethod = RefundMethodOptions.NOT_SELECTED;
		}

		const payload: TransactionRefundPayload = {
			transactionMethod: transactionRefundMethod,
			description: this.refundDescription !== '' ? this.refundDescription : 'Payment Refund',
			account: this.refundPaymentAccountId,
		};

		try {
			this.processingChange = true;

			await this.loanService.refundTransaction(
				this.fiId,
				this.loanId,
				this.transaction.id,
				payload,
			);

			this.showAlert('Transaction successfully refunded.', NoticeClass.SUCCESS, 5000);

			this.$emit('transaction-changed');
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.processingChange = false;
		}
	}

	async getBankAccounts(): Promise<void> {
		if (this.bankAccounts.length) {
			return;
		}

		try {
			this.bankAccountsLoading = true;

			const response = await this.consumerService.getBankAccounts(this.consumerId, this.fiId);

			this.setBankAccounts(response.data.data);
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		} finally {
			this.bankAccountsLoading = false;
		}
	}
}
