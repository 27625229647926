/* eslint-disable camelcase */
import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelope, ResponseEnvelopeCollection } from '@kasasa/fbase-components/lib';
import { Dictionary } from 'vue-router/types/router.d';
import { AuditLog } from '@/services/types/AuditLog';
import { extendBankAccount } from '@/utils/formatters';
import { BankAccountPayload, ExtendedBankAccount, FiRoutingNumber } from './types/BankAccount';

export interface OverviewLoan {
	id: string;
	loanName: string;
	currentBalance: string;
	loanNumber: string;
	status: string;
	type: string;
	borrowerType: string;
}

export interface OverviewLoanRow extends OverviewLoan {
	loadingDelete?: boolean;
}

export interface Consumer {
	id: string;
	name: string;
	email: string;
	ssn: string;
	ssnHash: string;
	ssnLastFour: string;
	dateOfBirth: string;
	nickname?: string;
	enrollmentCompleted: number;
	hasSeenLoanIntro: number;
	termsAcceptedAt?: string;
	dwollaTermsAcceptedAt?: string;
	authUserId?: string;
	deletedAt?: string;
	createdAt: string;
	updatedAt: string;
}
export interface ConsumerProfile {
	name: string;
	email: string;
	id: string;
	createdAt: string;
	username?: string;
	lastLogin?: string;
	phoneNumber?: string;
	status: string;
	startDate: string;
}
interface CurrentPrimaryAssetTermsSnapshot {
	id: number;
	loan_id: string;
	asset_id: string;
	asset_type: string;
	start_date: string;
	end_date?: string;
	term_change_reason: number;
	interest_rate: string;
	created_at: string;
	updated_at: string;
}
interface ConsumerInfoForCollection {
	id: string;
	name: string;
	email: string;
	nickname?: string;
	created_at: string;
}
interface LastNote {
	id: string;
	loan_id: string;
	note_body: string;
	entered_by: string;
	loan_status: string;
	past_due_days: number;
	created_at: string;
	updated_at: string;
	deleted_at?: string;
	entered_by_id: string;
}

export interface Collection {
	id: string;
	fi_id: number;
	consumer_id: string;
	product_id: string;
	loan_type_id: number;
	core_tracking_record_number: string;
	description: string;
	payment_frequency_id: number;
	first_payment_date: string;
	grace_period: number;
	returned_payment_fee: string;
	kasasa_loan_number: string;
	status: string;
	delinquent_days: number;
	total_payments: string;
	total_withdrawals: string;
	fees_paid: string;
	fees_due: string;
	principal_paid: string;
	principal_balance: string;
	interest_paid: string;
	past_due: string;
	take_back_balance: string;
	take_back_enabled: number;
	last_payment_date?: string;
	effective_start: string;
	effective_end?: string;
	installment_amount: string;
	original_principal: string;
	origination_date: string;
	maturity_date: string;
	interest_rate: string;
	current_primary_asset_terms_snapshot: CurrentPrimaryAssetTermsSnapshot;
	principal: string;
	loan_date: string;
	late_fee_flat: string;
	late_fee_percentage: string;
	late_fee_lesser_or_greater: string;
	late_fee_amount: string;
	consumer: ConsumerInfoForCollection;
	last_note?: LastNote;
}

export class ConsumerService extends FBaseService {
	serverErrorHandler?: () => Promise<void>;

	hanldeServerError(): Promise<void> {
		if (this.serverErrorHandler) {
			return this.serverErrorHandler();
		}
		return super.hanldeServerError();
	}

	setServerErrorHandler(handler: () => Promise<void>): void {
		this.serverErrorHandler = handler;
	}

	getCollectionsForFi(fiId: string): Promise<AxiosResponse<ResponseEnvelopeCollection<Collection>>> {
		return this.apiClient.get(`/api/v1/kloans-collections/${fiId}/loan/delinquent`);
	}

	getConsumer(fiId: string, consumerId: string): Promise<AxiosResponse<ResponseEnvelope<Consumer>>> {
		return this.apiClient.get(`/api/v1/kloans-consumer/fi/${fiId}/consumer/${consumerId}`);
	}

	getConsumerProfile(fiId: string, consumerId: string): Promise<AxiosResponse<ResponseEnvelope<ConsumerProfile>>> {
		return this.apiClient.get(` /api/v1/fi/${fiId}/consumer/${consumerId}/profile-data`);
	}

	getConsumersForFi(fiId: string): Promise<AxiosResponse<ResponseEnvelopeCollection<Consumer>>> {
		return this.apiClient.get(`/api/v1/kloans-consumer/fi/${fiId}/consumer`);
	}

	getConsumerAuditLog(fiId: string, consumerOrFiId: string, page: Number):
		Promise<AxiosResponse<ResponseEnvelopeCollection<AuditLog>>> {
		return this.apiClient.get(`/api/v1/kloans-audit/fi/${fiId}/audit?targetId=${consumerOrFiId}&page=${page}`);
	}

	getLoansForConsumer(fiId: string, consumerId: string):
		Promise<AxiosResponse<ResponseEnvelopeCollection<OverviewLoan>>> {
		return this.apiClient.get(`/api/v1/fi/${fiId}/consumer/${consumerId}/loan-list`);
	}

	async getBankAccounts(consumerId: string, fiId?: string):
		Promise<AxiosResponse<ResponseEnvelopeCollection<ExtendedBankAccount>>> {
		let params = '';
		if (fiId) {
			params = `?fi=${fiId}`;
		}
		const response = await this.apiClient.get(`/api/v1/kloans-iav/consumer/${consumerId}/bank-account${params}`);
		response.data.data = response.data.data.map(extendBankAccount);

		return response;
	}

	async getBankAccount(consumerId: string, accountId: string):
		Promise<AxiosResponse<ResponseEnvelope<ExtendedBankAccount>>> {
		const response = await this.apiClient.get(`/api/v1/kloans-iav/consumer/${consumerId}/bank-account/${accountId}`);

		response.data.data = extendBankAccount(response.data.data);
		return response;
	}

	async putBankAccount(consumerId: string, accountId: string, payload: BankAccountPayload):
		Promise<AxiosResponse<ResponseEnvelope<ExtendedBankAccount>>> {
		const response = await this.apiClient.put(`/api/v1/kloans-iav/consumer/${consumerId}/bank-account/${accountId}`, payload);

		response.data.data = extendBankAccount(response.data.data);
		return response;
	}

	async postBankAccount(consumerId: string, data: BankAccountPayload):
		Promise<AxiosResponse<ResponseEnvelopeCollection<ExtendedBankAccount>>> {
		const response = await this.apiClient.post(`/api/v1/kloans-iav/consumer/${consumerId}/bank-account`, data);

		response.data.data = extendBankAccount(response.data.data);
		return response;
	}

	deleteBankAccount(consumerId: string, accountId: string): Promise<AxiosResponse<any>> {
		return this.apiClient.delete(`/api/v1/kloans-iav/consumer/${consumerId}/bank-account/${accountId}`);
	}

	resendInvite(fiId: string, consumerId: string): Promise<AxiosResponse<any>> {
		return this.apiClient.post(`/api/v1/kloans-consumer/fi/${fiId}/consumer/${consumerId}/email/welcome`);
	}

	updateConsumerEmail(consumerId: string, consumer: Dictionary<string>): Promise<AxiosResponse<any>> {
		return this.apiClient.patch(`/api/v1/kloans-consumer/consumer/${consumerId}`, consumer);
	}

	deleteLoan(fiId: string, loanId: string): Promise<AxiosResponse<any>> {
		return this.apiClient.delete(`/api/v1/kloans-loan/fi/${fiId}/loan/${loanId}`);
	}

	async getAllowedRoutingNumbers(fiId: string): Promise<string[]> {
		const response: AxiosResponse<ResponseEnvelope<FiRoutingNumber[]>> = await this.apiClient.get(`/api/v1/kloans-iav/fi/${fiId}/routing-numbers`);
		return response.data.data.map((routingRecord: FiRoutingNumber) => routingRecord.routingNumber);
	}
}
