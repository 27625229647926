import { Component, Prop, Watch } from 'vue-property-decorator';
import { MenuHeading, MenuItem } from '@kasasa/fbase-components/lib';
import BasePage from '@/pages/BasePage';
import { KLOANS_MAINTENANCE, VKLOANS } from '@/pages/PermissionGroups';
import FeatureFlagSet from '@/services/types/FeatureFlagSet';
import { namespace } from 'vuex-class';

const featureFlagsModule = namespace('featuresFlag');

@Component
export default class BaseFiPage extends BasePage {
	@Watch('clientId')
	async onSwitchClientId(): Promise<void> {
		await this.fetchFeaturesFlag(this.fiId);
		this.updateMenuItems();
	}

	@Prop({ required: true })
	clientId!: string;

	@Prop({ required: true })
	fiId!: string;

	@featureFlagsModule.Action('fetchAndSetFeatureFlags')
	fetchFeaturesFlag!: (fiId: string) => Promise<void>;

	@featureFlagsModule.Getter('featureFlags')
	fiFeatureFlags!: FeatureFlagSet;

	get canReadLoanOnly(): boolean {
		return this.canRead(VKLOANS) && this.canWrite(VKLOANS) === false;
	}

	get canViewLoan(): boolean {
		return this.canRead(VKLOANS);
	}

	// Shared menus for loan pages.
	setClientIdForMenuItem(): (MenuItem | MenuHeading)[] {
		let items = [
			{
				icon: '',
				label: 'Borrowers',
				to: { name: 'consumer-list', params: { clientId: this.clientId } },
			},
			{
				icon: '',
				label: 'Collections',
				to: { name: 'collection-list', params: { clientId: this.clientId } },
			},
			{
				icon: '',
				label: 'Loan Import',
				to: { name: 'loan-import', params: { clientId: this.clientId } },
			},
			{
				label: 'Reports',
				expanded: false,
				children: [
					{
						icon: '',
						label: 'Historical',
						to: { name: 'historical-reports', params: { clientId: this.clientId } },
					},
					{
						icon: '',
						label: 'Report Builder',
						to: { name: 'report-builder', params: { clientId: this.clientId } },
					},
				],
			},
			{
				icon: '',
				label: 'Collateral Protection Insurance',
				to: { name: 'cpi-page', params: { clientId: this.clientId } },
			},
			{ icon: '', label: 'Audit Logs', to: { name: 'fi-audit', params: { clientId: this.clientId } } },
		];

		if (!(this.canWrite(KLOANS_MAINTENANCE) && this.canRead(KLOANS_MAINTENANCE))) {
			items = items.filter((group) => group.label !== 'Collateral Protection Insurance');
		}

		if (!(this.canReadCollections || this.canWriteCollections)) {
			items = items.filter((group) => group.label !== 'Collections');
		}

		return items;
	}

	updateMenuItems(): void {
		this.menuItems = this.setClientIdForMenuItem();
		this.setMenuItems(this.menuItems);
	}

	menuItems: (MenuItem | MenuHeading)[] = this.setClientIdForMenuItem();

	// TODO: Find a way to have this just work via routing.
	protected expandMenu(): void {
		const menuItem: (MenuItem | MenuHeading | undefined) = this.menuItems.find((object) => (object as MenuItem).label === 'Fi Settings');
		if (menuItem) {
			(menuItem as MenuItem).expanded = true;
		}
	}

	created(): void {
		this.updateMenuItems();
	}
}
