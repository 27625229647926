import { AxiosResponse } from 'axios';
import { FBaseService, ResponseEnvelope, ResponseEnvelopeCollection } from '@kasasa/fbase-components/lib';

export enum PageStatus {
	LOADING = ''
}
export interface TermsOverview {
	initialPrincipal: string;
	apr: string;
	termInMonths: string;
	originationDate: string;
	firstPaymentDueDate: string;
	maturityDate: string;
}

export interface BorrowerLoanOverview {
	description: string;
	status: string;
	principalBalance: string;
	escrowBalance: string;
	hasEscrowAccount: boolean;
	outstandingFees: string;
	minimumPayment: string;
	amountRemainingToSatisfy: string;
	termsOverview: TermsOverview;
	nextPaymentDueDate?: string;
}

export enum AutoPayFrequency {
	'None',
	'Monthly',
	'Quarterly', // Not used by Kloans, but defined by BSI
	'Semi-Annually', // Not used by Kloans, but defined by BSI
	'Annually', // Not used by Kloans, but defined by BSI
	'Bi-Weekly',
	'Weekly',
	'Semi-Monthly',
}

export enum AutoPayFrequencies {
	NONE = 0,
	MONTHLY = 1,
	QUARTERLY = 2, // Not used by Kloans, but defined by BSI
	SEMI_ANNUALLY = 3, // Not used by Kloans, but defined by BSI
	ANNUALLY = 4, // Not used by Kloans, but defined by BSI
	BI_WEEKLY = 5,
	WEEKLY = 6,
	SEMI_MONTHLY = 7,
}

export interface AutoPay {
	'accountId': string;
	'additionalEscrow': string;
	'additionalPrincipal': string;
	'draftDateOne': string;
	'draftDateTwo': string;
	'nextAutomaticPayment'?: string;
	'frequency': AutoPayFrequencies;
	'paymentTotal': string;
	'paymentTotalByFrequency': string;
	'regularMonthlyPayment': string;
}

export interface BorrowerAddressBSI {
	addressLine1: string;
	addressLine2: string;
	city: string;
	zip: string;
}

export enum BorrowerType {
	PRIMARY = 1,
	CO_BORROWER = 2,
}

export enum BorrowerTypeMap {
	Primary = 1,
	'Co-borrower' = 2,
}
export interface Borrower {
	name: string;
	ssn: string;
	dob: string;
	email: string;
	homePhone: string;
	mobilePhone: string;
	workPhone: string;
	address: BorrowerAddressBSI;
	type: BorrowerType;
}

export interface Consumer {
	id: string;
	name: string;
	email: string;
	ssn: string;
	ssnHash: string;
	ssnLastFour: string;
	dateOfBirth: string;
	nickname?: string;
	enrollmentCompleted: number;
	hasSeenLoanIntro: number;
	termsAcceptedAt?: string;
	dwollaTermsAcceptedAt?: string;
	authUserId?: string;
	deletedAt?: string;
	createdAt: string;
	updatedAt: string;
}

export interface AchAccount {
	id?: string;
	fiId?: string;
	description?: string;
	referenceCode?: string;
	achFiRoutingNumber?: string;
	achAccountNumber?: string;
	achTransactionType?: number;
	achImmediateOrigin?: string;
	achImmediateOriginName?: string;
	achImmediateDestination?: string;
	achImmediateDestinationName?: string;
	achCompanyIdentification?: string;
	achCompanyName?: string;
	achCompanyDiscretionaryData?: string;
	achFileIdModifier?: string;
	pmtHubId?: string;
}

export enum AllocationType {
	'Principal' = 1,
	'Interest' = 2,
	'Escrow' = 3,
	'Late charges' = 4,
	'Unapplied' = 5,
	'Liquidation' = 6,
	'Write off' = 7,
	'Additional principal' = 8,
	'Other fees' = 9,
	'Paid to take-back loan' = 10,
	'Take-back loan reversal' = 11,
	'Take-back payment interest' = 100,
	'Take-back payment principal' = 101,
	'Take-back payment fees' = 102,
	'Take-back payment additional principal' = 103,
	'Take-back payment charge off balance' = 104,
	'Take-back unpaid interest' = 105,
	'Take-back non-loan items' = 106,
	'Take-back overpayment' = 107,
	'Take-back SCRA interest refund' = 108,
}

export interface Allocation {
	type: AllocationType;
	amount: string;
}

export enum TransactionStatus {
	PENDING = 1,
	POSTED = 2,
	CANCELLED = 10,
}

export enum TransactionType {
	Fee = 1,
	Reversal = 2,
	Payment = 3,
	Escrow = 4,
	Payoff = 5,
	Other = 6,
}
export interface Transaction {
	id?: number;
	effectiveDate: string;
	status: TransactionStatus;
	statusDisplay?: string;
	amount: string;
	endingPrincipalBalance: string | null;
	type: TransactionType;
	typeDisplay?: string;
	allocations: Allocation[];
}

export interface Fee {
	description: string;
	balance: string;
	date: string;
}

export interface TransactionResponse {
	transactions: Transaction[];
	allocationTypes: AllocationType[];
	originationDate: string;
	maturityDate: string;
}

export class AchService extends FBaseService {
	serverErrorHandler?: () => Promise<void>;

	hanldeServerError(): Promise<void> {
		if (this.serverErrorHandler) {
			return this.serverErrorHandler();
		}
		return super.hanldeServerError();
	}

	setServerErrorHandler(handler: () => Promise<void>): void {
		this.serverErrorHandler = handler;
	}

	getAchAccounts(fiId: string):
	Promise<AxiosResponse<ResponseEnvelopeCollection<AchAccount>>> {
		return this.apiClient.get(`/api/v1/kloans-fi/fi/${fiId}/settlement-account`);
	}

	getAchAccount(fiId: string, accountId: string):
	Promise<AxiosResponse<ResponseEnvelope<AchAccount>>> {
		return this.apiClient.get(`/api/v1/kloans-fi/fi/${fiId}/settlement-account/${accountId}`);
	}

	addAchAccount(fiId: string, data: AchAccount):
	Promise<AxiosResponse<ResponseEnvelope<AchAccount>>> {
		return this.apiClient.post(`/api/v1/kloans-fi/fi/${fiId}/settlement-account`, data);
	}

	updateAchAccount(fiId: string, accountId: string, data: AchAccount):
	Promise<AxiosResponse<ResponseEnvelope<AchAccount>>> {
		return this.apiClient.put(`/api/v1/kloans-fi/fi/${fiId}/settlement-account/${accountId}`, data);
	}

	deleteAchAccount(fiId: string, accountId: string):
	Promise<AxiosResponse<string>> {
		return this.apiClient.delete(`/api/v1/kloans-fi/fi/${fiId}/settlement-account/${accountId}`);
	}
}
