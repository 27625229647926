
import KPage from '@/components/KPage.vue';
import { Component, Inject, Prop } from 'vue-property-decorator';
import AuditLogTable from '@/components/AuditLogTable.vue';
import { handleErrorResponse } from '@/utils/xhr';
import BaseLoanPage from '@/pages/loan/BaseLoanPage';
import { AuditLog } from '@/services/types/AuditLog';
import { Formatters } from '@/utils/formatters';

@Component({
	components: {
		AuditLogTable,
		KPage,
	},
})
export default class LoanAuditLogPage extends BaseLoanPage {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	consumerId!: string;

	title: string = 'Audit Log';

	search: string = '';

	loading: boolean = true;

	total: Number = 0;

	async created(): Promise<void> {
		this.loading = true;
		await this.getConsumer();
		this.loading = false;
		await this.getAuditLogs(true);
	}

	logs: AuditLog[] = [];

	loadingLogs: boolean = false;

	newPageRequest(page: Number) {
		this.getAuditLogs(true, page);
	}

	async getAuditLogs(force: boolean = false, page: Number = 1): Promise<AuditLog[]> {
		if (force || this.logs.length === 0) {
			try {
				this.loadingLogs = true;
				const response = await this.loanSvc.getAuditLogs(this.fiId, this.loanId, page);
				this.logs = response.data.data;
				this.total = response.data.paging.size;
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			} finally {
				this.loadingLogs = false;
			}
		}

		return this.logs;
	}

	get canView(): boolean {
		return this.canRead('vkloans');
	}
}
