
import {
	Component, Inject, Prop, Vue,
} from 'vue-property-decorator';

import { DataTableHeader } from 'vuetify';
import { Formatters } from '@/utils/formatters';
import VueJsonToCsv from 'vue-json-to-csv';

import { PaymentSchedule } from '@/services/types/loan/PaymentSchedule';

@Component({
	enums: {},
	components: {
		VueJsonToCsv,
	},
})
export default class HistoricalTransactionsTable extends Vue {
	@Inject('format')
	format!: Formatters;

	@Prop({ required: true })
	title!: string;

	@Prop({ required: true })
	kasasaLoanNumber!: string;

	@Prop({ required: true })
	items!: PaymentSchedule[];

	@Prop({ default: false })
	hideDefaultFooter!: boolean;

	sortDesc: boolean[] = [];

	itemLabels = {
		period: { title: 'Period' },
		startDate: { title: 'Start Date' },
		endDate: { title: 'End Date' },
		statementDate: { title: 'Statement Date' },
		lateFeeAssessmentDate: { title: 'Late Fee Date' },
		periodDays: { title: 'Days in Period' },
		paymentAmount: { title: 'Payment Amount' },
		startPrincipalBalance: { title: 'Starting Principal Balance' },
		endPrincipalBalance: { title: 'Ending Principal Balance' },
		interestPaid: { title: 'Interest Due' },
		principalPaid: { title: 'Principal Due' },
		endPrincipalPaid: { title: 'Total Principal Paid' },
		paidToDate: { title: 'Total Payments' },
		satisfyingTransactionId: { title: 'Satisfying Transaction Id' },
	};

	headers: DataTableHeader[] = [
		{
			text: 'Period',
			value: 'period',
		},
		{
			text: 'Start Date',
			value: 'startDate',
		},
		{
			text: 'End Date',
			value: 'endDate',
		},
		{
			text: 'Statement Date',
			value: 'statementDate',
		},
		{
			text: 'Late Fee Date',
			value: 'lateFeeAssessmentDate',
		},
		{
			text: 'Days in Period',
			value: 'periodDays',
		},
		{
			text: 'Payment Amount',
			value: 'paymentAmount',
		},

		{
			text: 'Starting Principal Balance',
			value: 'startPrincipalBalance',
		},

		{
			text: 'Ending Principal Balance',
			value: 'endPrincipalBalance',
		},

		{
			text: 'Interest Due',
			value: 'interestPaid',
		},

		{
			text: 'Principal Due',
			value: 'principalPaid',
		},
		{
			text: 'Total Principal Paid',
			value: 'endPrincipalPaid',
		},
		{
			text: 'Total Payments',
			value: 'paidToDate',
		},
		{
			text: 'Satisfying Transaction',
			value: 'satisfyingTransactionId',
		},
	];

	goToTransactionDetails(transactionId: string): void {
		this.$emit('go-transaction-details', transactionId);
	}
}
