// The feeMethods property returned with the config object is a string of comma separated values.
// This should be an array instead, once the API is fixed, we can remove this interface.
export interface SkipAPayRawConfig {
	fiId: number;
	feeMethods: string;
	feeAmount: string;
}

export enum SkipAPayFeeMethod {
	DEBIT_PRINCIPAL = 'debit_principal',
	LINKED_ACCOUNT = 'linked_account',
}

export interface SkipAPayConfig {
	fiId: number | string;
	feeMethods: string[] | SkipAPayFeeMethod[];
	feeAmount: string;
}

export interface SkipAPayMod {
	feeAmount: string;
	feeMethod: string;
	disableAutoPay: boolean;
	draftDateOne: string;
	draftDateTwo: string;
}

const skipAPayFeeMethodLabel = (method: SkipAPayFeeMethod): string => {
	switch (method) {
		case SkipAPayFeeMethod.DEBIT_PRINCIPAL:
			return 'Debit Principal';
		case SkipAPayFeeMethod.LINKED_ACCOUNT:
			return 'Linked Account';
		default:
			return 'N/A';
	}
};

export {
	skipAPayFeeMethodLabel,
};
