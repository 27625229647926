
import KCard from '@/components/KCard.vue';
import {
	Component, Inject, Prop, Vue,
} from 'vue-property-decorator';
import { KReadOnly, KText } from '@kasasa/fbase-components';
import { Asset } from '@/services/types/loan/Loan';
import { Validations } from 'vuelidate-property-decorators';
import { RuleDecl } from 'vue/types/options.d';
import { RequiredValidation } from '@kasasa/kloans-common-ui/dist/validations';
import { minValue, numeric, required } from 'vuelidate/lib/validators';
import { isNullOrEmpty } from '@/utils/tools';

@Component({
	components: {
		KCard,
		KReadOnly,
		KText,
	},
})
export default class CollectionGeneralCard extends Vue {
	private isEditing: boolean = false;

	private originalAsset: Asset = {};

	@Prop({ required: true })
	data!: Asset;

	@Prop({ required: true })
	canModifyLoan!: Boolean;

	@Inject('format')
	format!: object;

	@Validations()
	// eslint-disable-next-line class-methods-use-this
	validations(): RuleDecl {
		return {
			loanAsset: {
				vehicleType: RequiredValidation.validations(),
				make: RequiredValidation.validations(),
				model: RequiredValidation.validations(),
				year: {
					required,
					numeric,
					minValue: minValue(1900),
				},
				mileage: {
					required,
					numeric,
					minValue: minValue(0),
				},
				vin: RequiredValidation.validations(),
			},
		};
	}

	created() {
		this.originalAsset = { ...this.data };

		if (this.assetNeedsEdition()) {
			this.isEditing = true;
		}
	}

	get vehicleTypeErrorMessages(): string[] {
		return RequiredValidation.errors(
			this,
			'loanAsset.vehicleType',
			'Vehicle Type',
		);
	}

	get makeErrorMessages(): string[] {
		return RequiredValidation.errors(this, 'loanAsset.make', 'Make');
	}

	get modelErrorMessages(): string[] {
		return RequiredValidation.errors(this, 'loanAsset.model', 'Model');
	}

	get yearErrorMessages(): string[] {
		if (this.$v?.loanAsset?.year && !this.$v.loanAsset.year.numeric) {
			return ['Year must be a number.'];
		}

		if (this.$v?.loanAsset?.year && !this.$v.loanAsset.year.minValue) {
			return [
				`Year must be at least ${this.$v?.loanAsset.year.$params.minValue.min}.`,
			];
		}

		return RequiredValidation.errors(this, 'loanAsset.year', 'Year');
	}

	get mileageErrorMessages(): string[] {
		if (this.$v?.loanAsset?.mileage && !this.$v.loanAsset.mileage.numeric) {
			return ['Mileage must be a number.'];
		}

		if (
			this.$v?.loanAsset?.mileage?.minValue
			&& !this.$v.loanAsset.mileage.minValue
		) {
			return [
				`Mileage must be at least ${this.$v.loanAsset?.mileage.$params.minValue.min}.`,
			];
		}

		return RequiredValidation.errors(this, 'loanAsset.mileage', 'Mileage');
	}

	get vinErrorMessages(): string[] {
		return RequiredValidation.errors(this, 'loanAsset.vin', 'VIN');
	}

	get isShowingCollateralAsset(): boolean {
		return !this.isEditing;
	}

	get loanAsset(): Asset {
		return this.data;
	}

	saveCollateralAsset() {
		this.$emit('save-collateral', this.loanAsset);
		this.isEditing = false;
	}

	cancelAssetEdition(): void {
		this.$v.loanAsset.$reset();
		Object.assign(this.loanAsset, this.originalAsset);
		this.isEditing = false;
	}

	assetNeedsEdition(): boolean {
		return !Object.values(this.data).filter((val) => !isNullOrEmpty(val))
			.length;
	}
}
