import { render, staticRenderFns } from "./BorrowerSection.vue?vue&type=template&id=160ee57f&scoped=true&"
import script from "./BorrowerSection.vue?vue&type=script&lang=ts&"
export * from "./BorrowerSection.vue?vue&type=script&lang=ts&"
import style0 from "./BorrowerSection.vue?vue&type=style&index=0&id=160ee57f&prod&lang=scss&scoped=true&"
import style1 from "./BorrowerSection.vue?vue&type=style&index=1&id=160ee57f&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "160ee57f",
  null
  
)

export default component.exports