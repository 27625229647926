
import KPage from '@/components/KPage.vue';
import KCard from '@/components/KCard.vue';
import { Component } from 'vue-property-decorator';
import { KReadOnly } from '@kasasa/fbase-components';
import BaseLoanPage from '@/pages/loan/BaseLoanPage';
import { handleErrorResponse } from '@/utils/xhr';
import CollectionGeneralCard from '@/components/loan/collections/CollectionGeneralCard.vue';
import CommentsCard from '@/components/loan/comments/CommentsCard.vue';
import { Collection } from '@/services/types/loan/Collection';
import BorrowerCard from '@/components/loan/BorrowerCard.vue';
import { BankruptcyStatus } from '@/services/types/loan/Bankruptcy';
import OverviewBankruptcyCard from '@/components/loan/OverviewBankruptcyCard.vue';
import { LoanType, Asset } from '@/services/types/loan/Loan';
import CollateralAssetPayload, {
	CollateralAssetType,
} from '@/services/types/loan/Collateral';
import {
	CommentReferenceTypes, Comment,
} from '@/services/types/loan/Comment';
import CollateralAssetCard from '@/components/loan/collateral/CollateralAssetCard.vue';
import CommentsService from '@/services/CommentsService';

@Component({
	components: {
		KPage,
		KCard,
		KReadOnly,
		CollectionGeneralCard,
		BorrowerCard,
		OverviewBankruptcyCard,
		CommentsCard,
		CollateralAssetCard,
	},
	enums: {
		CommentReferenceTypes,
	},
})
export default class LoanCollectionsPage extends BaseLoanPage {
	title = 'Collections';

	loading: boolean = true;

	private processingCollateral: boolean = false;

	data: Collection = {} as Collection;

	commentItems: Comment[] = [];

	commentsSvc: CommentsService = new CommentsService(this.$store);

	async getCollectionDetails(force: boolean = false): Promise<any> {
		if (force || !this.data) {
			try {
				this.loading = true;
				const response = await this.loanSvc.getCollectionDetails(
					this.fiId,
					this.consumerId,
					this.loanId,
				);

				// set blank objects for collateralAsset and collateralAsset.asset if undefined
				// allows for CollateralAssetCard to show if no data is returned from API for those properties
				this.data = {
					...response.data.data,
					...{
						collateralAsset: {
							...response.data.data.collateralAsset,
							asset: {
								...response.data.data.collateralAsset?.asset,
							},
						},
					},
				};
			} catch (error) {
				handleErrorResponse((error as any).response, this);
			} finally {
				this.loading = false;
			}
		}

		return this.data;
	}

	async getCollectionComments(): Promise<void> {
		try {
			const response = await this.commentsSvc.getComments(this.loanId);
			// only want collection comments
			const filteredResults = response.data.data.filter(
				(item) => item.referenceType === CommentReferenceTypes.COLLECTION,
			);

			this.commentItems = filteredResults;
		} catch (error) {
			handleErrorResponse((error as any).response, this);
		}
	}

	async created(): Promise<void> {
		await Promise.all([
			await this.getBorrowers(),
			await this.getBankruptcies(),
			await this.getConsumer(),
			await this.getCollectionDetails(true),
			await this.getCollectionComments(),
		]);

		this.loading = false;
	}

	get canView(): boolean {
		return this.canRead('vkloans');
	}

	get isAutoLoan(): boolean {
		return this.loan?.general?.loanTypeId === LoanType.VEHICLE;
	}

	get displayBankruptcies(): boolean {
		if (this.bankruptcies.length) {
			return (
				this.bankruptcies.length > 0
				&& this.bankruptcies[0].status.toLowerCase()
					=== BankruptcyStatus.petitioned.toLowerCase()
			);
		}

		return false;
	}

	async saveCollateralAsset(asset: Asset): Promise<void> {
		const payload: CollateralAssetPayload = {
			type: CollateralAssetType.VEHICLE_ASSET,
			asset,
		};

		try {
			await this.loanSvc.setLoanCollateralAsset(
				this.consumerId,
				this.loanId,
				payload,
			);
			await this.fetchAndSetLoan(true);
		} catch (error) {
			await handleErrorResponse((error as any).response, this);
		}
	}
}
