import { Component, Prop } from 'vue-property-decorator';
import {
	MenuHeading,
	MenuItem,
} from '@kasasa/fbase-components/lib';
import BasePage from '@/pages/BasePage';

@Component
export default class BaseConsumerPage extends BasePage {
	@Prop({ required: true })
	clientId!: string;

	@Prop({ required: true })
	fiId!: string;

	@Prop()
	consumerId!: string;

	loadingLoans: boolean = false;

	// Shared menus for loan pages.
	menuItems: (MenuItem | MenuHeading)[] = [
		{ icon: '', label: 'Overview', to: { name: 'consumer-overview', params: { clientId: this.clientId, consumerId: this.consumerId } } },
		{ icon: '', label: 'Linked Accounts', to: { name: 'consumer-linked-accounts', params: { clientId: this.clientId, consumerId: this.consumerId } } },
		{ icon: '', label: 'Audit Log', to: { name: 'consumer-audit-log', params: { clientId: this.clientId, consumerId: this.consumerId } } },
	];
}
